import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import scssVariables from "shared/src/sass/jsExports.module.scss";

import ProfileCard from "shared/src/components/Profile/ProfileCard/ProfileCard";
import ProfileMenu from "shared/src/components/Profile/ProfileMenu/ProfileMenu";
import { EditProfileScreen } from "./EditProfileScreen";
import topography from "../assets/svg/topography.svg";

import { selectUser } from "@rtslabs/field1st-fe-common/dist/main/redux";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { Logout } from "shared/src/components/Logout/Logout";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../redux";

import styles from "./ProfileScreen.module.scss";
import { Action } from "redux";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import {
  setAssessmentsFilters,
  setEventsFilters,
} from "../redux/filters/actions";

export const ProfileScreen = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const isTablet = useMediaQuery({
    maxWidth: scssVariables.maxTablet,
  });

  const userProfile = useSelector(selectUser)!; //logged in, always going to be populated

  const groupLabel = useMemo(() => {
    if (!userProfile.clientGroups)
      return {
        mobile: "",
        desktop: "",
      };

    const primaryGroup = userProfile.clientGroups.find(
      (x) => x.id === userProfile.primaryGroupId
    );

    if (primaryGroup && primaryGroup.name) {
      const count = userProfile.clientGroups.length - 1;
      const other = count === 1 ? "other" : "others";
      const countLabel = count > 0 ? ` + ${count} ${other}` : "";
      const otherGroups = userProfile.clientGroups
        .filter((x) => x.id !== userProfile.primaryGroupId)
        .map((y) => y.name);
      const otherGroupsLabel =
        otherGroups.length > 0 ? `, ${otherGroups.join(", ")}` : undefined;

      return {
        mobile: `${primaryGroup.name}` + countLabel,
        desktop: otherGroupsLabel
          ? `${primaryGroup.name}` + otherGroupsLabel
          : `${primaryGroup.name}`,
      };
    }

    return {
      mobile: "",
      desktop: "",
    };
  }, [userProfile.clientGroups, userProfile.primaryGroupId]);

  // Group config terms
  const supervisorTerm = conjugateTerm({
    term: "supervisor",
    role: "noun",
    modifier: "plural",
    fallback: "Supervisor",
  });

  function handleLogout() {
    dispatch(setAssessmentsFilters({}));
    dispatch(setEventsFilters(undefined));
    dispatch(CoreRedux.logout());
  }

  return (
    <>
      <ProfileCard
        name={userProfile?.name}
        email={userProfile?.email}
        groupLabel={groupLabel}
        headerBackground={topography}
        supervisor={userProfile.supervisor?.fullName}
        //        adfsEnabled={userProfile?.adfsEnabled} // will Storm use ADFS?
        supervisorTerm={supervisorTerm}
      />
      {isTablet && <ProfileMenu />}
      {!isTablet && <EditProfileScreen />}
      {isTablet && (
        <Logout className={styles.logout_tablet} onLogOut={handleLogout} />
      )}
    </>
  );
};
