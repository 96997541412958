import React from "react";

import scssVariables from "../../../sass/jsExports.module.scss";
import { Icon } from "../../Icon/Icon";

import styles from "./EditProfile.module.scss";

const LockedWarning = () => {
  return (
    <div className={styles.lockedWarning}>
      <div className={styles.lockIconWrapper}>
        <Icon
          type="lock"
          color={scssVariables.darkGrey}
          className={styles.lockIcon}
        />
      </div>
      <div>
        <p className={styles.lockedWarningText}>
          Locked items cannot be manually edited. Contact your system
          administrator to make changes.
        </p>
      </div>
    </div>
  );
};

export default LockedWarning;
