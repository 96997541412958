import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import {
  AuthenticatedRoute,
  AuthenticatedRouteProps,
} from "shared/src/components/routes/AuthenticatedRoute";
import { EventView } from "./Events/EventView/EventView";
import {
  ALL_ROLES,
  STORM_CENTER_MANAGER_ROLES,
  ALL_STORM_ROLES,
} from "../permissions/permissionSets";
import { API, CoreRedux, CoreRole } from "@rtslabs/field1st-fe-common";
import AppNavigation from "./AppNavigation";
import { Events } from "./Events/Events";
import EditEventScreen from "./Events/EditEvent/EditEventScreen";
import DashboardScreen from "./DashboardScreen";
import FormList from "shared/src/components/clientAdmin/formList/FormList";
import { FormBuilderScreen } from "./FormBuilderScreen";
import { FormBuilderDebugScreen } from "./FormBuilderDebugScreen";
import { ProfileScreen } from "./ProfileScreen";
import { EditProfileScreen } from "./EditProfileScreen";
import AssessmentsScreen from "./AssessmentsScreen";
import { AssessmentWrapper } from "./Assessments/Assessment/AssessmentWrapper";
import { ReviewAssessmentsScreen } from "./ReviewAssessmentsScreen";
import Loader from "shared/src/components/Loader/Loader";
import { StormHeader } from "./navigation/StormHeader";
import Privacy from "shared/src/components/privacy/Privacy";
import { NoPermissions } from "shared/src/components/routes/NoPermissions";
import { RootState } from "../redux";
import { formTemplateWriteRoles } from "shared/src/components/routes/constants/permissionSets";

type AuthedRouteProps = Pick<
  AuthenticatedRouteProps,
  "Component" | "accessRoles"
>;

const AuthedRoute: FC<AuthedRouteProps> = ({ Component, accessRoles }) => {
  // Get location
  const location = useLocation();

  // Get user roles
  const userRoles = API.Environment.getRoles();

  // Is user authenticated
  const isAuthenticated = useSelector((state: RootState): boolean => {
    return !!(state.auth.token && state.user?.data?.participant.id);
  });

  // build the redirect URL for users to return to after authentication
  let redirectUrl = "";
  const pattern = /\/assessment\/(\d)+/; // Matches `/assessment/:id` route
  const routeMatches = pattern.test(location.pathname);
  if (routeMatches) {
    redirectUrl = location?.pathname;
  }

  return (
    <AuthenticatedRoute
      Component={Component}
      accessRoles={accessRoles}
      redirectPath={redirectUrl}
      userRoles={userRoles}
      isAuthenticated={isAuthenticated}
    />
  );
};

const Layout = () => {
  return (
    <>
      <StormHeader />
      <AppNavigation>
        <Outlet />
      </AppNavigation>
    </>
  );
};

const AuthenticatedRoutes = () => {
  const user = useSelector(CoreRedux.selectUser);

  // Is user authenticated with an unexpired token
  if (!API.Environment.isTokenValid()) {
    // build the redirect URL for users to return to after authentication
    let redirectUrl = location?.pathname || "";

    return <Navigate replace state={{ redirect: redirectUrl }} to="/login" />;
  }

  return (
    <Loader loading={!user}>
      <Routes>
        <Route
          element={
            <AuthedRoute
              Component={AssessmentWrapper}
              accessRoles={ALL_STORM_ROLES}
            />
          }
          path="/assessment/:id"
        />
        <Route
          element={
            <AuthedRoute
              Component={FormBuilderScreen}
              accessRoles={[CoreRole.ROLE_SUPER_ADMIN]} // temp until we are ready to release
              //   accessRoles={STORM_CENTER_MANAGER_ROLES}
            />
          }
          path="/forms/form/:id"
        />
        <Route
          element={
            <AuthedRoute
              Component={FormBuilderDebugScreen}
              accessRoles={[CoreRole.ROLE_SUPER_ADMIN]} // temp until we are ready to release
              //   accessRoles={STORM_CENTER_MANAGER_ROLES}
            />
          }
          path="/forms/form/debug/:id/:config"
        />
        <Route
          element={
            <AuthedRoute
              Component={ReviewAssessmentsScreen}
              accessRoles={ALL_STORM_ROLES}
            />
          }
          path="/assessment/review/:id"
        />
        <Route element={<Layout />} path="/">
          <Route
            element={
              <AuthedRoute Component={Events} accessRoles={ALL_STORM_ROLES} />
            }
            path="events"
          />
          <Route
            element={
              <AuthedRoute
                Component={EditEventScreen}
                accessRoles={ALL_STORM_ROLES}
              />
            }
            path="events/new"
          />
          <Route
            element={
              <AuthedRoute
                Component={EventView}
                accessRoles={ALL_STORM_ROLES}
              />
            }
            path="events/:id"
          />
          <Route
            element={
              <AuthedRoute
                Component={EditEventScreen}
                accessRoles={ALL_STORM_ROLES}
              />
            }
            path="events/:id/edit"
          />
          <Route
            element={
              <AuthedRoute Component={ProfileScreen} accessRoles={ALL_ROLES} />
            }
            path="profile"
          />
          <Route
            element={
              <AuthedRoute
                Component={EditProfileScreen}
                accessRoles={ALL_ROLES}
              />
            }
            path="profile/edit"
          />
          <Route
            element={
              <AuthedRoute
                Component={AssessmentsScreen}
                accessRoles={ALL_STORM_ROLES}
              />
            }
            path="assessments"
          />
          <Route
            element={
              <AuthedRoute
                Component={FormList}
                accessRoles={[CoreRole.ROLE_SUPER_ADMIN]} // temp until we are ready to release
                //   accessRoles={STORM_CENTER_MANAGER_ROLES}
              />
            }
            path="forms"
          />
          <Route
            element={
              <AuthedRoute
                Component={DashboardScreen}
                accessRoles={ALL_ROLES}
              />
            }
            index
          />

          {/* Privacy Policy */}
          <Route
            element={
              <AuthedRoute Component={Privacy} accessRoles={ALL_ROLES} />
            }
            path="privacy"
          />
        </Route>

        {/* No Permissions */}
        <Route
          element={
            <AuthedRoute Component={NoPermissions} accessRoles={ALL_ROLES} />
          }
          path="no-permissions"
        />
      </Routes>
    </Loader>
  );
};

export default AuthenticatedRoutes;
