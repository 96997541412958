import React, { Suspense } from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import ReactDOM from "react-dom";
import "./index.css";
import "./sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "./assets/icomoon/style.css";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import Loader from "shared/src/components/Loader/Loader";
import { PersistGate } from "redux-persist/integration/react";
import { getApiHost } from "shared/src/api/network";
import { API } from "@rtslabs/field1st-fe-common";
import "shared/src/i18n";
import { ThemeProvider } from "styled-components";
import { animations } from "shared/src/themes/animations";
import COLORS from "shared/src/themes/colors";

const devHost = "https://sat.api.dev.powerfields-dev.io";
// const devHost = "http://localhost:8080";
const host =
  window.location.hostname.indexOf("localhost") >= 0 ? devHost : getApiHost();

API.Environment.apiRoot = host + "/api";

const MUItheme = createTheme();
const theme = {
  animations,
  colors: COLORS.lightTheme,
  masterColors: COLORS.masterColors,
};

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={MUItheme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate
            loading={<Loader loading={true} />}
            persistor={persistor}
          >
            <Suspense fallback={<Loader loading={true} />}>
              <App />
            </Suspense>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
