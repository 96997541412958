import moment from "moment";
import {
  AssessmentState,
  EventWithDocStatsVM,
} from "@rtslabs/field1st-fe-common/storm";
import { floor } from "lodash";

export const DATE_FORMAT = "MMM Do, YYYY";

export function fmtDate(date?: string | null, fallback?: string) {
  if (!date && fallback) return fallback;
  return moment(date).format(DATE_FORMAT);
}

export interface EventProgressProps {
  progressPercentage: string;
  activePercentage: string;
  submittedPercentage: string;
  verified: number;
  incomplete: number;
  total: number;
  active: number;
  submitted: number;
}

/**
 * Returns number of assessments in "STORM_CENTER_VERIFIED" state,
 * total number of assessments, and calculated percentage
 */
export function calcEventProgress(
  event: EventWithDocStatsVM
): EventProgressProps {
  const verified =
    event.nrOfDocumentsPerState[AssessmentState.STORM_CENTER_VERIFIED] ?? 0;
  const active =
    (event.nrOfDocumentsPerState[AssessmentState.UNASSIGNED] ?? 0) +
    (event.nrOfDocumentsPerState[AssessmentState.ASSIGNED] ?? 0) +
    (event.nrOfDocumentsPerState[AssessmentState.IN_PROGRESS] ?? 0);
  const submitted =
    (event.nrOfDocumentsPerState[AssessmentState.ASSESSOR_COMPLETE] ?? 0) +
    (event.nrOfDocumentsPerState[AssessmentState.IN_REVIEW] ?? 0);
  const total = event.totalNumberOfAssessments;
  const incomplete = total - verified;
  // https://rtslabs.atlassian.net/wiki/spaces/DSA/pages/1387561160/Web+app+Dashboard#W.DSH.0130
  // Based on the question asked if total is 0. Set to 100% as "user feel-good" per Alan ;)
  const progressPercentage = `${
    total ? floor((verified / total) * 100) : 100
  }%`;
  const activePercentage = `${total ? floor((active / total) * 100) : 100}%`;
  const submittedPercentage = `${
    total ? floor((submitted / total) * 100) : 100
  }%`;

  return {
    progressPercentage,
    activePercentage,
    submittedPercentage,
    verified,
    incomplete,
    active,
    submitted,
    total,
  };
}
