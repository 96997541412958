import React, { FC, useState } from "react";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import {
  SortableColumn,
  TableHead,
} from "shared/src/components/TableUI/TableHead/TableHead";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import {
  PageOfStormDocumentExtensionVm,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import styles from "./AssessmentsTable.module.scss";
import Loader from "shared/src/components/Loader/Loader";
import moment from "moment";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import AssessmentsActionButtons from "./AssessmentsActionButtons";
import { AssessorCellContent } from "./AssessorCellContent";
import NoSearchResults from "../common/NoSearchResults";
import { AssessmentActionMenu } from "./AssessmentActionMenu/AssessmentActionMenu";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import { SortParams, Storm } from "@rtslabs/field1st-fe-common";
import { AssignAssessmentDrawer } from "./AssignAssessmentDrawer/AssignAssessmentDrawer";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { AssessmentStateDisplay } from "./AssessmentStateDisplay";

interface Props {
  assessments?: PageOfStormDocumentExtensionVm;
  assessmentsRefresh: () => void;
  loading: boolean;
  onPaginate: (page: number, size?: number) => void;
  onClearSearch: () => void;
  handleSortOrderChange: (column: SortableColumn, sortOrder: SortOrder) => void;
  onViewAssessment: (id: number) => void;
  currSort?: SortParams;
  exportAssessmentsParams: Storm.API.StormDocumentRequestParameters;
}

export const AssessmentsTable: FC<Props> = ({
  assessments,
  assessmentsRefresh,
  loading,
  onPaginate,
  onClearSearch,
  handleSortOrderChange,
  onViewAssessment,
  currSort,
  exportAssessmentsParams,
}) => {
  const [selected, setSelected] = useState<StormDocumentExtensionVm[]>([]);
  const [selectedAssessment, setSelectedAssessment] =
    useState<StormDocumentExtensionVm | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isAssignDrawerOpen, setIsAssignDrawerOpen] = useState<boolean>(false);
  const activeAssessments = assessments?.content.filter((a) => a.event.active);

  function handleSelectAll(checked: boolean) {
    if (checked && activeAssessments) {
      setSelected(activeAssessments);
    } else {
      setSelected([]);
    }
  }

  function changePage(page: number) {
    onPaginate(page);
  }

  function changePageSize(size: number) {
    onPaginate(0, size);
  }

  const handleClickAssign = (assessment: StormDocumentExtensionVm) => {
    setSelected([assessment]);
    setIsAssignDrawerOpen(true);
  };

  return (
    <>
      <div className={styles.actionBtnsContainer}>
        <h2>Assessments</h2>
        <AssessmentsActionButtons
          onAssign={() => setIsAssignDrawerOpen(true)}
          selected={selected}
          setSelected={setSelected}
          assessmentsRefresh={assessmentsRefresh}
        />
      </div>
      <TableSummary
        pageSize={assessments?.size}
        currentPage={assessments?.number}
        totalElements={assessments?.totalElements}
        ofWhat="assessments"
        exports={["print", "xls", "csv"]}
        onExport={Storm.API.downloadAssessments}
        exportParams={exportAssessmentsParams}
      />
      <Loader loading={loading} overlay={true}>
        {assessments && (
          <Table>
            <TableHead
              columns={[
                {
                  id: "check",
                  isUnsortable: true,
                  label: (
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={activeAssessments?.every((a) =>
                        selected.find((s) => s.id === a.id)
                      )}
                    />
                  ),
                  className: styles.noPrint,
                },
                {
                  id: "workLocation.region",
                  isUnsortable: false,
                  label: "Region",
                },
                {
                  id: "workLocation.nickname",
                  isUnsortable: false,
                  label: "Supervisor",
                },
                {
                  id: "substation",
                  isUnsortable: false,
                  label: "Substation",
                },
                {
                  id: "assessor",
                  isUnsortable: false,
                  label: "Assessor",
                },
                {
                  id: "statuses",
                  isUnsortable: false,
                  label: "Status",
                },
                {
                  id: "event",
                  isUnsortable: false,
                  label: "Event",
                },
                {
                  id: "document.lastModifiedDate",
                  isUnsortable: false,
                  label: "Last Updated",
                },
                {
                  id: "actionMenu",
                  isUnsortable: true,
                  label: "",
                  className: styles.noPrint,
                },
              ]}
              handleSortOrderChange={handleSortOrderChange}
              initialSort={
                currSort && {
                  columnId: currSort[0],
                  order: currSort[1] === "asc" ? "ascending" : "descending",
                }
              }
            />
            <tbody>
              {assessments.content.length ? (
                assessments.content.map((assessment, index) => {
                  const isCancelled =
                    assessment.assessmentStatus.currentState ===
                    Storm.AssessmentState.CANCELLED;
                  return (
                    <TableRow
                      key={"row-" + index}
                      onClick={() => onViewAssessment(assessment.id)}
                    >
                      <TableCell className={styles.noPrint}>
                        {assessment.event.active && !isCancelled && (
                          <Checkbox
                            checked={
                              !!selected.find((s) => s.id === assessment.id)
                            }
                            onChange={(checked) => {
                              if (checked) {
                                setSelected([...selected, assessment]);
                              } else {
                                setSelected(
                                  selected.filter((s) => s.id !== assessment.id)
                                );
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>{assessment.workLocation.region}</TableCell>
                      <TableCell>{assessment.workLocation.nickname}</TableCell>
                      <TableCell>{assessment.workLocation.name}</TableCell>
                      <TableCell>
                        <AssessorCellContent
                          assessment={assessment}
                          handleClickAssign={handleClickAssign}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={styles.statusContainer}>
                          <AssessmentStateDisplay
                            actionRequired={assessment.actionRequired}
                            currentState={
                              assessment.assessmentStatus.currentState
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            color: assessment.event.active
                              ? scssVariables.success
                              : scssVariables.error,
                          }}
                        >
                          {assessment.event.eventName}
                        </span>
                      </TableCell>
                      <TableCell>
                        {assessment.document?.lastModifiedDate && (
                          <span>
                            {moment(
                              assessment.document.lastModifiedDate
                            ).format("M/DD/YYYY HH:mm")}
                          </span>
                        )}
                      </TableCell>
                      <TableCell className={styles.noPrint}>
                        <ActionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAssessment(assessment);
                            setOpen(true);
                          }}
                          label="View Assessment Details"
                          shouldShowTooltip={false}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <NoSearchResults onClear={onClearSearch} />
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        )}
        {!!selectedAssessment && (
          <AssessmentActionMenu
            open={open}
            assessment={selectedAssessment}
            onClose={() => {
              setSelectedAssessment(null);
              assessmentsRefresh();
              setOpen(false);
            }}
          />
        )}
        {!!isAssignDrawerOpen && (
          <AssignAssessmentDrawer
            selected={selected}
            isOpen={isAssignDrawerOpen}
            onClose={() => {
              setIsAssignDrawerOpen(false);
              assessmentsRefresh();
            }}
          />
        )}
      </Loader>
      <PaginationWrapper>
        <RowsPerPage
          pageSize={assessments?.pageable.pageSize}
          onClick={changePageSize}
        />
        <Pagination
          currentPage={assessments?.number}
          totalPages={assessments?.totalPages}
          onClick={changePage}
        />
        <BackToTop />
      </PaginationWrapper>
    </>
  );
};
