import React, { FC, useEffect, useState } from "react";
import { Storm } from "@rtslabs/field1st-fe-common";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import { AssessmentHistoryContent } from "./AssessmentHistorySidebarContent/AssessmentHistoryContent";
import styles from "./AssessmentHistorySidebar.module.scss";
import { DrawerHeaderWithClose } from "shared/src/components/Drawer/DrawerHeaderWithClose";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface Props {
  assessment: Storm.StormDocumentExtensionVm;
  open: boolean;
  onClose: () => void;
  term: string;
  isMap?: boolean;
}

const AssessmentHistorySidebar: FC<Props> = ({
  assessment,
  open,
  onClose,
  term,
  isMap,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [history, setHistory] = useState<Storm.StormDocumentHistoryVm[]>([]);

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const getAssessmentHistory = async (id: number) => {
    setError(false);
    setLoading(true);
    try {
      const history = await Storm.API.getAssessmentHistory({
        id,
        sort: "dateCreated",
        size: 100,
      });
      setHistory(history.content);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const HistoryContent = () => (
    <>
      {/* Header */}
      <DrawerHeaderWithClose
        title={`${term} History`}
        onClose={onClose}
        subtitle={"Who has been working on this assessment?"}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <div className={styles.assessmentHistoryTable}>
          <AssessmentHistoryContent
            assessment={assessment}
            history={history}
            isAssessmentHistoryDataLoading={loading}
            isAssessmentHistoryError={error}
          />
        </div>
      </DrawerContentWrapper>
    </>
  );

  useEffect(() => {
    getAssessmentHistory(assessment.id);
  }, [assessment.id]);

  return (
    <>
      {!isMap && (
        <Drawer
          anchor={isDesktop ? "right" : "bottom"}
          id="AssessmentHistory"
          onClose={onClose}
          isOpen={open}
          disableBackdrop
        >
          <HistoryContent />
        </Drawer>
      )}
      {isMap && open && <HistoryContent />}
    </>
  );
};

export default AssessmentHistorySidebar;
