import React, { FC } from "react";
import { SecondaryButton } from "shared/src/components/Button/Button";
import { useNavigate } from "react-router-dom";
import styles from "./EventView.module.scss";
import { STORM_CENTER_ROLES } from "../../../permissions/permissionSets";

interface EventActionButtonsProps {
  userRoles: string[];
  eventId: string;
}

const EventActionButtons: FC<EventActionButtonsProps> = ({
  userRoles,
  eventId,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {userRoles.some((r) => STORM_CENTER_ROLES.includes(r)) && (
        <SecondaryButton
          className={styles.editButton}
          onClick={() => navigate(`/events/${eventId}/edit`)}
        >
          Edit
        </SecondaryButton>
      )}
    </div>
  );
};

export default EventActionButtons;
