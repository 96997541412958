import React, { FC } from "react";
import { Button } from "shared/src/components/Button/Button";
import { Icon } from "../../../../shared/src/components/Icon/Icon";
import styles from "./NoSearchResults.module.scss";

interface Props {
  onClear: () => void;
}

const NoSearchResults: FC<Props> = ({ onClear }) => {
  return (
    <div data-testid="noSearchResults" className={styles.wrapper}>
      <Icon type="nothing_found" className={styles.nothingFoundIcon} />
      <p className={styles.label}>No Results Found</p>
      <p>Try adjusting your search options to find what you’re looking for.</p>
      <Button className={styles.clearButton} onClick={onClear}>
        Clear Search
      </Button>
    </div>
  );
};

export default NoSearchResults;
