import { CoreRedux, FormSectionVm, Storm } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { Modal } from "shared/src/components/Modal/Modal";
import { useSubmitAssessment } from "../Assessment/useSubmitAssessment";
import {
  findInspectedByQuestion,
  reviewerQuestion,
} from "../../../helpers/findInspectedByQuestion.helper";
import { useSelector } from "react-redux";
import { putAssessmentState } from "../../../api/putAssessmentState";

interface VerifyAssessmentProps {
  assessments: Storm.StormDocumentExtensionVm[];
  open: boolean;
  onClose: () => void;
}

export const VerifyAssessment: FC<VerifyAssessmentProps> = ({
  assessments,
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmitAssessment } = useSubmitAssessment();
  const user = useSelector(CoreRedux.selectUser);

  if (!assessments.length) return null;

  const handleVerify = async () => {
    setLoading(true);

    // Since we are bypassing IN REVIEW, we need to prefil Storm Center Reviewer
    // before setting the status from ASSESSOR COMPLETE to STORM CENTER VERIFIED
    assessments.map(async (a) => {
      const sections: FormSectionVm[] = a.document.form.sections;

      const targetQuestion = findInspectedByQuestion(
        sections,
        reviewerQuestion
      );

      if (targetQuestion) {
        const reviewerResponseIndex = a.document.responses.findIndex(
          (r) =>
            r.questionRootId === targetQuestion.rootId &&
            r.questionId === targetQuestion.id
        );

        const updateResponseItem = {
          answer: user?.fullName || user?.name || "",
          associatedId: user?.participantId,
          questionId: targetQuestion.id,
          questionRootId: targetQuestion.rootId,
          timeAnswered: new Date().toISOString(),
        };

        if (reviewerResponseIndex > -1) {
          a.document.responses[reviewerResponseIndex] = updateResponseItem;
        } else {
          a.document.responses.push(updateResponseItem);
        }

        await handleSubmitAssessment({
          document: a.document,
          assessmentId: a.id,
          workLocationId: a.workLocation.id,
          bypass: true,
        });
      }
    });

    const assessmentStatus: Storm.API.PutAssessmentStateArgs = {
      updateAssessmentStatus: {
        action: Storm.AssessmentAction.COMPLETE_REVIEW,
        stormDocumentExtensionIds: assessments.map((a) => a.id),
      },
    };

    await putAssessmentState(assessmentStatus);
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      title="Verify"
      open={open}
      handleClose={onClose}
      cancellable
      action={{
        text: "Proceed",
        loading,
        callback: handleVerify,
      }}
      content={
        <div>
          <span>{`Do you want to bypass the Review process, and move the status of this Assessment(s) to ${Storm.AssessmentStateLabel.STORM_CENTER_VERIFIED}?`}</span>
        </div>
      }
    />
  );
};
