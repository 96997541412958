import {
  DocumentSubmissionType,
  DocumentVm,
} from "@rtslabs/field1st-fe-common";
import { startCase } from "lodash";
import moment from "moment";
import React, { CSSProperties, FC, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { selectIsPreview } from "../../redux/document/documentSelectors";
import { useAppSelector } from "../../redux/reduxHooks";
import {
  errorToastOptions,
  infoToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
  updateToast,
  warningToastOptions,
} from "../Toast/Toastify";
import styles from "./DocumentToasts.module.scss";

const statusToastId = "submissionStatus";

interface DocumentToastsProps {
  documentTerm: string;
  documentsTerm: string;
  hasErrors: boolean;
  error: string;
  submissionSuccessful: boolean;
  initialFormValues: DocumentVm;
  formValues: DocumentVm;
  returnToDocs(): void;
  setError: React.Dispatch<React.SetStateAction<string>>;
  altToastTitle?: string;
}

/**
 * Spacing for toast banner that can scroll with the page between header and first form field
 * @param submissionType must be initial value, not from formik's `values`
 * @param hasErrors Errors that includes formik's errors and error from usePutDocument or equiv
 *
 * Example of setting `hasErrors` to pass in as param
 * ```ts
 * const hasErrors = const hasErrors = useMemo(
 *   () => Object.keys(errors).length > 0 || !!error,
 *   [errors, error]
 * );
 * ```
 */
export const toastScrollBannerStyle = (
  submissionType: DocumentSubmissionType,
  hasErrors: boolean
): CSSProperties => ({
  marginTop:
    [DocumentSubmissionType.SAVE_DRAFT, DocumentSubmissionType.SUBMIT].includes(
      submissionType
    ) || hasErrors
      ? 55
      : 0,
});

export const DocumentToasts: FC<DocumentToastsProps> = ({
  documentTerm,
  documentsTerm,
  hasErrors,
  error,
  submissionSuccessful,
  initialFormValues,
  formValues,
  returnToDocs,
  setError,
  altToastTitle,
}) => {
  const isPreview = useAppSelector(selectIsPreview);

  useEffect(() => {
    if (
      formValues.submissionType === "SUBMIT" &&
      submissionSuccessful &&
      !hasErrors
    ) {
      updateToast(
        `Submitted! Return to ${startCase(documentsTerm)}`,
        statusToastId,
        {
          ...successToastOptions,
          autoClose: 5000,
          onClose: returnToDocs,
        }
      );
    }
  }, [formValues, submissionSuccessful, hasErrors]);

  useEffect(() => {
    if (
      initialFormValues.submissionType === "SUBMIT" &&
      !submissionSuccessful &&
      !hasErrors
    ) {
      updateToast(
        `Submitted ${altToastTitle || formValues.title} by ${
          formValues.latestAuthorName || formValues.owner.name
        } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`,
        statusToastId,
        {
          ...successToastOptions,
          autoClose: false,
        }
      );
    }

    if (
      initialFormValues.submissionType === "SAVE_DRAFT" &&
      !submissionSuccessful &&
      !hasErrors
    ) {
      updateToast(
        `Saved (draft) ${altToastTitle || formValues.title} by ${
          formValues.latestAuthorName || formValues.owner.name
        } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`,
        statusToastId,
        {
          ...warningToastOptions,
          autoClose: false,
        }
      );
    }
  }, [formValues, hasErrors, initialFormValues, submissionSuccessful]);

  useEffect(() => {
    if (formValues.submissionType === "SAVE_DRAFT" && submissionSuccessful) {
      updateToast(
        `Saved! Return to ${startCase(documentsTerm)}`,
        statusToastId,
        {
          ...warningToastOptions,
          autoClose: 5000,
          onClose: returnToDocs,
        }
      );
    }
  }, [formValues, submissionSuccessful]);

  useEffect(() => {
    if (formValues.isRehuddle) {
      toast.info(
        <Toast
          status={ToastStatus.Info}
          message={`Rehuddle started at ${moment(formValues.dateCreated).format(
            "h:mma M/DD/YYYY"
          )}`}
        />,
        { ...infoToastOptions, toastId: "rehuddle" }
      );
    }
  }, [formValues]);

  useEffect(() => {
    if (error !== "") {
      toast.error(
        <Toast
          status={ToastStatus.Error}
          message={`Recent changes to your ${documentTerm.toLowerCase()} may not have saved to the server. Please verify intended information is complete and re-submit.`}
        />,
        {
          ...errorToastOptions,
          onClose: () => setError(""),
          toastId: "submissionError",
        }
      );
    }
  }, [error]);

  return !isPreview ? (
    <ToastContainer
      autoClose={false}
      className={styles.toastContainer}
      closeButton={false}
      position="top-left"
    />
  ) : null;
};
