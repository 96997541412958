import React, { FC } from "react";
import Filter, { FilterOption } from "./Filter";
import styles from "./Filters.module.scss";

export interface FilterSelectProps {
  options: FilterOption[];
  selected?: string | string[];
  handleSelectFilter: (value: string) => void;
  name: string;
  multiSelect?: boolean;
}

const FilterSelect: FC<FilterSelectProps> = ({
  options,
  selected,
  handleSelectFilter,
  name,
  multiSelect,
}) => {
  return (
    <select
      id={name}
      size={options.length}
      className={styles.optionsContainer}
      aria-labelledby={`${name}_label`}
      value={selected}
      onChange={(e) => handleSelectFilter(e.target.value)}
      multiple={multiSelect}
    >
      {options.map((option, index) => (
        <Filter key={index} option={option} />
      ))}
    </select>
  );
};

export default FilterSelect;
