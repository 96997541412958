import { API, Storm } from "@rtslabs/field1st-fe-common";

export const isActionAllowed = (
  selectedAssessments: Storm.StormDocumentExtensionVm[],
  action: Storm.AssessmentAction
): boolean => {
  const userRoles = API.Environment.getRoles();

  return (
    !!selectedAssessments.length &&
    selectedAssessments.every(
      (s) =>
        s.assessmentStatus?.allowedActions?.[action] &&
        userHasRequiredRole(
          userRoles,
          s.assessmentStatus.allowedActions[action]!
        )
    )
  );
};

const userHasRequiredRole = (userRoles: string[], allowedRoles: string[]) => {
  return allowedRoles.some((role) => userRoles.includes(role));
};
