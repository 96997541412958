import React from "react";
import { Drawer } from "../../../Drawer/Drawer";
import DrawerHeader from "../../../Drawer/DrawerHeader";
import PrivacyPolicyContent from "../../../privacy/PrivacyPolicyContent";
import styles from "./PrivacySidebar.module.scss";

interface PrivacySidebarProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  terms: {
    employee: {
      singular: string;
      plural: string;
    };
  };
}

const PrivacySidebar = ({
  isOpen,
  onClose,
  title = "Privacy Policy",
  terms,
}: PrivacySidebarProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      id="PrivacySidebar"
      onClose={onClose}
      isOpen={isOpen}
    >
      <>
        {/* Header */}
        <DrawerHeader title={title} onClose={onClose} />
        {/* Content */}
        <div className={styles.body}>
          <PrivacyPolicyContent terms={terms} />
        </div>
      </>
    </Drawer>
  );
};

export default PrivacySidebar;
