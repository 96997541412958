import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import scssVariables from "../../sass/jsExports.module.scss";
import { Icon } from "../Icon/Icon";

import styles from "./TitleBar.module.scss";

interface Props {
  title: string;
  onPressBack?: () => void;
}

export const TitleBar = ({ title, onPressBack }: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const navigate = useNavigate();
  const defaultOnPressBack = () => navigate(-1);

  if (isDesktop) return null;

  return (
    <header className={styles.header}>
      <button
        className={styles.backButton}
        onClick={onPressBack || defaultOnPressBack}
      >
        <Icon type="chevron-left" color={scssVariables.darkGrey} />
      </button>
      <p className={styles.title}>{title}</p>
      <div className={styles.placeholder} />
    </header>
  );
};
