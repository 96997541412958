import { WorkLocationDTO } from "@rtslabs/field1st-fe-common";
import { Storm } from "@rtslabs/field1st-fe-common/";
import { useFormikContext } from "formik";
import { orderBy } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import { SortableColumn } from "shared/src/components/TableUI/TableHead/TableHead";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import { ConfirmModal } from "../../../common/ConfirmModal";
import AppliedFilters from "../../../common/filters/AppliedFilters";
import { FilterOption } from "../../../common/filters/Filter";
import { SUBSTATION_STATUS_OPTIONS } from "../Filters/SubstationFilters";
import SubstationsActionButtons from "../SubstationsTable/SubstationsActionButtons";
import SubstationsSearchBar from "../SubstationsTable/SubstationsSearchBar";
import SubstationsTable from "../SubstationsTable/SubstationsTable";
import styles from "../SubstationsTable/SubstationsTable.module.scss";
import { useSelected } from "../useSelected";

enum StationFilterKey {
  region = "region",
  supervisor = "supervisor",
  status = "status",
}

export interface SubstationSelectedFilters {
  [StationFilterKey.region]: string[];
  [StationFilterKey.supervisor]: string[];
  [StationFilterKey.status]?: string;
}

export const INITIAL_SUBSTATION_FILTERS: SubstationSelectedFilters = {
  region: [],
  status: undefined,
  supervisor: [],
};

interface Props {
  loading: boolean;
  error?: string | undefined;
  stations: WorkLocationDTO[];
  initialSubstationIds: Storm.EventVm["substationIds"];
}

const SubstationsList: FC<Props> = ({
  loading,
  error,
  stations,
  initialSubstationIds,
}) => {
  const { values, setFieldValue } = useFormikContext<Storm.EventVm>();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [stationIdsToRemove, setStationIdsToRemove] = useState<number[]>([]);
  const [touchedSubstationIds, setTouchedSubstationIds] = useState<number[]>(
    []
  );
  const [assessmentLoading, setAssessmentLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [elementsPerPage, setElementsPerPage] = useState<number>(10);
  const [sizeMemo, setSizeMemo] = useState<number>(10);
  const [selectedFilters, setSelectedFilters] =
    useState<SubstationSelectedFilters>(INITIAL_SUBSTATION_FILTERS);
  const [query, setQuery] = useState<string>("");
  const [sort, setSort] = useState<{ column: string; dir: "asc" | "desc" }>();
  const {
    selected,
    handleSelectStation,
    handleDeselectStation,
    handleSelectAll,
    handleDeselectAll,
  } = useSelected();

  const areRegionsFiltered = !!selectedFilters.region.length;

  useEffect(() => {
    setPage(0);
  }, [stations, elementsPerPage, selectedFilters, query]);

  const handleSelectPage = (value: number) => {
    setPage(value);
  };

  const handleClearSearch = () => {
    setSelectedFilters(INITIAL_SUBSTATION_FILTERS);
    setQuery("");
  };

  const handleRemoveFilter = (filter: StationFilterKey) => {
    if (filter === "region") {
      setElementsPerPage(sizeMemo);
    }
    setSelectedFilters((prev) => {
      return {
        ...prev,
        [filter]: INITIAL_SUBSTATION_FILTERS[filter],
      };
    });
  };

  const handleSelectFilter = (filters: SubstationSelectedFilters) => {
    setSelectedFilters(filters);
    if (!filters.region.length) {
      setElementsPerPage(sizeMemo);
    }
  };

  const handleFilterStations = (filters: SubstationSelectedFilters) => {
    let filteredStations = [...stations];

    if (filters.supervisor.length) {
      filteredStations = filteredStations.filter((station) => {
        return filters.supervisor.includes(station.nickname || "none");
      });
    }

    if (filters.status) {
      filteredStations = filteredStations.filter((station) => {
        return filters.status === "included"
          ? values.substationIds.indexOf(station.id) > -1
          : values.substationIds.indexOf(station.id) === -1;
      });
    }
    if (query) {
      const lowercaseQuery = query.toLowerCase();
      filteredStations = filteredStations.filter((station) =>
        [station.name, station.locationId].some((val) =>
          val.toLowerCase().includes(lowercaseQuery)
        )
      );
    }
    if (filters.region?.length) {
      filteredStations = filteredStations.filter(
        (station) => station.region && filters.region?.includes(station.region)
      );
    }

    return filteredStations;
  };

  const handleFilterResultsCount = (
    filters: SubstationSelectedFilters
  ): number => {
    const result = handleFilterStations(filters);
    return result.length;
  };

  const filteredStations = useMemo(
    () => handleFilterStations(selectedFilters),
    [selectedFilters, query, stations]
  );

  const onHandleSortOrderChange = (
    column: SortableColumn,
    sortOrder: SortOrder
  ) => {
    if (sortOrder === "none") {
      setSort(undefined);
    } else {
      const sortDir = sortOrder === "ascending" ? "asc" : "desc";
      setSort({ column: column.id, dir: sortDir });
    }
  };

  const sorters =
    sort?.column === "status"
      ? (a: WorkLocationDTO) => values.substationIds.includes(a.id)
      : sort?.column === "supervisor"
      ? (s: WorkLocationDTO) =>
          filteredStations[filteredStations.indexOf(s)].supervisor?.lastName
      : sort?.column;

  const sortedStations = useMemo(
    () =>
      sort ? orderBy(filteredStations, sorters, sort.dir) : filteredStations,
    [sort, filteredStations]
  );

  const supervisorOptions = useMemo(() => {
    const options = stations.reduce(
      (supervisors, station) => {
        if (
          station.nickname &&
          !supervisors.find((s) => s.value === station.nickname)
        ) {
          supervisors.push({
            label: station.nickname,
            value: station.nickname,
          });
        }
        return supervisors;
      },
      [
        {
          label: "All Supervisors",
          value: "all",
        },
      ] as FilterOption[]
    );
    options.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    options.push({
      label: "No Supervisor",
      value: "none",
    });
    return options;
  }, [stations]);

  function getSubstationFilterLabel(
    key: StationFilterKey,
    value: string
  ): string | undefined {
    switch (key) {
      case StationFilterKey.region:
        return value;
      case StationFilterKey.status:
        return SUBSTATION_STATUS_OPTIONS.find((e) => e.value === value)?.label;
      case StationFilterKey.supervisor:
        return value;
      default:
        return;
    }
  }

  const appliedFilters = useMemo(
    () =>
      Object.entries(selectedFilters).reduce((filters, [key, value]) => {
        if (![undefined, "all"].includes(value)) {
          filters.push({
            value: key,
            label:
              getSubstationFilterLabel(key as StationFilterKey, value) || "",
          });
        }
        return filters;
      }, [] as FilterOption[]),
    [selectedFilters]
  );

  const handleResetFilters = () => {
    setSelectedFilters(INITIAL_SUBSTATION_FILTERS);
  };

  const pageNumbers: number[] = [];
  const pages = Math.ceil(sortedStations.length / elementsPerPage);
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }

  const indexOfLastElement = currentPage * elementsPerPage + elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;

  let currentStations = sortedStations.slice(
    indexOfFirstElement,
    indexOfLastElement
  );

  const findTouchedStationIds = async () => {
    setAssessmentLoading(true);
    // fetch assessments only if it's unassigned and assigned because those two statuses will
    // have "NEW" as a submissionType.
    const res = await Storm.API.getAssessments({
      eventIds: values.id,
      statuses: [
        Storm.AssessmentState.UNASSIGNED,
        Storm.AssessmentState.ASSIGNED,
      ],
    });

    const { content } = res;

    // Now we filter only the "NEW" submissionType because the assessment has not been modified yet
    const unTouchedAssessments = content.filter(
      (t) => t.document.submissionType === "NEW"
    );

    // Then, we map to grab only the substation Ids. Clean out dups.
    const unTouchedSubstationIds = [
      ...new Set(unTouchedAssessments.map((a) => a.workLocation.id)),
    ];

    // Set the substation ids of anything that is touched based off from initial array value when loading
    // edit event. This prevents any new substation to include and remove to pop up the confirmation while
    // on that page.
    const fileredTouchedIds = initialSubstationIds.filter(
      (val) => !unTouchedSubstationIds.includes(val)
    );
    setTouchedSubstationIds(fileredTouchedIds);
    setAssessmentLoading(false);
  };

  useEffect(() => {
    values.id && findTouchedStationIds();
  }, []);

  const confirmMessage = (
    <div className={styles.confirmMessage}>
      <div>
        The current assessment(s) for the selected substation(s) will be changed
        to 'Cancelled'. The current assessment(s) will be retained in cancelled
        status.
      </div>
      <div className={styles.undoneQuestion}>
        This change cannot be undone. Are you sure?
      </div>
    </div>
  );

  const handleStationRemove = (stationIds: number[]) => {
    setStationIdsToRemove(stationIds);
    // REQ: W.EVT.0230 - This checks to see if there are any existing assessments has been touched and
    // the initialSubstationIds on load in order to pop up the confirmation window for removing it
    if (touchedSubstationIds.some((ts) => stationIds.includes(ts))) {
      return setOpenConfirm(true);
    }

    return handleRemoveStationId(stationIds);
  };

  const handleRemoveStationId = (stationIds: number[]) => {
    setFieldValue(
      "substationIds",
      values.substationIds.filter((id) => {
        return !stationIds.includes(id);
      })
    );
  };

  const handleShowAll = (all: boolean) => {
    // 1000 is doable, but decreases performace. This should be a one time thing. I set it to 1500 to
    // leave some space in case DE decides to add more substations. As of 1/11/23, they have 860
    // substations total. Filtering region can reduce the number between 80-400 depending how many
    // regions are multi selected.
    setElementsPerPage(all ? 1500 : sizeMemo);
    setSizeMemo(elementsPerPage);
  };

  return (
    <>
      <ConfirmModal
        action={() => {
          stationIdsToRemove && handleRemoveStationId(stationIdsToRemove);
          setOpenConfirm(false);
        }}
        open={openConfirm}
        closeModal={() => setOpenConfirm(false)}
        loading={false}
        content={confirmMessage}
        title="Remove Selected Substation(s)"
      />
      <Loader loading={loading || assessmentLoading}>
        <div className={styles.tableContainer}>
          <div className={styles.subHeader}>
            <h2>Pick Substations to Include in Event</h2>
            {selected.length > 0 && (
              <SubstationsActionButtons
                removeSelected={() => handleStationRemove(selected)}
                includeSelected={() => {
                  const newStations = selected.filter(
                    (s) => !values.substationIds.includes(s)
                  );
                  setFieldValue(
                    "substationIds",
                    values.substationIds.concat(newStations)
                  );
                }}
              />
            )}
          </div>
          <div className={styles.searchBarContainer}>
            <SubstationsSearchBar
              filtersOpen={open}
              setFiltersOpen={setOpen}
              query={query}
              setQuery={setQuery}
              results={sortedStations.length}
              selectedFilters={selectedFilters}
              handleSelectFilter={handleSelectFilter}
              filterResultsCount={handleFilterResultsCount}
              handleResetFilters={handleResetFilters}
              supervisorOptions={supervisorOptions}
            />
            <div className={styles.filterContainer}>
              <AppliedFilters
                onRemoveFilter={(key) =>
                  handleRemoveFilter(key as keyof SubstationSelectedFilters)
                }
                selectedFilters={appliedFilters}
              />
            </div>
          </div>
          <SubstationsTable
            disabled={!values.active}
            substations={currentStations}
            onHandleSortOrderChange={onHandleSortOrderChange}
            handleClearSearch={handleClearSearch}
            selected={selected}
            handleSelectAll={handleSelectAll}
            handleDeselectAll={handleDeselectAll}
            handleDeselectStation={handleDeselectStation}
            handleSelectStation={handleSelectStation}
            summary={{
              size: elementsPerPage,
              page: currentPage,
              totalElements: sortedStations.length,
              totalPages: pageNumbers.length,
            }}
            elementsPerPage={elementsPerPage}
            handleSelectPage={handleSelectPage}
            setElementsPerPage={setElementsPerPage}
            handleStationRemove={handleStationRemove}
            onShowAll={areRegionsFiltered ? handleShowAll : undefined}
          />
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </Loader>
    </>
  );
};

export default SubstationsList;
