import React, { useCallback, useMemo } from "react";
import { isNil } from "lodash";
import { Field, Form, Formik } from "formik";
import { useMediaQuery } from "react-responsive";

import scssVariables from "../../../sass/jsExports.module.scss";
import {
  UpdateUserConfig,
  ParticipantUserVM,
} from "@rtslabs/field1st-fe-common";

import LockedWarning from "./LockedWarning";
import { ProfileField } from "./ProfileField";

import { ButtonRow } from "./ButtonRow";
import styles from "./EditProfile.module.scss";
import { Logout } from "../../Logout/Logout";
import { TextInput } from "../../TextInput/TextInput";
import { RenderGroups } from "./RenderGroups";
import { Select, SelectOption } from "../../Select/Select";
import { Components, ElementType } from "../../../qa-slugs";

export interface OfficeLocation {
  label: string | undefined;
  value?: number;
  id: number;
}

export interface EditProfileFormProps {
  userProfile: ParticipantUserVM;
  officeLocations?: OfficeLocation[];
  onCancel?: () => void;
  onSubmit: (
    values: UpdateUserConfig,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => void;
  logout: () => void;
}

/**
 * Contains all form logic for Edit Profile
 * as well as form config (labels, names, ect)
 */
export const EditProfileForm = ({
  userProfile,
  officeLocations = [],
  onCancel,
  onSubmit,
  logout,
}: EditProfileFormProps) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const groupNames: Array<string> = useMemo(() => {
    return (
      userProfile.clientGroups?.map((x) => x.name || "").filter((x) => !!x) ||
      []
    );
  }, [userProfile.clientGroups]);

  const workLocationOptions: SelectOption<number>[] = useMemo(
    () =>
      officeLocations.reduce((result, location) => {
        if (location.id && location.label) {
          result.push({
            value: location.id,
            label: location.label,
          });
        }
        return result;
      }, [] as SelectOption<number>[]),
    [officeLocations]
  );

  const primaryGroupOptions: SelectOption<number>[] = useMemo(
    () =>
      userProfile.clientGroups
        .filter((x) => x.id && x.name)
        .map((x) => ({
          value: x.id || 0,
          label: x.name || "",
        })),
    [userProfile.clientGroups]
  );

  const getLockedSelectFieldValueFor = useCallback(
    (id: number, field: "group" | "office") => {
      if (field === "group") {
        const group = userProfile.clientGroups.find((x) => x.id === id);
        if (group) {
          return group.name;
        }
      }

      if (field === "office") {
        const office = officeLocations.find((x) => x.id === id);
        if (office) {
          return office.label;
        }
      }

      return id.toString();
    },
    [userProfile.clientGroups, officeLocations]
  );

  const handleOnBlur = useCallback(
    async (handleSubmit: () => void) => {
      if (isDesktop) return null;

      await handleSubmit();
    },
    [isDesktop]
  );

  // Requirements don't mention an ADFS flag, so disabling everything for now -- GK
  const locked = true; // PPP-351 -- GK

  return (
    <>
      {locked && isDesktop && <LockedWarning />}
      <Formik
        initialValues={{
          email: userProfile.email || "",
          firstName: userProfile.firstName || "",
          imageUrl: userProfile.imageUrl || "",
          langKey: userProfile.langKey || "",
          lastName: userProfile.lastName || "",
          nickname: userProfile.nickname || "",
          primaryGroupId: userProfile.primaryGroupId,
          workLocationId: !isNil(userProfile.workLocation?.id)
            ? userProfile.workLocation!.id
            : -1,
        }}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          submitForm,
        }) => (
          <Form className={styles.form}>
            <ProfileField
              isDesktop={isDesktop}
              locked={locked}
              label="First Name"
              name="firstName"
              lockedValue={values.firstName}
            >
              <Field
                as={TextInput}
                label=""
                name="firstName"
                onBlur={() => handleOnBlur(handleSubmit)}
              />
            </ProfileField>

            <ProfileField
              isDesktop={isDesktop}
              locked={locked}
              label="Last Name"
              name="lastName"
              lockedValue={values.lastName}
            >
              <Field
                as={TextInput}
                label=""
                name="lastName"
                onBlur={() => handleOnBlur(handleSubmit)}
              />
            </ProfileField>

            <ProfileField
              isDesktop={isDesktop}
              locked={locked}
              label="Nickname"
              name="nickname"
              lockedValue={values.nickname}
            >
              <Field
                as={TextInput}
                label=""
                name="nickname"
                onBlur={() => handleOnBlur(handleSubmit)}
              />
            </ProfileField>

            <ProfileField
              isDesktop={isDesktop}
              locked={locked}
              label="Email"
              name="email"
              lockedValue={values.email}
            >
              <Field
                as={TextInput}
                label=""
                name="email"
                onBlur={() => handleOnBlur(handleSubmit)}
              />
            </ProfileField>

            <ProfileField
              isDesktop={isDesktop}
              lockedValue={
                <RenderGroups
                  isDesktop={isDesktop}
                  values={groupNames}
                  locked
                />
              }
              name="group"
              label="Groups"
              locked={true}
            ></ProfileField>

            {!!values.primaryGroupId && (
              <ProfileField
                isDesktop={isDesktop}
                name="primaryGroupId"
                label="Primary Group"
                locked={locked}
                lockedValue={getLockedSelectFieldValueFor(
                  values.primaryGroupId,
                  "group"
                )}
              >
                <Field
                  as={Select}
                  disableUnderline
                  name="primaryGroupId"
                  variant={!isDesktop ? "standard" : "outlined"}
                  label=""
                  classes={{
                    select: styles.primaryGroupSelect,
                  }}
                  onBlur={() => handleOnBlur(handleSubmit)}
                  options={primaryGroupOptions}
                  onChange={(option?: SelectOption<number>) =>
                    setFieldValue("primaryGroupId", option?.value)
                  }
                  qa={`${Components.Profile}-${ElementType.SelectInput}-primaryGroup`}
                />
              </ProfileField>
            )}

            {!!values.workLocationId && (
              <ProfileField
                isDesktop={isDesktop}
                locked={locked}
                label="Office"
                name="workLocationId"
                lockedValue={getLockedSelectFieldValueFor(
                  values.workLocationId,
                  "office"
                )}
              >
                <Field
                  as={Select}
                  label=""
                  name="workLocationId"
                  onBlur={() => handleOnBlur(handleSubmit)}
                  options={workLocationOptions}
                  onChange={(option?: SelectOption<number>) =>
                    setFieldValue("workLocationId", option?.value)
                  }
                />
              </ProfileField>
            )}

            {locked && !isDesktop && <LockedWarning />}

            {!locked && isDesktop ? (
              <ButtonRow
                loading={isSubmitting}
                onCancel={onCancel}
                onSubmit={submitForm}
                qa={Components.Profile}
              />
            ) : (
              <Logout onLogOut={logout} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
