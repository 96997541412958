import React, { FC, useEffect, useState } from "react";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import style from "./EventView.module.scss";
import Header from "./Header/Header";
import { useParams } from "react-router-dom";
import Loader from "shared/src/components/Loader/Loader";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Hashtags from "./Hashtags";
import DateOfImpact from "./DateOfImpact";
import EventProgress from "./EventProgress";
import EventActionButtons from "./EventActionButtons";
import { API, Storm, useAsyncEffect } from "@rtslabs/field1st-fe-common";
import Assessments from "../../Assessments/Assessments";
import { fetchActiveEvents } from "../../../api/getEvents";
import { useAppDispatch } from "../../../redux";
import { setAssessmentsFilters } from "../../../redux/filters/actions";
import { useSelector } from "react-redux";
import { selectAssessmentsFilters } from "../../../redux/filters/selectors";

export const EventView: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [event, setEvent] = useState<Storm.EventWithDocStatsVM>();
  const [activeEvents, setActiveEvents] =
    useState<Storm.PageOfEventWithDocStatsVM>();
  useAsyncEffect(async () => {
    setActiveEvents(await fetchActiveEvents());
  }, []);
  const assessmentsFilters = useSelector(selectAssessmentsFilters);
  const dispatch = useAppDispatch();
  const userRoles = API.Environment.getRoles();

  const params = useParams<{ id: string }>();
  const id = params.id!;

  async function fetchEvent(id: string) {
    try {
      setLoading(true);
      const res = await Storm.API.getEventByIdWithDocStats({ id });
      setEvent(res);
    } catch (err: unknown) {
      process.env.NODE_ENV === "development" && console.warn(err);
      setError("Unable to load event");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEvent(id);
    dispatch(
      setAssessmentsFilters({
        ...assessmentsFilters,
        eventIds: +id,
      })
    );
  }, []);

  return (
    <Loader loading={loading}>
      {event && (
        <div className={style.eventViewContainer}>
          <Breadcrumbs
            paths={[
              { pathName: "Events", href: "/events" },
              { pathName: event.eventName },
            ]}
            data-testid="breadcrumb"
          />
          <Header title={event.eventName} description={"event"}>
            <EventActionButtons userRoles={userRoles} eventId={id} />
          </Header>
          <div className={style.detailsContainer}>
            <DateOfImpact startDate={event.startDate} endDate={event.endDate} />
            <Hashtags tags={event.hashtags} />
            <EventProgress event={event} />
          </div>
          <Assessments events={activeEvents!} eventOnlyId={event.id} />
        </div>
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </Loader>
  );
};
