import React from "react";
import ReactSelect, { Props as SelectProps, StylesConfig } from "react-select";
import { FieldErrorType } from "../../api/form.types";
import { joinClassNames } from "../../helpers/theme.helpers";
import scssVariables from "../../sass/jsExports.module.scss";
import { AssistiveText } from "../AssistiveText/AssistiveText";
import ErrorText from "../ErrorText/ErrorText";
import Label from "../Label/Label";
import { AssistiveLink } from "../TextInput/types";
import styles from "./Select.module.scss";

export type SelectOption<V = string | number | boolean> = {
  label: string;
  value: V;
};

export interface SelectInputProps<
  V = SelectOption["value"],
  T = SelectOption<V>
> extends Omit<SelectProps<T, false>, "value" | "onChange"> {
  answerValue?: string;
  assistiveLink?: AssistiveLink;
  assistiveText?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  error?: FieldErrorType;
  isLoading?: boolean;
  label?: string;
  options?: T[];
  overrideStyles?: StylesConfig<T, false>;
  qa?: string;
  required?: boolean;
  value?: V | null;
  wrapperClassName?: string;
  onChange: (option: T | null) => void;
  onMenuScrollToBottom?: () => void;
}

/**
 * Common select drop down
 *
 * For an example of how to test, look at the "Form builder location source" tests in SettingsForm.test.tsx
 */
export const Select = <V, T extends SelectOption<V | boolean>>({
  answerValue,
  assistiveLink,
  assistiveText,
  className,
  labelClassName,
  disabled,
  error,
  isLoading,
  label,
  menuPosition,
  name,
  onBlur,
  onChange,
  onMenuScrollToBottom,
  options,
  overrideStyles,
  placeholder,
  qa,
  required,
  value,
  wrapperClassName,
  ...additionalSelectProps
}: SelectInputProps<V, T>): JSX.Element => {
  const selectedOption = options?.find(
    (o) =>
      o.value === value || o.label?.toLowerCase() === answerValue?.toLowerCase()
  );

  return (
    <div
      className={joinClassNames(styles.selectInput, wrapperClassName)}
      onClick={(e) => e.preventDefault()}
    >
      {label && (
        <Label
          className={labelClassName}
          htmlFor={name}
          assistiveLink={assistiveLink}
          required={required}
        >
          {label}
        </Label>
      )}
      <div data-testid={qa} className={styles.container}>
        <ReactSelect
          aria-invalid={!!error}
          options={options}
          styles={customStyles<T>(overrideStyles)}
          value={selectedOption}
          placeholder={placeholder}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={onChange}
          onBlur={onBlur}
          onMenuScrollToBottom={onMenuScrollToBottom}
          menuPosition={menuPosition}
          className={className}
          classNamePrefix="react-select"
          {...additionalSelectProps}
        />
      </div>
      <AssistiveText>{assistiveText}</AssistiveText>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

// see https://react-select.com/styles#provided-styles-and-state for styling docs
// to style react-select classNames from another scss module, use the :global scope selector (https://github.com/css-modules/css-modules#exceptions)
const customStyles = <T,>(
  overrideStyles?: StylesConfig<T, false>
): StylesConfig<T, false> => ({
  control: (provided, state) => {
    let backgroundColor = scssVariables.white;
    let borderColor = scssVariables.darkGrey;
    if (state.isDisabled) {
      backgroundColor = scssVariables.lightGrey;
      borderColor = scssVariables.darkGrey;
    }
    if (state.selectProps["aria-invalid"]) {
      backgroundColor = scssVariables.errorFill;
      borderColor = scssVariables.error;
    }
    if (state.isFocused) {
      borderColor = "transparent";
    }
    return {
      ...provided,
      backgroundColor,
      borderColor,
      boxShadow: state.isFocused
        ? `0 0 0 3px ${scssVariables.focusIndicatorOutline}`
        : provided.boxShadow,
    };
  },
  container: (provided, state) => ({
    ...provided,
    flex: 1,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0.875rem",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  ...overrideStyles,
});
