import React, { FC } from "react";
import { AssessmentSummaryCard } from "./AssessmentSummaryCard";
import styles from "./AssessmentsReport.module.scss";
import { Storm } from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import ErrorText from "shared/src/components/ErrorText/ErrorText";

interface AssessmentsReportProps {
  assessmentsCount: Storm.StormDocumentStateCountVm;
  isLoading: boolean;
  error: string | null;
}

export const AssessmentsReport: FC<AssessmentsReportProps> = ({
  assessmentsCount,
  isLoading,
  error,
}) => {
  return (
    <div>
      <Loader loading={isLoading}>
        {error && <ErrorText>{error}</ErrorText>}
        <div className={styles.row}>
          <AssessmentSummaryCard
            count={assessmentsCount.totalNumberOfAssessments}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.UNASSIGNED}
            status={Storm.AssessmentState.UNASSIGNED}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.ASSIGNED}
            status={Storm.AssessmentState.ASSIGNED}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.IN_PROGRESS}
            status={Storm.AssessmentState.IN_PROGRESS}
          />
        </div>
        <div className={styles.row}>
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.ASSESSOR_COMPLETE}
            status={Storm.AssessmentState.ASSESSOR_COMPLETE}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.IN_REVIEW}
            status={Storm.AssessmentState.IN_REVIEW}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsPerState.STORM_CENTER_VERIFIED}
            status={Storm.AssessmentState.STORM_CENTER_VERIFIED}
          />
          <AssessmentSummaryCard
            count={assessmentsCount.nrOfDocumentsWithActionRequired}
            status="ACTION_REQUIRED"
          />
        </div>
      </Loader>
    </div>
  );
};
