import React, { FC, useState } from "react";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import { Storm } from "@rtslabs/field1st-fe-common";
import FilterSection from "../common/filters/FilterSection";
import { FilterButtons } from "../FilterButtons";
import {
  EVENT_MONTH_OPTIONS,
  EVENT_STATUS_OPTIONS,
  EVENT_YEAR_OPTIONS,
  filterStartDates,
} from "./eventFilters.helpers";
import {
  EventViewFilter,
  INITIAL_EVENT_FILTERS,
} from "../../redux/filters/reducer";
import { useAppDispatch, useAppSelector } from "../../redux";
import { selectEventsFilters } from "../../redux/filters/selectors";
import { setEventsFilters } from "../../redux/filters/actions";
import { fetchEvents } from "../../api/getEvents";

interface Props {
  onClose: () => void;
  onFilter: () => void;
}

const EventFilters: FC<Props> = ({ onClose, onFilter }) => {
  const eventFilters = useAppSelector(selectEventsFilters);
  const [selected, setSelected] = useState<EventViewFilter>(eventFilters);
  const [loading, setLoading] = useState<boolean>(false);
  const [resultCount, setResultCount] = useState<number>();
  const dispatch = useAppDispatch();

  const handleSelectFilter = (
    filterKey: "statusFilter" | "eventYear" | "eventMonth",
    value: string
  ): void => {
    let filters: Storm.API.GetEventsArgs = {};

    // if filtering by dates, do this. else any filters
    if (["eventYear", "eventMonth"].includes(filterKey)) {
      if (filterKey === "eventYear") {
        setSelected((prev) => ({ ...prev, year: value }));
        filters = {
          statusFilter: selected.statusFilter,
          ...filterStartDates(value, selected.month),
        };
      }

      if (filterKey === "eventMonth") {
        setSelected((prev) => ({ ...prev, month: value }));
        filters = {
          statusFilter: selected.statusFilter,
          ...filterStartDates(selected.year, value),
        };
      }
    } else {
      setSelected((prev) => ({ ...prev, [filterKey]: value }));
      filters = {
        statusFilter: value as Storm.EventStatusFilter,
        ...filterStartDates(selected.year, selected.month),
      };
    }

    fetchResultCount(filters);
  };

  const fetchResultCount = async (
    eventFilter: Storm.API.GetEventsArgs
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await fetchEvents({ ...eventFilter, size: 0 });
      setResultCount(res.totalElements);
    } catch (err: unknown) {
      process.env.NODE_ENV === "development" && console.warn(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (): void => {
    dispatch(setEventsFilters(selected));
    onFilter();
  };

  const handleResetFilters = (): void => {
    const { year, month, ...eventArgs } = INITIAL_EVENT_FILTERS;
    const filters: Storm.API.GetEventsArgs = {
      ...eventArgs,
      ...filterStartDates(year, month),
    };
    setSelected(INITIAL_EVENT_FILTERS);
    fetchResultCount(filters);
  };

  return (
    <div>
      <DrawerHeader onClose={onClose} title="Filters" />
      <FilterSection
        title="Event Status"
        options={EVENT_STATUS_OPTIONS}
        selected={selected.statusFilter}
        handleSelectFilter={(value) =>
          handleSelectFilter("statusFilter", value)
        }
        name="eventStatus"
      />
      <FilterSection
        title="Event Year"
        options={EVENT_YEAR_OPTIONS}
        selected={selected.year}
        handleSelectFilter={(value) => handleSelectFilter("eventYear", value)}
        name="eventYear"
      />
      <FilterSection
        title="Event Month"
        options={EVENT_MONTH_OPTIONS}
        selected={selected.month}
        handleSelectFilter={(value) => handleSelectFilter("eventMonth", value)}
        name="eventMonth"
      />
      <FilterButtons
        loading={loading}
        resultCount={resultCount}
        onFilters={handleFilters}
        onResetFilters={handleResetFilters}
      />
    </div>
  );
};

export default EventFilters;
