import React, { FC, useMemo, useState } from "react";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import { FilterOption } from "../common/filters/Filter";
import styles from "../common/filters/Filters.module.scss";
import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import FilterSection from "../common/filters/FilterSection";
import {
  ACTION_REQUIRED_OPTIONS,
  ASSESSOR_STATE_OPTIONS,
  buildEventOptions,
  getRegionOptions,
  getSupervisorOptions,
  LOCATION_OPTIONS,
  STATUS_OPTIONS,
} from "./assessmentFilterOptions";
import {
  ParticipantDTO,
  Storm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import {
  AssessmentsFiltersArgs,
  fetchAssessmentStats,
} from "../../api/getAssessments";
import { FilterButtons } from "../FilterButtons";
import { useSelector } from "react-redux";
import { selectAssessmentsFilters } from "../../redux/filters/selectors";
import { getCurrentPosition } from "shared/src/api/geolocationAPI";
import { SearchAssessorDrawer } from "./SearchAssessorDrawer/SearchAssessorDrawer";
import { AssessmentAssignment } from "@rtslabs/field1st-fe-common/dist/main/storm";

export interface EventNameAndIDProp {
  name: string;
  id: number;
}

interface AssessmentsFiltersProps {
  onClose: () => void;
  handleFilter: (filters: AssessmentsFiltersArgs) => void;
  events: EventWithDocStatsVM[];
  eventOnlyId?: number;
  isDashboard?: boolean;
  eventNameAndId?: EventNameAndIDProp;
}

interface AssessmentsFiltersArgsMulti {
  regions: string[];
  statuses: Storm.AssessmentState[];
  workLocationNicknames: string[];
}

const AssessmentsFilters: FC<AssessmentsFiltersProps> = ({
  onClose,
  handleFilter,
  events,
  eventOnlyId,
  isDashboard,
  eventNameAndId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchAssessorDrawerOpen, setSearchAssessorDrawerOpen] =
    useState<boolean>(false);
  const [resultCount, setResultCount] = useState<number>();
  const [regionOptions, setRegionOptions] = useState<FilterOption[]>([
    { value: "all", label: "All Regions" },
  ]);
  const [supervisorOptions, setSupervisorOptions] = useState<FilterOption[]>([
    { value: "all", label: "All Supervisors" },
  ]);
  const [hasGPS, setHasGPS] = useState<boolean>(false);
  const assessmentsFilters = useSelector(selectAssessmentsFilters);
  const [selected, setSelected] = useState<AssessmentsFiltersArgs>(
    isDashboard ? { eventIds: "active" } : assessmentsFilters
  );

  function onResetFilters(): void {
    const filters: AssessmentsFiltersArgs = eventOnlyId
      ? { eventIds: eventOnlyId }
      : { eventIds: "active" };

    setSelected(filters);
    fetchResultCount(filters);
  }

  function onSelectFilter(
    filterKey: string,
    value: FilterOption["value"] | ParticipantDTO,
    multiSelect?: boolean
  ): void {
    if (
      filterKey === "assessorState" &&
      value === Storm.AssessmentAssignment.ASSIGNED_TO_SPECIFIED
    ) {
      return setSearchAssessorDrawerOpen(true);
    }

    let filters: AssessmentsFiltersArgs;
    const isAssessorSpecific =
      selected.assessorState === AssessmentAssignment.ASSIGNED_TO_SPECIFIED;
    const assessorName = isAssessorSpecific ? selected.assessorName : undefined;

    if (multiSelect) {
      if (value === "all") {
        filters = { ...selected, assessorName, [filterKey]: [] };
      } else {
        let newValues =
          value &&
          selected[filterKey as keyof AssessmentsFiltersArgsMulti] &&
          (
            selected[filterKey as keyof AssessmentsFiltersArgsMulti] || []
          ).includes(value.toString())
            ? selected[filterKey as keyof AssessmentsFiltersArgsMulti]!.filter(
                (v) => v !== value
              )
            : [
                ...(selected[filterKey as keyof AssessmentsFiltersArgsMulti] ||
                  []),
                value,
              ];

        filters = {
          ...selected,
          assessorName,
          [filterKey]: newValues,
        };
      }
    } else if (filterKey === "specificAssessor") {
      filters = {
        ...selected,
        assessorState: AssessmentAssignment.ASSIGNED_TO_SPECIFIED,
        assessorId: (value as ParticipantDTO).id,
        assessorName: (value as ParticipantDTO).name,
      };
    } else {
      filters = {
        ...selected,
        assessorName,
        [filterKey]: value === "all" ? undefined : value,
      };
    }
    setSelected(filters);
    fetchResultCount(filters);
  }

  async function fetchResultCount(args: AssessmentsFiltersArgs): Promise<void> {
    try {
      setLoading(true);
      const res = await fetchAssessmentStats(args);

      // totatNumberOfAssessments did not include cancelled. So lets include that
      const cancelledCount = res.nrOfDocumentsPerState.CANCELLED || 0;

      setResultCount(res.totalNumberOfAssessments + cancelledCount);
    } catch (err: unknown) {
      process.env.NODE_ENV === "development" && console.warn(err);
    } finally {
      setLoading(false);
    }
  }

  function onFilter(): void {
    handleFilter(selected);
    onClose();
  }

  useAsyncEffect(async () => {
    setRegionOptions(await getRegionOptions());
    setSupervisorOptions(await getSupervisorOptions());
  }, []);

  const eventOptions = useMemo(
    () => buildEventOptions(events, eventNameAndId),
    [events, eventNameAndId]
  );

  useAsyncEffect(async () => {
    // check if user has location turned on. This will return resolve/reject on promise
    try {
      await getCurrentPosition();
      setHasGPS(true);
    } catch (e) {
      setHasGPS(false);
    }
  }, []);

  const assessorOptions = selected.assessorName
    ? [
        ...ASSESSOR_STATE_OPTIONS,

        {
          label: selected.assessorName,
          value: selected.assessorName,
        },
      ]
    : ASSESSOR_STATE_OPTIONS;

  return (
    <div>
      {!!searchAssessorDrawerOpen && (
        <SearchAssessorDrawer
          onSelectAssessor={(value: any) => {
            onSelectFilter("specificAssessor", value);
            setSearchAssessorDrawerOpen(false);
          }}
          isOpen={searchAssessorDrawerOpen}
          onCancel={() => setSearchAssessorDrawerOpen(false)}
        />
      )}

      <DrawerHeader onClose={onClose} title="Filters" />
      <div className={styles.filterContainer}>
        <FilterSection
          title="Event"
          options={eventOptions}
          selected={selected.eventIds?.toString() || "all"}
          handleSelectFilter={(value) => onSelectFilter("eventIds", value)}
          name="event"
          disabled={!!eventOnlyId}
        />
        <FilterSection
          title="Status"
          options={STATUS_OPTIONS}
          selected={selected.statuses || ["all"]}
          handleSelectFilter={(value) =>
            onSelectFilter("statuses", value, true)
          }
          name="status"
          multiSelect
        />
        <FilterSection
          title="Action Required"
          options={ACTION_REQUIRED_OPTIONS}
          selected={
            selected.actionRequiredFilter ||
            Storm.AssessmentActionRequiredFilter.ALL
          }
          handleSelectFilter={(value) =>
            onSelectFilter("actionRequiredFilter", value)
          }
          name="actionRequiredFilter"
        />
        <FilterSection
          title="Location"
          options={LOCATION_OPTIONS}
          selected={selected.location?.toString() || "all"}
          handleSelectFilter={(value) => onSelectFilter("location", value)}
          name="location"
          disabled={!hasGPS}
        />
        <FilterSection
          title="Region"
          options={regionOptions}
          selected={selected.regions || ["all"]}
          handleSelectFilter={(value) => onSelectFilter("regions", value, true)}
          name="region"
          multiSelect
        />
        <FilterSection
          title="Assessor"
          options={assessorOptions}
          selected={
            selected.assessorName ||
            selected.assessorState ||
            Storm.AssessmentAssignment.ALL
          }
          handleSelectFilter={(value) => onSelectFilter("assessorState", value)}
          name="assessorState"
        />
        <FilterSection
          title="Supervisor"
          options={supervisorOptions}
          selected={selected.workLocationNicknames || ["all"]}
          handleSelectFilter={(value) =>
            onSelectFilter("workLocationNicknames", value, true)
          }
          name="supervisor"
          multiSelect
        />
      </div>
      <FilterButtons
        loading={loading}
        resultCount={resultCount}
        onFilters={onFilter}
        onResetFilters={onResetFilters}
      />
    </div>
  );
};

export default AssessmentsFilters;
