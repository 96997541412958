import React from "react";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { QAProps } from "../../../../qa-slugs";
import { Icon } from "../../../Icon/Icon";
import styles from "./CameraButton.module.scss";

interface CameraButtonProps extends QAProps {
  photoCount: number;
  expanded: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const CameraButton = ({
  qa,
  photoCount,
  expanded,
  onClick,
  disabled,
}: CameraButtonProps) => {
  const color = disabled
    ? "#aaaaaa"
    : photoCount || expanded
    ? "#0072CE"
    : "#666666";
  return (
    <button
      data-testid={qa}
      type="button"
      style={{ borderColor: color }}
      className={joinClassNames(
        styles.button,
        disabled && styles.buttonDisabled
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {photoCount ? (
        <span style={{ color }} className={styles.accessory}>
          {photoCount}
        </span>
      ) : (
        <Icon
          color={color}
          size="0.75rem"
          type={expanded ? "delete_sign" : "plus_math"}
          className={styles.accessory}
        />
      )}
      <Icon color={color} type="camera" className={styles.camera} />
      <Icon
        color={color}
        size="1rem"
        type={expanded ? "cheveron-down" : "cheveron-up"}
        className={styles.accessory}
      />
      {expanded && !disabled && <div className={styles.arrow}></div>}
    </button>
  );
};
