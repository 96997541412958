import React, { FC, useEffect, useState } from "react";

import { LatLng, Storm, useAPI } from "@rtslabs/field1st-fe-common";
import styles from "./MapWidget.module.scss";
import { GlMap as MapComponent } from "shared/src/components/GlMap/GlMap";
import { Viewport } from "shared/src/components/GlMap/types";
import { setAssessmentMapMarker } from "../../Assessments/MapWidget/setAssessmentMapMarker";
import Loader from "shared/src/components/Loader/Loader";
import { SecondaryButton } from "shared/src/components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { setAssessmentsFilters } from "../../../redux/filters/actions";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import {
  calculateZoom,
  getDistanceBetweenGeoLocations,
  getMidPoint,
} from "shared/src/util/geolocation";

interface Props {
  event: Storm.EventVm;
}

export const DashboardMapWidget: FC<Props> = ({ event }) => {
  const [markers, setMarkers] = useState<LocationMarker[]>([]);
  const [viewport, setViewport] = useState<Viewport>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const assessmentsCall = useAPI(Storm.API.getAssessments, {
    eventIds: event.id,
    statuses: [
      Storm.AssessmentState.UNASSIGNED,
      Storm.AssessmentState.ASSIGNED,
      Storm.AssessmentState.IN_PROGRESS,
      Storm.AssessmentState.ASSESSOR_COMPLETE,
      Storm.AssessmentState.IN_REVIEW,
      Storm.AssessmentState.STORM_CENTER_VERIFIED,
    ],
    size: 100,
  });

  /**
   * Get a calculated viewport that encompasses any pins currently placed on the map
   * @param locations - geo coordinates of all location responses in the widget
   */
  function getViewport(locations: LatLng[]): Viewport {
    const { latitude = 0, longitude = 0 } = getMidPoint(locations);
    return {
      width: "100%",
      height: "100%",
      center: { latitude, longitude },
      zoom: calculateZoom(getDistanceBetweenGeoLocations(locations)),
    };
  }

  function handleViewMap() {
    dispatch(setAssessmentsFilters({ eventIds: event.id }));
    navigate(`/assessments`, { state: "assessmentsMap" });
  }

  useEffect(() => {
    if (!assessmentsCall.isLoading) {
      if (assessmentsCall.data?.content.length) {
        // this just to make sure we have geolocation available to pin on the map
        const filteredGeolocation = assessmentsCall.data.content.filter(
          (assessment) => !!assessment.workLocation.geolocation
        );

        const mappedMarkers: LocationMarker[] = filteredGeolocation.map((a) =>
          setAssessmentMapMarker(a)
        );

        const mappedLocations: LatLng[] = filteredGeolocation.map((a) => ({
          latitude: a.workLocation.geolocation!.latitude,
          longitude: a.workLocation.geolocation!.longitude,
        }));

        setMarkers(mappedMarkers);

        const newViewPort = getViewport(mappedLocations);
        setViewport(newViewPort);
      } else {
        // if there are no assessments, set default storm center location
        let initialViewport: Viewport = {
          width: "100%",
          height: "100%",
          center: {
            latitude: 37.54129,
            longitude: -77.43476,
          },
          zoom: 5,
        };
        setViewport(initialViewport);
      }
    }
  }, [assessmentsCall.data, assessmentsCall.isLoading]);

  const bottomLeft = (
    <div className={styles.buttonContainer}>
      <SecondaryButton className={styles.buttonStyles} onClick={handleViewMap}>
        View Map
      </SecondaryButton>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.map}>
        <Loader loading={!viewport}>
          <MapComponent
            initialViewport={viewport!}
            markers={markers}
            bottomLeft={bottomLeft}
            staticOnly
          />
        </Loader>
      </div>
    </div>
  );
};
