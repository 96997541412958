import { DocumentVm, Storm } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { Modal } from "shared/src/components/Modal/Modal";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { canSaveWhileAssigned } from "./assessment.helpers";
import { SubmitAssessmentProps } from "./useSubmitAssessment";

interface Props {
  assessment: Storm.StormDocumentExtensionVm;
  handleSubmit: (props: SubmitAssessmentProps) => Promise<void>;
  assessmentRefresh?: () => void;
  setIsInspectedBySet: (val: boolean) => void;
}

export const UnassignedEditModal: FC<Props> = ({
  assessment,
  handleSubmit,
  assessmentRefresh,
  setIsInspectedBySet,
}) => {
  const { values, dirty, resetForm } = useFormikContext<DocumentVm>();

  const documentTerm: string = useGroupTerm(
    "document",
    "noun",
    "singular",
    "Assessment"
  );

  const isNotAssignedPopup = dirty && !canSaveWhileAssigned(assessment);

  return (
    <Modal
      title="Move Assessment"
      open={isNotAssignedPopup}
      handleClose={() => {
        setIsInspectedBySet(false);
        return resetForm();
      }}
      cancellable
      action={{
        text: "Yes",
        loading: false,
        callback: async () => {
          await handleSubmit({
            document: values,
            assessmentId: assessment.id,
            workLocationId: assessment.workLocation.id,
          });
          assessmentRefresh && assessmentRefresh();
        },
      }}
      secondaryText="No"
      content={
        <div>
          <span>
            Do you wish to proceed with editing this{" "}
            {documentTerm.toLowerCase()}? This will save it and assign it to
            yourself. Changes will be lost if you press No
          </span>
        </div>
      }
    />
  );
};
