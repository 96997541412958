import {
  AssessmentAction,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { SecondaryButton } from "../../../../shared/src/components/Button/Button";
import { isActionAllowed } from "./helpers";
import { VerifyAssessment } from "./Modals/VerifyAssessment";

interface AssessmentsActionButtonsProps {
  onAssign?: () => void;
  selected: StormDocumentExtensionVm[];
  setSelected: Dispatch<SetStateAction<StormDocumentExtensionVm[]>>;
  assessmentsRefresh: () => void;
}

const AssessmentsActionButtons: FC<AssessmentsActionButtonsProps> = ({
  onAssign,
  selected,
  setSelected,
  assessmentsRefresh,
}) => {
  const [openVerify, setOpenVerify] = useState<boolean>(false);
  //   const canReview = isActionAllowed(selected, AssessmentAction.START_REVIEW); // @TODO: SAT-272

  const canDoSomeAssigns =
    !!selected.length &&
    selected.every(
      (s) =>
        isActionAllowed([s], AssessmentAction.ASSIGN) ||
        isActionAllowed([s], AssessmentAction.UNASSIGN) ||
        isActionAllowed([s], AssessmentAction.REASSIGN)
    );

  const canVerify = isActionAllowed(selected, AssessmentAction.COMPLETE_REVIEW);

  return (
    <div>
      <VerifyAssessment
        assessments={selected}
        open={openVerify}
        onClose={() => {
          setSelected([]);
          setOpenVerify(false);
          assessmentsRefresh();
        }}
      />

      {/* // @TODO: SAT-272 Review */}
      {/* <SecondaryButton disabled={!canReview} onClick={onReview}>
        Review
      </SecondaryButton> */}
      <SecondaryButton
        disabled={!canVerify}
        onClick={() => setOpenVerify(true)}
      >
        Verify
      </SecondaryButton>
      <SecondaryButton disabled={!canDoSomeAssigns} onClick={onAssign}>
        Assign
      </SecondaryButton>
    </div>
  );
};

export default AssessmentsActionButtons;
