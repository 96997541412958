import React, { useEffect, useState } from "react";
import Loader from "shared/src/components/Loader/Loader";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import {
  TabContent,
  TabGroup,
} from "shared/src/components/Tabs/TabGroup/TabGroup";

import { SearchAssessments } from "./Assessments/SearchAssessments";
import { AssessmentsFiltersArgs } from "../api/getAssessments";
import { useNavigate } from "react-router-dom";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import {
  PageOfEventWithDocStatsVM,
  EventWithDocStatsVM,
} from "@rtslabs/field1st-fe-common/storm";
import { DashboardTabPage } from "./Dashboard/DashboardTabPage";

import styles from "./DashboardScreen.module.scss";
import { API, useAPI } from "@rtslabs/field1st-fe-common";
import { useAppDispatch } from "../redux";
import { setAssessmentsFilters } from "../redux/filters/actions";
import { fetchActiveEvents } from "../api/getEvents";
import { NoPermissions } from "shared/src/components/routes/NoPermissions";
import { ALL_STORM_ROLES } from "../permissions/permissionSets";

const DashboardScreen = () => {
  const [events, setEvents] = useState<PageOfEventWithDocStatsVM>();
  const [currentEventId, setCurrentEventId] =
    useState<EventWithDocStatsVM["id"]>();
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();
  const userRoles = API.Environment.getRoles();

  const navigate = useNavigate();

  const handleUpdateFilters = async (filters: AssessmentsFiltersArgs) => {
    dispatch(setAssessmentsFilters(filters));
    navigate("/assessments");
  };

  const handleTabChange = (tab: TabContent) => {
    eventsCall.refresh();
    setCurrentEventId(+tab.tabId);
  };

  const eventsCall = useAPI(fetchActiveEvents);

  useEffect(() => {
    if (eventsCall.data) {
      setError("");
      setEvents(eventsCall.data);
    } else if (eventsCall.error) {
      setError("Unable to load events");
    }
  }, [eventsCall.data, eventsCall.error]);

  if (!userRoles.some((r) => ALL_STORM_ROLES.includes(r))) {
    return <NoPermissions noReturn />;
  }

  return (
    <>
      <SearchAssessments
        onUpdateFilters={handleUpdateFilters}
        events={events?.content || []}
        isDashboard
      />
      <PageHeader title="Dashboard"></PageHeader>
      <Loader loading={!events && !error}>
        <TabGroup
          variant="white"
          tabs={
            events?.content.map((event) => ({
              label: event.eventName,
              tabId: event.id.toString(),
              tabPanelId: event.id.toString(),
              tabPanelContent: (
                <DashboardTabPage
                  event={event}
                  currentEventId={currentEventId || events.content[0].id}
                />
              ),
            })) || []
          }
          classes={{
            tabLabel: styles.tabLabel,
            tabList: styles.tabList,
            tab: styles.tab,
            select: styles.tabSelect,
          }}
          maxWidth={990} // pulled from Wrappers.module.scss
          maxTabs={5}
          dropDownText="View All"
          onTabChange={handleTabChange}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Loader>
    </>
  );
};

export default DashboardScreen;
