import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordReset from "../../../shared/src/components/auth/PasswordReset";
import { AuthRequestStatus } from "../../../shared/src/components/auth/types";
import { API, CoreRedux } from "@rtslabs/field1st-fe-common";

const PasswordResetScreen = () => {
  const [username, setUsername] = useState<string>("");
  const [keyExpired, setKeyExpired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<AuthRequestStatus>();

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const resetKey = params.get("key");
  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  const onResetPassword = async (newPassword: string) => {
    try {
      if (resetKey) {
        await API.resetPassword({
          vm: {
            key: resetKey,
            newPassword,
          },
        });
        setStatus("success");
      }
    } catch (err) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    if (resetKey) {
      (async () => {
        try {
          setLoading(true);
          const user = await API.validatePasswordKey({ key: resetKey });
          if (user.email) {
            setUsername(user.email);
          } else {
            setKeyExpired(true);
          }
        } catch {
          setKeyExpired(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [resetKey]);

  return (
    <PasswordReset
      username={username}
      keyExpired={keyExpired}
      loading={loading}
      logo={logo}
      status={status}
      onResetPassword={onResetPassword}
      onReturnToLogin={() => navigate("/login")}
    />
  );
};

export default PasswordResetScreen;
