import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import AppliedFilters from "../common/filters/AppliedFilters";
import { FilterOption } from "../common/filters/Filter";
import { LOCATION_OPTIONS, STATUS_OPTIONS } from "./assessmentFilterOptions";
import styles from "./Assessments.module.scss";
import AssessmentsFilters, { EventNameAndIDProp } from "./AssessmentsFilters";
import {
  Storm,
  useAsyncEffect,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { toTitleCase } from "shared/src/util/toTitleCase";
import { useSelector } from "react-redux";
import { selectAssessmentsFilters } from "../../redux/filters/selectors";
import { useAppDispatch } from "../../redux";
import { setAssessmentsFilters } from "../../redux/filters/actions";
import { fetchEventById } from "../../api/getEvents";
import { AssessmentsFiltersArgs } from "../../api/getAssessments";

interface SearchAssessmentsProps {
  onUpdateFilters?: (filters: AssessmentsFiltersArgs) => void;
  events: Storm.EventWithDocStatsVM[];
  isDashboard?: boolean;
  setPropQuery?: Dispatch<SetStateAction<string | undefined>>;
  eventOnlyId?: number;
  showFilterMessage?: boolean;
  clearSelected?: () => void;
  noPills?: boolean;
}

export const SearchAssessments: FC<SearchAssessmentsProps> = ({
  onUpdateFilters,
  events,
  isDashboard,
  setPropQuery,
  eventOnlyId,
  showFilterMessage,
  clearSelected,
  noPills,
}) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const assessmentsFilters = useSelector(selectAssessmentsFilters);
  const [eventNameAndId, setEventNameAndId] = useState<EventNameAndIDProp>();
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>(assessmentsFilters.query || ""); // for debounced
  const debouncedQuery = useDebouncedValue(query, 600);

  const handleSearchQuery = (query: string) => {
    if (clearSelected) {
      clearSelected();
    }
    if (setPropQuery) {
      // if exist, set the query to the parent that requires it bypassing the redux state.
      // Usually found in new assessments drawer when searching for any existing assessment
      // that they can start rather than creating a new assessment
      setPropQuery(query);
    } else {
      // sets the query for debounced. This will be dipatched after the time
      // in the useEffect
      setQuery(query);

      if (isDashboard && onUpdateFilters) {
        onUpdateFilters({ eventIds: "active", query });
      }
    }
  };

  function handleOpenDrawer(): void {
    setDrawerIsOpen(true);
  }

  function handleCloseDrawer(): void {
    setDrawerIsOpen(false);
  }

  function handleRemoveFilter(filterKey: string) {
    let updatedFilters: AssessmentsFiltersArgs = {
      ...assessmentsFilters,
      [filterKey]: undefined,
    };

    if (filterKey === "assessorName") {
      updatedFilters = {
        ...assessmentsFilters,
        assessorState: undefined,
        assessorId: undefined,
        assessorName: undefined,
      };
    }

    onUpdateFilters?.(updatedFilters);
  }

  const appliedFilters = useMemo(
    () =>
      assessmentsFilters &&
      !!events.length &&
      Object.entries(assessmentsFilters).reduce((filters, [key, value]) => {
        if (
          ![undefined, "ALL"].includes(value) &&
          !(eventOnlyId && key === "eventIds") &&
          !(!value.length && key === "regions") &&
          !(key === "assessorId") &&
          !(
            key === "assessorState" &&
            value === Storm.AssessmentAssignment.ASSIGNED_TO_SPECIFIED
          ) &&
          !(key === "query")
        ) {
          filters.push({
            value: key,
            label: getFilterLabel(key, value, events) || "",
          });
        }
        return filters;
      }, [] as FilterOption[]),
    [assessmentsFilters, events]
  );

  const filterMessage =
    appliedFilters &&
    showFilterMessage &&
    !eventOnlyId &&
    !appliedFilters.find((sf) => sf.value === "eventIds") &&
    "All Events Currently Shown";

  useEffect(() => {
    // listen to debounced query and then dispatch after the time
    dispatch(setAssessmentsFilters({ ...assessmentsFilters, query }));
  }, [debouncedQuery]);

  // if the label is blank while eventIds is applied, it would mean the event is already closed
  // so lets fetch the event by id to get a name. Or, if it's on event view, fetch that
  const needClosedEventName =
    appliedFilters &&
    (appliedFilters.find((sf) => sf.value === "eventIds")?.label === "" ||
      (!appliedFilters.length && !!eventOnlyId));

  useAsyncEffect(async () => {
    let thisEvent: Storm.EventWithDocStatsVM;

    if (
      needClosedEventName &&
      assessmentsFilters.eventIds &&
      assessmentsFilters.eventIds !== "active"
    ) {
      thisEvent = await fetchEventById(assessmentsFilters.eventIds);
      setEventNameAndId({
        name: thisEvent.eventName,
        id: thisEvent.id,
      });
    }
  }, [needClosedEventName]);

  return (
    <>
      <div className={styles.searchBarContainer}>
        <SearchBar
          filterDrawer={
            onUpdateFilters && {
              props: {
                isOpen: drawerIsOpen,
                anchor: "right",
                onClose: handleCloseDrawer,
                onOpen: handleOpenDrawer,
              },
              filterComponent: (
                <AssessmentsFilters
                  events={events}
                  onClose={handleCloseDrawer}
                  handleFilter={onUpdateFilters}
                  eventOnlyId={eventOnlyId}
                  isDashboard={isDashboard}
                  eventNameAndId={eventNameAndId}
                />
              ),
            }
          }
          onSearch={handleSearchQuery}
          initialQuery={
            isDashboard || setPropQuery ? "" : assessmentsFilters.query
          }
          className={styles.searchBar}
          waitForEnter={isDashboard}
        />
      </div>
      {/* on Dashboard, hide the pills until they hit enter to see the results */}
      {!noPills && appliedFilters && !isDashboard && (
        <AppliedFilters
          onRemoveFilter={handleRemoveFilter}
          selectedFilters={appliedFilters}
          filterMessage={filterMessage}
          closedEventName={eventNameAndId?.name}
        />
      )}
    </>
  );
};

function getFilterLabel(
  key: string,
  value: string | string[] | number,
  events: Storm.EventWithDocStatsVM[]
): string | undefined {
  switch (key) {
    case "eventIds":
      if (value === "active") return "All Active Events";
      return events.find((e) => e.id === +value)?.eventName;
    case "actionRequiredFilter":
      return `Action ${
        value === Storm.AssessmentActionRequiredFilter.NO ? "Not" : ""
      } Required`;
    case "location":
      return LOCATION_OPTIONS.find((e) => e.value === +value)?.label;
    case "regions":
    case "statuses":
    case "workLocationNicknames":
      return (value as string[]).map((v) => toTitleCase(v)).join(", ");
    case "assessorState":
    case "assessorName":
      return toTitleCase(value as string);
    default:
      return;
  }
}
