import { orderBy } from "lodash";
import React from "react";

import { AnswerSourceType, MapWidgetSaveVm } from "@rtslabs/field1st-fe-common";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { AssistiveLink } from "shared/src/components/TextInput/types";
import { QAProps } from "shared/src/qa-slugs";
import { FBItem, FBQuestion } from "shared/src/components/clientAdmin/types";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import { GlMap } from "shared/src/components/GlMap/GlMap";

export interface MapWidgetQuestion {
  answerSourceType?: AnswerSourceType | null;
  assistiveLink?: AssistiveLink | null;
  assistiveText?: string | null;
  iconColor: string;
  isRequired?: boolean;
  label?: string | null;
  name: string;
  placeholder?: string | null;
  questionRootId?: number | null;
}

/**
 * Sort widget questions by their answer source type (descending), placing "DATA_SOURCE" before "CURRENT_GPS"
 * @param questions
 */
function sortQuestions(questions: Array<MapWidgetQuestion>) {
  return orderBy(questions, (question) => question.answerSourceType, ["desc"]);
}

function generateQuestions(
  item: FBItem<MapWidgetSaveVm>,
  siblings: Array<FBItem>
) {
  const questions = item.questions?.map((question) => {
    /** global form question */
    const globalQuestion: FBQuestion | undefined = siblings.find(
      (s) => s.rootId === question.questionRootId
    ) as FBQuestion;

    const globalProperties = globalQuestion?.properties;
    const globalAnswerSource = globalQuestion?.answerSource;
    const detailedSearch =
      globalAnswerSource?.properties?.detailedSearch ||
      question.answerSource?.properties?.detailedSearch;
    const defaultLinkName =
      question.answerSource?.type === "CURRENT_GPS"
        ? "Find My Geo Location"
        : question.answerSource?.type === "DATA_SOURCE"
        ? "Search Locations"
        : "";

    const widgetQuestion: MapWidgetQuestion = {
      answerSourceType: globalAnswerSource?.type,
      iconColor: question.iconColor,
      name: `mapQuestion_${question.id}`,
      assistiveText: detailedSearch?.enabled
        ? globalProperties?.assistiveText
        : undefined,
      label: globalQuestion?.title,
      placeholder: globalProperties?.placeHolderText,
      assistiveLink: detailedSearch?.enabled
        ? {
            label: detailedSearch.linkName || defaultLinkName,
          }
        : undefined,
      isRequired: !!globalQuestion.formProperties?.isRequired,
    };

    return widgetQuestion;
  });
  return sortQuestions(questions || []);
}

interface Props extends QAProps {
  item: FBItem<MapWidgetSaveVm>;
  /** Items contained in the same section.items array as the widget */
  siblings: Array<FBItem>;
  disableControls?: boolean;
}

export function MapWidget({ qa, item, siblings, disableControls }: Props) {
  const [mapQuestions, setMapQuestions] = React.useState<
    Array<MapWidgetQuestion>
  >([]);
  const [mapMarkers, setMapMarkers] = React.useState<Array<LocationMarker>>([]);

  /*
   * Configure mock location fields on item.questions.length prop change
   *
   * Icon color and GPS enablement are derived from each formik widget question.
   * All other properties are derived from the related global form question.
   * This strategy keeps mutable and immutable properties separate for form marshalling and submission.
   */
  React.useEffect(() => {
    const mapQuestions = generateQuestions(item, siblings);
    const mapMarkers = mapQuestions.map((q) => ({
      onUpdate: () => undefined,
      color: q.iconColor,
    }));

    setMapQuestions(mapQuestions);
    setMapMarkers(mapMarkers);
  }, [item.questions, item.questions?.length, siblings, generateQuestions]);

  return (
    <div data-testid={qa}>
      {item.includeMap && (
        <GlMap
          disableControls={disableControls}
          initialViewport={{
            width: "100%",
            height: 281,
            zoom: 14,
            center: { latitude: 37.548083, longitude: -77.466722 },
          }}
          markers={mapMarkers}
        />
      )}
      {mapQuestions.map((question) => (
        <TextInput
          key={question.name}
          assistiveLink={question.assistiveLink}
          elementBefore={
            <i
              className="icon icon-icons8-marker"
              style={{
                fontSize: 38,
                color: question.iconColor,
              }}
            />
          }
          assistiveText={question.assistiveText}
          label={question.label}
          name={question.name}
          placeholder={question.placeholder || ""}
          required={question.isRequired}
        />
      ))}
    </div>
  );
}
