import {
  AssessmentState,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import React, { FC } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./AssessmentActionMenu.module.scss";
import moment from "moment";
import { AssessmentStateDisplay } from "../../AssessmentStateDisplay";
interface Props {
  assessment: StormDocumentExtensionVm;
  onClose: () => void;
}

function showStatusDetails(status: AssessmentState): boolean {
  if (
    status === AssessmentState.UNASSIGNED ||
    status === AssessmentState.CANCELLED
  ) {
    return false;
  } else {
    return true;
  }
}

export const getAdverb = (status: AssessmentState): string | null => {
  if (
    status === AssessmentState.UNASSIGNED ||
    status === AssessmentState.CANCELLED
  ) {
    return null;
  } else if (status === AssessmentState.ASSIGNED) {
    return "to";
  } else {
    return "by";
  }
};

export const AssessmentActionMenuHeader: FC<Props> = ({
  assessment,
  onClose,
}) => {
  const statusDate = moment(assessment.document.dateLastSubmitted).format(
    "M/DD/YYYY"
  );
  const statusDetails = showStatusDetails(
    assessment.assessmentStatus.currentState
  )
    ? `${getAdverb(assessment.assessmentStatus.currentState)} ${
        assessment.assessor?.fullName
      } ${statusDate}`
    : statusDate;

  return (
    <div className={styles.assessmentContainer}>
      <div className={styles.flexContainer}>
        <h3 className={styles.assessmentHeader}>{assessment.document.title}</h3>
        <div className={styles.wrapper}>
          <span className={styles.substationName}>
            {assessment.workLocation.name}
          </span>
        </div>
        <div className={styles.wrapper}>
          <span className={styles.substationName}>
            {assessment.workLocation.physicalAddress?.addressString}
          </span>
        </div>
        <div className={styles.statusContainer}>
          <AssessmentStateDisplay
            actionRequired={assessment.actionRequired}
            currentState={assessment.assessmentStatus.currentState}
            statusDetails={statusDetails}
          />
        </div>
      </div>
      <div className={styles.iconContainer} onClick={onClose}>
        <Icon type="delete_sign" className={styles.icon} />
      </div>
    </div>
  );
};
