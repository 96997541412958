import React, { FC, useCallback } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MUIMenu from "@mui/material/Menu";

import styles from "./UserMenu.module.scss";
import MenuHeader from "./UserMenuHeader";
import { Icon } from "../../Icon/Icon";
import { API, ParticipantUserVM } from "@rtslabs/field1st-fe-common";
import { Components, ElementType } from "../../../qa-slugs";

interface Props {
  onLogout: () => void;
  user: ParticipantUserVM | null;
}

// todo move this somewhere better?
function getUserInitials(firstName: string, lastName: string): string {
  let initials = "";
  if (firstName) {
    initials += firstName[0].toUpperCase();
  }
  if (lastName) {
    initials += lastName[0].toUpperCase();
  }

  return initials;
}

/**
 * @deprecated
 * Use packages/field-first/src/components/navigation/UserMenu/UserMenu.tsx
 * This component can be deleted when there are no more things that reference it.
 *
 * Renders the User Menu component in the top right of the Desktop view
 */
export const UserMenu: FC<Props> = ({ onLogout, user }) => {
  const firstName =
    user?.firstName || API.Environment.getParsedAuthToken?.firstName || "";
  const lastName =
    user?.lastName || API.Environment.getParsedAuthToken?.lastName || "";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleLogout = useCallback(() => {
    onLogout();
    handleClose();
  }, [handleClose]);

  const userInitials = getUserInitials(firstName, lastName);

  return (
    <div className={styles.userMenu}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        classes={{
          root: styles.button,
        }}
        onClick={handleClick}
        data-testid={`user-initials-profile-${ElementType.Button}`}
      >
        <span className={styles.initials}>{userInitials}</span>
        <span>
          <Icon type="expand_arrow" className={styles.expandIcon} />
        </span>
      </Button>
      <MUIMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="user-menu-modal"
      >
        <MenuHeader
          userInitials={userInitials}
          name={`${firstName} ${lastName}`}
          onNavigate={handleClose}
        />
        <MenuItem
          onClick={handleLogout}
          classes={{
            root: styles.menuItem,
          }}
        >
          <div
            data-testid={`sign-out-${ElementType.Button}`}
            className={styles.label}
          >
            <p className={styles.signOutText}>Sign Out</p>
            <Icon color="red" type="chevron-right" />
          </div>
        </MenuItem>
      </MUIMenu>
    </div>
  );
};
