import React, { FC } from "react";
import style from "./Header.module.scss";

interface HeaderProps {
  children?: React.ReactNode;
  title: string;
  description?: string;
}

const Header: FC<HeaderProps> = ({ title, description, children }) => {
  return (
    <div className={style.header}>
      <div>
        {description && (
          <span className={style.sectionDescription}>{description}</span>
        )}
        <h1 className={style.h1}>{title}</h1>
      </div>
      {children}
    </div>
  );
};

export default Header;
