import React, { FC } from "react";
import FormBuilder from "shared/src/components/clientAdmin/formBuilder/FormBuilder";
import { FBConfigsProvider } from "shared/src/util/hooks/useFBConfigs";
import { ConfigsContextProps } from "shared/src/components/clientAdmin/defaultFBConfigs";
import {
  AppWidgets,
  appWidgetsList,
} from "./clientAdmin/formBuilder/create/content/AppWidgets";
import { Params, useParams } from "react-router-dom";
import { Widget } from "./Assessments/Assessment/Widget";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

export const FormBuilderDebugScreen: FC = () => {
  const configs: ConfigsContextProps = { enableSettings: false };
  const formConfig = useParams<Params>().config;
  const qaConfigs = formConfig && JSON.parse(atob(formConfig));
  const documentTerm = useGroupTerm(
    "document",
    "noun",
    undefined,
    "Assessment"
  );

  return (
    <FBConfigsProvider value={qaConfigs || configs}>
      <FormBuilder
        appWidgets={AppWidgets}
        appWidgetsList={appWidgetsList}
        widget={Widget}
        documentTerm={documentTerm}
      />
    </FBConfigsProvider>
  );
};
