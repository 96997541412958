import React, { FC } from "react";
import { useSelector } from "react-redux";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { AssessmentActionMenuHeader } from "./AssessmentActionMenuHeader/AssessmentActionMenuHeader";
import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import AssessmentSidebarMenu from "./AssessmentSidebarMenu/AssessmentSidebarMenu";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface Props {
  open: boolean;
  onClose: (isReset?: boolean) => void;
  assessment: StormDocumentExtensionVm;
  hideView?: boolean;
}

export const AssessmentActionMenu: FC<Props> = ({
  open,
  onClose,
  assessment,
  hideView = false,
}) => {
  const documentGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "document")
  );

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  return (
    <>
      <Drawer
        isOpen={open}
        anchor={isDesktop ? "right" : "bottom"}
        onClose={onClose}
      >
        <DrawerContentWrapper>
          <AssessmentActionMenuHeader
            assessment={assessment}
            onClose={onClose}
          />
          <AssessmentSidebarMenu
            assessment={assessment}
            terms={documentGroupTerm}
            hideView={hideView}
            onClose={onClose}
          />
        </DrawerContentWrapper>
      </Drawer>
    </>
  );
};
