import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./PageHeader.module.scss";

interface Props {
  children?: React.ReactNode;
  title?: string;
  className?: string;
}

export const PageHeader: FC<Props> = ({ children, title, className }) => {
  return (
    <div className={joinClassNames(styles.wrapper, className)}>
      {title && <h1 className={styles.pageTitle}>{title}</h1>}
      {children}
    </div>
  );
};
