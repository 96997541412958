import { Storm } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import { FilterOption } from "../common/filters/Filter";

interface FilterOptionEventStatus extends FilterOption {
  value: Storm.EventStatusFilter;
}

export const EVENT_STATUS_OPTIONS: FilterOptionEventStatus[] = [
  {
    label: "Active Events",
    value: Storm.EventStatusFilter.ACTIVE,
  },
  {
    label: "Closed Events",
    value: Storm.EventStatusFilter.CLOSED,
  },
];

const yearOptions: FilterOption[] = [];
const minYear = 2020;
const currentDate = moment();
for (let i = currentDate.year(); i >= minYear; i--) {
  yearOptions.push({
    label: i.toString(),
    value: i.toString(),
  });
}

export const EVENT_YEAR_OPTIONS: FilterOption[] = yearOptions;

const monthOptions: FilterOption[] = [];
const dateStart = moment().startOf("year");
const dateEnd = moment().endOf("year");
while (dateStart <= dateEnd) {
  monthOptions.push({
    label: dateStart.format("MMMM"),
    value: dateStart.format("M"),
  });
  dateStart.add(1, "month");
}
export const EVENT_MONTH_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  ...monthOptions,
];

export const filterStartDates = (year: string, month: string) => {
  const isWholeYear = month === "ALL";
  const currentFilterDate = moment(
    `"${year}-${isWholeYear ? "01" : month}-01"`
  );
  return {
    minStartDate: currentFilterDate
      .startOf(isWholeYear ? "year" : "month")
      .format("YYYY-MM-DD"),
    maxStartDate: currentFilterDate
      .endOf(isWholeYear ? "year" : "month")
      .format("YYYY-MM-DD"),
  };
};
