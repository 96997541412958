import React, { FC } from "react";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { AssignAssessmentContent } from "../../AssignAssessmentDrawer/AssignAssessmentContent";
import { Storm } from "@rtslabs/field1st-fe-common";

interface ReassignAssessmentSidebarProps {
  assessment: Storm.StormDocumentExtensionVm;
  open: boolean;
  onCancel: () => void;
  onClose: (isReset?: boolean) => void;
  isStandalone?: boolean;
  customTemp?: (selectedAssessor: Storm.StormParticipantVm) => void;
}

export const ReassignAssessmentSidebar: FC<ReassignAssessmentSidebarProps> = ({
  assessment,
  open,
  onCancel,
  onClose,
  isStandalone,
  customTemp,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });
  const AssignContent = () => (
    <AssignAssessmentContent
      selected={[assessment]}
      onClose={onClose}
      onCancel={onCancel}
      customTemp={customTemp}
    />
  );

  return (
    <>
      {!isStandalone && (
        <Drawer
          isOpen={open}
          onClose={onCancel}
          anchor={isDesktop ? "right" : "bottom"}
          disableBackdrop
        >
          <AssignContent />
        </Drawer>
      )}
      {isStandalone && open && <AssignContent />}
    </>
  );
};
