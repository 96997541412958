import React, { FC } from "react";
import styles from "./Assessments.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";

export const NoAssessments: FC = () => {
  return (
    <div data-testid="noAssessments" className={styles.noAssessments}>
      <Icon type="document-2" className={styles.noAssessmentsIcon} />
      <p className={styles.label}>No Assessments Found</p>
      <p>
        Ask a manager to add assessment locations or get started by adding a new
        assessment.
      </p>
    </div>
  );
};
