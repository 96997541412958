import { Storm, UserVm } from "@rtslabs/field1st-fe-common";
import { isStormCenterAdmin } from "../../../permissions/permissions.helpers";
import { store } from "../../../redux";

export const isReadonly = (
  assessment: Storm.StormDocumentExtensionVm,
  user: UserVm
): boolean => {
  const { currentState } = assessment.assessmentStatus;
  const isStormCenterVerified =
    currentState === Storm.AssessmentState.STORM_CENTER_VERIFIED;
  const isCancelled = currentState === Storm.AssessmentState.CANCELLED;
  const readOnly =
    !assessment.event.active ||
    assessment.document.readOnly ||
    isStormCenterVerified ||
    isCancelled ||
    ([
      Storm.AssessmentState.IN_REVIEW,
      Storm.AssessmentState.STORM_CENTER_VERIFIED,
    ].includes(currentState) &&
      !isStormCenterAdmin(user));

  return readOnly;
};

export const canSaveWhileAssigned = (
  assessment: Storm.StormDocumentExtensionVm
) => {
  const { user } = store.getState();
  const participantId = user.data?.participantId;
  const isCurrentUser = assessment.assessor?.id === participantId;
  const isInFieldAssessment = isInFieldStatus(assessment);

  // returns true if it is current user on active assessments. This is where it will
  // automatically assign to that user when saving. If assessments are submitted, then
  // the assignments does not change, so therefore, returns true.
  return isInFieldAssessment ? isCurrentUser : true;
};

export const isInFieldStatus = (assessment: Storm.StormDocumentExtensionVm) => {
  return [
    Storm.AssessmentState.UNASSIGNED,
    Storm.AssessmentState.ASSIGNED,
    Storm.AssessmentState.IN_PROGRESS,
  ].includes(assessment.assessmentStatus.currentState);
};
