import {
  API,
  DataSourceType,
  ParticipantDTO,
  ResponseContent,
  Storm,
  useDataSourceValues,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import MultiInput from "shared/src/components/MultiInput/MultiInput";
import { DEFAULT_USE_DEBOUNCE_DELAY } from "shared/src/util/debounceDelays";
import ErrorArray from "./ErrorArray";

interface ParticipantItem {
  id: number;
  label: string;
  content?: ResponseContent;
}

interface EmailUpdatesProps {
  canManagerEdit: boolean;
}

export const EmailUpdates: FC<EmailUpdatesProps> = ({ canManagerEdit }) => {
  const { values, errors, setFieldValue, setFieldTouched } =
    useFormikContext<Storm.EventVm>();
  const [currentValue, setCurrentValue] = React.useState("");
  const editable = values.active && canManagerEdit;

  const participantEmails = values.notifiedParticipantEmails.map((email) => ({
    id: -1,
    label: email,
  }));

  const [multiInputField] = useField("notifiedParticipantEmails");

  const debouncedQuery = useDebouncedValue(
    currentValue,
    DEFAULT_USE_DEBOUNCE_DELAY
  );

  const { dataSourceValues } = useDataSourceValues<
    ParticipantDTO,
    API.GetDataSourceValuesArgs["sort"]
  >({
    dataSourceKey: DataSourceType.PARTICIPANT,
    getDataSourceValues: API.getDataSourceValues,
    query: debouncedQuery,
  });

  const participantExistingEmails: Storm.EventVm["notifiedParticipantEmails"] =
    values.notifiedParticipantEmails;

  /** set suggestions as a email dropdown to add or list of names from AD */
  const suggestions = debouncedQuery.includes("@")
    ? [
        {
          id: -1,
          label: debouncedQuery,
        },
      ]
    : dataSourceValues
        .filter((dsv) => !participantExistingEmails.includes(dsv.content.email))
        .map((dsv) => ({
          id: dsv.id,
          label: dsv.content.email,
        }));

  /** When user selects a Participant from the AutoComplete Menu */
  const handleAddParticipantEmail = async (
    participantItem: ParticipantItem
  ) => {
    const participantEmails: string[] = [];
    participantItem.label.split(",").map((email: string) => {
      if (email.trim().length) {
        participantEmails.push(email.trim());
      }
    });

    setFieldValue("notifiedParticipantEmails", [
      ...participantExistingEmails,
      ...participantEmails,
    ]);
  };

  const handleRemoveParticipantEmail = (participantItem: ParticipantItem) => {
    setFieldValue(
      "notifiedParticipantEmails",
      values.notifiedParticipantEmails.filter(
        (value) => value !== participantItem.label
      )
    );
  };

  return (
    <MultiInput
      {...multiInputField}
      autoCompleteSuggestions={suggestions}
      canUseCustomValues={false}
      error={
        errors.notifiedParticipantEmails && (
          <ErrorArray error={errors.notifiedParticipantEmails} />
        )
      }
      idField="notifiedParticipantEmails"
      labelField="label"
      label="Email Updates"
      onChangeInput={setCurrentValue}
      onAddItem={handleAddParticipantEmail}
      onRemoveItem={handleRemoveParticipantEmail}
      onFocus={() => setFieldTouched("notifiedParticipantEmails", true)}
      placeholder="Who should receive updates?"
      selectedValues={participantEmails}
      disabled={!editable}
    />
  );
};
