import {
  FormSectionVm,
  Functions,
  QuestionDTO,
  isQuestionDTO,
} from "@rtslabs/field1st-fe-common";

export const inspectedByQuestion: QuestionDTO = {
  id: 1,
  rootId: 1,
  type: "QUESTION",
  subType: "TEXT_LINE",
  title: "Field Inspected By",
  workflowType: "FINAL",
};

export const reviewerQuestion: QuestionDTO = {
  id: 1,
  rootId: 1,
  type: "QUESTION",
  subType: "TEXT_LINE",
  title: "Storm Center Reviewer",
  workflowType: "FINAL",
};

// This is a copy from fe-common but we need to find this question and populate inspected by on first assessor
// that made the edit. Typically when the assessment status changed from UNASSIGNED/ASSIGNED to IN_PROGRESS
export function findInspectedByQuestion(
  childSections: FormSectionVm[],
  relatedSourceQuestion: QuestionDTO
): QuestionDTO | undefined {
  /* intentionally commented out because question is hardcoded with fake id. So it never hit and returns here.
   * so, I will not force calling the Functions for nothing
  // try first by root id
  const byRootId = Functions.findQuestionByRootId(
    childSections,
    relatedSourceQuestion.rootId
  );

  //   if (byRootId) {
  //     return byRootId.question;
  //   }
  */

  // then try by type & title
  for (const section of childSections) {
    for (const item of section.items) {
      if (
        isQuestionDTO(item) &&
        item.subType === relatedSourceQuestion.subType &&
        item.title.toLowerCase() === relatedSourceQuestion.title.toLowerCase()
      ) {
        return item;
      }
    }
  }

  // give up
  return undefined;
}
