import { CoreRedux, Storm } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import { Link } from "shared/src/components/Link/Link";
import { ReadOnlyDoc } from "shared/src/components/Document/ReadOnly/ReadOnlyDoc";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import { FormikDocument } from "./Assessments/Assessment/FormikDocument";
import { ReadOnlySection } from "./Assessments/Assessment/ReadOnlySection";
import styles from "./ReviewAssessmentsScreen.module.scss";
import { Button } from "shared/src/components/Button/Button";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { StormHeader } from "./navigation/StormHeader";
import { Params, useNavigate, useParams } from "react-router-dom";
import { isReadonly } from "./Assessments/Assessment/assessment.helpers";
import { useSelector } from "react-redux";
import { STORM_CENTER_ROLES } from "../permissions/permissionSets";
import { ReassignAssessmentSidebar } from "./Assessments/AssessmentActionMenu/AssessmentSidebarMenu/ReassignAssessmentSidebar";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { toast } from "react-toastify";
import {
  Toast,
  ToastStatus,
  successToastOptions1,
} from "shared/src/components/Toast/Toastify";
import { fetchAssessmentById } from "../api/getAssessments";
import { toTitleCase } from "shared/src/util/toTitleCase";

export const ReviewAssessmentsScreen: FC = () => {
  const navigate = useNavigate();
  const [formProgress, setFormProgress] = useState<number>(0);
  const [assessment, setAssessment] =
    useState<Storm.StormDocumentExtensionVm>();
  const [error, setError] = useState<string>();
  const [actionRequired, setActionRequired] = useState<boolean>(false);
  const [allowRedirect, setAllowRedirect] = useState<boolean>(false);
  const [allowVerify, setAllowVerify] = useState<boolean>(false);
  const [allowReassign, setAllowReassign] = useState<boolean>(false);
  const [openReassign, setOpenReassign] = useState<boolean>(false);
  const [statusUpdatedOnLoad, setStatusUpdatedOnLoad] =
    useState<boolean>(false);
  const params = useParams<Params>();
  const { user } = useSelector(CoreRedux.selectUser)!;
  const isStormCenterAdmin = user.authorities?.some((r) =>
    STORM_CENTER_ROLES.includes(r)
  );
  const isCancelled =
    assessment?.assessmentStatus.currentState ===
    Storm.AssessmentState.CANCELLED;

  const id = Number(params.id);

  const getAssessments = async () => {
    try {
      const res = await fetchAssessmentById(id);
      setAssessment(res);
      setActionRequired(res.actionRequired);
    } catch (_e) {
      setError(`Unable to load assessment with id of ${id}`);
    }
  };

  const isSubmittedAssessment =
    assessment?.assessmentStatus.currentState ===
      Storm.AssessmentState.ASSESSOR_COMPLETE ||
    assessment?.assessmentStatus.currentState ===
      Storm.AssessmentState.IN_REVIEW;

  const onSubmit = async () => {
    if (assessment) {
      setAllowVerify(false);
      setAllowReassign(false);
      const assessmentStatus: Storm.API.PutAssessmentStateArgs = {
        updateAssessmentStatus: {
          action: Storm.AssessmentAction.COMPLETE_REVIEW,
          actionRequired,
          stormDocumentExtensionIds: [assessment.id],
        },
      };

      await Storm.API.putAssessmentState(assessmentStatus);
      setAllowRedirect(true);
      window.scrollTo(0, 0);
    }
  };

  const documentTerm = useGroupTerm(
    "document",
    "noun",
    "singular",
    "Assessment"
  );

  const isUnassigned =
    assessment?.assessmentStatus.currentState ===
    Storm.AssessmentState.UNASSIGNED;

  const reviewActionBar = (
    <>
      <Checkbox
        disabled={!allowVerify || !assessment?.event.active}
        containerClassName={styles.actionRequired}
        label="Action required"
        checked={actionRequired}
        onChange={(checked) => setActionRequired(checked)}
      />
      <Button
        disabled={!allowVerify || !assessment?.event.active || isCancelled}
        onClick={() => onSubmit()}
        className={styles.button}
      >
        Verify {documentTerm}
      </Button>
      <Button
        disabled={!allowReassign || !assessment?.event.active || isCancelled}
        onClick={() => setOpenReassign(true)}
        className={styles.button}
      >
        {isUnassigned ? "Assign" : "Reassign"} {documentTerm}
      </Button>
    </>
  );
  const customFooter = (
    <div className={joinClassNames(styles.formActions)}>{reviewActionBar}</div>
  );

  useEffect(() => {
    setAllowVerify(isSubmittedAssessment && !!isStormCenterAdmin);
    setAllowReassign(!!isStormCenterAdmin);
  }, [isSubmittedAssessment]);

  useEffect(() => {
    getAssessments();
  }, []);

  const readOnly = assessment && isReadonly(assessment, user);

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const setAssessmentToInReview = async (): Promise<void> => {
    if (isStormCenterAdmin && assessment) {
      if (
        [
          Storm.AssessmentState.ASSESSOR_COMPLETE,
          Storm.AssessmentState.STORM_CENTER_VERIFIED,
        ].includes(assessment.assessmentStatus.currentState)
      ) {
        const action =
          Storm.AssessmentState.ASSESSOR_COMPLETE ===
          assessment.assessmentStatus.currentState
            ? Storm.AssessmentAction.START_REVIEW
            : Storm.AssessmentAction.UNDO_COMPLETE_REVIEW;
        const assessmentStatus: Storm.API.PutAssessmentStateArgs = {
          updateAssessmentStatus: {
            action,
            actionRequired,
            stormDocumentExtensionIds: [assessment.id],
          },
        };
        await Storm.API.putAssessmentState(assessmentStatus);
      }
    }
  };

  // This is a temp call for reviewing submitted document until we can actually implement Review Assessment
  // page in Release 2. But for now, this is a temp call to set assessment directly to ASSIGNED from IN_REVIEW
  // So, DE wants to reassign the any submitted assessment status directly. Our current workflow allows IN_REVIEW
  // to ASSIGNED
  const customTemp = async (
    selectedAssessor: Storm.StormParticipantVm
  ): Promise<void> => {
    if (assessment) {
      await setAssessmentToInReview();

      await Storm.API.putAssessmentState({
        updateAssessmentStatus: {
          action:
            assessment.assessmentStatus.currentState ===
            Storm.AssessmentState.UNASSIGNED
              ? Storm.AssessmentAction.ASSIGN
              : Storm.AssessmentAction.REASSIGN,
          assessorParticipantId: selectedAssessor.participant.id,
          stormDocumentExtensionIds: [assessment.id],
        },
      });
      getAssessments();
      setOpenReassign(false);
    }
  };

  const updateAssessmentStatusToReview = async () => {
    setStatusUpdatedOnLoad(true);
    if (isStormCenterAdmin && assessment && assessment.event.active) {
      setAssessment(undefined); // purge the state and reload
      await setAssessmentToInReview();
      getAssessments();
    }
  };

  function returnToAssessments(): void {
    return navigate("/assessments");
  }

  useEffect(() => {
    // Because we are still fetching assessment and setting the state, we need to use statusUpdatedOnLoad check
    // to ensure we don't need to keep running this function for any re-rendering changes until next refresh
    !statusUpdatedOnLoad && assessment && updateAssessmentStatusToReview();
  }, [assessment]);

  useEffect(() => {
    if (allowRedirect) {
      toast.success(
        <Toast
          status={ToastStatus.Redirecting}
          message={"Assessment Verified"}
        />,
        {
          ...successToastOptions1,
          autoClose: 5000,
          onClose: returnToAssessments,
        }
      );
    }
  }, [allowRedirect]);

  return (
    <Loader loading={!assessment && !error}>
      {assessment && (
        <Drawer
          isOpen={openReassign}
          anchor={isDesktop ? "right" : "bottom"}
          onClose={() => setOpenReassign(false)}
          qa={`ReviewAssessmentScreen-${id}-cancel-reassign`}
        >
          <ReassignAssessmentSidebar
            assessment={assessment}
            open={openReassign}
            onCancel={() => setOpenReassign(false)}
            onClose={() => false}
            isStandalone
            customTemp={customTemp}
          />
        </Drawer>
      )}
      <StormHeader />
      <nav className={styles.navigationHeader}>
        <Link to="/" className={styles.navigationLink}>
          <Icon type="chevron-left" className={styles.navigationChevron} />
          <span className={styles.dashboardLink}>Back to dashboard</span>
        </Link>
      </nav>

      {!!error && <ErrorText>{error}</ErrorText>}
      {!assessment && <Loader loading={!error} />}
      <div className={styles.reviewActionBarContainer}>{reviewActionBar}</div>
      {!!assessment && (
        <div className={styles.document}>
          {readOnly && (
            <>
              <ReadOnlySection assessment={assessment} />
              <ReadOnlyDoc
                document={assessment.document}
                altToastTitle={`${toTitleCase(
                  assessment.workLocation.name
                )} ${documentTerm}`}
              />
            </>
          )}

          {!readOnly && (
            <FormikDocument
              assessment={assessment}
              setFormProgress={setFormProgress}
              customFooter={customFooter}
              assessmentRefresh={getAssessments}
              inReviewView
            />
          )}
        </div>
      )}
    </Loader>
  );
};
