import React, { FC, useEffect, useState } from "react";

import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import {
  API,
  SortParams,
  Storm,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { SortableColumn } from "shared/src/components/TableUI/TableHead/TableHead";
import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import { getSortDirection } from "shared/src/helpers/mapping.helpers";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";

import styles from "./Events.module.scss";
import { EventsTable } from "./EventsTable";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import EventFilters from "./EventFilters";
import AppliedFilters from "../common/filters/AppliedFilters";
import { NewEventButton } from "./NewEventButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EventViewFilter,
  INITIAL_EVENT_FILTERS,
} from "../../redux/filters/reducer";
import { useAppDispatch, useAppSelector } from "../../redux";
import { selectEventsFilters } from "../../redux/filters/selectors";
import { filterStartDates } from "./eventFilters.helpers";
import moment from "moment";
import { startCase } from "lodash";
import { setEventsFilters } from "../../redux/filters/actions";
import { toast, ToastContainer } from "react-toastify";
import {
  successToastOptions,
  Toast,
  ToastStatus,
} from "shared/src/components/Toast/Toastify";

export const Events: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const message = location.state as string;
  const eventFilters = useAppSelector(selectEventsFilters);
  const [events, setEvents] = useState<EventWithDocStatsVM[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [size, setSize] = useState<number>(10);
  const [sort, setSort] = useState<SortParams | undefined>([
    "startDate",
    "desc",
  ]);
  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const userRoles = API.Environment.getRoles();

  async function fetchEvents(
    filterParams: Storm.API.GetEventsArgs
  ): Promise<void> {
    setLoading(true);
    try {
      const res = await Storm.API.getEvents(filterParams);
      setEvents(res.content);
      setPage(res.number);
      setTotalElements(res.totalElements);
      setTotalPages(res.totalPages);
      setSize(res.size);
    } catch {
      setError("Unable to load events");
    } finally {
      setLoading(false);
    }
  }

  function handleSortOrderChange(
    column: SortableColumn,
    sortOrder: SortOrder
  ): void {
    const sortDir = getSortDirection(sortOrder);

    // TODO progress sorting?
    const sortBy = column.id === "date" ? "startDate" : column.id;
    let newSort: SortParams | undefined = sortDir
      ? [sortBy, sortDir]
      : undefined;
    setSort(newSort);
  }

  function handlePaginationChange(page: number, size?: number): void {
    setPage(page);
    if (size !== undefined) setSize(size);
  }

  function handleClearSearch(): void {
    setQuery("");
  }

  function handleCloseFilters() {
    setFiltersOpen(false);
  }

  function handleOpenFilters() {
    setFiltersOpen(true);
  }

  function handleRemoveFilter(filterKey: string) {
    const updatedFilters: EventViewFilter = {
      ...INITIAL_EVENT_FILTERS,
      [filterKey]: INITIAL_EVENT_FILTERS[filterKey as keyof EventViewFilter],
    };

    dispatch(setEventsFilters(updatedFilters));
  }

  function handleFilter() {
    setPage(0);
    handleCloseFilters();
  }

  const debouncedQuery = useDebouncedValue(query, 600);

  useEffect(() => {
    fetchEvents({
      page,
      size,
      sort,
      query: debouncedQuery,
      statusFilter: eventFilters.statusFilter,
      ...filterStartDates(eventFilters.year, eventFilters.month),
    });
  }, [page, size, sort, debouncedQuery, eventFilters]);

  const hideBanner = () => {
    navigate("/events", { replace: true });
  };

  const filterMessage = `All ${startCase(
    eventFilters.statusFilter.toLowerCase()
  )} Events in Year ${eventFilters.year} Currently Shown`;

  useEffect(() => {
    !!message &&
      toast.success(<Toast status={ToastStatus.Success} message={message} />, {
        ...successToastOptions,
        autoClose: 5000,
        onClose: hideBanner,
        onClick: hideBanner,
      });
  }, [message]);

  return (
    <div>
      <ToastContainer closeButton={false} position="top-left" />
      <Breadcrumbs paths={[{ pathName: "Events" }]} data-testid="breadcrumb" />
      <PageHeader title="Events">
        <NewEventButton className={styles.eventButton} userRoles={userRoles} />
      </PageHeader>
      <div className={styles.searchBarContainer}>
        <SearchBar
          filterDrawer={{
            props: {
              anchor: "right",
              onClose: handleCloseFilters,
              onOpen: handleOpenFilters,
              isOpen: filtersOpen,
            },
            filterComponent: (
              <EventFilters
                onClose={handleCloseFilters}
                onFilter={handleFilter}
              />
            ),
          }}
          onSearch={setQuery}
          initialQuery={query}
          className={styles.searchBar}
        />
      </div>
      <AppliedFilters
        onRemoveFilter={(filterKey) => handleRemoveFilter(filterKey)}
        selectedFilters={
          eventFilters.month !== "ALL"
            ? [
                {
                  label: moment(eventFilters.month)
                    .format("MMMM")
                    .toLowerCase(),
                  value: eventFilters.month,
                },
              ]
            : []
        }
        filterMessage={filterMessage}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <EventsTable
        loading={loading}
        events={events}
        userRoles={userRoles}
        summary={{ page, size, totalElements, totalPages, sort }}
        onSortOrderChange={handleSortOrderChange}
        onPaginationChange={handlePaginationChange}
        searched={!!query}
        onClearSearch={handleClearSearch}
        onExportEvents={Storm.API.downloadEvents}
        exportEventsParams={{
          query,
          statusFilter: eventFilters.statusFilter,
        }}
      />
    </div>
  );
};

export default Events;
