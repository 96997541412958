import React, { FC } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./AppliedFilters.module.scss";
import { FilterOption } from "./Filter";
import { castArray } from "lodash";

interface Props {
  onRemoveFilter: (filterKey: string) => void;
  selectedFilters: FilterOption[];
  filterMessage?: string | false;
  closedEventName?: string;
}

const AppliedFilters: FC<Props> = ({
  onRemoveFilter,
  selectedFilters,
  filterMessage,
  closedEventName,
}) => {
  return (
    <>
      {filterMessage && (
        <div className={styles.filterMessage}>{filterMessage}</div>
      )}
      <div className={styles.appliedFilters}>
        {selectedFilters.map((filter) => {
          const labelArray = castArray(filter.label);
          if (filter.value && labelArray.length) {
            return (
              <div key={String(filter.value)} className={styles.filterPill}>
                <span>{labelArray.join(", ") || closedEventName}</span>
                <div
                  className={styles.iconContainer}
                  onClick={() => {
                    onRemoveFilter(filter.value as string);
                  }}
                  data-testid="delete_sign"
                >
                  <Icon type="delete_sign" className={styles.icon} />
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default AppliedFilters;
