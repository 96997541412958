import {
  CoreRedux,
  DocumentVm,
  QuestionDTO,
  SectionItem,
  Storm,
} from "@rtslabs/field1st-fe-common";
import { Formik, FormikProps } from "formik";
import React, {
  createRef,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { DocumentFormWrapper } from "shared/src/components/Document/DocumentForm/DocumentFormWrapper";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { validate } from "shared/src/components/Document/DocumentForm/validation";
import { DocumentToasts } from "shared/src/components/Document/DocumentToasts";
import { getFlattenedItems } from "shared/src/components/Document/DocumentForm/formEntity.helpers";
import { useSubmitAssessment } from "./useSubmitAssessment";
import { Widget } from "./Widget";
import { ReadOnlySection } from "./ReadOnlySection";
import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import { MapWidget } from "./MapWidget/MapWidget";
import { UnassignedEditModal } from "./UnassignedEditModal";
import { canSaveWhileAssigned, isInFieldStatus } from "./assessment.helpers";
import { useSelector } from "react-redux";
import { useInspectedBy } from "./useInspectedBy";
import { SetInReview } from "./SetInReview";

interface FormikDocumentProps {
  assessment: StormDocumentExtensionVm;
  setFormProgress: Dispatch<SetStateAction<number>>;
  customFooter?: ReactNode;
  assessmentRefresh?: () => void;
  inReviewView?: boolean;
}

export const FormikDocument: FC<FormikDocumentProps> = ({
  assessment,
  setFormProgress,
  customFooter,
  assessmentRefresh,
  inReviewView,
}) => {
  const navigate = useNavigate();
  const canSaveWhileAssignedRef = useRef<boolean>();
  const isInReview = useRef<boolean>();
  const isInFieldAssessment = isInFieldStatus(assessment);
  const user = useSelector(CoreRedux.selectUser);
  const isCurrentUserAssigned = user?.participantId === assessment.assessor?.id;
  const {
    questionsToDisable,
    handleInspectedBy,
    setIsInspectedBySet,
    handleReviewer,
  } = useInspectedBy({
    assessment,
    user,
  });

  useEffect(() => {
    isInReview.current =
      assessment.assessmentStatus.currentState ===
      Storm.AssessmentState.IN_REVIEW;
  }, [assessment.assessmentStatus.currentState]);

  const { error, handleSubmitAssessment, setError, submissionSuccessful } =
    useSubmitAssessment();

  const documentTerm: string = useGroupTerm(
    "document",
    "noun",
    "singular",
    "Assessment"
  );
  const documentsTerm: string = useGroupTerm(
    "document",
    "noun",
    "plural",
    "Assessments"
  );

  const flattenedItems: SectionItem[] = useMemo(
    () => getFlattenedItems(assessment.document.form.sections || []),
    [document]
  );

  const flattenedQuestions: QuestionDTO[] = useMemo(
    () =>
      flattenedItems.filter(
        (item) => item.type === "QUESTION"
      ) as QuestionDTO[],
    [flattenedItems]
  );

  const itemRefs: Map<number, React.RefObject<HTMLDivElement>> = useMemo(
    () =>
      flattenedItems.reduce((result, fi) => {
        result.set(fi.id, createRef<HTMLDivElement>());
        return result;
      }, new Map<number, React.RefObject<HTMLDivElement>>()),
    [flattenedItems]
  );

  function returnToDocs(): void {
    return navigate("/assessments");
  }

  const handleSubmit = async (values: DocumentVm) => {
    if (!canSaveWhileAssignedRef.current) {
      // Show a popup, prevents saving until user makes a decision
      return;
    }

    await handleSubmitAssessment({
      document: values,
      assessmentId: assessment.id,
      workLocationId: assessment.workLocation.id,
    });
  };

  useEffect(() => {
    canSaveWhileAssignedRef.current = canSaveWhileAssigned(assessment);
  }, [assessment.assessmentStatus.currentState, assessment.assessor?.id]);

  return (
    <Formik
      initialValues={assessment.document}
      validate={(values) => validate(values)}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<DocumentVm>) => {
        const { values, errors } = formikProps;
        const hasErrors = useMemo(
          () => Object.keys(errors).length > 0 || !!error,
          [errors, error]
        );

        return (
          <>
            {!inReviewView && (
              <UnassignedEditModal
                assessment={assessment}
                handleSubmit={handleSubmitAssessment}
                assessmentRefresh={assessmentRefresh}
                setIsInspectedBySet={setIsInspectedBySet}
              />
            )}

            <DocumentToasts
              documentTerm={documentTerm}
              documentsTerm={documentsTerm}
              hasErrors={hasErrors}
              error={error}
              submissionSuccessful={submissionSuccessful}
              initialFormValues={assessment.document}
              formValues={values}
              returnToDocs={returnToDocs}
              setError={setError}
              altToastTitle={`${assessment.workLocation.name} ${documentTerm}`}
            />
            <ReadOnlySection assessment={assessment} hasErrors={hasErrors} />
            <MapWidget assessment={assessment} />
            {isInReview.current && (
              <SetInReview
                assessment={assessment}
                inReviewView={!!inReviewView}
              />
            )}
            <DocumentFormWrapper
              formikProps={formikProps}
              formWidget={Widget}
              handleAutoSync={(values) => {
                isInFieldAssessment &&
                  handleInspectedBy(values, formikProps.setValues);
                isInReview.current &&
                  handleReviewer(values, formikProps.setValues);
                return handleSubmit(values);
              }}
              setFormProgress={setFormProgress}
              documentTerm={documentTerm}
              flattenedQuestions={flattenedQuestions}
              itemRefs={itemRefs}
              customFooter={customFooter}
              appPath={"storm/assessments"}
              questionsToDisable={questionsToDisable}
              disableSubmit={!isCurrentUserAssigned}
              disableSave={!isCurrentUserAssigned}
            />
          </>
        );
      }}
    </Formik>
  );
};
