import React, { FC } from "react";
import styles from "./EventView.module.scss";
import { fmtDate } from "../helpers";

interface DateOfImpactProps {
  startDate?: string | null;
  endDate?: string | null;
}

const DateOfImpact: FC<DateOfImpactProps> = ({ startDate, endDate }) => {
  return (
    <div className={styles.flexColumn}>
      <span className={styles.sectionDescription}>
        {!endDate && "Start "}Date of Impact
      </span>
      <span className={styles.bodyLarge}>
        {fmtDate(startDate)} {endDate && `- ${fmtDate(endDate)}`}
      </span>
    </div>
  );
};

export default DateOfImpact;
