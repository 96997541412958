import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/dist/main/storm";
import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { AssessmentStateMarker } from "../common/AssessmentStateMarker";

import styles from "./AssessmentItem.module.scss";
import { AssessmentStateDisplay } from "./AssessmentStateDisplay";

interface Props {
  assessment: StormDocumentExtensionVm;
  className?: string;
}

export const AssessmentItem: FC<Props> = ({ assessment, className }) => {
  const {
    actionRequired,
    assessmentStatus: { currentState },
  } = assessment;

  return (
    <div className={joinClassNames(styles.substationWrapper, className)}>
      <AssessmentStateMarker currentState={currentState} />
      <div className={styles.substationRowWrapper}>
        <div className={styles.substationNameWrapper}>
          <span className={styles.substationName}>
            {assessment.workLocation.name}
          </span>
          <span className={styles.substationDistance}></span>
        </div>
        <AssessmentStateDisplay
          currentState={currentState}
          actionRequired={actionRequired}
        />
      </div>
    </div>
  );
};
