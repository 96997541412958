import React, { FC } from "react";
import styles from "shared/src/components/auth/auth.module.scss";
import { Link } from "shared/src/components/Link/Link";

const LoginFailureScreen: FC = () => {
  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <h1>Access Denied</h1>
        <p>
          You do not have access to "Storm Center Electronic Field Assessment
          Tool (EFAT)", please call the Storm Center to obtain access to this
          application.
        </p>
        <Link to="/">Return to home</Link>
      </div>
    </div>
  );
};

export default LoginFailureScreen;
