import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { AssistiveText } from "../../../AssistiveText/AssistiveText";
import ErrorText from "../../../ErrorText/ErrorText";
import { PillRadioButton } from "../../../RadioButtons/RadioButton";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "../../../RadioButtons/RadioButtonsGroup";
import CommentField from "../CommentField/CommentField";
import MoreOptionsLoader from "../MoreOptionsLoader/MoreOptionsLoader";
import styles from "../RadioField/RadioField.module.scss";
import { useQuestion } from "../useQuestion";

interface Props {
  question: QuestionDTO;
}
const options: RadioButtonOption<string>[] = [
  { data: "true", label: { text: "Yes" } },
  { data: "false", label: { text: "No" } },
];

export const YesNoField: FC<Props> = ({ question }) => {
  const {
    error,
    onBlur,
    questionSelections,
    label = "",
    name,
    assistiveText,
    value,
    handleChangeBasicResponse,
    required,
    response,
    onChangeComment,
    commentRequired,
    enableComments,
    commentValue,
    commentError,
    qa,
  } = useQuestion(question);
  if (!questionSelections && !options) return null;

  let loadMore: () => void | undefined;
  let isLoading: boolean;
  let isLastPage: boolean;
  isLoading = false;
  isLastPage = true;

  const handleChange = (data: string): void => {
    handleChangeBasicResponse({
      id: response?.id ?? undefined,
      answer: data,
    });
  };

  const handleChangeComment = (comments: string) => {
    onChangeComment(comments);
  };

  function handleClearComment(): void {
    onChangeComment("");
  }

  return (
    <div
      className={joinClassNames(styles.container, error && styles.error)}
      onBlur={onBlur}
    >
      <RadioButtonsGroup
        label={label}
        name={name.toString()}
        onChange={handleChange}
        options={options}
        qa={qa}
        required={required}
        RadioButtonComponent={PillRadioButton}
        row={true}
        value={value || ""}
      />
      {question.answerSource?.dataSourceKey && (
        <MoreOptionsLoader
          onLoadMore={loadMore!}
          isLoadingMore={isLoading}
          isFinalPage={isLastPage}
        />
      )}

      <AssistiveText>{assistiveText}</AssistiveText>
      {error && <ErrorText>{error}</ErrorText>}

      {enableComments && value && (
        <CommentField
          className={styles.commentField}
          commentValue={commentValue}
          error={commentError}
          name={`${name}_comment`}
          handleClearComment={handleClearComment}
          handleUpdateComment={handleChangeComment}
          required={commentRequired}
          response={response}
          useDynamicTextArea
        />
      )}
    </div>
  );
};
