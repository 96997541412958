import React, { FC, ReactNode } from "react";
import { Modal } from "shared/src/components/Modal/Modal";

interface ConfirmationProps {
  action: () => void;
  open: boolean;
  closeModal: () => void;
  loading: boolean;
  content: ReactNode;
  title: string;
}

export const ConfirmModal: FC<ConfirmationProps> = ({
  action,
  loading,
  open,
  closeModal,
  content,
  title,
}) => {
  return (
    <Modal
      action={{
        text: "yes",
        callback: action,
        loading,
      }}
      content={content}
      handleClose={closeModal}
      open={open}
      title={title}
    />
  );
};
