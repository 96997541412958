import React from "react";
import styles from "./DrawerHeader.module.scss";
import { Icon } from "../Icon/Icon";
interface Props {
  onClose?: () => void;
  title?: string;
  subtitle?: string;
}

export const DrawerHeaderWithClose = ({ onClose, title, subtitle }: Props) => {
  return (
    <div className={styles.wrapper}>
      {/* Chevron */}

      <div
        onClick={onClose}
        className={styles.deleteWrapper}
        data-testid="drawerHeaderWithClose-delete_sign"
      >
        <Icon type="delete_sign" className={styles.icon} />
      </div>

      {/* Title */}
      {!subtitle && <h6 className={styles.title}>{title}</h6>}
      {subtitle && (
        <div>
          <h6 className={styles.title}>{title}</h6>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      )}
    </div>
  );
};
