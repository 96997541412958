import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import LoginScreen from "./components/LoginScreen";
import LoginFailureScreen from "./components/LoginFailureScreen";
import PasswordLoginScreen from "./components/PasswordLoginScreen";
import PasswordHelpScreen from "./components/PasswordHelpScreen";
import DevAppFlag from "shared/src/components/DevAppFlag";
import PasswordResetScreen from "./components/PasswordResetScreen";
import { ActivationScreen } from "./components/ActivationScreen";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import { useSelector } from "react-redux";
import { ApplicationConfigKey } from "@rtslabs/field1st-fe-common";
import {
  getApplicationConfigByName,
  selectRehydrated,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { useAppDispatch } from "./redux";

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const rehydrated = useSelector(selectRehydrated);

  useEffect(() => {
    getClientLogos();
  }, []);

  const getClientLogos = async () => {
    dispatch(
      getApplicationConfigByName({ keyName: ApplicationConfigKey.clientConfig })
    );
    await dispatch(
      getApplicationConfigByName({ keyName: ApplicationConfigKey.logoConfigs })
    );
    // getLogos();
  };

  // todo this could be better...
  if (!rehydrated) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <DevAppFlag appName="Storm Assessment Tool" />
      <Routes>
        <Route element={<LoginScreen />} path="/login" />
        <Route element={<LoginFailureScreen />} path="/login-failure" />
        <Route element={<PasswordLoginScreen />} path="/password-login" />
        <Route element={<PasswordHelpScreen />} path="/help" />
        <Route element={<PasswordResetScreen />} path="/password-reset" />
        <Route element={<ActivationScreen />} path="/activate" />

        <Route element={<AuthenticatedRoutes />} path="/*" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
