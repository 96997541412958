import React, { FC } from "react";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { SearchAssessorContent } from "./SearchAssessorContent";

interface SearchAssessorDrawerProps {
  onSelectAssessor: (value: any) => void;
  isOpen: boolean;
  onCancel: () => void;
}

export const SearchAssessorDrawer: FC<SearchAssessorDrawerProps> = ({
  onSelectAssessor,
  isOpen,
  onCancel,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onCancel}
      anchor={isDesktop ? "right" : "bottom"}
    >
      <SearchAssessorContent
        onSelectAssessor={onSelectAssessor}
        onCancel={onCancel}
      />
    </Drawer>
  );
};
