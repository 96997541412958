import React, { FC } from "react";
import { PieChart } from "shared/src/components/Charts/PieChart/PieChart";
import { EventProgressProps } from "./helpers";
import colors from "shared/src/components/Charts/PieChart/PieChartColors.module.scss";
import styles from "../Dashboard/EventCard.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface EventPieChartProps {
  progress: EventProgressProps;
  hideCenteredContent?: boolean;
}

export const EventPieChart: FC<EventPieChartProps> = ({
  progress,
  hideCenteredContent,
}) => {
  const sliceColors = [
    colors.warning, // Complete
    colors.primary, // Active
    colors.success, // Submitted
  ];

  const centeredContent = !hideCenteredContent && (
    <div className={joinClassNames(styles.percentContainer, styles.overlay)}>
      <div className={styles.percentWrapper}>
        <div className={joinClassNames(styles.percentage, styles.complete)}>
          {progress.progressPercentage}
        </div>
        <div className={styles.status}>Complete</div>
      </div>
    </div>
  );

  const data = [
    { id: "complete", value: progress.verified, label: "Complete" },
    {
      id: "active",
      value: progress.active,
      label: "Active",
    },
    {
      id: "submitted",
      value: progress.submitted,
      label: "Submitted",
    },
  ];

  return (
    <PieChart
      ariaLabel="Pie chart showing the proportion of documents by status."
      labels={false}
      innerRadius={hideCenteredContent ? 0.5 : 0.75}
      sliceColors={sliceColors}
      centeredContent={centeredContent}
      data={data}
    />
  );
};
