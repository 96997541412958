import { Storm } from "@rtslabs/field1st-fe-common";
import { colorMap } from "../../common/assessmentStateConstants";
import { LocationMarker } from "shared/src/api/geolocationAPI";

export const setAssessmentMapMarker = (
  assessment: Storm.StormDocumentExtensionVm
): LocationMarker => {
  const {
    actionRequired,
    assessmentStatus: { currentState },
    workLocation: { geolocation, name },
  } = assessment;

  return {
    locationName: name,
    geolocation: geolocation!,
    color: colorMap[actionRequired ? "ACTION_REQUIRED" : currentState].fill,
    borderColor:
      colorMap[actionRequired ? "ACTION_REQUIRED" : currentState].border,
  };
};
