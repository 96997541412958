import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { joinClassNames } from "../../helpers/theme.helpers";
import { Components, ElementType, QAProps } from "../../qa-slugs";
import { SecondaryButton } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import {
  errorToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
} from "../Toast/Toastify";
import styles from "./PhotoTarget.module.scss";

const FIVE_MEGABYTES = 5 * 1024 * 1024;

interface Props extends QAProps {
  onUploadPhoto: (files: File[]) => Promise<void>[];
  questionTitle: string;
  type: "IMAGES_ABOVE" | "IMAGES_BELOW";
}

export const PhotoTarget: FC<Props> = ({
  onUploadPhoto,
  type,
  qa,
  questionTitle,
}) => {
  const [hovering, setHovering] = useState(false);
  const [dropped, setDropped] = useState(false);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  function handleClick(
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) {
    hiddenFileInput.current?.click();
  }
  function validateAndUpload(files: File[]) {
    if (files.length === 0) return;
    const uploadable: File[] = [];
    files.forEach((file) => {
      if (
        file.size <= FIVE_MEGABYTES &&
        ["image/png", "image/jpeg"].includes(file.type)
      ) {
        uploadable.push(file);
      } else {
        toast.error(
          <Toast
            status={ToastStatus.Error}
            message={`${file.name} is not a PNG or JPEG file under 5MB. Please retry with a PNG or JPEG file under 5MB.`}
          />,
          errorToastOptions
        );
      }
    });

    Promise.allSettled(onUploadPhoto(uploadable)).then((uploads) => {
      const successfulUploads = uploads.filter(
        (upload) => upload.status === "fulfilled"
      );
      const failedUploads = uploads.filter(
        (upload): upload is PromiseRejectedResult =>
          upload.status === "rejected"
      );
      if (successfulUploads.length > 0) {
        toast.success(
          <Toast
            status={ToastStatus.Success}
            message={`${successfulUploads.length} ${
              successfulUploads.length === 1 ? "Photo" : "Photos"
            } Added${questionTitle ? ` to ${questionTitle}` : ""}`}
          />,
          {
            ...successToastOptions,
            autoClose: 5000,
          }
        );
      }
      failedUploads.forEach((upload) => {
        toast.error(
          <Toast status={ToastStatus.Error} message={upload.reason.message} />,
          errorToastOptions
        );
      });
    });
  }

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDropped(true);
    setHovering(false);
    if (e.dataTransfer.items) {
      const fileList: File[] = [];
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file) fileList.push(file);
        }
      }
      if (fileList.length > 0) {
        validateAndUpload(fileList);
      }
    }
    // transitions PhotoTarget background-color to white following photo drop
    setTimeout(() => setDropped(false), 2000);
  };
  function handleFileButton(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || e.target.files.length < 1) return;
    const filesToCheck: File[] = [];
    for (var i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i);
      if (file) filesToCheck.push(file);
    }
    validateAndUpload(filesToCheck);
  }

  return (
    <div
      className={joinClassNames(
        type === "IMAGES_ABOVE"
          ? styles.photoGalleryTarget
          : styles.photoTarget,
        hovering && styles.photoTargetHover,
        dropped && styles.photoTargetDrop
      )}
      onDragEnter={() => setHovering(true)}
      onDragLeave={() => setHovering(false)}
      onDragOver={(e) => {
        e.preventDefault();
        !hovering && setHovering(true);
      }}
      onDropCapture={handleDrop}
      data-testid={qa ? `${qa}-${ElementType.DropTarget}` : undefined}
    >
      {/* Photo Gallery Photo Target */}
      {type === "IMAGES_ABOVE" && (
        <div
          onClick={handleClick}
          className={joinClassNames(
            styles.dragAndDropArea,
            styles.addToGallery
          )}
        >
          <Icon type="camera" size={30} className={styles.cameraIcon} />
          <span>
            Add to <br /> Gallery
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileButton}
              style={{ display: "none" }}
              multiple={true}
              accept="image/jpeg,image/png"
              data-testid={qa ? `${qa}-${ElementType.FileUpload}` : undefined}
            />
          </span>
        </div>
      )}

      {/* Question Photo Target */}
      {type === "IMAGES_BELOW" && (
        <>
          <span className={styles.dragAndDropArea}>
            <Icon type="photo-icons" size={40} className={styles.photoIcons} />
            Drag and Drop
          </span>
          <span>
            <SecondaryButton
              onClick={handleClick}
              qa={qa ? `${qa}-${ElementType.Button}-upload` : undefined}
            >
              Upload Photo
            </SecondaryButton>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileButton}
              style={{ display: "none" }}
              multiple={true}
              accept="image/jpeg,image/png"
              data-testid={qa ? `${qa}-${ElementType.FileUpload}` : undefined}
            />
          </span>
        </>
      )}
    </div>
  );
};
