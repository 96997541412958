import React, { FC, useEffect, useMemo } from "react";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import moment from "moment";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { useField, useFormikContext } from "formik";
import { Select, SelectOption } from "shared/src/components/Select/Select";
import { FormSummaryVm, Storm } from "@rtslabs/field1st-fe-common";
import styles from "../EditEvent.module.scss";
import { EmailUpdates } from "./EmailUpdates";

type Props = {
  forms: FormSummaryVm[];
  canManagerEdit: boolean;
};

const FormElements: FC<Props> = ({ forms, canManagerEdit }) => {
  const { values, errors, setFieldValue, initialValues } =
    useFormikContext<Storm.EventVm>();
  const formOptions: SelectOption<number>[] = useMemo(
    () =>
      forms.map((form) => ({
        label: form.name,
        value: form.id,
      })),
    [forms]
  );

  async function validateUniqueEventName(
    value: string
  ): Promise<string | undefined> {
    let trimmedValue = value.trim();
    if (trimmedValue !== initialValues.eventName) {
      const isUnique = await Storm.API.getIsUniqueEventName({
        eventName: trimmedValue,
      });

      // W.EVT.0080 - If Event name without a year is used in the past year, append a year.
      if (!isUnique) {
        const startDateYear = moment(values.startDate).year();
        const last4Char = trimmedValue.slice(trimmedValue.length - 4);

        // This checks if year is already appended so it doesn't save as "Event Name 2022 2022"
        if (last4Char !== startDateYear.toString()) {
          const eventNameWithYear = `${trimmedValue} ${startDateYear}`;
          const isUniqueWithYear = await Storm.API.getIsUniqueEventName({
            eventName: eventNameWithYear,
          });

          if (!isUniqueWithYear) {
            return "Event Name must be unique";
          }

          setFieldValue("eventName", eventNameWithYear);
        } else {
          return "Event Name must be unique";
        }
      }
    }
  }

  const [formField] = useField("formId");
  const [eventNameField] = useField({
    name: "eventName",
    validate: validateUniqueEventName,
  });
  const [hashtagsField] = useField("hashtags");
  const editable = values.active && canManagerEdit;

  const handleClearEndDate = () => {
    setFieldValue("endDate", undefined);
  };

  useEffect(() => {
    formOptions.length === 1 && setFieldValue("formId", formOptions[0].value);
  }, [formOptions]);

  return (
    <div className={styles.detailsForm}>
      <TextInput
        {...eventNameField}
        label="Event Name"
        id="eventName"
        placeholder="Event Name"
        error={errors.eventName}
        disabled={!editable}
      />

      <TextInput
        {...hashtagsField}
        label="Hashtags"
        id="hashtags"
        placeholder="Hashtags"
        error={errors.hashtags}
        value={values.hashtags || ""}
        disabled={!editable}
      />

      <DateRange
        name="range"
        labelId="dateRange"
        variant="column"
        labels={{
          labelFrom: { text: "Start Date", className: "label" },
          labelTo: { text: "End Date", className: "label" },
        }}
        initialValues={{
          from: values.startDate ? moment(values.startDate) : undefined,
          to: values.endDate ? moment(values.endDate) : undefined,
        }}
        handleChange={(range) => {
          setFieldValue("startDate", range.startDate.format("YYYY-MM-DD"));
          range.endDate &&
            setFieldValue("endDate", range.endDate.format("YYYY-MM-DD"));
        }}
        disabled={!editable}
        allowBlankEndDate
        onClear={editable ? handleClearEndDate : undefined}
      />

      <Select
        {...formField}
        placeholder="Please select a form"
        options={formOptions}
        label="Select Form"
        id="formId"
        error={errors.formId}
        onChange={(option: SelectOption<number> | null) =>
          setFieldValue("formId", option?.value)
        }
        disabled={!editable}
      />

      <EmailUpdates canManagerEdit={editable} />
    </div>
  );
};

export default FormElements;
