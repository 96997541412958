import React, { FC } from "react";

import { Storm } from "@rtslabs/field1st-fe-common";
import styles from "./MapWidget.module.scss";
import { GlMap as MapComponent } from "shared/src/components/GlMap/GlMap";
import { Viewport } from "shared/src/components/GlMap/types";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import { setAssessmentMapMarker } from "../../MapWidget/setAssessmentMapMarker";

interface Props {
  assessment: Storm.StormDocumentExtensionVm;
}

export const MapWidget: FC<Props> = ({ assessment }) => {
  if (!assessment.workLocation.geolocation) return null;

  const substationLocation = assessment.workLocation.geolocation;
  const { latitude, longitude } = substationLocation;

  let viewport: Viewport = {
    width: "100%",
    height: "100%",
    center: {
      latitude,
      longitude,
    },
    zoom: 12,
  };

  let markers: LocationMarker[] = [setAssessmentMapMarker(assessment)];

  return (
    <div className={styles.wrapper}>
      <div className={styles.map}>
        <MapComponent initialViewport={viewport} markers={markers} staticOnly />
      </div>
    </div>
  );
};
