import Collapse from "@mui/material/Collapse";
import { camelCase } from "lodash";
import React, { FC } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Components, ElementType } from "../../../qa-slugs";
import { Icon } from "../../Icon/Icon";
import styles from "./NavigationList.module.scss";

interface NavigationListProps {
  menuData: NavigationMenuLink[];
  userRoles: string[];
}

export interface NavigationMenuLink {
  disabled?: boolean;
  expansion?: NavigationMenuLink[];
  icon: string;
  id: string;
  link: any;
  linkTo?: string;
  roles?: string[];
  /** Routes that exist under the parent route but do not have their own navigation item
   *
   * Used for keeping the "parent" navigation item in active state when on a subroute
   */
  subRoutes?: string[];
}

// Builds an object that drives what's collapsed
// If a navigation item has an expansion property, then that property has a sub item with
// a `linkTo` of exact or partial `currentPath`, then the sub item's parent ID is added
// to an object that is then used for initial `expanded` value.
const getDefaultExpandedItems = (
  items: NavigationMenuLink[],
  pathName: string
) => {
  const result: { [id: string]: boolean } = {};
  if (items) {
    items.forEach((item) => {
      const expansionArray = item.expansion || [];
      if (expansionArray.length > 0) {
        if (
          expansionArray.some(
            (expansionItem) =>
              expansionItem.linkTo && pathName.includes(expansionItem.linkTo)
          )
        ) {
          result[item.id] = true;
        }
      }
    });
  }
  return result;
};

export const canAccessLink = (
  link: NavigationMenuLink,
  roles: string[]
): boolean => {
  return link.roles
    ? roles.some((role) => link.roles!.includes(role))
    : link.expansion
    ? link.expansion.reduce(
        (canAccess, expansionLink) =>
          canAccess ||
          roles.some((role) =>
            expansionLink.roles ? expansionLink.roles.includes(role) : true
          ),
        false
      )
    : true;
};

/**
 * @deprecated
 * Use packages/field-first/src/components/navigation/NavigationList/NavigationList.tsx
 * This component can be deleted when there are no more things that reference it.
 */
const NavigationList: FC<NavigationListProps> = ({ menuData, userRoles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const defaultExpanded = getDefaultExpandedItems(menuData, pathname);
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const setOpen = (key: string, value: boolean) => {
    setExpanded({
      ...expanded,
      [key]: value,
    });
  };

  // determines if one of the nav items subroutes matches the current location
  const matchSubRoute = (subRoutes: string[]): boolean => {
    return subRoutes.some(
      (subRoute) => !!matchPath({ path: subRoute, end: true }, pathname)
    );
  };

  return (
    <div>
      {menuData.map((menuItem: NavigationMenuLink, i: number) => {
        if (!canAccessLink(menuItem, userRoles)) {
          return null;
        }

        let active =
          pathname === menuItem.linkTo ||
          (menuItem.subRoutes && matchSubRoute(menuItem.subRoutes));

        return (
          <React.Fragment key={i}>
            <div
              data-testid={`${Components.SidebarNavigation}-${
                ElementType.Link
              }-${camelCase(menuItem.link)}`}
              className={joinClassNames(
                styles.navigationItem,
                active && styles.active,
                menuItem.disabled && styles.disabled
              )}
              onClick={() => {
                if (menuItem.expansion && menuItem.id) {
                  setOpen(menuItem.id, !expanded[menuItem.id]);
                }
                if (menuItem.linkTo) {
                  navigate(menuItem.linkTo);
                }
              }}
            >
              <Icon
                type={menuItem.icon}
                className={styles.navigationItemIcon}
              />
              <div className={styles.navigationItemLink}>{menuItem.link}</div>
            </div>
            {menuItem.expansion &&
              menuItem.expansion
                .filter((s) => canAccessLink(s, userRoles))
                .map((s: NavigationMenuLink, index: number) => {
                  return (
                    <Collapse
                      key={index}
                      in={expanded[menuItem.id || ""]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <div
                        data-testid={`${Components.SidebarNavigation}-${
                          ElementType.Link
                        }-${camelCase(s.link)}`}
                        key={s.id}
                        className={joinClassNames(
                          styles.navigationItem,
                          styles.subNavigationItem,
                          s.linkTo === pathname && styles.active,
                          menuItem.disabled && styles.disabled
                        )}
                        onClick={() => s.linkTo && navigate(s.linkTo)}
                      >
                        <Icon
                          type={s.icon}
                          className={styles.navigationItemIcon}
                        />
                        <div
                          className={styles.navigationItemLink}
                          onClick={() => undefined}
                        >
                          {s.link}
                        </div>
                      </div>
                    </Collapse>
                  );
                })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default NavigationList;
