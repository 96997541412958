import React, { FC } from "react";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { AssignAssessmentContent } from "./AssignAssessmentContent";

interface AssignAssessmentDrawerProps {
  selected: StormDocumentExtensionVm[];
  isOpen: boolean;
  onClose: () => void;
}

export const AssignAssessmentDrawer: FC<AssignAssessmentDrawerProps> = ({
  selected,
  isOpen,
  onClose,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      anchor={isDesktop ? "right" : "bottom"}
    >
      <AssignAssessmentContent onClose={onClose} selected={selected} />
    </Drawer>
  );
};
