import { LatLng } from "@rtslabs/field1st-fe-common";

/** Format a geolocation for display */
export function formatGeolocation(geolocation: LatLng): string {
  return `(${geolocation.latitude}, ${geolocation.longitude})`;
}

/**
 * return the center between all given geoLocations
 */
export function getMidPoint(locations: LatLng[]): LatLng {
  if (locations.length === 1) {
    return locations[0];
  }

  if (!locations[0].latitude && locations[1].latitude) {
    return locations[1];
  }

  let x = 0,
    y = 0,
    z = 0;

  for (const location of locations) {
    if (!location.longitude || !location.latitude) break;

    const latitude = (location.latitude * Math.PI) / 180;
    const longitude = (location.longitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = locations.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
}

/**
 * calculates the distance in KM between the first 2
 * geoLocations in the given array of locations.
 * If only 1 geoLocation exists or any of our
 * values are null then we'll early return null
 */
export function getDistanceBetweenGeoLocations(
  locations: LatLng[]
): number | null {
  if (locations.length === 1) {
    return null;
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  const earthRadius = 6367;

  const {
    min_cords: { latitude: lat1, longitude: lng1 },
    max_cords: { latitude: lat2, longitude: lng2 },
  } = locations.reduce(
    function (prev, curr) {
      if (curr.latitude < prev.min_cords.latitude)
        prev.min_cords.latitude = curr.latitude;
      if (curr.latitude > prev.max_cords.latitude)
        prev.max_cords.latitude = curr.latitude;
      if (curr.longitude < prev.min_cords.longitude)
        prev.min_cords.longitude = curr.longitude;
      if (curr.longitude > prev.max_cords.longitude)
        prev.max_cords.longitude = curr.longitude;
      return prev;
    },
    {
      min_cords: { latitude: Infinity, longitude: Infinity },
      max_cords: { latitude: -Infinity, longitude: -Infinity },
    }
  );

  if (!lat1 || !lat2 || !lng1 || !lng2) return null;

  const diffLng = deg2rad(lng2 - lng1);
  const diffLat = deg2rad(lat2 - lat1);

  const a =
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(diffLng / 2) *
      Math.sin(diffLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c; //distance in km
}

export function calculateZoom(distance: number | null) {
  // closest: 15 furthest: 0
  if (!distance || distance < 1) return 13;

  if (distance < 10) return 10;

  if (distance < 20) return 9;

  if (distance < 35) return 8;

  if (distance < 50) return 7;

  if (distance < 350) return 6;

  return 5;
}

// We need to move this to fe-common. This is a copy from field1st-mobile
function roundTo1Decimal(number: number): number {
  return Math.round(number * 10) / 10;
}

// We need to move this to fe-common. This is a copy from field1st-mobile
export const convertToMeters = (
  value: number,
  outputUnits: "K" | "M"
): number => {
  if (outputUnits === "M") {
    return roundTo1Decimal((value / 0.621371) * 1000);
  }

  return roundTo1Decimal(value * 1000);
};
