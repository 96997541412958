import React, { FC } from "react";
import styles from "./Filters.module.scss";

export type FilterOption = {
  label: string;
  value?: string | string[] | number;
};

interface FilterProps {
  option: FilterOption;
}

const Filter: FC<FilterProps> = ({ option }) => {
  return (
    <option className={styles.filterOption} value={option.value}>
      {option.label}
    </option>
  );
};

export default Filter;
