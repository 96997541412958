import { Storm } from "@rtslabs/field1st-fe-common";

export const ALL_ROLES: string[] = [
  Storm.roles.ROLE_CLIENT_ADMIN,
  Storm.roles.ROLE_CLIENT_REVIEWER,
  Storm.roles.ROLE_SUPER_ADMIN,
  Storm.roles.ROLE_FORM_ARCHITECT,
  Storm.roles.ROLE_CONTENT_EDITOR,
  Storm.roles.ROLE_GROUP_MANAGER,
  Storm.roles.ROLE_REPORTER,
  Storm.roles.ROLE_USER,
  Storm.roles.ROLE_STORM_CENTER_ASSOCIATE,
  Storm.roles.ROLE_STORM_CENTER_MANAGER,
];

export const ALL_STORM_ROLES: string[] = [
  Storm.roles.ROLE_CLIENT_ADMIN,
  Storm.roles.ROLE_SUPER_ADMIN,
  Storm.roles.ROLE_FORM_ARCHITECT,
  Storm.roles.ROLE_USER,
  Storm.roles.ROLE_STORM_CENTER_ASSOCIATE,
  Storm.roles.ROLE_STORM_CENTER_MANAGER,
];

export const STORM_CENTER_MANAGER_ROLES: string[] = [
  Storm.roles.ROLE_SUPER_ADMIN,
  Storm.roles.ROLE_CLIENT_ADMIN,
  Storm.roles.ROLE_STORM_CENTER_MANAGER,
];

export const STORM_CENTER_ROLES: string[] = [
  Storm.roles.ROLE_SUPER_ADMIN,
  Storm.roles.ROLE_CLIENT_ADMIN,
  Storm.roles.ROLE_STORM_CENTER_ASSOCIATE,
  Storm.roles.ROLE_STORM_CENTER_MANAGER,
];

export const ASSESSOR_ROLES: string[] = [
  Storm.roles.ROLE_SUPER_ADMIN,
  Storm.roles.ROLE_CLIENT_ADMIN,
  Storm.roles.ROLE_USER,
];
