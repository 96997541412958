import React, { FC } from "react";

interface ErrorArrayProps {
  error?: string | string[];
}

const ErrorArray: FC<ErrorArrayProps> = ({ error }) => (
  <>
    {typeof error === "object"
      ? error.map((e) => <div key={e}>{e}</div>)
      : error}
  </>
);

export default ErrorArray;
