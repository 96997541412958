import React, { FC } from "react";
import { Storm } from "@rtslabs/field1st-fe-common";
import { AssessmentStateIndicator } from "../common/AssessmentStateIndicator";
import styles from "./AssessmentSummaryCard.module.scss";
import { startCase } from "lodash";
import { useAppDispatch } from "../../redux";
import { setAssessmentsFilters } from "../../redux/filters/actions";
import { useSelector } from "react-redux";
import { selectAssessmentsFilters } from "../../redux/filters/selectors";

interface Props {
  status?: Storm.AssessmentState | "ACTION_REQUIRED";
  count?: number;
}

export const AssessmentSummaryCard: FC<Props> = ({ count, status }) => {
  const assessmentsFilters = useSelector(selectAssessmentsFilters);
  const dispatch = useAppDispatch();
  const handleStatusFilter = (status: Props["status"]) => {
    if (status !== "ACTION_REQUIRED") {
      dispatch(
        setAssessmentsFilters({
          ...assessmentsFilters,
          statuses: status ? [status] : undefined,
          actionRequiredFilter: Storm.AssessmentActionRequiredFilter.ALL,
        })
      );
    } else {
      dispatch(
        setAssessmentsFilters({
          ...assessmentsFilters,
          statuses: undefined,
          actionRequiredFilter: Storm.AssessmentActionRequiredFilter.YES,
        })
      );
    }
  };

  return (
    <div className={styles.summaryCardContainer}>
      <div
        className={styles.summaryCard}
        onClick={() => handleStatusFilter(status)}
      >
        <div className={styles.status}>
          <span>{startCase(status) || "Total"}</span>
          {status && <AssessmentStateIndicator status={status} />}
        </div>
        <div className={styles.count}>{count ?? "--"}</div>
      </div>
    </div>
  );
};
