import React, { FC } from "react";
import { Storm } from "@rtslabs/field1st-fe-common";
import styles from "./AssessmentStateMarker.module.scss";
import { Pin } from "shared/src/components/GlMap/Pin";
import { colorMap } from "./assessmentStateConstants";

interface Props {
  currentState: Storm.AssessmentState;
}

export const AssessmentStateMarker: FC<Props> = ({ currentState }) => {
  return (
    <Pin
      color={colorMap[currentState].fill}
      borderColor={colorMap[currentState].border}
      visible={true}
      className={styles.locationMapPinIcon}
    />
  );
};
