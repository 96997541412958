import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { EventPieChart } from "../EventPieChart";
import { calcEventProgress } from "../helpers";
import styles from "./EventView.module.scss";

interface EventProgressProps {
  event: Storm.EventWithDocStatsVM;
}

const EventProgress: FC<EventProgressProps> = ({ event }) => {
  const progress = calcEventProgress(event);

  return (
    <div className={styles.flexRow}>
      <div className={styles.flexColumn}>
        <span className={styles.sectionDescription}>Progress</span>
        <span className={styles.bodyLarge}>
          {progress.progressPercentage} Complete
        </span>
      </div>
      <div className={styles.chartContainer}>
        <EventPieChart progress={progress} hideCenteredContent />
      </div>
    </div>
  );
};

export default EventProgress;
