import React, {
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { AssistiveText } from "../AssistiveText/AssistiveText";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import Label from "../Label/Label";
import styles from "./TextInput.module.scss";
import { AdornmentProps, TextInputProps } from "./types";
import { useAutosizeTextArea } from "./useAutosizeTextArea";

const Adornment = ({
  className,
  button,
  label,
  icon,
  customIcon,
  handleClick,
  qa,
}: AdornmentProps) =>
  button ? (
    <Button qa={qa} onClick={handleClick} className={className}>
      {icon && <Icon type={icon} />}
      {customIcon}
      {label}
    </Button>
  ) : (
    <span data-testid={qa} onClick={handleClick} className={className}>
      {icon && <Icon type={icon} />}
      {customIcon}
      {label}
    </span>
  );

export interface TextInputRef {
  focus: () => void;
  showPicker: () => void;
}

/** Basic html text input with optional label */
export const TextInput = forwardRef<TextInputRef, TextInputProps>(
  function TextInput(
    {
      assistiveLink,
      autoFocus,
      autoComplete,
      className,
      disabled,
      elementBefore,
      endAdornment,
      error,
      assistiveText,
      id,
      required,
      label,
      labelClass,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      rounded,
      smallLabel,
      startAdornment,
      value,
      type = "text",
      qa,
      wrapperClassName,
      multiline,
      rows = 4,
      useDynamicTextArea,
    },
    ref
  ) {
    const input = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const [isMouseFocused, setIsMouseFocused] = useState(false);
    const [isMouseBlur, setIsMouseBlur] = useState(false);
    const [startSelection, setStartSelection] = useState(0);
    const [endSelection, setEndSelection] = useState(0);

    if (useDynamicTextArea)
      useAutosizeTextArea(
        input.current as HTMLTextAreaElement,
        value as string
      );

    useEffect(() => {
      (input.current?.type === "textarea" || input.current?.type === "text") &&
        input.current.setSelectionRange(startSelection, endSelection);
    }, [value]);

    const handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void = (e) => {
      if (
        input.current?.type === "textarea" ||
        input.current?.type === "text"
      ) {
        setStartSelection(input.current.selectionStart!);
        setEndSelection(input.current.selectionEnd!);
      }
      onChange && onChange(e);
    };

    const handleBlur: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      setIsMouseBlur(isMouseFocused);
      setIsMouseFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    };

    const handleFocus: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      setIsMouseBlur(false);
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleMouseDown: MouseEventHandler = () => {
      setIsMouseFocused(true);
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        input.current && input.current.focus();
      },
      showPicker: () => {
        const inputRef = input.current as any;
        inputRef?.showPicker && inputRef.showPicker();
      },
    }));

    const inputProps = {
      autoFocus,
      className: joinClassNames(
        styles.input,
        isMouseBlur && styles.inputMouseBlurred,
        disabled && styles.disabled,
        !!error && styles.error,
        rounded && styles.rounded,
        className
      ),
      disabled,
      id: id ? id : name,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      onClick,
      onFocus: handleFocus,
      onKeyDown,
      onMouseDown: handleMouseDown,
      placeholder,
      value: value === null ? "" : value,
    };

    return (
      <div className={styles.column}>
        {label && (
          <Label
            assistiveLink={assistiveLink}
            className={labelClass}
            htmlFor={name}
            qa={qa}
            required={required}
            smallLabel={smallLabel}
          >
            {label}
          </Label>
        )}
        <div className={styles.row}>
          {elementBefore && (
            <div className={styles.beforeContainer}>{elementBefore}</div>
          )}
          <div className={styles.column}>
            <div className={styles.row}>
              <div
                className={joinClassNames(
                  styles.inputWrapper,
                  wrapperClassName && wrapperClassName,
                  isMouseFocused && styles.inputMouseFocused,
                  isMouseBlur && styles.inputMouseBlurred,
                  disabled && styles.disabled,
                  !!error && styles.error,
                  rounded && styles.rounded
                )}
              >
                {startAdornment?.visible && (
                  <Adornment
                    {...startAdornment}
                    className={joinClassNames(
                      styles.startAdornment,
                      startAdornment.className
                    )}
                  />
                )}
                {multiline ? (
                  <textarea
                    {...inputProps}
                    ref={input as React.MutableRefObject<HTMLTextAreaElement>}
                    data-testid={qa}
                    rows={rows}
                  />
                ) : (
                  <input
                    {...inputProps}
                    autoComplete={autoComplete}
                    ref={input as React.MutableRefObject<HTMLInputElement>}
                    type={type}
                    data-testid={qa}
                  />
                )}
                {endAdornment?.visible && (
                  <Adornment
                    {...endAdornment}
                    className={joinClassNames(
                      styles.endAdornment,
                      endAdornment.className
                    )}
                  />
                )}
              </div>
            </div>
            {assistiveText && <AssistiveText>{assistiveText}</AssistiveText>}
            {error && <span className={styles.errorText}>{error}</span>}
          </div>
        </div>
      </div>
    );
  }
);
