import { useAsyncEffect } from "@rtslabs/field1st-fe-common";
import { PageOfEventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import React, { useState } from "react";
import { fetchActiveEvents } from "../api/getEvents";
import Assessments from "./Assessments/Assessments";

const AssessmentsScreen = () => {
  const [events, setEvents] = useState<PageOfEventWithDocStatsVM>();

  useAsyncEffect(async () => {
    setEvents(await fetchActiveEvents());
  }, []);

  if (!events) return null;

  return <Assessments events={events} />;
};

export default AssessmentsScreen;
