import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../shared/src/components/Button/Button";
import { STORM_CENTER_MANAGER_ROLES } from "../../permissions/permissionSets";

interface Props {
  text?: string;
  userRoles?: string[];
  className?: string;
  hideWhenDisabled?: boolean;
}

export const NewEventButton: React.FC<Props> = ({
  text = "New Event",
  userRoles = [],
  className,
  hideWhenDisabled = false,
}) => {
  const navigate = useNavigate();
  const newButtonDisabled = !userRoles.some((r) =>
    STORM_CENTER_MANAGER_ROLES.includes(r)
  );

  if (hideWhenDisabled && newButtonDisabled) {
    return null;
  }

  return (
    <Button
      className={className}
      onClick={() => navigate("/events/new")}
      disabled={newButtonDisabled}
      data-testid="neweventbutton"
    >
      {text}
    </Button>
  );
};
