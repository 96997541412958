import React from "react";
import { Icon } from "../../../Icon/Icon";

import styles from "./StartNewFormButton.module.scss";

interface StartNewFormButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

/**
 * **StartNewFormButton** is a button type component used within
 * `DashboardBottomNavigation.tsx`. When clicked, it opens
 * the Drawer component which leads into the _Start New Form_ feature.
 */
export const StartNewFormButton = React.forwardRef<
  HTMLButtonElement,
  StartNewFormButtonProps
>(({ disabled, onClick }, ref) => (
  <button
    className={styles.startNewFormBtn}
    ref={ref}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon type="add_file" className={styles.styledIcon} />
  </button>
));
