import React, { FC } from "react";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";

type Props = {
  removeSelected: () => void;
  includeSelected: () => void;
};

const SubstationsActionButtons: FC<Props> = ({
  removeSelected,
  includeSelected,
}) => {
  return (
    <div>
      <TertiaryButton onClick={removeSelected}>Remove</TertiaryButton>
      <Button onClick={includeSelected}>Include</Button>
    </div>
  );
};

export default SubstationsActionButtons;
