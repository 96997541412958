import { CircularProgress } from "@mui/material";
import React, { FC } from "react";
import {
  SecondaryButton,
  TertiaryButton,
} from "shared/src/components/Button/Button";
import styles from "./common/filters/Filters.module.scss";
import buttonStyles from "shared/src/components/Button/Button.module.scss";

interface FilterButtonsProps {
  loading: boolean;
  resultCount?: number;
  onFilters: () => void;
  onResetFilters: () => void;
}

export const FilterButtons: FC<FilterButtonsProps> = ({
  loading,
  resultCount,
  onFilters,
  onResetFilters,
}) => (
  <div className={styles.buttonContainer}>
    <SecondaryButton onClick={onFilters}>
      Show Results {loading && <CircularProgress size={14} />}
      {typeof resultCount === "number" && <>{!loading && `(${resultCount})`}</>}
    </SecondaryButton>
    <TertiaryButton className={buttonStyles.linkBtn} onClick={onResetFilters}>
      Reset Filters
    </TertiaryButton>
  </div>
);
