import React, { FC } from "react";
import { joinClassNames } from "../../../../shared/src/helpers/theme.helpers";
import { AssessmentState } from "@rtslabs/field1st-fe-common/storm";
import styles from "./AssessmentStateIndicator.module.scss";

interface Props {
  status: AssessmentState | "ACTION_REQUIRED";
}

export const AssessmentStateIndicator: FC<Props> = ({ status }) => {
  return (
    <span className={styles.indicatorWrapper}>
      <span className={joinClassNames(styles.indicator, styles[status])} />
    </span>
  );
};
