import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC, ReactNode } from "react";
import { AssessmentStateIndicator } from "../../../../common/AssessmentStateIndicator";
import { AssessmentStateDisplay } from "../../../AssessmentStateDisplay";
import styles from "./HistoryEntryTitle.module.scss";

type HistoryEntryTitleProps = {
  item: Storm.StormDocumentHistoryVm;
};

export const HistoryEntryTitle: FC<HistoryEntryTitleProps> = ({ item }) => {
  const isCreated = item.historyType === Storm.StormDocumentHistoryType.CREATED;
  const isUnassigned = item.status === Storm.AssessmentState.UNASSIGNED;
  const isAssigned =
    item.historyType === Storm.StormDocumentHistoryType.ASSESSOR_CHANGE;
  const isEdited =
    item.historyType === Storm.StormDocumentHistoryType.STATUS_CHANGE &&
    item.status === Storm.AssessmentState.IN_PROGRESS;
  const isReviewed =
    item.historyType === Storm.StormDocumentHistoryType.REVIEWED;
  const showByline = !isCreated && !isReviewed;
  const isStatusChange =
    ([Storm.StormDocumentHistoryType.STATUS_CHANGE].includes(
      item.historyType
    ) &&
      item.status !== Storm.AssessmentState.IN_PROGRESS) ||
    isReviewed;

  const documentStatuses = {
    created: "Created by",
    edited: "In Progress",
    assigned: "Assigned to",
    unassigned: "Unassigned",
    reassigned: "Reassigned to",
    reset: "Reset to Unassigned",
    uncancelled: "Restored to Unassigned",
  };

  //   const prettifyHistoryStatus = useCallback(() => {}, []);
  const prettifyHistoryStatus = (): string | ReactNode => {
    const { historyType, status } = item;

    if (historyType) {
      switch (historyType) {
        case Storm.StormDocumentHistoryType.CREATED:
          return documentStatuses.created;
        case Storm.StormDocumentHistoryType.ASSESSOR_CHANGE:
          if (status === Storm.AssessmentState.UNASSIGNED)
            return documentStatuses.unassigned;
          if (status === Storm.AssessmentState.IN_PROGRESS)
            return documentStatuses.reassigned;
          return documentStatuses.assigned;
        case Storm.StormDocumentHistoryType.STATUS_CHANGE:
          if (status === Storm.AssessmentState.IN_PROGRESS)
            return documentStatuses.edited;
          return <></>;
        case Storm.StormDocumentHistoryType.RESET:
          return documentStatuses.reset;
        case Storm.StormDocumentHistoryType.UNCANCELLED:
          return documentStatuses.uncancelled;
        default:
          return "-";
      }
    } else {
      return "-";
    }
  };

  const status = prettifyHistoryStatus();

  return (
    <>
      {!isStatusChange && (
        <div className={styles.blockCol}>
          <div className={styles.block}>
            <AssessmentStateIndicator status={item.status} />
            <span className={styles.text}>
              &nbsp;{status}&nbsp;
              {(!isUnassigned || isCreated) && !isEdited && (
                <span className={styles.subText}>
                  {isAssigned ? item.assessor?.fullName : item.createdBy}
                </span>
              )}
            </span>
          </div>

          <div>
            <span className={styles.text}>
              {showByline && (
                <>
                  By <span className={styles.subText}>{item.createdBy}</span>
                </>
              )}
            </span>
          </div>
        </div>
      )}

      {isStatusChange && (
        <div className={styles.blockCol}>
          <div className={styles.block}>
            <span className={styles.text}>Status updated to&nbsp;</span>
            <AssessmentStateDisplay currentState={item.status} />
          </div>

          <div>
            <span className={styles.text}>
              By <span className={styles.subText}>{item.createdBy}</span>
            </span>
          </div>
        </div>
      )}
    </>
  );
};
