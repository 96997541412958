import {
  DeviceEnum,
  DocumentSubmissionType,
  DocumentUpdateVm,
  DocumentVm,
  Storm,
} from "@rtslabs/field1st-fe-common";
import { AssessmentState } from "@rtslabs/field1st-fe-common/storm";
import { useState } from "react";
import { serializeError } from "serialize-error";

export interface SubmitAssessmentProps {
  document: DocumentVm;
  assessmentId: Storm.StormDocumentExtensionVm["id"];
  workLocationId: Storm.StormDocumentExtensionVm["workLocation"]["id"];
  bypass?: boolean;
}

export const useSubmitAssessment = () => {
  const [error, setError] = useState<string>("");
  const [submissionSuccessful, setSubmissionSuccessful] =
    useState<boolean>(false);

  const handleSubmitAssessment = async ({
    document,
    assessmentId,
    workLocationId,
    bypass,
  }: SubmitAssessmentProps): Promise<void> => {
    const documentUpdateVm: DocumentUpdateVm = {
      documentDevice: DeviceEnum.WEB,
      formActionRequests: document.formActionRequests,
      id: document.id,
      operationalExperiences: document.operationalExperiences,
      participants: document.participants,
      photos: document.photos,
      responses: document.responses,
      sectionComments: document.sectionComments,
      submissionDate: new Date().toISOString(),
      submissionType: document.submissionType,
    };

    try {
      const assessment: Storm.UpdateDocumentExtensionVm = {
        document: documentUpdateVm,
        stormDocumentExtensionId: assessmentId,
        workLocationId: workLocationId,
      };

      const res = await Storm.API.editAssessment({ assessment });

      if (
        // bypass is usually for verify to prefil storm center reviewer. it doesn't need
        // to change the state of the assessment.
        !bypass &&
        (res.document.submissionType === DocumentSubmissionType.SUBMIT ||
          res.document.submissionType === DocumentSubmissionType.SAVE_DRAFT)
      ) {
        setSubmissionSuccessful(true);

        if (
          res.document.submissionType === DocumentSubmissionType.SUBMIT &&
          ![
            AssessmentState.ASSESSOR_COMPLETE,
            AssessmentState.IN_REVIEW,
          ].includes(res.assessmentStatus.currentState)
        ) {
          const assessmentStatus: Storm.API.PutAssessmentStateArgs = {
            updateAssessmentStatus: {
              action: Storm.AssessmentAction.COMPLETE_ASSESSMENT,
              stormDocumentExtensionIds: [res.id],
            },
          };

          await Storm.API.putAssessmentState(assessmentStatus);
        }
      }
    } catch (e) {
      const errorString = JSON.stringify(serializeError(e));
      setError(errorString);
    }
  };

  return {
    error,
    setError,
    submissionSuccessful,
    handleSubmitAssessment,
  };
};
