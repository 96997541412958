import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { AssessmentCount } from "./AssessmentCount";
import { DashboardCardItem } from "./DashboardCardItem";

import styles from "./EventCard.module.scss";

interface Props {
  documentCount: Storm.StormDocumentStateCountVm;
}

export const AssessmentCounts: FC<Props> = ({ documentCount }) => {
  return (
    <>
      <div className={styles.section}>
        <AssessmentCount
          status={Storm.AssessmentState.UNASSIGNED}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.UNASSIGNED
            ] ?? 0
          }
        />
        <AssessmentCount
          status={Storm.AssessmentState.ASSIGNED}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.ASSIGNED
            ] ?? 0
          }
        />
        <AssessmentCount
          status={Storm.AssessmentState.IN_PROGRESS}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.IN_PROGRESS
            ] ?? 0
          }
        />
      </div>
      <div className={styles.section}>
        <AssessmentCount
          status={Storm.AssessmentState.ASSESSOR_COMPLETE}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.ASSESSOR_COMPLETE
            ] ?? 0
          }
        />
        <AssessmentCount
          status={Storm.AssessmentState.IN_REVIEW}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.IN_REVIEW
            ] ?? 0
          }
        />
        <AssessmentCount
          status={Storm.AssessmentState.STORM_CENTER_VERIFIED}
          count={
            documentCount.nrOfDocumentsPerState[
              Storm.AssessmentState.STORM_CENTER_VERIFIED
            ] ?? 0
          }
        />
      </div>
      <div className={styles.section}>
        <DashboardCardItem>
          <div className={styles.total}>Total</div>
          <div className={styles.total}>
            {documentCount.totalNumberOfAssessments}
          </div>
        </DashboardCardItem>
      </div>
      <div className={styles.section}>
        <AssessmentCount
          status="ACTION_REQUIRED"
          count={documentCount.nrOfDocumentsWithActionRequired}
        />
      </div>
    </>
  );
};
