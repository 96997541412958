import React, { FC, useMemo } from "react";

import {
  API,
  CoreRole,
  MainNavigationCopyKey,
} from "@rtslabs/field1st-fe-common";
import {
  selectMainNavigationConfigProperties,
  selectTermByVisibleId,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { useSelector } from "react-redux";
import AppNavWrapper from "shared/src/components/navigation/Deprecated/AppNavWrapper";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { NavigationMenuLink } from "../../../shared/src/components/navigation/Deprecated/NavigationList";
import {
  STORM_CENTER_MANAGER_ROLES,
  STORM_CENTER_ROLES,
} from "../permissions/permissionSets";
import { RootState } from "../redux";

interface NavigationTerms {
  documentsTerm: string;
  dashboardTerm: string;
}

// const peopleMenu: NavigationMenuLink[] = [
//   {
//     id: "101",
//     icon: "business_conference_female_speaker",
//     link: "People",
//     roles: STORM_CENTER_ROLES,
//     expansion: [
//       {
//         id: "101-1",
//         icon: "",
//         link: "Groups",
//         linkTo: "/people/groups",
//       },
//       {
//         id: "101-2",
//         icon: "",
//         link: "Users",
//         linkTo: "/people/users",
//       },
//     ],
//   },
// ];

const formsMenu: NavigationMenuLink[] = [
  {
    id: "003",
    icon: "multi_edit",
    link: "Form Templates",
    roles: [CoreRole.ROLE_SUPER_ADMIN], // temp until we are ready to release
    // roles: STORM_CENTER_MANAGER_ROLES,
    linkTo: "/forms",
    // expansion: [
    //   {
    //     id: "003-1",
    //     icon: "",
    //     link: "Form Templates",
    //     linkTo: "/forms",
    //   },
    //   {
    //     id: "003-2",
    //     icon: "",
    //     link: "Data Sets",
    //     linkTo: "/forms/data-sets",
    //   },
    //   {
    //     id: "003-4",
    //     icon: "",
    //     link: "Widgets",
    //     linkTo: "/forms/widgets",
    //     disabled: true,
    //   },
    // ],
  },
];

const contentMenu: NavigationMenuLink[] = [
  {
    id: "004",
    icon: "overview_pages_4",
    link: "Content",
    roles: STORM_CENTER_MANAGER_ROLES,
    expansion: [
      {
        id: "004-1",
        icon: "",
        link: "Resources",
        linkTo: "/content/resources",
      },
      {
        id: "004-2",
        icon: "",
        link: "Tag Library",
        linkTo: "/content/tag-library",
      },
    ],
  },
];

const buildUserMenu = (terms: NavigationTerms): NavigationMenuLink[] => [
  {
    id: "001",
    icon: "a_home",
    link: terms.dashboardTerm,
    linkTo: "/",
  },
  {
    id: "002",
    icon: "document-2",
    link: terms.documentsTerm,
    linkTo: "/assessments",
  },
  {
    id: "003",
    icon: "combo_chart",
    link: "Events",
    linkTo: "/events",
    subRoutes: ["/events/:id"],
  },
  // Temp turn off since we don't have it built for Storm
  //   {
  //     id: "004",
  //     icon: "help",
  //     link: "Help Desk",
  //     linkTo: "/help-desk",
  //     subRoutes: ["/feedback"],
  //   },
];
interface Props {
  children?: React.ReactNode;
  inMobileExpansion?: boolean;
}

const AppNavigation: FC<Props> = ({ inMobileExpansion = false, children }) => {
  const mainNavigationConfig = useSelector(
    selectMainNavigationConfigProperties
  );

  const documentGroupTerm = useSelector((state: RootState) =>
    selectTermByVisibleId(state, "document")
  );
  const employeeGroupTerm = useSelector((state: RootState) =>
    selectTermByVisibleId(state, "employee")
  );
  const documentsTerm = conjugateTerm({
    term:
      documentGroupTerm ||
      mainNavigationConfig?.copy?.[MainNavigationCopyKey.documents],
    role: "noun",
    modifier: "plural",
    fallback: "Assessments",
  });
  const dashboardTerm = conjugateTerm({
    term: mainNavigationConfig?.copy?.[MainNavigationCopyKey.dashboard],
    role: "noun",
    modifier: "singular",
    fallback: "Dashboard",
  });
  const employeeTerms = {
    singular: conjugateTerm({
      term: employeeGroupTerm,
      role: "noun",
      modifier: "singular",
      fallback: "Employee",
    }),
    plural: conjugateTerm({
      term: employeeGroupTerm,
      role: "noun",
      modifier: "plural",
      fallback: "Employees",
    }),
  };

  // Home, Documents, Reports menu
  const userMenu = useMemo(
    () => buildUserMenu({ documentsTerm, dashboardTerm }),
    [documentsTerm, dashboardTerm]
  );

  // Get user roles
  const userRoles = API.Environment.getRoles();

  // Array of options for admin menu sections
  //   const adminMenuData: NavigationMenuLink[] = peopleMenu.concat(formsMenu);
  const adminMenuData: NavigationMenuLink[] = formsMenu;

  // Has fewer links because bottom navigation already has them -- GK
  const _userMenu = useMemo(() => {
    if (inMobileExpansion) {
      return userMenu.filter(
        (menuItem) =>
          menuItem.linkTo && !["/", "/documents"].includes(menuItem.linkTo)
      );
    }
    return userMenu;
  }, [inMobileExpansion]);

  return (
    <AppNavWrapper
      menuData={_userMenu}
      adminMenuData={adminMenuData}
      adminMenuHeader="System Admin"
      userRoles={userRoles}
      mainNavigationConfig={mainNavigationConfig}
      terms={{
        employee: employeeTerms,
      }}
    >
      {/* Route content */}
      {children}
    </AppNavWrapper>
  );
};

export default AppNavigation;
