import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import configureStore from "./configureStore";

export const { persistor, store } = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// These hooks give complete typing to dispatch and selectors.
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
