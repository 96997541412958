import React, { FC } from "react";
import { Button } from "shared/src/components/Button/Button";
import {
  AssessmentAction,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import styles from "./AssessmentsTable.module.scss";
import { isActionAllowed } from "./helpers";

interface Props {
  assessment: StormDocumentExtensionVm;
  handleClickAssign: (assessment: StormDocumentExtensionVm) => void;
}

export const AssessorCellContent: FC<Props> = ({
  assessment,
  handleClickAssign,
}) => {
  // if assigned, display the assessor's name
  if (assessment.assessor) {
    return <span>{assessment.assessor.fullName}</span>;
  }
  // if unassigned, and assessment can be assigned, return the Assign button
  if (isActionAllowed([assessment], AssessmentAction.ASSIGN)) {
    return (
      <Button
        className={styles.assignBtn}
        onClick={(e) => {
          e.stopPropagation();
          handleClickAssign(assessment);
        }}
      >
        ASSIGN
      </Button>
    );
  }
  // if unassigned and cannot be assigned, return "Unassigned"
  return <span>Unassigned</span>;
};
