import { CoreRedux, DocumentVm, Storm } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useInspectedBy } from "./useInspectedBy";
import { STORM_CENTER_ROLES } from "../../../permissions/permissionSets";

interface SetInReviewProps {
  assessment: Storm.StormDocumentExtensionVm;
  inReviewView: boolean; // since it's in formik, pass the value if it's in review page
}

export const SetInReview: FC<SetInReviewProps> = ({
  assessment,
  inReviewView,
}) => {
  const user = useSelector(CoreRedux.selectUser);
  const { values, setValues } = useFormikContext<DocumentVm>();
  const { handleReviewer } = useInspectedBy({
    assessment,
    user,
  });
  const isStormCenterAdmin = user?.user.authorities?.some((r) =>
    STORM_CENTER_ROLES.includes(r)
  );

  const isInReview =
    assessment.assessmentStatus.currentState ===
    Storm.AssessmentState.IN_REVIEW;

  useEffect(() => {
    if (isInReview && inReviewView && isStormCenterAdmin) {
      handleReviewer(values, setValues);
    }
  }, [isInReview]);

  return null;
};
