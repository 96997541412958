import React, { useContext, useState, useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction, {
  BottomNavigationActionProps,
} from "@mui/material/BottomNavigationAction";

import { StartNewFormButton } from "./StartNewFormButton/StartNewFormButton";
import styles from "./DashboardBottomNavigation.module.scss";
import BottomNavigationContext from "./Context";
import { NavigationExpansionDrawerContent } from "./NavigationExpansionDrawerContent";
import { Drawer } from "../../Drawer/Drawer";
import {
  MainNavigationConfigProperties,
  MainNavigationCopyKey,
} from "@rtslabs/field1st-fe-common";
import { Icon } from "../../Icon/Icon";

interface BasicActionProps extends BottomNavigationActionProps {
  isActive?: boolean;
}

const BasicAction: FC<BasicActionProps> = ({ isActive, ...rest }) => (
  <BottomNavigationAction
    classes={
      isActive
        ? {
            root: styles.activeTab,
          }
        : {}
    }
    {...rest}
  />
);

interface Props {
  drawerContent: JSX.Element;
  mainNavigationConfig?: MainNavigationConfigProperties;
}

const DashboardBottomNavigation: FC<Props> = ({
  drawerContent,
  mainNavigationConfig,
}) => {
  const { drawerIsVisible, setDrawerIsVisible } = useContext(
    BottomNavigationContext
  );
  const location = useLocation();
  const navigate = useNavigate();

  // Navigation expansion drawer
  const [navigationDrawerVisible, setNavigationDrawerVisible] =
    useState<boolean>(false);

  // Hide drawer when location changes (user navigated away)
  useEffect(() => {
    setNavigationDrawerVisible(false);
  }, [location.pathname]);

  const getCopy = (key: MainNavigationCopyKey): string | undefined => {
    let copy;
    if (mainNavigationConfig?.mobileCopy?.[key]) {
      copy = mainNavigationConfig.mobileCopy[key];
    } else if (mainNavigationConfig?.copy?.[key]) {
      copy = mainNavigationConfig.copy[key];
    }
    return copy;
  };

  return (
    <div className={styles.bottomNavigation}>
      {/* Navigation expansion drawer */}
      <Drawer
        anchor="right"
        id="menuExpansionDrawer"
        onClose={() => setNavigationDrawerVisible(false)}
        isOpen={navigationDrawerVisible}
      >
        <NavigationExpansionDrawerContent>
          {drawerContent}
        </NavigationExpansionDrawerContent>
      </Drawer>
      <BottomNavigation showLabels>
        <BasicAction
          label="Home"
          icon={<Icon className={styles.icon} type="a_home" />}
          isActive={location.pathname === "/"}
          onClick={() => navigate("/")}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.documents) || "Documents"}
          icon={<Icon className={styles.icon} type="document" />}
          isActive={location.pathname === "/documents"}
          onClick={() => navigate("/documents")}
        />

        <BottomNavigationAction
          label=""
          component={React.forwardRef<HTMLButtonElement>((props, ref) => (
            <StartNewFormButton
              onClick={() => setDrawerIsVisible(!drawerIsVisible)}
              ref={ref}
            />
          ))}
          showLabel={false}
          icon={<div />}
          style={{}}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.profile) || "Profile"}
          icon={<Icon className={styles.icon} type="user_male_circle" />}
          isActive={location.pathname === "/profile"}
          onClick={() => navigate("/profile")}
        />

        <BasicAction
          label={getCopy(MainNavigationCopyKey.menu) || "Menu"}
          icon={<Icon className={styles.icon} type="menu" />}
          onClick={() => setNavigationDrawerVisible(true)}
        />
      </BottomNavigation>
    </div>
  );
};

export default DashboardBottomNavigation;
