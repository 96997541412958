import {
  applyMiddleware,
  combineReducers,
  createStore,
  PreloadedState,
  Reducer,
} from "redux";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { API, CoreRedux } from "@rtslabs/field1st-fe-common";
import { PersistConfig } from "redux-persist/es/types";
import { documentReducer } from "shared/src/redux/document/documentReducers";
import { filtersReducer } from "./filters/reducer";

export const appReducer = combineReducers({
  user: CoreRedux.userReducer,
  appConfigs: CoreRedux.appConfigsReducer,
  clientGroupConfigs: CoreRedux.clientGroupConfigsReducer,
  document: documentReducer,
  participantConfigs: CoreRedux.participantConfigsReducer,
  auth: CoreRedux.authTokenReducer,
  rehydrated: CoreRedux.rehydratedReducer,
  filters: filtersReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === CoreRedux.LOGOUT.ACTION) {
    localStorage.clear();
    API.logout();
    state = undefined;
  }
  return appReducer(state, action);
};

const whitelist = ["auth", "user"];

type RootState = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage: AsyncStorage,
  ...(process.env.NODE_ENV === "production" && { whitelist }),
  blacklist: ["rehydrated"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

type ActionsOfReducer<G> = G extends Reducer<any, infer S> ? S : never;
type RootActions = ActionsOfReducer<typeof rootReducer>;

export default function configureStore(
  preloadedState?: PreloadedState<RootState>
) {
  const middleWareEnhancer = applyMiddleware<
    ThunkDispatch<RootState, any, RootActions>,
    any
  >(thunkMiddleware);

  const store = createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(middleWareEnhancer)
  );
  const persistor = persistStore(store, undefined, () => {
    // on rehydrate
    const { token } = store.getState().auth;
    if (token) {
      API.Environment.authToken = token;
    }
    store.dispatch({ type: CoreRedux.REHYDRATE_COMPLETE.ACTION });
  });

  return { store, persistor };
}
