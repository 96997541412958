import {
  API,
  DataSourceType,
  DocumentQuestionResponseVm,
  DocumentVm,
  ParticipantDTO,
  QuestionDTO,
  ResponseContent,
  useDataSourceValues,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import {
  createOrUpdateResponse,
  deleteResponse,
} from "../../../api/responseRequests";
import { DEFAULT_USE_DEBOUNCE_DELAY } from "../../../util/debounceDelays";
import { DataSourceItem } from "../../ItemSelectorDrawer/ItemSelectorForm";
import MultiInput from "../../MultiInput/MultiInput";
import DisabledTextField from "../../TextInput/DisabledTextField";
import DataSourceDrawer from "./DataSourceDrawer";
import { useQuestion } from "./useQuestion";

interface ParticipantItem {
  id: number;
  label: string;
  content?: ResponseContent;
}

export interface ParticipantProps {
  question: QuestionDTO;
  disabled?: boolean;
}

export const ParticipantField = ({ question, disabled }: ParticipantProps) => {
  const { values } = useFormikContext<DocumentVm>();
  const [currentValue, setCurrentValue] = React.useState("");
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const debouncedQuery = useDebouncedValue(
    currentValue,
    DEFAULT_USE_DEBOUNCE_DELAY
  );

  const {
    error,
    onBlur,
    label,
    qa,
    responses,
    setResponses,
    shouldUpdateForm,
  } = useQuestion(question);

  /** When user selects a Participant from the AutoComplete Menu */
  const handleAddParticipant = async (participantItem: ParticipantItem) => {
    const updatedResponse = {
      answer: participantItem.label,
      associatedId: participantItem.id,
      questionId: question.id,
      questionRootId: question.rootId,
      timeAnswered: moment.utc().format(),
    };

    const newResponse = shouldUpdateForm
      ? updatedResponse
      : await createOrUpdateResponse(values.id, updatedResponse);

    // if we only allow one answer, update the existing response
    if (question.properties?.allowMultipleAnswers) {
      await setResponses(
        responses.concat(newResponse),
        participantItem.content
      );
    } else {
      await setResponses([newResponse], participantItem.content);
      const responseIdToDelete = responses.find(
        (r) => r.associatedId !== participantItem.id
      )?.id;
      if (!shouldUpdateForm && responseIdToDelete)
        await deleteResponse(values.id, responseIdToDelete);
    }
  };

  /**
   * When user clicks Submit on the ItemSelectorDrawer we'll invoke
   * this function to create the Form values
   * @param participantIds
   */
  const itemSelectorDrawerHandleSubmit = async (
    participants: Array<DataSourceItem>
  ) => {
    // filter out existing participants for this question
    const participantsToAdd = participants.filter(
      (p) => responses.findIndex((res) => res.associatedId === p.id) === -1
    );
    // get de-selected responses from participant drawer
    const responsesToRemove = responses.filter(
      (res) => participants.findIndex((p) => p.id === res.associatedId) === -1
    );

    const newResponses: DocumentQuestionResponseVm[] = [];
    for (const participant of participantsToAdd) {
      const updatedResponse = {
        answer: participant.title,
        associatedId: Number(participant.id), // @TODO just pass it as a number to ListItemContent
        associatedRootId: Number(participant.id), // @TODO just pass it as a number to ListItemContent
        questionId: question.id,
        questionRootId: question.rootId,
        timeAnswered: moment.utc().format(),
      };
      const newResponse = shouldUpdateForm
        ? updatedResponse
        : await createOrUpdateResponse(values.id, updatedResponse);
      newResponses.push(newResponse);
    }
    for (const response of responsesToRemove) {
      if (!shouldUpdateForm && response.id)
        await deleteResponse(values.id, response.id);
    }

    const updatedResponses = [
      ...responses.filter(
        (res) => responsesToRemove.findIndex((rem) => res.id === rem.id) === -1
      ),
      ...newResponses,
    ];
    await setResponses(updatedResponses);
    setDrawerOpen(false);
  };

  const handleRemoveParticipant = async (participantItem: ParticipantItem) => {
    const participantId = responses.find(
      (r) => r.associatedId === participantItem.id
    )?.id;
    if (!shouldUpdateForm && participantId)
      await deleteResponse(values.id, participantId);
    await setResponses(
      responses.filter((r) => r.associatedId !== participantItem.id)
    );
  };

  /* We won't need this if rendering Participant field for the signed in user */
  if (question.answerSource?.properties?.readOnly) {
    return (
      <DisabledTextField
        name={`${question.id}`}
        label={label}
        value={responses?.[0]?.answer}
      />
    );
  }

  const { dataSourceValues } = useDataSourceValues<
    ParticipantDTO,
    API.GetDataSourceValuesArgs["sort"]
  >({
    dataSourceKey: DataSourceType.PARTICIPANT,
    getDataSourceValues: API.getDataSourceValues,
    query: debouncedQuery,
  });

  const participantsInExistingResponses = responses
    .filter((r) => !!r.associatedId)
    .map((r) => r.associatedId!);

  const suggestions = dataSourceValues
    .filter((dsv) => !participantsInExistingResponses.includes(dsv.id))
    .map((dsv) => ({
      id: dsv.id,
      label: dsv.content.name,
      content: dsv.content,
    }));

  const selectedParticipants = responses
    .filter((r) => r.questionId === question.id && r.associatedId)
    .map(
      (r) =>
        ({
          id: r.associatedId,
          title: r.answer,
          associatedLocation: r.associatedLocation,
        } as DataSourceItem)
    );

  return (
    <>
      {question.answerSource && (
        <DataSourceDrawer
          isOpen={drawerOpen}
          answerSource={question.answerSource} // @todo change to options, pass participants
          onChangeOpen={setDrawerOpen}
          onSubmit={itemSelectorDrawerHandleSubmit}
          allowMultipleAnswers={question.properties?.allowMultipleAnswers}
          qa="addParticipant"
          selected={selectedParticipants}
          submitButtonLabel={{
            prefix: "Add",
            label: "Participant",
          }}
        />
      )}
      <MultiInput
        autoCompleteSuggestions={suggestions}
        assistiveLink={
          !question.answerSource?.properties?.readOnly &&
          !disabled &&
          question.answerSource?.dataSourceKey
            ? {
                label:
                  question.answerSource?.properties?.detailedSearch?.linkName ??
                  "Select",
                onClick: () => setDrawerOpen(true),
              }
            : undefined
        }
        canUseCustomValues={false}
        error={error}
        assistiveText={question.properties?.assistiveText}
        idField="id"
        label={question.title}
        labelField="label"
        name={`${question.id}`}
        onBlur={onBlur}
        onChangeInput={setCurrentValue}
        onAddItem={handleAddParticipant}
        onRemoveItem={handleRemoveParticipant}
        placeholder={question.properties?.placeHolderText ?? question.title}
        qa={`${qa}-participant`}
        required={question.properties?.isRequired}
        selectedValues={responses.map((r) => ({
          label: r.answer,
          id: r.associatedId!,
        }))}
        disabled={disabled}
      />
    </>
  );
};
