import React, { useState, useEffect, FC } from "react";
import {
  AssessmentAction,
  AssessmentState,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import filterStyles from "../../common/filters/Filters.module.scss";
import { Button, SecondaryButton } from "shared/src/components/Button/Button";
import styles from "./AssessmentDrawer.module.scss";
import { List } from "shared/src/components/List/List";
import { ListItem } from "shared/src/components/List/ListItem";
import {
  DocumentVm,
  Storm,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import { StormParticipantVm } from "@rtslabs/field1st-fe-common/dist/main/storm";
import { AssignModal, UnassignModal } from "./AssignModals";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import { isActionAllowed } from "../helpers";
import { ListItemContent } from "shared/src/components/List/ListItemContent";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import { ReassignDataModal } from "../Assessment/ReassignDataModal";
import { useSubmitAssessment } from "../Assessment/useSubmitAssessment";
import { toast, ToastContainer } from "react-toastify";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
} from "shared/src/components/Toast/Toastify";
import {
  fetchCurrentParticipant,
  fetchParticipants,
} from "../../../api/getParticipants";
import { putAssessmentState } from "../../../api/putAssessmentState";

interface AssignAssessmentContentProps {
  selected: StormDocumentExtensionVm[];
  onClose: (isReset?: boolean) => void;
  onCancel?: () => void;
  customTemp?: (selectedAssessor: Storm.StormParticipantVm) => void;
}

export const AssignAssessmentContent: FC<AssignAssessmentContentProps> = ({
  selected,
  onClose,
  onCancel,
  customTemp,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assessors, setAssessors] = useState<StormParticipantVm[]>([]);
  const [selectedAssessor, setSelectedAssessor] =
    useState<StormParticipantVm | null>(null);
  const [currentParticipant, setCurrentParticipant] =
    useState<StormParticipantVm>();
  const [isReassignModalOpen, setIsReassignModalOpen] =
    useState<boolean>(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [isUnassignModalOpen, setIsUnassignModalOpen] =
    useState<boolean>(false);
  const { handleSubmitAssessment } = useSubmitAssessment();

  const debouncedSearchQuery = useDebouncedValue(searchQuery, 1000);

  const handleAssessmentsStatusCheck = async () => {
    // Check any assessments that are in "In Progress" status to show a pop up
    // modal asking user to keep or erase data before reassigning
    if (
      selected.some(
        (assessment) =>
          assessment.assessmentStatus.currentState ===
          Storm.AssessmentState.IN_PROGRESS
      )
    ) {
      setIsReassignModalOpen(true);
      setIsAssignModalOpen(false);
    } else {
      // this should always run if ALL assessments are not "In Progress"
      await handleAssignAssessments();
    }
  };

  const handleDataOnReassign = async (erase?: boolean) => {
    // If the user click Erase, then erase the responses on assessments that are
    // in "In Progress" state. And finally, in any status, assessments is reassigned
    if (erase) {
      const inProgressAssessments = selected.filter(
        (assessment) =>
          assessment.assessmentStatus.currentState ===
          Storm.AssessmentState.IN_PROGRESS
      );

      for (const assessment of inProgressAssessments) {
        const cleanDocument: DocumentVm = {
          ...assessment.document,
          responses: [],
          photos: [],
          participants: [],
        };
        await handleSubmitAssessment({
          document: cleanDocument,
          assessmentId: assessment.id,
          workLocationId: assessment.workLocation.id,
        });
      }
    }

    await handleAssignAssessments(erase);
    setIsAssignModalOpen(false);
  };

  const handleAssignAssessments = async (isReset?: boolean) => {
    if (!selectedAssessor) {
      return;
    }
    // This is a temp call for reviewing submitted document until we can actually implement Review Assessment
    // page in Release 2. But for now, this is a temp call to set assessment directly to ASSIGNED from IN_REVIEW
    if (customTemp) {
      return customTemp(selectedAssessor);
    }

    const assignable = selected.filter(
      (a) => a.assessmentStatus.currentState === "UNASSIGNED"
    );
    const reassignable = selected.filter(
      (a) => a.assessmentStatus.currentState !== "UNASSIGNED"
    );

    if (assignable.length) {
      await putAssessmentState({
        updateAssessmentStatus: {
          action: Storm.AssessmentAction.ASSIGN,
          assessorParticipantId: selectedAssessor.participant.id,
          stormDocumentExtensionIds: assignable.map((s) => s.id),
        },
      });
    }
    if (reassignable.length) {
      await putAssessmentState({
        updateAssessmentStatus: {
          action: Storm.AssessmentAction.REASSIGN,
          assessorParticipantId: selectedAssessor.participant.id,
          stormDocumentExtensionIds: reassignable.map((s) => s.id),
        },
      });
    }

    setIsAssignModalOpen(false);
    onClose(isReset);
  };

  const handleUnassignAssessments = async () => {
    await putAssessmentState({
      updateAssessmentStatus: {
        action: Storm.AssessmentAction.UNASSIGN,
        stormDocumentExtensionIds: selected.map((s) => s.id),
      },
    });
    setIsUnassignModalOpen(false);
    onClose();
  };

  useEffect(() => {
    async function setCurrPar() {
      try {
        const currPar = await fetchCurrentParticipant();
        setCurrentParticipant(currPar);
      } catch (err) {
        toast.error(
          <Toast
            status={ToastStatus.Error}
            message="Error setting current participant"
          />,
          errorToastOptions
        );
      }
    }
    setCurrPar();
  }, []);

  useEffect(() => {
    async function getAssessors() {
      setIsLoading(true);
      try {
        const res = await fetchParticipants({
          query: debouncedSearchQuery,
          isAvailable: true,
          sort: ["lastName", "asc"],
        });

        let availableAssessors = res.content.filter(
          (a) => a.participant.id !== currentParticipant?.participant.id
        );
        const maybeSameAssessorId = selected[0].assessor?.id;
        const isSameAssessor = selected.every(
          (o) => o.assessor?.id === maybeSameAssessorId
        );

        if (isSameAssessor) {
          availableAssessors = availableAssessors.filter(
            (a) => a.participant.id !== maybeSameAssessorId
          );
        }
        setAssessors(availableAssessors);
      } catch (err) {
        toast.error(
          <Toast
            status={ToastStatus.Error}
            message="Error fetching participants"
          />,
          errorToastOptions
        );
      } finally {
        setIsLoading(false);
      }
    }
    currentParticipant && getAssessors();
  }, [currentParticipant, debouncedSearchQuery]);

  return (
    <>
      <ToastContainer
        autoClose={false}
        closeButton={false}
        position="top-left"
      />
      <ReassignDataModal
        open={isReassignModalOpen}
        setOpen={setIsReassignModalOpen}
        handleDataOnReassign={handleDataOnReassign}
      />
      {selectedAssessor && (
        <AssignModal
          open={isAssignModalOpen}
          assessor={selectedAssessor}
          selected={selected}
          closeModal={() => setIsAssignModalOpen(false)}
          onConfirm={handleAssessmentsStatusCheck}
        />
      )}
      {selected.length && (
        <UnassignModal
          open={isUnassignModalOpen}
          selected={selected}
          closeModal={() => setIsUnassignModalOpen(false)}
          onConfirm={handleUnassignAssessments}
        />
      )}
      <DrawerHeader
        title="Assign"
        subtitle="Who should be doing the assessment?"
        onClose={onCancel || onClose}
      />
      <DrawerContentWrapper>
        <div className={styles.searchBarContainer}>
          <SearchBar
            className={styles.searchBar}
            onSearch={(q: string) => setSearchQuery(q)}
          />
        </div>
        <Loader loading={isLoading}>
          <List>
            {selectedAssessor && (
              <ListItem
                onClick={() => setSelectedAssessor(null)}
                active
                className={styles.assessorListItem}
              >
                <ListItemContent
                  title={selectedAssessor.participant.fullName}
                  aside={selectedAssessor.participant.nickname || ""}
                />
              </ListItem>
            )}
            {!selectedAssessor &&
              currentParticipant &&
              !selected.every(
                (s) => s.assessor?.id === currentParticipant.participant.id
              ) && (
                <ListItem
                  onClick={() => setSelectedAssessor(currentParticipant)}
                  className={styles.assessorListItem}
                >
                  Assign To Me
                </ListItem>
              )}
            {!selectedAssessor &&
              assessors.map((a) => (
                <ListItem
                  onClick={() => setSelectedAssessor(a)}
                  className={styles.assessorListItem}
                  key={a.id}
                >
                  <ListItemContent
                    title={a.participant.fullName}
                    aside={a.participant.nickname || ""}
                  />
                </ListItem>
              ))}
          </List>
        </Loader>
        <div className={filterStyles.buttonContainer}>
          <SecondaryButton
            onClick={() => {
              setIsUnassignModalOpen(true);
            }}
            disabled={
              !selected.every(
                (a) =>
                  a.assessmentStatus.currentState === AssessmentState.ASSIGNED
              ) && !isActionAllowed(selected, AssessmentAction.UNASSIGN)
            }
          >
            UNASSIGN
          </SecondaryButton>
          <Button
            onClick={() => {
              setIsAssignModalOpen(true);
            }}
            disabled={
              !assessors.length ||
              !(
                selectedAssessor &&
                (isActionAllowed(selected, AssessmentAction.ASSIGN) ||
                  isActionAllowed(selected, AssessmentAction.REASSIGN))
              )
            }
          >
            ASSIGN
          </Button>
        </div>
      </DrawerContentWrapper>
    </>
  );
};
