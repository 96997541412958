import React, { FC } from "react";
import { joinClassNames } from "../../../../shared/src/helpers/theme.helpers";

import styles from "./DashboardCardItem.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const DashboardCardItem: FC<Props> = ({ className, children }) => (
  <div className={joinClassNames(styles.container, className)}>{children}</div>
);
