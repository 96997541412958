import React, { useState } from "react";
import { groupBy } from "lodash";
import { useFormikContext } from "formik";

import { TextInputProps } from "../../../../../../TextInput/types";
import {
  API,
  FormSectionSaveVm,
  FormVm,
  QuestionSaveVm,
  QuestionSelectionsSaveVm,
  SafetyRatingWidgetQuestionSaveVm,
  SafetyRatingWidgetSaveVm,
  SectionItemSaveVm,
} from "@rtslabs/field1st-fe-common";
import { TextButton } from "../../../../../../common/Button";
import {
  marshallItem,
  marshallSection,
  unmarshallForm,
} from "../../../../helpers";
import { SuggestionType } from "../../../../../../MultiInput/MultiInput";
import MultiInputItemOption from "../../../../../../MultiInput/MultiInputItemOption";
import Label from "../../../../../../Label/Label";

import { ratingSelections } from "../../../content/widgets/RatingsWidget/RatingsWidget";
import AreasToReviewDrawer from "../../drawers/AreasToReviewDrawer";
import ps from "../../styles.module.scss";
import vs from "../../../validation/styles.module.scss";
import FieldErrorMessage from "../../../validation/FieldErrorMessage";
import { AreaToReview, FBForm, FBItem, FBSection } from "../../../../../types";
import { getItemInSection } from "../../getItemInSection";

interface Props extends TextInputProps {
  areas: SafetyRatingWidgetQuestionSaveVm[];
  name: string;
  item: FBItem<SafetyRatingWidgetSaveVm>;
  touched?: boolean;
}

const AreasToReview = ({
  name,
  label,
  item: widget,
  assistiveText,
  areas,
  error,
  touched,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<AreaToReview | undefined>(
    undefined
  );
  const { values, setValues } = useFormikContext<FBForm>();

  // get the parent MULTI_SELECT item
  const parentItem = getItemInSection(
    values,
    widget.parentSectionRootId,
    widget.parentQuestionRootId
  );

  // group the widget's questions by section
  const groupedAreas = groupBy(areas, "sectionRootId");
  // build the added areas to review with necessary values to update/remove them
  const selectedValues: SuggestionType[] = Object.keys(groupedAreas)
    .map((key) => {
      const area = groupedAreas[key];
      const parentSelectionRootId = area[0].parentSelectionRootId; // this will be the same for all items in this area
      const section: FBSection | undefined = values.sections.find(
        (section) => String(section.rootId) === key
      );
      if (section) {
        return {
          id: section.id, // same as sectionId
          parentSelectionRootId,
          label: section.title,
          subLabel: `Related: ${section.items?.length} subcategories`,
          items: section.items,
        } as SuggestionType;
      } else {
        return undefined;
      }
    })
    .filter((v): v is SuggestionType => !!v);

  /**
   * Build the RATING question items for an area
   * @param area area that is being added/updated
   * @param parentWidgetSelectionRootId the id of the parent MULTI_SELECT item's selection
   * that will trigger this RATING question
   */
  function buildRatingsItems(
    area: AreaToReview,
    parentWidgetSelectionRootId: number | null
  ): SectionItemSaveVm[] {
    return area.items.map((item) => {
      if (item.id) {
        return item;
      } else {
        return {
          title: item.title,
          type: "QUESTION",
          subType: "RATING",
          workflowType: "DRAFT",
          parentWidgetRootId: widget.rootId,
          parentWidgetSelectionRootId,
          selections: ratingSelections,
        };
      }
    });
  }

  /**
   * Add or update the parent MULTI_SELECT item's selection values
   * @param area area that is being added/updated
   */
  function updateSelections(area: AreaToReview): QuestionSelectionsSaveVm[] {
    let itemSelections = parentItem?.selections || [];
    if (area.id) {
      itemSelections = itemSelections.map((selection) => {
        if (selection.rootId === area.parentSelectionRootId) {
          return { ...selection, title: area.label };
        }
        return selection;
      });
    } else {
      itemSelections = [
        ...itemSelections,
        { title: area.label, id: null, tags: [], defenseIds: [] },
      ];
    }
    return itemSelections;
  }

  /**
   * Update the parent MULTI_SELECT item's selections based on the areas to review
   * @param area area that is being added/updated
   */
  async function updateParentQuestion(
    area: AreaToReview
  ): Promise<FBForm | undefined> {
    if (parentItem) {
      const item: QuestionSaveVm = {
        ...marshallItem(parentItem),
        selections: updateSelections(area),
        workflowType: "DRAFT",
      };

      const res = await API.addOrUpdateFormItem({
        formId: values.id,
        sectionId: parentItem.parentSectionId,
        item,
      });

      return unmarshallForm(res);
    }
  }

  /**
   * Add or update the sections containing the RATING questions (including adding/removing RATING items)
   * @param area area that is being added/updated
   * @param ratingsSection ratings section that is being added/removed
   * @param parentWidgetSelectionRootId the id of the parent MULTI_SELECT item's
   * selection that will trigger this RATING question
   */
  async function updateRatingsSection(
    area: AreaToReview,
    ratingsSection: FormSectionSaveVm,
    parentWidgetSelectionRootId?: number | null
  ): Promise<FormVm> {
    const ratingsItems = buildRatingsItems(
      area,
      parentWidgetSelectionRootId || area.parentSelectionRootId || null
    );
    const formVm = await API.addOrUpdateFormSection({
      formId: values.id,
      section: {
        ...ratingsSection,
        title: area.label,
        items: ratingsItems,
        workflowType: "DRAFT",
      },
    });
    setValues(unmarshallForm(formVm));
    return formVm;
  }

  /**
   * Add an area to review:
   *  1. Updates the parent MULTI_SELECT item with the new selection
   *  2. Add a section with RATING items for each area item (subcategory)
   * @param area area that is being added
   */
  async function addArea(area: AreaToReview) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    // update the area in the parent MULTI_SELECT item
    const updateMultiSelectResponse = await updateParentQuestion(area);
    if (updateMultiSelectResponse) {
      // get the updated parent MULTI_SELECT item from the response
      // we can't use parentItem here, because it hasn't been updated to include the selection ids
      const multiSelect = getItemInSection(
        updateMultiSelectResponse,
        widget.parentSectionRootId,
        widget.parentQuestionRootId
      );
      if (multiSelect && multiSelect.selections) {
        // get the most recently added selection's id so we can add it to all the RATING questions
        const { rootId: parentWidgetSelectionRootId } =
          multiSelect.selections[multiSelect.selections.length - 1];
        // add a new section w/ RATINGS question for each selection
        const ratingsSection: FormSectionSaveVm = {
          items: [],
          title: area.label,
          workflowType: "DRAFT",
        };
        await updateRatingsSection(
          area,
          ratingsSection,
          parentWidgetSelectionRootId
        );
      }
    }
  }

  /**
   * Update an area to review:
   *  1. Updates the parent MULTI_SELECT item's associated selection label
   *  2. Updates the associated section's title and RATING items
   * @param area area that is being updated
   */
  async function updateArea(area: AreaToReview) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    // update the selection label in the parent MULTI_SELECT item
    const updateMultiSelectResponse = await updateParentQuestion(area);
    if (updateMultiSelectResponse) {
      // update the corresponding ratings section's title + items (using the updated form response)
      const ratingsSection = updateMultiSelectResponse.sections.find(
        (section: FBSection) => section.id === area.id
      );
      if (ratingsSection) {
        await updateRatingsSection(area, marshallSection(ratingsSection));
      }
    }
  }

  /**
   * Remove an area to review from the widget
   *  - Removes the associated section containing RATING questions
   * @param areaId ID of area to be removed
   */
  async function onRemoveArea(areaId: number) {
    const updateResponse = await API.deleteFormSection({
      formId: values.id,
      sectionId: areaId,
    });
    // update the form state
    setValues(unmarshallForm(updateResponse));
  }

  return (
    <>
      <div className={error && touched ? vs.fieldErrorWrapper : ""}>
        <Label className={ps.label} htmlFor={name}>
          {label}
        </Label>
        <p className={ps.text}>{assistiveText}</p>
        <div>
          {selectedValues.map((value, index) => (
            <MultiInputItemOption
              onClick={() => {
                setActiveItem(selectedValues[index] as unknown as AreaToReview);
                setShowDrawer(true);
              }}
              className={ps.multiInputItem}
              key={value.id}
              subLabel={value.subLabel}
              label={value.label || ""}
              onRemove={() => onRemoveArea(Number(value.id))}
            />
          ))}
        </div>
        <TextButton
          className={ps.textButton}
          onClick={() => setShowDrawer(true)}
        >
          + Add Area
        </TextButton>
      </div>

      <FieldErrorMessage fieldName={name} />

      <AreasToReviewDrawer
        areaName={name}
        initialValues={activeItem}
        onAddArea={addArea}
        onUpdateArea={updateArea}
        onDeleteArea={onRemoveArea}
        show={showDrawer}
        closeDrawer={() => {
          setActiveItem(undefined);
          setShowDrawer(false);
        }}
      />
    </>
  );
};

export default AreasToReview;
