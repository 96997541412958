import { Storm } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import { AssessmentsFiltersArgs } from "../../api/getAssessments";
import { ASSESSMENTS_FILTERS, EVENTS_FILTERS } from "./actions";
import { FiltersActions } from "./types";

export interface FiltersState {
  assessments: AssessmentsFiltersArgs;
  events: any;
}

export interface EventViewFilter {
  statusFilter: Storm.EventStatusFilter;
  year: string;
  month: string | "ALL";
}

export const INITIAL_EVENT_FILTERS: EventViewFilter = {
  statusFilter: Storm.EventStatusFilter.ACTIVE,
  year: moment().year().toString(),
  month: "ALL",
};

const initialState: FiltersState = {
  assessments: { eventIds: "active" },
  events: INITIAL_EVENT_FILTERS,
};

export const filtersReducer = (
  state = initialState,
  action: FiltersActions
): FiltersState => {
  switch (action.type) {
    case ASSESSMENTS_FILTERS.SET:
      return { ...state, assessments: action.filters };
    case ASSESSMENTS_FILTERS.RESET:
      // if any, reset to specific filtering.
      // Example, event view page should retain eventIds value
      const resetAssessmentsState = { ...action.filters };
      return { ...state, assessments: resetAssessmentsState };
    case EVENTS_FILTERS.SET:
      return { ...state, events: action.filters };
    case EVENTS_FILTERS.RESET:
      return { ...state, events: initialState.events };
    default:
      return state;
  }
};
