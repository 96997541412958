import React from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import scssVariables from "../../sass/jsExports.module.scss";
import { Icon } from "../Icon/Icon";
import styles from "./Toastify.module.scss";

export enum ToastStatus {
  Success = "SUCCESS",
  Error = "ERROR",
  Warning = "WARNING",
  Info = "INFO",
  Redirecting = "REDIRECTING",
}

interface ToastProps {
  status: ToastStatus;
  message: string;
}

export const errorToastOptions: ToastOptions = {
  bodyClassName: styles.errorBody,
  className: styles.errorBackground,
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.error}
      size={24}
      type="high_priority"
    />
  ),
  type: "error",
};

export const infoToastOptions: ToastOptions = {
  bodyClassName: styles.infoBody,
  className: styles.infoBackground,
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.primary}
      size={24}
      type="info"
    />
  ),
  type: "info",
};

export const successToastOptions: ToastOptions = {
  bodyClassName: styles.successBody,
  className: styles.successBackground,
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.success}
      size={24}
      type="checkmark"
    />
  ),
  type: "success",
};
export const successToastOptions1: ToastOptions = {
  bodyClassName: styles.successBody,
  className: styles.successBackground,
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.success}
      size={24}
      type="checkmark"
    />
  ),
  type: "success",
};

export const warningToastOptions: ToastOptions = {
  bodyClassName: styles.warningBody,
  className: styles.warningBackground,
  icon: () => (
    <Icon
      className={styles.icon}
      color={scssVariables.warning}
      size={24}
      type="error-filled"
    />
  ),
  type: "warning",
};

export const updateToast = (
  content: ToastContent,
  toastId: string,
  options: ToastOptions
) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { ...options, render: content });
  } else {
    toast(content, { toastId, ...options });
  }
};

export const Toast = ({ message, status }: ToastProps) => {
  return (
    <div className={styles.toastWrapper}>
      <span className={styles.toastMessage}>{message}</span>
      <span className={styles.toastStatus}>{status}</span>
    </div>
  );
};
