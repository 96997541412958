import React, { useMemo } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Loader from "../../../components/Loader/Loader";
import { Button, TertiaryButton } from "../../Button/Button";
import { Icon } from "../../Icon/Icon";
import styles from "./ShareFormButtons.module.scss";

import scssVariables from "../../../sass/jsExports.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Components, ElementType } from "../../../qa-slugs";

const handleButtonStatus = (
  status: string | null,
  loading: boolean,
  isDesktop: boolean
) => {
  if (loading) {
    return <Loader loading={loading} spinnerProps={{ size: 14 }} />;
  }

  if (status === "success") {
    return (
      <>
        <Icon type="checkmark" />
        DOCUMENT SHARED
      </>
    );
  }

  if (status === "failure") {
    return "FAILED TO SHARE";
  }

  return isDesktop ? "SHARE WITH TEAM MEMBER" : "SHARE";
};

interface Props {
  onCancel: () => void;
  submitStatus: "success" | "failure" | "request" | null;
  disabled: boolean;
}

export const ShareFormButtons = ({
  submitStatus,
  onCancel,
  disabled,
}: Props) => {
  const submitButtonType = useMemo(
    () => (submitStatus !== "success" ? "submit" : undefined),
    [submitStatus]
  );

  const loading = useMemo(() => submitStatus === "request", [submitStatus]);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minDesktop})`,
  });

  const buttonStatus = useMemo(() => {
    return handleButtonStatus(submitStatus, loading, isDesktop);
  }, [isDesktop, submitStatus, loading]);

  return (
    /// @TODO how are these different? can they be merged?
    <div
      data-testid={`${Components.Document}s-share-form-drawer`}
      className={styles.shareButtonsContainer}
    >
      {/* Buttons Desktop */}
      <MediaQuery minWidth={scssVariables.minTablet}>
        <Button
          className={joinClassNames(
            styles.fullWidth,
            disabled && styles.disabled
          )}
          disabled={disabled}
          type={submitButtonType}
          qa={`${ElementType.Button}-submit`}
        >
          {buttonStatus}
        </Button>
        {submitStatus !== "success" && (
          <TertiaryButton
            className={joinClassNames(
              styles.fullWidth,
              styles.shareButton,
              loading && styles.disabled
            )}
            disabled={loading}
            onClick={onCancel}
            qa={`${ElementType.Button}-cancel`}
          >
            CANCEL
          </TertiaryButton>
        )}
      </MediaQuery>

      {/* Buttons Mobile */}
      <MediaQuery maxWidth={scssVariables.maxPhone}>
        <Button
          className={joinClassNames(
            styles.fullWidth,
            disabled && styles.disabled
          )}
          disabled={disabled}
          type="submit"
          qa={`${ElementType.Button}-submit`}
        >
          {buttonStatus}
        </Button>
        {submitStatus !== "success" && (
          <TertiaryButton
            className={joinClassNames(
              styles.fullWidth,
              styles.shareButton,
              loading && styles.disabled
            )}
            disabled={loading}
            onClick={onCancel}
            qa={`${ElementType.Button}-cancel`}
          >
            CANCEL
          </TertiaryButton>
        )}
      </MediaQuery>
    </div>
  );
};
