import React, { FC, useState } from "react";
import FilterSection from "../../../common/filters/FilterSection";
import { SubstationSelectedFilters } from "../SubstationsList/SubstationsList";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import { FilterOption } from "../../../common/filters/Filter";
import { getRegionOptions } from "../../../Assessments/assessmentFilterOptions";
import { useAsyncEffect } from "@rtslabs/field1st-fe-common";
import { FilterButtons } from "../../../FilterButtons";

interface Options {
  resetFilters: () => void;
  handleSelectFilter: (filters: SubstationSelectedFilters) => void;
  filterResultsCount?: (filters: SubstationSelectedFilters) => number;
  selectedFilters: SubstationSelectedFilters;
  onClose: () => void;
  results?: number;
  supervisorOptions: FilterOption[];
}

interface SubstationFiltersArgsMulti {
  region: string[];
  supervisor: string[];
}

const INITIAL_FILTERS: SubstationSelectedFilters = {
  region: [],
  status: undefined,
  supervisor: [],
};

const SubstationFilters: FC<Options> = ({
  resetFilters,
  handleSelectFilter,
  filterResultsCount,
  selectedFilters,
  onClose,
  supervisorOptions,
}) => {
  const [selected, setSelected] =
    useState<SubstationSelectedFilters>(selectedFilters);
  const [regionOptions, setRegionOptions] = useState<FilterOption[]>([
    { value: "all", label: "All Regions" },
  ]);
  const [resultCount, setResultCount] = useState<number>();

  function onSelectFilter(
    filterKey: string,
    value: FilterOption["value"],
    multiSelect?: boolean
  ): void {
    let filters;

    if (multiSelect) {
      if (value === "all") {
        filters = {
          ...selected,
          [filterKey]:
            INITIAL_FILTERS[filterKey as keyof SubstationSelectedFilters],
        };
      } else {
        const thisSelection =
          selected[filterKey as keyof SubstationFiltersArgsMulti]!;
        let newValues =
          value && thisSelection.includes(value.toString())
            ? thisSelection.filter((v) => v !== value)
            : [...thisSelection, value];

        filters = {
          ...selected,
          [filterKey]: newValues,
        };
      }
    } else {
      filters = {
        ...selected,
        [filterKey]:
          value === "all"
            ? INITIAL_FILTERS[filterKey as keyof SubstationSelectedFilters]
            : value,
      };
    }
    handleFilterResultsCount(filters);
    setSelected(filters);
  }

  function handleFilters(): void {
    handleSelectFilter(selected);
    onClose();
  }

  function reset(): void {
    resetFilters();
    setSelected(INITIAL_FILTERS);
    handleFilterResultsCount(INITIAL_FILTERS);
  }

  const handleFilterResultsCount = (filters: SubstationSelectedFilters) => {
    const filteredResults = filterResultsCount && filterResultsCount(filters);
    setResultCount(filteredResults);
  };

  useAsyncEffect(async () => {
    setRegionOptions(await getRegionOptions());
  }, []);

  return (
    <div>
      <DrawerHeader onClose={onClose} title="Filters" />
      <FilterSection
        handleSelectFilter={(value) => onSelectFilter("status", value)}
        title="Status"
        selected={selected.status || "all"}
        options={SUBSTATION_STATUS_OPTIONS}
        name="status"
      />
      <FilterSection
        handleSelectFilter={(value) => onSelectFilter("region", value, true)}
        title="Region"
        selected={selected.region}
        options={regionOptions}
        name="region"
        multiSelect
      />
      <FilterSection
        handleSelectFilter={(value) =>
          onSelectFilter("supervisor", value, true)
        }
        title="Supervisor"
        selected={selected.supervisor}
        options={supervisorOptions}
        name="supervisor"
        multiSelect
      />
      <FilterButtons
        loading={false}
        resultCount={resultCount}
        onFilters={handleFilters}
        onResetFilters={reset}
      />
    </div>
  );
};

export default SubstationFilters;

export const SUBSTATION_STATUS_OPTIONS = [
  {
    label: "All Statuses",
    value: "all",
  },
  {
    label: "Included",
    value: "included",
  },
  {
    label: "Not Included",
    value: "notIncluded",
  },
];
