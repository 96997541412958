import { AssessmentState } from "@rtslabs/field1st-fe-common/storm";

type colorMapProps = {
  [K in AssessmentState | "ACTION_REQUIRED"]: {
    border: string;
    fill: string;
  };
};

export const colorMap: colorMapProps = {
  [AssessmentState.UNASSIGNED]: {
    border: "#214467",
    fill: "#D4E6F7",
  },
  [AssessmentState.IN_REVIEW]: {
    border: "#006C55",
    fill: "#4AAB96",
  },
  [AssessmentState.ASSIGNED]: {
    border: "#214467",
    fill: "#3085D8",
  },
  [AssessmentState.ASSESSOR_COMPLETE]: {
    border: "#006C55",
    fill: "#D0E3DF",
  },
  [AssessmentState.IN_PROGRESS]: {
    border: "#000000",
    fill: "#214467",
  },
  [AssessmentState.STORM_CENTER_VERIFIED]: {
    border: "#00853c",
    fill: "#006C55",
  },
  [AssessmentState.CANCELLED]: {
    border: "#000000",
    fill: "#919191",
  },
  ACTION_REQUIRED: {
    border: "#c8102e",
    fill: "#fae6e9",
  },
};
