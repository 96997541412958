import {
  API,
  DataSourceValueDTO,
  DocumentParticipantVm,
  DocumentQuestionResponseVm,
  DocumentStatus,
  DocumentSubmissionType,
  DocumentVm,
  Functions,
  QuestionDTO,
  ResponseContent,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { get, isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import {
  createOrUpdateResponse,
  deleteResponse,
} from "../../../api/responseRequests";
import { selectIsPreview } from "../../../redux/document/documentSelectors";
import { useAppSelector } from "../../../redux/reduxHooks";
import { MinimumSelection } from "./dataSourceValues.helpers";

interface UseQuestionReturn {
  value: string;
  error: string;
  handleChangeBasicResponse: ({
    answer,
    associatedId,
    associatedRootId,
    comments,
    content,
  }: BasicResponseChangeArguments) => void;
  onBlur: () => void;
  label: string;
  name: string;
  required: boolean;
  assistiveText: string | undefined;
  placeholder: string;
  onChangeComment: (comments: DocumentQuestionResponseVm["comments"]) => void;
  enableComments: boolean | undefined;
  allowPhotos: boolean | undefined;
  commentRequired: boolean | undefined;
  commentValue: string;
  commentError: any;
  qa: string;
  questionSelections: MinimumSelection[];
  responses: DocumentQuestionResponseVm[];
  response?: DocumentQuestionResponseVm;
  setResponses: (
    newResponses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null | undefined
  ) => DocumentQuestionResponseVm[];
  shouldUpdateForm: boolean;
}

export function useQuestion(question: QuestionDTO): UseQuestionReturn {
  const { values, errors, setFieldTouched, setValues } =
    useFormikContext<DocumentVm>();
  const valuesRef = useRef<typeof values>(values);

  /**
   * Storm assessments are updated with every response update
   * CORE doucments should only update on response changes when in SUBMITTED status
   * documents IN_PROGRESS will update via the DocumentResponse endpoints
   */
  const client = new URL(API.Environment.apiRoot).host.split(".")[0];
  const isStorm = client === "sat" || client === "efat";
  const isPreview = useAppSelector(selectIsPreview);
  const shouldUpdateForm =
    isStorm || isPreview || values.status === DocumentStatus.SUBMITTED;

  const responses = values.responses?.filter(
    (r) => r.questionRootId === question.rootId
  );

  const response = responses?.find((r) => r.questionRootId === question.rootId);

  const name = question.rootId.toString();
  const questionSelections = getQuestionSelections(question, []);

  async function onChangeComment(
    comments: DocumentQuestionResponseVm["comments"]
  ) {
    if (response) {
      const updatedResponse = shouldUpdateForm
        ? { ...response, comments }
        : await createOrUpdateResponse(values.id, {
            ...response,
            comments,
          });
      await setResponses([updatedResponse]);
    }
  }

  async function handleChangeBasicResponse({
    id,
    answer,
    associatedId,
    associatedRootId,
    content,
    comments,
  }: BasicResponseChangeArguments): Promise<void> {
    const updatedResponse: DocumentQuestionResponseVm = {
      id,
      answer: answer ?? "",
      questionId: question.id,
      questionRootId: question.rootId,
      timeAnswered: new Date().toISOString(),
      associatedId: associatedId ?? undefined,
      associatedRootId: associatedRootId ?? undefined,
      comments: comments ? comments : response?.comments,
    };
    if (updatedResponse.answer) {
      const newResponse = shouldUpdateForm
        ? updatedResponse
        : await createOrUpdateResponse(values.id, updatedResponse);
      await setResponses([newResponse], content);
    } else {
      if (!shouldUpdateForm && id) await deleteResponse(values.id, id);
      await setResponses([], content);
    }
  }

  function setResponses(
    newResponses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null
  ): DocumentQuestionResponseVm[] {
    const currentValues = valuesRef.current;
    let updatedResponses = (currentValues.responses || [])
      .filter((res) => res.questionId !== question.id)
      .concat(newResponses);

    const { displayConditions, sections } = currentValues.form;
    if (!isEmpty(displayConditions) && (content || isEmpty(newResponses))) {
      updatedResponses = Functions.prefillFromQuestionContent({
        question,
        content,
        displayConditions: displayConditions!,
        sections,
        existingResponses: updatedResponses,
      });
    }

    /* set new participants to existing by default, update them if question responses are participants */
    const updatedParticipants = Functions.generateParticipants(
      currentValues.participants,
      updatedResponses,
      sections
    );

    const newValues: DocumentVm = {
      ...currentValues,
      submissionType: DocumentSubmissionType.AUTO_SYNC,
      participants: updatedParticipants as DocumentParticipantVm[], // todo fix
      responses: updatedResponses,
    };
    setValues(newValues, false);
    return updatedResponses;
  }

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  return {
    value:
      response?.associatedRootId?.toString() ||
      response?.associatedId?.toString() ||
      response?.answer ||
      "",
    error: get(errors, question.id.toString()),
    handleChangeBasicResponse,
    onBlur: () => setFieldTouched(name, false),
    name,
    label: question.title,
    required: !!question.formProperties?.isRequired,
    assistiveText: question.properties?.assistiveText,
    placeholder: question.properties?.placeHolderText ?? "",
    enableComments: question.properties?.enableComments,
    allowPhotos: question.properties?.allowPhotos,
    onChangeComment,
    commentRequired:
      question.properties?.commentRequired ||
      questionSelections.find(
        (selection) => selection.id === response?.associatedId
      )?.properties?.commentRequired,
    commentValue: response?.comments ?? "",
    commentError: get(errors, `${name}_comment`),
    qa: `${question.title.replace(/ /g, "")}-${name}`,
    questionSelections,
    response,
    responses,
    setResponses,
    shouldUpdateForm,
  };
}

function getQuestionSelections(
  question: QuestionDTO,
  dataSourceSuggestions: DataSourceValueDTO<ResponseContent>[]
): MinimumSelection[] {
  if (dataSourceSuggestions?.length && question.answerSource?.dataSourceKey) {
    return Functions.mapDSValueToUI(
      dataSourceSuggestions,
      question.answerSource.dataSourceKey
    );
  }
  return question.selections || [];
}

interface BasicResponseChangeArguments {
  id?: number;
  answer?: string;
  associatedId?: number | null;
  associatedRootId?: number | null;
  content?: ResponseContent | null;
  comments?: string | null;
}
