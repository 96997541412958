import React, { useEffect, useMemo, useState } from "react";
import { TextInput } from "./TextInput";
import { TextInputProps } from "./types";

import { debounce } from "lodash";
import { AssistiveText } from "../AssistiveText/AssistiveText";
import ErrorText from "../ErrorText/ErrorText";
import { Suggestions } from "./Suggestions";

export interface TextInputSuggestion {
  [keyName: string]: any;
}

interface TextInputWithSuggestionsProps<T> extends TextInputProps {
  idField: string;
  labelField: string;
  onInputChange: (value: string) => void;
  onSelectSuggestion: (suggestion: T) => void;
  suggestions: T[];
  value: string;
  debounceDelay?: number;
  disabled?: boolean;
}

const TextInputWithSuggestions = <T extends TextInputSuggestion>({
  idField,
  labelField,
  onInputChange,
  onSelectSuggestion,
  suggestions,
  value,
  assistiveText,
  error,
  response,
  debounceDelay = 0,
  disabled,
  ...textInputProps
}: TextInputWithSuggestionsProps<T>): JSX.Element => {
  const [input, setInput] = useState<string>(value);

  // updates input when selecting suggestion and updating Map markers
  useEffect(() => {
    if (response) {
      setInput(response?.answer);
    } else if (value) {
      setInput(value);
    } else {
      setInput("");
    }
  }, [response, value]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(onInputChange, debounceDelay, {
        trailing: true,
      }),
    [response, value]
  );

  return (
    <>
      <div style={{ position: "relative" }}>
        <TextInput
          {...textInputProps}
          value={input}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setInput(e.target.value);
            debouncedChangeHandler.cancel();
            debouncedChangeHandler(e.target.value);
          }}
          error={!!error}
          disabled={disabled}
        />
        {value && suggestions.length > 0 && (
          <Suggestions
            suggestions={suggestions}
            labelField={labelField}
            onSelectSuggestion={onSelectSuggestion}
          />
        )}
      </div>
      {assistiveText && <AssistiveText>{assistiveText}</AssistiveText>}
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default TextInputWithSuggestions;
