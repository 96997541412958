import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  EditProfileForm,
  OfficeLocation,
} from "shared/src/components/Profile/EditProfile/EditProfileForm";
import { TitleBar } from "shared/src/components/TitleBar/TitleBar";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import styles from "./EditProfile.module.scss";
import { API, CoreRedux, UpdateUserConfig } from "@rtslabs/field1st-fe-common";
import { useAppDispatch } from "../redux";
import {
  setAssessmentsFilters,
  setEventsFilters,
} from "../redux/filters/actions";

export const EditProfileScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    maxWidth: scssVariables.maxTablet,
  });

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const authToken = API.Environment.authToken;
  const userProfile = useSelector(CoreRedux.selectUser)!;

  /**
   * If user widens viewport to desktop view
   * we'll replace the route with the desktop
   * profile route. '/profile' is used for
   * Edit Profile and View Profile on Desktop
   */
  useEffect(() => {
    if (!isTablet) {
      navigate("/profile", { replace: true });
    }
  }, [isTablet]);

  // handle submitting user form
  const handleSubmit = async (
    values: UpdateUserConfig,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      setSubmitting(true);
      await API.updateMe({ me: values });
      /**
       * We'll only need to fetch when viewing on desktop.
       * On Desktop there are elements which reflect the cached profile data.
       * On Mobile we don't worry any user data except for the form
       * which will always be up to date. (or ahead)
       */
      if (isDesktop) {
        dispatch(CoreRedux.getUser());
      }
    } finally {
      setSubmitting(false);
    }
  };

  const officeLocations: OfficeLocation[] = []; // useSelector(getOfficeLocations);
  function handleLogout() {
    dispatch(setAssessmentsFilters({}));
    dispatch(setEventsFilters(undefined));
    dispatch(CoreRedux.logout());
  }

  return (
    <div className={styles.editProfile}>
      <TitleBar title="Edit Profile" />
      <EditProfileForm
        onSubmit={handleSubmit}
        userProfile={userProfile}
        officeLocations={[]} // not sure yet how to pull this
        logout={handleLogout}
      />
    </div>
  );
};
