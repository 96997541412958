import { CoreRedux, LogoFileType } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import Logo from "shared/src/components/Logo";

export const BACKUP_EFAT_IMAGE = {
  src: "/assets/efat/efat-icon.svg",
  alt: "Storm Center Electronic Field Assessment Tool",
  fileType: LogoFileType.svg,
};

export const EFATLogo: FC = () => {
  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  return <Logo logo={logo || BACKUP_EFAT_IMAGE} />;
};
