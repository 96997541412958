import {
  DocumentPhotoVm,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ElementType, QAProps } from "../../qa-slugs";
import { PhotosHandling } from "../Document/DocumentForm/usePhotos";
import { Icon } from "../Icon/Icon";
import { ModalPromptCloseButton } from "../Modal/ModalPromptCloseButton";
import { TextInput } from "../TextInput/TextInput";
import {
  errorToastOptions,
  successToastOptions,
  Toast,
  ToastStatus,
} from "../Toast/Toastify";
import { DeletePhotoConfirmationModal } from "./DeletePhotoConfirmationModal";
import styles from "./Photo.module.scss";

interface Props extends QAProps {
  photo: DocumentPhotoVm;
  onClick: () => void;
  onDeletePhoto: PhotosHandling["handleDeletePhoto"];
  onUpdatePhoto: PhotosHandling["handleUpdatePhoto"];
  type: "IMAGES_ABOVE" | "IMAGES_BELOW";
  questionTitle: string;
  readOnly?: boolean;
}

export const Photo: FC<Props> = ({
  photo,
  onClick,
  onDeletePhoto,
  onUpdatePhoto,
  type,
  questionTitle,
  qa,
  readOnly,
}) => {
  const [value, setValue] = useState(photo.description);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const caption = useDebouncedValue(value, 300);

  useEffect(() => {
    if (caption !== photo.description) {
      onUpdatePhoto({ ...photo, description: caption });
    }
  }, [caption]);

  const handleDeletePhoto = async () => {
    try {
      setDeleteLoading(true);
      await onDeletePhoto(photo.id);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
      toast.success(
        <Toast
          status={ToastStatus.Success}
          message={`Photo Deleted from ${questionTitle}`}
        />,
        {
          ...successToastOptions,
          autoClose: 5000,
        }
      );
    } catch (err) {
      toast.error(
        <Toast
          status={ToastStatus.Error}
          message={"Unable to delete photo. Please refresh and try again."}
        />,
        {
          ...errorToastOptions,
        }
      );
    }
  };

  return (
    <div
      data-testid={qa}
      className={
        type === "IMAGES_ABOVE"
          ? styles.galleryPhotoWrapper
          : styles.photoWrapper
      }
    >
      <DeletePhotoConfirmationModal
        loading={deleteLoading}
        onCancel={() => setDeleteModalVisible(false)}
        onConfirm={handleDeletePhoto}
        visible={deleteModalVisible}
      />
      {/* Photo Gallery Photo View */}
      {type === "IMAGES_ABOVE" && (
        <div className={styles.galleryPhoto} onClick={onClick}>
          <img src={photo.imageUrl} className={styles.galleryPhoto} />
          <ModalPromptCloseButton
            qa={qa ? `${qa}-delete` : undefined}
            onClick={(event?: React.MouseEvent) => {
              event?.stopPropagation();
              setDeleteModalVisible(true);
            }}
            className={styles.galleryDeleteButton}
          />
        </div>
      )}
      {/* Question Photo View */}
      {type === "IMAGES_BELOW" && (
        <div className={styles.photoAndCaptionContainer}>
          <img
            src={photo.imageUrl}
            className={styles.photo}
            onClick={onClick}
          />
          <div className={styles.captionArea}>
            <div className={styles.captionFirstLine}>
              <span>Photo Caption</span>
              {!readOnly && (
                <button
                  data-testid={qa ? `${qa}-delete` : undefined}
                  className={styles.deleteButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteModalVisible(true);
                  }}
                  type="button"
                >
                  <Icon type="icons8-delete_sign" />
                </button>
              )}
            </div>
            {!readOnly && (
              <TextInput
                qa={qa ? `${qa}-${ElementType.TextInput}` : undefined}
                value={value}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setValue(e.target.value)}
              />
            )}

            {readOnly && <div>{value}</div>}
          </div>
        </div>
      )}
    </div>
  );
};
