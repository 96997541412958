import { Storm } from "@rtslabs/field1st-fe-common";

export function fetchEvents(
  args: Storm.API.GetEventsArgs
): Promise<Storm.PageOfEventWithDocStatsVM> {
  return Storm.API.getEvents(args);
}

export function fetchActiveEvents(): Promise<Storm.PageOfEventWithDocStatsVM> {
  return Storm.API.getEvents({
    statusFilter: Storm.EventStatusFilter.ACTIVE,
    sort: ["startDate", "desc"],
  });
}

export function fetchEventById(
  id: number | string
): Promise<Storm.EventWithDocStatsVM> {
  return Storm.API.getEventById({ id });
}
