import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import { Link } from "shared/src/components/Link/Link";
import { AssessmentItem } from "../Assessments/AssessmentItem";
import {
  EventWithDocStatsVM,
  StormDocumentExtensionVm,
} from "@rtslabs/field1st-fe-common/storm";
import { NoAssessments } from "../Assessments/NoAssessments";
import { DashboardCardItem } from "./DashboardCardItem";

import styles from "./RecentActivity.module.scss";
import { Storm, useAPI } from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import { useAppDispatch } from "../../redux";
import { setAssessmentsFilters } from "../../redux/filters/actions";

interface Props {
  event: Storm.EventWithDocStatsVM;
  currentEventId: EventWithDocStatsVM["id"];
}

export const RecentActivity: FC<Props> = ({ event, currentEventId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [assessments, setAssessments] = useState<StormDocumentExtensionVm[]>(
    []
  );

  const assessmentsCall = useAPI(Storm.API.getAssessments, {
    eventIds: event.id,
    actionRequiredFilter: Storm.AssessmentActionRequiredFilter.ALL,
    statuses: [
      Storm.AssessmentState.IN_PROGRESS,
      Storm.AssessmentState.ASSESSOR_COMPLETE,
      Storm.AssessmentState.IN_REVIEW,
      Storm.AssessmentState.STORM_CENTER_VERIFIED,
    ],
    sort: "document.lastModifiedDate,desc",
    size: 7,
  });

  const handleViewAllAssessments = () => {
    dispatch(setAssessmentsFilters({ eventIds: event.id }));
    navigate("/assessments");
  };

  useEffect(() => {
    assessmentsCall.data && setAssessments(assessmentsCall.data.content);
  }, [assessmentsCall.data]);

  useEffect(() => {
    // This is where we want to refresh assessments list on just the current event tab on tab change
    event.id === currentEventId &&
      assessmentsCall.refresh &&
      assessmentsCall.refresh();
  }, [currentEventId]);

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>Recent Activity</div>
      <Loader loading={assessmentsCall.isLoading}>
        {!!assessments.length &&
          assessments.map((a) => (
            <div className={styles.section} key={a.id}>
              <DashboardCardItem className={styles.cardItemWrapper}>
                <AssessmentItem assessment={a} />
                <div>
                  <Link
                    to={`/assessment/review/${a.id}`}
                    className={styles.link}
                  >
                    <span className={styles.underline}>Review</span>
                    <Icon type="chevron-right" className={styles.icon} />
                  </Link>
                </div>
              </DashboardCardItem>
            </div>
          ))}
        {!assessments.length && <NoAssessments />}
        <div className={styles.buttonContainer}>
          <SecondaryButton
            onClick={handleViewAllAssessments}
            className={styles.wide}
          >
            View All Assessments
          </SecondaryButton>
        </div>
      </Loader>
    </div>
  );
};
