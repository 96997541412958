import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { Components, ElementType, QAProps } from "../../../qa-slugs";
import { PhotoArea } from "../../Photos/PhotoArea";
import { CameraButton } from "./CameraButton/CameraButton";
import styles from "./QuestionPhotoWrapper.module.scss";
import { usePhotos } from "./usePhotos";
import { useQuestion } from "./useQuestion";

interface Props {
  children?: React.ReactNode;
  question: QuestionDTO;
  appPath?: string;
}

export const QuestionPhotoWrapper: FC<Props> = ({
  question,
  children,
  appPath,
}) => {
  const { allowPhotos, qa, response } = useQuestion(question);

  // this handles the saving or rendering photos if a question has photos for each options rather than main question
  const photoAssociatedId = ["MULTI_SELECT"].includes(question.subType)
    ? response?.associatedId
    : undefined;

  const photoHandling = usePhotos(question.rootId, photoAssociatedId, appPath);
  const { photos } = photoHandling;
  const [photosExpanded, setPhotosExpanded] = useState<boolean>(false);
  return (
    <>
      <div className={styles.photoQuestionWrapper}>
        <div className={styles.question}>{children}</div>
        {allowPhotos && (
          <CameraButton
            qa={
              qa
                ? `${qa}-${Components.Document}-${ElementType.Button}-radio}`
                : undefined
            }
            photoCount={photos.length}
            expanded={photosExpanded}
            onClick={() => setPhotosExpanded(!photosExpanded)}
          />
        )}
      </div>
      {allowPhotos && (
        <PhotoArea
          {...photoHandling}
          photosExpanded={photosExpanded}
          qa={qa ? `${qa}-${Components.PhotoArea}-question` : undefined}
          type="IMAGES_BELOW"
        />
      )}
    </>
  );
};
