import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import { Assessment } from "./Assessment";
import { fetchAssessmentById } from "../../../api/getAssessments";

interface Params extends Record<string, string> {
  id: string;
}

export const AssessmentWrapper: FC = () => {
  const [assessment, setAssessment] =
    useState<Storm.StormDocumentExtensionVm>();
  const [error, setError] = useState<string>();
  const params = useParams<Params>();
  const id = Number(params.id);

  const fetchAssessment = async () => {
    try {
      const res = await fetchAssessmentById(id);
      setError("");
      setAssessment(res);
    } catch (_e) {
      setError(`Unable to load assessment with id of ${id}`);
    }
  };

  useEffect(() => {
    fetchAssessment();
  }, []);

  const assessmentReload = async () => {
    // set to undefined so the loader shows spinner and reset the state
    setAssessment(undefined);
    await fetchAssessment();
  };

  const assessmentRefresh = () => {
    fetchAssessment();
  };

  return (
    <Loader loading={!assessment && !error}>
      {!!error && <ErrorText>{error}</ErrorText>}
      {assessment && (
        <Assessment
          assessment={assessment}
          assessmentRefresh={assessmentRefresh}
          assessmentReload={assessmentReload}
        />
      )}
    </Loader>
  );
};
