import React from "react";

import styles from "./UserMenuHeader.module.scss";
import menuStyles from "./UserMenu.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Link } from "../../Link/Link";
import { ElementType } from "../../../qa-slugs";

interface Props {
  userInitials: string;
  name: string;
  onNavigate: () => void;
}

const MenuHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ userInitials, name, onNavigate }, ref) => {
    return (
      <div className={styles.menuHeader} ref={ref}>
        <div className={styles.col}>
          <span
            className={joinClassNames(menuStyles.initials, styles.initials)}
          >
            {userInitials}
          </span>
        </div>
        <div className={styles.col}>
          <p className={styles.username}>{name}</p>
          <Link
            qa={`view-profile-${ElementType.Link}`}
            to="/profile"
            onClick={onNavigate}
          >
            View Profile
          </Link>
        </div>
      </div>
    );
  }
);

export default MenuHeader;
