import React, { FC } from "react";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import { useSelector } from "react-redux";
import Header from "./Header";
import { useAppDispatch } from "../../redux";
import { BACKUP_EFAT_IMAGE } from "../Assessments/EFATLogo";
import {
  setAssessmentsFilters,
  setEventsFilters,
} from "../../redux/filters/actions";

export const StormHeader: FC = () => {
  const user = useSelector(CoreRedux.selectUser);
  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);
  const dispatch = useAppDispatch();

  function handleLogout() {
    dispatch(setAssessmentsFilters({}));
    dispatch(setEventsFilters(undefined));
    dispatch(CoreRedux.logout());
  }

  return (
    <Header
      logo={logo || BACKUP_EFAT_IMAGE}
      onLogout={handleLogout}
      user={user}
    />
  );
};
