import React, { FC, useState } from "react";
import MediaQuery from "react-responsive";
import Loader from "../Loader/Loader";
import scssVariables from "../../sass/jsExports.module.scss";
import { Link } from "../Link/Link";
import { Field, Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import { PASSWORD_REGEX } from "./constants";
import Tooltip, { lightTooltipClasses } from "../Tooltip/Tooltip";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";
import PasswordRequirements from "./PasswordRequirements";
import ErrorText from "../ErrorText/ErrorText";
import { AuthRequestStatus } from "./types";
import AuthAnimatedButton from "./AuthAnimatedButton";
import { newPasswordSchema } from "./helpers";
import Logo from "../Logo";
import { LogoImgProps } from "@rtslabs/field1st-fe-common";

const buttonText = (status?: AuthRequestStatus): string => {
  switch (status) {
    case "success":
      return "Password Reset Successful";
    case "failure":
      return "Unable to Reset Password";
    default:
      return "Save New Password";
  }
};

interface PasswordResetProps {
  username: string;
  loading?: boolean;
  status?: AuthRequestStatus;
  keyExpired?: boolean;
  onReturnToLogin: () => void;
  onResetPassword: (password: string) => Promise<void>;
  logo?: LogoImgProps;
}

const PasswordReset: FC<PasswordResetProps> = ({
  username,
  keyExpired,
  loading,
  status,
  onReturnToLogin,
  onResetPassword,
  logo,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={styles.passwordReset}>
      <MediaQuery minWidth={scssVariables.minTablet}>
        <Logo logo={logo} />
      </MediaQuery>
      <MediaQuery maxWidth={scssVariables.maxPhone}>
        <div className={styles.mobileHeader}>
          <i
            className={`icon-icons8-chevron-left ${styles.chevron}`}
            onClick={onReturnToLogin}
            data-testid={`${Components.PasswordReset}-${ElementType.Link}-toLogin`}
          />
          <span>Return to login</span>
        </div>
      </MediaQuery>
      <Loader loading={loading}>
        {keyExpired ? (
          <span className={styles.validationError}>
            Your key has expired. Please restart the reset process and try
            again.
          </span>
        ) : (
          <>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await onResetPassword(values.password);
                setSubmitting(false);
                resetForm();
              }}
              validationSchema={newPasswordSchema}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <span className={styles.title}>Reset Your Password</span>
                  <span className={styles.message}>
                    Enter a new password for username: <b>{username}</b>
                  </span>
                  <Tooltip
                    classes={lightTooltipClasses}
                    placement="bottom-start"
                    open={showTooltip}
                    title={
                      <PasswordRequirements
                        newPassword={values.password}
                        passwordRegex={{
                          DIGIT: PASSWORD_REGEX.DIGIT,
                          FULL: PASSWORD_REGEX.FULL,
                          LOWERCASE: PASSWORD_REGEX.LOWERCASE,
                          SPECIAL: PASSWORD_REGEX.SPECIAL,
                          UPPERCASE: PASSWORD_REGEX.UPPERCASE,
                        }}
                      />
                    }
                  >
                    <Field
                      as={TextInput}
                      endAdornment={{
                        visible: true,
                        button: true,
                        label: showPassword ? "Hide" : "Show",
                        handleClick: () => setShowPassword((sp) => !sp),
                      }}
                      disabled={status === "success"}
                      error={!showTooltip && errors.password}
                      name="password"
                      onBlur={() => setShowTooltip(false)}
                      onFocus={() => setShowTooltip(true)}
                      placeholder="Password"
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                    />
                  </Tooltip>
                  <Field
                    as={TextInput}
                    endAdornment={{
                      visible: true,
                      button: true,
                      label: showPassword ? "Hide" : "Show",
                      handleClick: () => setShowPassword((sp) => !sp),
                    }}
                    error={touched.confirmPassword && errors.confirmPassword}
                    disabled={status === "success"}
                    name="confirmPassword"
                    placeholder="Re-enter Your New Password"
                    label="Confirm New Password"
                    type={showPassword ? "text" : "password"}
                  />
                  <AuthAnimatedButton
                    qa={`${Components.PasswordReset}-${ElementType.Button}-submit`}
                    className={styles.submitButton}
                    submitStatus={status}
                    onComplete={onReturnToLogin}
                  >
                    {buttonText(status)}
                  </AuthAnimatedButton>
                  {status === "failure" && (
                    <ErrorText>
                      The password reset process has failed. Please restart the
                      reset process and try again.
                    </ErrorText>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
        <div className={styles.returnLink}>
          <Link
            to="/login"
            qa={`${Components.PasswordReset}-${ElementType.Link}-returnToLoginFromPasswordReset`}
          >
            Return to login
          </Link>
        </div>
      </Loader>
    </div>
  );
};

PasswordReset.displayName = "PasswordReset";
export default PasswordReset;
