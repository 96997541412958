import { useCallback, useState } from "react";
import { fetchAssessmentStats } from "../../api/getAssessments";
import { useAppSelector } from "../../redux";
import { selectAssessmentsFilters } from "../../redux/filters/selectors";
import { Storm } from "@rtslabs/field1st-fe-common";

const initialState: Storm.StormDocumentStateCountVm = {
  nrOfDocumentsPerState: {
    UNASSIGNED: 0,
    ASSIGNED: 0,
    IN_PROGRESS: 0,
    ASSESSOR_COMPLETE: 0,
    IN_REVIEW: 0,
    STORM_CENTER_VERIFIED: 0,
    CANCELLED: 0,
  },
  nrOfDocumentsWithActionRequired: 0,
  totalNumberOfAssessments: 0,
};

export const useAssessmentsCount = () => {
  const [assessmentsCount, setAssessmentsCount] =
    useState<Storm.StormDocumentStateCountVm>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const assessmentsFilters = useAppSelector(selectAssessmentsFilters);

  const getAssessmentsCount = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const stats = await fetchAssessmentStats(assessmentsFilters);

      // totatNumberOfAssessments did not include cancelled. So lets include that
      const cancelledCount = stats.nrOfDocumentsPerState.CANCELLED || 0;

      setAssessmentsCount({
        ...initialState,
        nrOfDocumentsPerState: {
          ...initialState.nrOfDocumentsPerState,
          ...stats.nrOfDocumentsPerState,
        },
        nrOfDocumentsWithActionRequired: stats.nrOfDocumentsWithActionRequired,
        totalNumberOfAssessments:
          stats.totalNumberOfAssessments + cancelledCount,
      });
    } catch (err: unknown) {
      setError("An error occurred retrieving assessment counts");
    } finally {
      setIsLoading(false);
    }
  }, [assessmentsFilters]);

  return { getAssessmentsCount, assessmentsCount, isLoading, error };
};
