import React, { FC } from "react";
import styles from "./SidebarNavigation.module.scss";
import { Components, ElementType } from "../../qa-slugs";
import { Link } from "../Link/Link";

interface Props {
  children?: React.ReactNode;
  onNavToPrivacy?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

const SidebarNavigation: FC<Props> = ({ children, onNavToPrivacy }) => {
  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
      <div className={styles.privacyTermsWrapper}>
        <Link
          data-testid={`${Components.SidebarNavigation}-${ElementType.Link}-privacy`}
          className={styles.link}
          onClick={onNavToPrivacy}
          to="/privacy"
        >
          Privacy
        </Link>
        <span className={styles.separator}>&middot;</span>
        <Link
          data-testid={`${Components.SidebarNavigation}-${ElementType.Link}-privacyTerms`}
          className={styles.link}
          disabled={true}
          to="/terms-and-conditions"
        >
          Terms
        </Link>
      </div>
    </div>
  );
};

export default SidebarNavigation;
