import React, { FC } from "react";
import styles from "./FormTitle.module.scss";

interface FormTitleProps {
  title: string;
}

export const FormTitle: FC<FormTitleProps> = ({ title }) => {
  return <span className={styles.title}>{title}</span>;
};
