import React, { FC } from "react";
import {
  Button,
  TertiaryButton,
  SecondaryButton,
} from "shared/src/components/Button/Button";

type Props = {
  active?: boolean | null;
  isSubmitting: boolean;
  onClick: () => void;
  handleReopenEvent: () => void;
  handleSubmit: () => void;
  canManagerEdit: boolean;
};

export const EditEventActionButtons: FC<Props> = ({
  active,
  isSubmitting,
  onClick,
  handleReopenEvent,
  handleSubmit,
  canManagerEdit,
}) => {
  if (active) {
    return (
      <div>
        {canManagerEdit && (
          <TertiaryButton onClick={onClick}>Close Event</TertiaryButton>
        )}
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          Save Changes
        </Button>
      </div>
    );
  }

  return (
    <div>
      {canManagerEdit && (
        <SecondaryButton
          disabled={isSubmitting}
          onClick={handleReopenEvent}
          type="submit"
        >
          Reopen Event
        </SecondaryButton>
      )}
    </div>
  );
};
