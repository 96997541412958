import React, { FC } from "react";
import { joinClassNames } from "../../../../../shared/src/helpers/theme.helpers";

import styles from "./ReadOnlyField.module.scss";

interface Props {
  label: string;
  className?: string;
  children?: React.ReactNode;
}
export const ReadOnlyField: FC<Props> = ({ label, children, className }) => (
  <div className={joinClassNames(styles.wrapper, className)}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{children}</div>
  </div>
);
