import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { Modal } from "shared/src/components/Modal/Modal";
import { putAssessmentState } from "../../../api/putAssessmentState";

interface ResetAssessmentProps {
  assessment: Storm.StormDocumentExtensionVm;
  open: boolean;
  onClose: () => void;
}

export const ResetAssessment: FC<ResetAssessmentProps> = ({
  assessment,
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleReset = async () => {
    setLoading(true);
    const assessmentStatus: Storm.API.PutAssessmentStateArgs = {
      updateAssessmentStatus: {
        action: Storm.AssessmentAction.RESET,
        stormDocumentExtensionIds: [assessment.id],
      },
    };

    await putAssessmentState(assessmentStatus);
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      title="Reset Assessment"
      alert={{
        variant: "warning",
        title: `Are you sure you would like to reset this assessment to ${Storm.AssessmentStateLabel.UNASSIGNED}?`,
        message: "This cannot be undone.",
        isVisible: true,
      }}
      open={open}
      handleClose={onClose}
      cancellable
      action={{
        text: "Confirm",
        loading,
        callback: handleReset,
      }}
    />
  );
};
