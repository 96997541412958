import React, { FC } from "react";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import HistoryDateCell from "shared/src/components/Documents/components/DocumentSidebarDrawer/DocumentHistory/HistoryDateCell";
import { Storm } from "@rtslabs/field1st-fe-common";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import styles from "../AssessmentHistorySidebar.module.scss";
import { HistoryEntryTitle } from "./HistoryEntryTitle";
import { AdditionalInformation } from "./AdditionalInformation";
interface Props {
  assessment: Storm.StormDocumentExtensionVm;
  history: Storm.StormDocumentHistoryVm[];
  isAssessmentHistoryDataLoading: boolean;
  isAssessmentHistoryError: boolean;
}

export const AssessmentHistoryContent: FC<Props> = ({
  assessment,
  history,
  isAssessmentHistoryDataLoading,
  isAssessmentHistoryError,
}) => {
  return (
    <Loader loading={isAssessmentHistoryDataLoading}>
      {history.length > 0 ? (
        <Table>
          <TableHead
            columns={[
              { id: "action", label: "Action", isUnsortable: true },
              { id: "date", label: "Date", isUnsortable: true },
            ]}
          />
          <tbody>
            {history.map((d: Storm.StormDocumentHistoryVm) => {
              return (
                <TableRow key={`row-${d.id}`}>
                  <TableCell className={styles.cellEntryItem}>
                    <HistoryEntryTitle item={d} />
                  </TableCell>
                  <TableCell className={styles.cellEntryItem}>
                    <HistoryDateCell historyDate={d.dateCreated} />
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <span className={styles.noData}>No data available</span>
      )}
      {isAssessmentHistoryError && (
        <ErrorText>{isAssessmentHistoryError}</ErrorText>
      )}

      <AdditionalInformation assessment={assessment} />
    </Loader>
  );
};
