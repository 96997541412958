import React, { FC } from "react";
import scssVariables from "../../sass/jsExports.module.scss";
import Toast, { ToastProps } from "./Toast";

type ErrorToastProps = Omit<ToastProps, "backgroundColor" | "color">;

/**
 * @deprecated Use react-toastify and Toastify.tsx instead.
 * @see https://fkhadra.github.io/react-toastify/introduction
 */
export const ErrorToast: FC<ErrorToastProps> = (toastProps) => {
  return (
    <Toast
      backgroundColor={scssVariables.errorFill}
      color={scssVariables.error}
      {...toastProps}
    />
  );
};
