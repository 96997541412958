import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./AssessmentSidebarMenu.module.scss";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import ListSimple, {
  ListSimpleItem,
} from "shared/src/components/ListSimple/ListSimple";
import { StyledLinkCaretRight } from "shared/src/components/StyledLinkCaretRight/StyledLinkCaretRight";
import AssessmentHistorySidebar from "../AssessmentHistorySidebar/AssessmentHistorySidebar";
import ShareDocumentSidebar from "shared/src/components/Documents/components/DocumentSidebarDrawer/ShareDocumentSidebar/ShareDocumentSidebar";
import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import { Storm } from "@rtslabs/field1st-fe-common";
import { VerifyAssessmentSidebar } from "./VerifyAssessmentSidebar";
import { ReassignAssessmentSidebar } from "./ReassignAssessmentSidebar";
import { isActionAllowed } from "../../helpers";
import { ResetAssessment } from "../../Modals/ResetAssessment";

interface Props {
  assessment: StormDocumentExtensionVm;
  terms: string;
  hideView: boolean;
  onClose: (isReset?: boolean) => void;
}

type DrawerItemNames = "history" | "reassign" | "share" | "verify" | "reset";
type DrawerItemsProps = {
  [key in DrawerItemNames]: boolean;
};

const AssessmentSidebarMenu = ({
  assessment,
  terms,
  hideView,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const [drawerItems, setDrawerItems] = useState<DrawerItemsProps>({
    history: false,
    reassign: false,
    share: false,
    verify: false,
    reset: false,
  });

  // Group config terms
  const documentTerm = conjugateTerm({
    term: terms,
    role: "noun",
    modifier: undefined,
    fallback: "Assessment",
  });

  const lowerDocumentTerm = documentTerm.toLowerCase();

  const isEventActive = assessment.event.active;
  const isCancelled =
    assessment.assessmentStatus.currentState ===
    Storm.AssessmentState.CANCELLED;

  const showReassignLink =
    isEventActive &&
    !isCancelled &&
    (isActionAllowed([assessment], Storm.AssessmentAction.UNASSIGN) ||
      isActionAllowed([assessment], Storm.AssessmentAction.ASSIGN) ||
      isActionAllowed([assessment], Storm.AssessmentAction.REASSIGN));

  const showVerifyLink =
    isEventActive &&
    !isCancelled &&
    isActionAllowed([assessment], Storm.AssessmentAction.COMPLETE_REVIEW);

  const showResetLink =
    isEventActive &&
    !isCancelled &&
    isActionAllowed([assessment], Storm.AssessmentAction.RESET);

  const handleNavigateToAssessment = useCallback(
    () => navigate(`/${lowerDocumentTerm}/${assessment.id}`),
    [navigate, assessment.id]
  );

  const handleNavigateToAssessmentReview = useCallback(
    () => navigate(`/${lowerDocumentTerm}/review/${assessment.id}`),
    [navigate, assessment.id]
  );

  /* Nav items */
  const viewDocument: ListSimpleItem = {
    primary: <span className={styles.navLabel}>View {documentTerm}</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: handleNavigateToAssessment,
    showLink: !hideView,
  };

  const documentHistory: ListSimpleItem = {
    primary: <span className={styles.navLabel}>{documentTerm} History</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => setDrawerItems({ ...drawerItems, history: true }),
    showLink: true,
  };

  const documentShare: ListSimpleItem = {
    primary: <span className={styles.navLabel}>Share</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => setDrawerItems({ ...drawerItems, share: true }),
    showLink: true,
  };

  const reassignAssessment: ListSimpleItem = {
    primary: (
      <span className={styles.navLabel}>
        {assessment.assessmentStatus.currentState ===
        Storm.AssessmentState.UNASSIGNED
          ? "Assign"
          : "Reassign"}
      </span>
    ),
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => setDrawerItems({ ...drawerItems, reassign: true }),
    showLink: showReassignLink,
  };

  const reviewAssessment: ListSimpleItem = {
    primary: <span className={styles.navLabel}>Review</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: handleNavigateToAssessmentReview,
    showLink: !isCancelled,
  };

  const verifyAssessment: ListSimpleItem = {
    primary: <span className={styles.navLabel}>Verify</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => setDrawerItems({ ...drawerItems, verify: true }),
    showLink: showVerifyLink,
  };

  const resetAssessment: ListSimpleItem = {
    primary: <span className={styles.navLabel}>Reset</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => setDrawerItems({ ...drawerItems, reset: true }),
    showLink: showResetLink,
  };

  /* Group nav items together */
  // top nav group
  let docDetailsGroup: ListSimpleItem[] = [
    viewDocument,
    documentHistory,
    documentShare,
  ];

  let assessmentActionsGroup: ListSimpleItem[] = [
    reassignAssessment,
    reviewAssessment,
    verifyAssessment,
    resetAssessment,
  ];

  const handleShareDocument = async (
    participantIds: number[]
  ): Promise<void> => {
    const share: Storm.ShareAssessmentRequestVm = {
      assessmentId: assessment.id,
      participantIds,
    };

    await Storm.API.shareAssessment(share);
  };

  const handleCloseDrawer = (drawerItem: DrawerItemNames) => {
    setDrawerItems({ ...drawerItems, [drawerItem]: false });
  };

  return (
    <div>
      <div style={{ marginLeft: "-16px", marginRight: "-16px", marginTop: 0 }}>
        <hr className={styles.divider} />
        <ListSimple listItems={docDetailsGroup} />
        <hr className={styles.divider} />
        <ListSimple listItems={assessmentActionsGroup} />
      </div>
      <AssessmentHistorySidebar
        assessment={assessment}
        open={drawerItems.history}
        onClose={() => handleCloseDrawer("history")}
        term={documentTerm}
      />

      <ShareDocumentSidebar
        isOpen={drawerItems.share}
        onClose={() => handleCloseDrawer("share")}
        onShareDocument={handleShareDocument}
      />

      <ReassignAssessmentSidebar
        assessment={assessment}
        open={drawerItems.reassign}
        onCancel={() => handleCloseDrawer("reassign")}
        onClose={(isReset?: boolean) => {
          handleCloseDrawer("reassign");
          onClose(isReset);
        }}
      />

      <VerifyAssessmentSidebar
        assessment={assessment}
        open={drawerItems.verify}
        onClose={() => {
          handleCloseDrawer("verify");
          onClose();
        }}
      />

      <ResetAssessment
        assessment={assessment}
        open={drawerItems.reset}
        onClose={() => {
          handleCloseDrawer("reset");
          onClose();
        }}
      />
    </div>
  );
};

export default AssessmentSidebarMenu;
