import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import React, { FC } from "react";
import { VerifyAssessment } from "../../Modals/VerifyAssessment";

interface VerifyAssessmentSideBarProps {
  assessment: StormDocumentExtensionVm;
  open: boolean;
  onClose: () => void;
}

export const VerifyAssessmentSidebar: FC<VerifyAssessmentSideBarProps> = ({
  assessment,
  open,
  onClose,
}) => {
  return (
    <VerifyAssessment
      assessments={[assessment]}
      open={open}
      onClose={onClose}
    />
  );
};
