import { useState } from "react";

export function useSelected() {
  const [selected, setSelected] = useState<number[]>([]);

  const handleSelectStation = (substationId: number) => {
    setSelected((prev) => {
      return [...prev, substationId];
    });
  };

  const handleDeselectStation = (substationId: number) => {
    setSelected((prev) => {
      return [
        ...prev.filter((id) => {
          return substationId != id;
        }),
      ];
    });
  };

  const handleSelectAll = (values: number[]) => {
    let updatedOptions = Array.from(new Set(selected.concat(values)));
    setSelected(updatedOptions);
  };

  const handleDeselectAll = (values: number[]) => {
    setSelected((prev) => {
      return prev.filter((id) => !values.includes(id));
    });
  };

  return {
    selected,
    handleSelectStation,
    handleDeselectStation,
    handleSelectAll,
    handleDeselectAll,
  };
}
