import React, { FC } from "react";
import { EventCard } from "./EventCard";
import { RecentActivity } from "./RecentActivity";

import styles from "./DashboardTabPage.module.scss";
import { SectionTitle } from "./SectionTitle";
import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import { DashboardMapWidget } from "./MapWidget/MapWidget";

interface Props {
  event: EventWithDocStatsVM;
  currentEventId: EventWithDocStatsVM["id"];
}

export const DashboardTabPage: FC<Props> = ({ event, currentEventId }) => (
  <div className={styles.row}>
    <div className={styles.column}>
      <EventCard event={event} />
    </div>
    <div className={styles.wide}>
      <DashboardMapWidget event={event} />
      <RecentActivity event={event} currentEventId={currentEventId} />
    </div>
  </div>
);
