import React, { useState, createContext, ReactNode } from "react";

interface State {
  drawerIsVisible: boolean;
  setDrawerIsVisible: (visible: boolean) => void;
}

const BottomNavigationContext = createContext<State>({
  drawerIsVisible: false,
  setDrawerIsVisible: () => {},
});

interface Props {
  children: ReactNode;
}

/**
 * Used to share state with DashboardDrawer (Start New Doc)
 * and the Bottom Navigation component
 */
const BottomNavigationContextProvider = ({ children }: Props) => {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  return (
    <BottomNavigationContext.Provider
      value={{
        drawerIsVisible,
        setDrawerIsVisible,
      }}
    >
      {children}
    </BottomNavigationContext.Provider>
  );
};

export { BottomNavigationContext as default, BottomNavigationContextProvider };
