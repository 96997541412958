import React, { FC } from "react";
import moment from "moment";

import { Storm } from "@rtslabs/field1st-fe-common";

import { ReadOnlyField } from "./ReadOnlyField";

import styles from "./Assessment.module.scss";
import { toastScrollBannerStyle } from "shared/src/components/Document/DocumentToasts";
import { AssessmentStateMarker } from "../../common/AssessmentStateMarker";
import { AssessmentStateDisplay } from "../AssessmentStateDisplay";
interface Props {
  assessment: Storm.StormDocumentExtensionVm;
  hasErrors?: boolean;
}

export const ReadOnlySection: FC<Props> = ({
  assessment,
  hasErrors = false,
}) => {
  const subStation = assessment.workLocation.name;

  return (
    <div
      style={toastScrollBannerStyle(
        assessment.document.submissionType,
        hasErrors
      )}
    >
      <div className={styles.substationWrapper}>
        <AssessmentStateMarker
          currentState={assessment.assessmentStatus.currentState}
        />
        <div className={styles.substationRowWrapper}>
          <div className={styles.substationNameWrapper}>
            <span className={styles.substationName} data-testid={subStation}>
              {subStation}
            </span>
            <span className={styles.substationDistance}></span>
          </div>
          <AssessmentStateDisplay
            actionRequired={assessment.actionRequired}
            currentState={assessment.assessmentStatus.currentState}
          />
        </div>
      </div>
      <ReadOnlyField label="Event Name">
        {assessment.event.eventName}
      </ReadOnlyField>
      <ReadOnlyField label="Report ID">#{assessment.document.id}</ReadOnlyField>
      <ReadOnlyField label="Request Date">
        {moment(assessment.document.dateCreated).format("h:mm:ss on MM/DD/YY")}
      </ReadOnlyField>
      <ReadOnlyField label="Substation Supervisor">
        {assessment.workLocation.nickname}
      </ReadOnlyField>
      {assessment.assessmentStatus.currentState !== "UNASSIGNED" && (
        <>
          <ReadOnlyField label="Assessor">
            {assessment.assessor?.name}{" "}
          </ReadOnlyField>
          <ReadOnlyField label="Assessor's Supervisor">
            {assessment.assessor?.supervisorName}{" "}
          </ReadOnlyField>
          {assessment.assessmentStatus.currentState !== "IN_PROGRESS" && (
            <ReadOnlyField label="Submission Date">
              {moment(assessment.document.submissionDate).format(
                "h:mm:ss on MM/DD/YY"
              )}{" "}
            </ReadOnlyField>
          )}
        </>
      )}
    </div>
  );
};
