import React, { FC } from "react";
import {
  TertiaryButton,
  SecondaryButton,
} from "shared/src/components/Button/Button";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import styles from "./SubstationsTable.module.scss";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { SortableColumn } from "shared/src/components/TableUI/TableHead/TableHead";
import { BackToTop } from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import NoSearchResults from "../../../common/NoSearchResults";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Storm, WorkLocationDTO } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";

interface Props {
  selected: number[];
  handleSelectAll: (ids: number[]) => void;
  handleDeselectAll: (ids: number[]) => void;
  handleSelectStation: (id: number) => void;
  handleDeselectStation: (id: number) => void;
  substations: WorkLocationDTO[];
  onHandleSortOrderChange: (
    column: SortableColumn,
    sortOrder: SortOrder
  ) => void;
  handleSelectPage: (value: number) => void;
  setElementsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleClearSearch: () => void;
  summary: {
    size: number;
    page: number;
    totalElements: number;
    totalPages: number;
  };
  elementsPerPage: number;
  disabled?: boolean;
  handleStationRemove: (ids: number[]) => void;
  onShowAll?: (all: boolean) => void;
}

const SubstationsTable: FC<Props> = ({
  handleSelectAll,
  handleDeselectAll,
  handleDeselectStation,
  handleSelectStation,
  selected,
  substations,
  onHandleSortOrderChange,
  summary,
  handleSelectPage,
  setElementsPerPage,
  handleClearSearch,
  elementsPerPage,
  disabled = false,
  handleStationRemove,
  onShowAll,
}) => {
  const { values, setFieldValue } = useFormikContext<Storm.EventVm>();

  return (
    <>
      <TableSummary
        pageSize={summary.size}
        currentPage={summary.page}
        totalElements={summary.totalElements}
        ofWhat={
          values.substationIds.length < 1
            ? "Substations"
            : `Substations (${values.substationIds.length} included in the event)`
        }
        onShowAll={onShowAll}
      />
      <Table>
        <TableHead
          columns={[
            {
              id: "check",
              isUnsortable: true,
              label: (
                <Checkbox
                  disabled={!values.active}
                  onChange={(checked) => {
                    if (checked) {
                      handleSelectAll(substations.map((station) => station.id));
                    } else {
                      handleDeselectAll(
                        substations.map((station) => station.id)
                      );
                    }
                  }}
                  checked={substations.every((station) => {
                    return selected.includes(station.id);
                  })}
                />
              ),
            },
            {
              id: "region",
              isUnsortable: false,
              label: "Region",
            },
            {
              id: "nickname",
              isUnsortable: false,
              label: "Supervisor",
            },
            {
              id: "name",
              isUnsortable: false,
              label: "Substation",
            },
            {
              id: "status",
              isUnsortable: false,
              label: "Status",
            },
            { id: "add", isUnsortable: true, label: "" },
          ]}
          handleSortOrderChange={onHandleSortOrderChange}
        />
        <tbody>
          {substations.length ? (
            substations.map((station) => {
              const isIncluded = values.substationIds.includes(station.id);
              return (
                <TableRow key={station.id}>
                  <TableCell>
                    <Checkbox
                      disabled={!values.active}
                      checked={selected.includes(station.id)}
                      onChange={(checked) => {
                        if (checked) {
                          handleSelectStation(station.id);
                        } else {
                          handleDeselectStation(station.id);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{station.region}</TableCell>
                  <TableCell>{station.nickname}</TableCell>
                  <TableCell>{station.name}</TableCell>
                  <TableCell>
                    <div className={styles.statusContainer}>
                      <div
                        className={
                          isIncluded
                            ? styles.statusIncluded
                            : styles.statusNotIncluded
                        }
                      ></div>
                      {isIncluded ? "Included" : "Not Included"}
                    </div>
                  </TableCell>
                  <TableCell>
                    {isIncluded && (
                      <TertiaryButton
                        disabled={disabled}
                        onClick={() => handleStationRemove([station.id])}
                      >
                        Remove from Event
                      </TertiaryButton>
                    )}

                    {!isIncluded && (
                      <SecondaryButton
                        disabled={disabled}
                        onClick={() => {
                          setFieldValue(
                            "substationIds",
                            values.substationIds.concat(station.id)
                          );
                        }}
                      >
                        Add to Event
                      </SecondaryButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <tr>
              <TableCell colSpan={7}>
                <NoSearchResults onClear={handleClearSearch} />
              </TableCell>
            </tr>
          )}
        </tbody>
      </Table>
      <PaginationWrapper>
        <RowsPerPage
          pageSize={elementsPerPage}
          onClick={(value) => {
            setElementsPerPage(value);
          }}
        />
        <Pagination
          currentPage={summary.page}
          totalPages={summary.totalPages}
          onClick={handleSelectPage}
        />
        <BackToTop />
      </PaginationWrapper>
    </>
  );
};

export default SubstationsTable;
