import {
  API,
  FormSummaryVm,
  PageOfWorkLocationDTO,
  Storm,
  WorkLocationDTO,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditEvent } from "./EditEvent";
import { toast, ToastContainer } from "react-toastify";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
} from "shared/src/components/Toast/Toastify";
import { fetchEventById } from "../../../api/getEvents";

interface Params extends Record<string, string> {
  id: string;
}

const EditEventScreen: FC = () => {
  const [event, setEvent] = useState<Storm.EventVm>({
    active: true,
    endDate: null,
    eventName: "",
    formId: 0,
    hashtags: "",
    id: 0,
    notifiedParticipantEmails: [],
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    substationIds: [],
  });
  const [eventWithDocStats, setEventWithDocStats] = useState<
    Storm.EventWithDocStatsVM | undefined
  >();
  const [forms, setForms] = useState<FormSummaryVm[]>([]);
  const [isEventLoading, setIsEventLoading] = useState<boolean>(false);
  const [closeEventModalOpen, setCloseEventModalOpen] =
    useState<boolean>(false);
  const params = useParams<Params>();

  const id = params.id;

  function handleModalClose() {
    setCloseEventModalOpen(false);
  }

  const setError = (message: string) => {
    toast.error(
      <Toast status={ToastStatus.Error} message={message} />,
      errorToastOptions
    );
  };

  async function handleCloseEvent(id: string): Promise<void> {
    try {
      const res = await Storm.API.getEventByIdWithDocStats({ id });
      setEventWithDocStats(res);
    } catch (err: unknown) {
      process.env.NODE_ENV === "development" && console.warn(err);
      toast.error(
        <Toast status={ToastStatus.Error} message="Unable to load Event" />,
        errorToastOptions
      );
    } finally {
      setCloseEventModalOpen(true);
    }
  }

  function handleGetWorkLocations(): Promise<PageOfWorkLocationDTO> {
    return API.getWorkLocations({
      page: 0,
      size: 2000,
    });
  }

  useEffect(() => {
    async function getEventById(id: string): Promise<void> {
      setIsEventLoading(true);
      try {
        const res = await fetchEventById(id);
        setEvent(res);
      } catch (err: unknown) {
        process.env.NODE_ENV === "development" && console.warn(err);
        toast.error(
          <Toast status={ToastStatus.Error} message="Unable to load Event" />,
          errorToastOptions
        );
      } finally {
        setIsEventLoading(false);
      }
    }
    if (id && !isEventLoading) {
      getEventById(id);
    }
  }, [id]);

  useEffect(() => {
    async function getAllForms(): Promise<void> {
      try {
        const res = await API.getAllForms({});
        setForms(res);
      } catch (err: unknown) {
        process.env.NODE_ENV === "development" && console.warn(err);
        toast.error(
          <Toast
            status={ToastStatus.Error}
            message="Unable to load form options"
          />,
          errorToastOptions
        );
      }
    }
    getAllForms();
  }, []);

  const [stations, setStations] = useState<WorkLocationDTO[]>([]);
  const [isStationsListLoading, setIsStationsListLoading] =
    useState<boolean>(false);
  const [stationsListError, setStationsListError] = useState<
    string | undefined
  >();
  useEffect(() => {
    async function fetchWorkLocations() {
      setIsStationsListLoading(true);
      try {
        const res = await handleGetWorkLocations();
        setStations(res.content);
      } catch (err: unknown) {
        setIsStationsListLoading(false);
        process.env.NODE_ENV === "development" && console.warn(err);
        setStationsListError("Unable to load substations");
      } finally {
        setIsStationsListLoading(false);
      }
    }
    fetchWorkLocations();
  }, []);

  const userRoles = API.Environment.getRoles();

  return (
    <>
      <ToastContainer closeButton={false} position="top-left" />
      <EditEvent
        closeEventModalOpen={closeEventModalOpen}
        event={event}
        eventWithDocStats={eventWithDocStats}
        forms={forms}
        id={id}
        isEventLoading={isEventLoading}
        isStationsListLoading={isStationsListLoading}
        stations={stations}
        stationsListError={stationsListError}
        handleModalClose={handleModalClose}
        handleCloseEvent={handleCloseEvent}
        handleGetWorkLocations={handleGetWorkLocations}
        setError={setError}
        userRoles={userRoles}
      />
    </>
  );
};

export default EditEventScreen;
