import { Storm } from "@rtslabs/field1st-fe-common";
import moment from "moment";

export const buildEditEventPayload = (
  values: Storm.EventVm
): Storm.API.EditEventArgs => {
  let endDate;

  if (!values.active) {
    endDate = values.endDate
      ? values.endDate
      : moment(new Date()).format("YYYY-MM-DD");
  } else {
    endDate = values.endDate;
  }

  const eventPayload: Storm.API.EditEventArgs = {
    id: values.id,
    event: {
      ...values,
      startDate: values.startDate,
      endDate,
      hashtags: values.hashtags,
    },
  };

  return eventPayload;
};

export const buildCreateEventPayload = (
  values: Storm.EventVm
): Storm.API.CreateEventArgs => {
  const event: Storm.CreateEventVM = {
    ...values,
    endDate: values.endDate,
    hashtags: values.hashtags || undefined,
    startDate: values.startDate,
  };

  return { event };
};
