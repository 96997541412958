import {
  FilterParams,
  PageableRequest,
  SortParams,
  Storm,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useMemo, useState } from "react";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { PageHeader } from "shared/src/components/PageHeader/PageHeader";
import { SortableColumn } from "shared/src/components/TableUI/TableHead/TableHead";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import {
  TabContent,
  TabGroup,
} from "shared/src/components/Tabs/TabGroup/TabGroup";
import { getSortDirection } from "shared/src/helpers/mapping.helpers";
import {
  AssessmentsFiltersArgs,
  fetchAssessments,
} from "../../api/getAssessments";
import styles from "./Assessments.module.scss";
import { AssessmentsReport } from "./AssessmentsReport";
import { AssessmentsTable } from "./AssessmentsTable";
import { SearchAssessments } from "./SearchAssessments";
import { useLocation, useNavigate } from "react-router-dom";
import { AssessmentsMapWidget } from "./MapWidget/AssessmentsMapWidget";
import { useSelector } from "react-redux";
import { selectAssessmentsFilters } from "../../redux/filters/selectors";
import { useAppDispatch } from "../../redux";
import {
  resetAssessmentsFilters,
  setAssessmentsFilters,
} from "../../redux/filters/actions";
import { useAssessmentsCount } from "./useAssessmentsCount";

interface AssessmentsProps {
  events: Storm.PageOfEventWithDocStatsVM;
  eventOnlyId?: number;
}

const Assessments: FC<AssessmentsProps> = ({ events, eventOnlyId }) => {
  const [refreshCount, setRefreshCount] = useState<boolean>(false);
  const { assessmentsCount, getAssessmentsCount, isLoading, error } =
    useAssessmentsCount();
  const [size, setSize] = useState<number>(10);
  const [sizeMemo, setSizeMemo] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<SortParams | undefined>([
    "document.dateLastSubmitted",
    "desc",
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const assessmentTab = location.state as string;
  const [currentTabId, setCurrentTabId] = useState<string>(
    assessmentTab || "assessmentsList"
  );

  const assessmentsFilters = useSelector(selectAssessmentsFilters);
  const dispatch = useAppDispatch();
  const eventOnlySize = 1000;

  const cleanAssessmentsFilters: Storm.API.StormDocumentRequestParameters =
    useMemo(() => {
      const { eventIds } = assessmentsFilters;
      if (eventIds === "active") {
        return {
          ...assessmentsFilters,
          eventIds: undefined,
          eventActive: true,
        };
      }

      return { ...assessmentsFilters, eventIds };
    }, [assessmentsFilters]);

  const filterParams: PageableRequest = {
    size,
    page,
    sort,
  };

  const [assessments, setAssessments] =
    useState<Storm.PageOfStormDocumentExtensionVm>();

  // a flag where we show all the substations if it's filtered by event on the map
  const eventOnlyOnMap: boolean =
    currentTabId === "assessmentsMap" && !!cleanAssessmentsFilters.eventIds;

  // If it's event only view, we show all the substations
  const customFilterParams: FilterParams = eventOnlyOnMap
    ? { size: eventOnlySize }
    : filterParams;

  const assessmentsCall = useAPI(fetchAssessments, {
    ...assessmentsFilters, // pass the actual filter. do not use cleanAssessmentsFilters
    ...customFilterParams,
  });

  useEffect(() => {
    if (assessmentsCall.data && !assessmentsCall.isLoading) {
      setAssessments(assessmentsCall.data);
      setRefreshCount(true);
    }
  }, [assessmentsCall.data, assessmentsCall.isLoading]);

  function handlePaginate(page: number, size?: number): void {
    setPage(page);
    if (size) {
      if (size !== eventOnlySize) {
        // put size into memory so we can handle event only on map that uses
        // eventOnlySize value for view count
        setSizeMemo(size);
      }
      setSize(size);
    }
  }

  const handleUpdateFilters = (filters: AssessmentsFiltersArgs): void => {
    if (size !== sizeMemo && !filters.eventIds) {
      // Reset to normal size count when event filter is removed
      setSize(sizeMemo);
    }
    dispatch(setAssessmentsFilters(filters));
    setPage(0);
  };

  function handleResetFilters(): void {
    dispatch(resetAssessmentsFilters());
    setPage(0);
  }

  function handleSortOrderChange(
    column: SortableColumn,
    sortOrder: SortOrder
  ): void {
    const sortDir = getSortDirection(sortOrder);
    const sortBy = column.id;
    let newSort: SortParams | undefined = sortDir
      ? [sortBy, sortDir]
      : undefined;
    setSort(newSort);
  }

  const handleViewAssessment = (id: number) => {
    navigate(`/assessment/${id}`);
  };

  const searchBar = (showFilterMessage: boolean) => (
    <div className={styles.searchBarContainer}>
      <SearchAssessments
        onUpdateFilters={handleUpdateFilters}
        events={events?.content || []}
        eventOnlyId={eventOnlyId}
        showFilterMessage={showFilterMessage}
      />
    </div>
  );

  const customContent = (
    <div className={styles.assessmentsMap}>
      {/* @TODO SAT-267 Future (R2) */}
      {searchBar(false)}
    </div>
  );

  const tabs = [
    {
      label: "List",
      tabId: "assessmentsList",
      tabPanelId: "assessmentsList",
      tabPanelContent: (
        <div className={styles.assessmentsList}>
          {searchBar(true)}
          <AssessmentsReport
            assessmentsCount={assessmentsCount}
            isLoading={isLoading}
            error={error}
          />
          <AssessmentsTable
            assessments={assessments}
            loading={false}
            onPaginate={handlePaginate}
            onClearSearch={handleResetFilters}
            handleSortOrderChange={handleSortOrderChange}
            onViewAssessment={handleViewAssessment}
            currSort={sort}
            exportAssessmentsParams={cleanAssessmentsFilters}
            assessmentsRefresh={assessmentsCall.refresh}
          />
        </div>
      ),
    },
    {
      label: "Map",
      tabId: "assessmentsMap",
      tabPanelId: "assessmentsMap",
      tabPanelContent: (
        <div>
          <AssessmentsMapWidget
            assessments={assessments}
            assessmentsRefresh={assessmentsCall.refresh}
            assessmentsFilterParams={cleanAssessmentsFilters}
            customContent={customContent}
            onPaginate={handlePaginate}
            loading={assessmentsCall.isLoading}
            assessmentsCount={assessmentsCount}
          />
        </div>
      ),
    },
  ];

  const handleTabChange = (newTab: TabContent) => {
    setCurrentTabId(newTab.tabId);
    if (!!cleanAssessmentsFilters.eventIds) {
      if (newTab.tabId === "assessmentsMap") {
        handlePaginate(0, eventOnlySize);
      } else {
        handlePaginate(0, sizeMemo);
      }
    }
  };

  useEffect(() => {
    if (refreshCount) {
      getAssessmentsCount();
      setRefreshCount(false);
    }
  }, [refreshCount]);

  return (
    <>
      <div>
        {!eventOnlyId && (
          <>
            <Breadcrumbs
              paths={[{ pathName: "Assessments" }]}
              data-testid="breadcrumb"
            />

            <PageHeader title="Assessments"></PageHeader>
          </>
        )}
        <TabGroup
          variant="white"
          tabs={tabs}
          onTabChange={handleTabChange}
          initiallySelectedTabIndex={assessmentTab === "assessmentsMap" ? 1 : 0}
        />
      </div>
    </>
  );
};

export default Assessments;
