import React, { FC, useMemo } from "react";
import MediaQuery from "react-responsive";
import { useLocation } from "react-router-dom";

import scssVariables from "shared/src/sass/jsExports.module.scss";
// import { UserMenu } from "shared/src/components/navigation/UserMenu/UserMenu";

import styles from "./Header.module.scss";
// import LogoCondensed from "shared/src/components/LogoCondensed";
import { LogoImgProps, ParticipantUserVM } from "@rtslabs/field1st-fe-common";
import LogoCondensed from "../../../../shared/src/components/LogoCondensed";
import { UserMenu } from "../../../../shared/src/components/navigation/UserMenu/UserMenu";

const blacklistMobileHeader = ["/profile/settings", "/profile/edit"];

interface Props {
  logo?: LogoImgProps;
  onLogout: () => void;
  user: ParticipantUserVM | null;
}

const Header: FC<Props> = ({ logo, onLogout, user }) => {
  const urlPath = useLocation().pathname;

  const renderMobileHeader = useMemo(
    () => !blacklistMobileHeader.includes(urlPath),
    [urlPath]
  );

  return (
    <>
      <MediaQuery minWidth={scssVariables.minDesktop}>
        <div className={styles.desktopHeader}>
          <LogoCondensed logo={logo} height={55} />
          <div className={styles.appName}>
            Storm Center Electronic Field Assessment Tool
          </div>
          <UserMenu user={user} onLogout={onLogout} />
        </div>
      </MediaQuery>
      {renderMobileHeader && (
        <MediaQuery maxWidth={scssVariables.maxTablet}>
          <div className={styles.mobileHeader}>
            <LogoCondensed logo={logo} />
          </div>
        </MediaQuery>
      )}
    </>
  );
};

Header.displayName = "Header";
export default Header;
