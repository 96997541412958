import React, { FC } from "react";
import { NewEventButton } from "./NewEventButton";
import styles from "./Events.module.scss";
import { Icon } from "../../../../shared/src/components/Icon/Icon";

interface NoEventsProps {
  userRoles: string[];
}

export const NoEvents: FC<NoEventsProps> = ({ userRoles }) => {
  return (
    <div data-testid="noEvents" className={styles.noEvents}>
      <Icon type="storm_marker" className={styles.noEventsIcon} />
      <p className={styles.label}>No Events Found</p>
      <NewEventButton
        text="Create a new event"
        userRoles={userRoles}
        hideWhenDisabled={true}
      />
    </div>
  );
};
