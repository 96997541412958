import {
  DocumentParticipantVm,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVm,
  Functions,
  QuestionDTO,
} from "@rtslabs/field1st-fe-common";
import { FormikProps } from "formik";

export function setResponses(
  question: QuestionDTO,
  values: DocumentVm,
  newResponses: DocumentQuestionResponseVm[],
  setValues: FormikProps<DocumentVm>["setValues"]
): DocumentQuestionResponseVm[] {
  let updatedResponses = (values.responses || [])
    .filter((res) => res.questionId !== question.id)
    .concat(newResponses);

  const { sections } = values.form;

  /* set new participants to existing by default, update them if question responses are participants */
  const updatedParticipants = Functions.generateParticipants(
    values.participants,
    updatedResponses,
    sections
  );

  const newValues: DocumentVm = {
    ...values,
    submissionType: DocumentSubmissionType.AUTO_SYNC,
    participants: updatedParticipants as DocumentParticipantVm[], // todo fix
    responses: updatedResponses,
  };
  setValues(newValues, false);
  return updatedResponses;
}
