import React, { FC } from "react";
import SubstationFilters from "../Filters/SubstationFilters";
import { FilterOption } from "../../../common/filters/Filter";
import {
  INITIAL_SUBSTATION_FILTERS,
  SubstationSelectedFilters,
} from "../SubstationsList/SubstationsList";
import SearchBar from "shared/src/components/SearchBar/SearchBar";

type Props = {
  className?: string;
  handleResetFilters?: () => void;
  handleSelectFilter?: (filters: SubstationSelectedFilters) => void;
  filterResultsCount?: (filters: SubstationSelectedFilters) => number;
  filtersOpen?: boolean;
  query: string;
  results: number;
  selectedFilters?: SubstationSelectedFilters;
  setFiltersOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  supervisorOptions: FilterOption[];
};

const SubstationsSearchBar: FC<Props> = ({
  className,
  handleResetFilters,
  handleSelectFilter,
  filterResultsCount,
  filtersOpen = false,
  query,
  results,
  selectedFilters = INITIAL_SUBSTATION_FILTERS,
  setFiltersOpen,
  setQuery,
  supervisorOptions,
}) => {
  const showFilters =
    !!handleResetFilters && !!setFiltersOpen && !!handleSelectFilter;
  return (
    <SearchBar
      filterDrawer={
        showFilters
          ? {
              props: {
                isOpen: filtersOpen,
                anchor: "right",
                onClose: () => {
                  setFiltersOpen!(false);
                },
                onOpen: () => {
                  setFiltersOpen!(true);
                },
              },
              filterComponent: (
                <SubstationFilters
                  resetFilters={handleResetFilters}
                  onClose={() => setFiltersOpen!(false)}
                  handleSelectFilter={handleSelectFilter}
                  filterResultsCount={filterResultsCount}
                  selectedFilters={selectedFilters}
                  results={results}
                  supervisorOptions={supervisorOptions}
                />
              ),
            }
          : undefined
      }
      onSearch={setQuery}
      initialQuery={query}
      className={className}
    />
  );
};

export default SubstationsSearchBar;
