import React, { useState, useEffect, FC } from "react";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import filterStyles from "../../common/filters/Filters.module.scss";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import styles from "./SearchAssessorDrawer.module.scss";
import { List } from "shared/src/components/List/List";
import { ListItem } from "shared/src/components/List/ListItem";
import { Storm, useDebouncedValue } from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import { StormParticipantVm } from "@rtslabs/field1st-fe-common/dist/main/storm";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import { ListItemContent } from "shared/src/components/List/ListItemContent";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import { toast, ToastContainer } from "react-toastify";
import {
  errorToastOptions,
  Toast,
  ToastStatus,
} from "shared/src/components/Toast/Toastify";
import { fetchParticipants } from "../../../api/getParticipants";

interface SearchAssessorContentProps {
  onSelectAssessor: (value: any) => void;
  onCancel: () => void;
}

export const SearchAssessorContent: FC<SearchAssessorContentProps> = ({
  onSelectAssessor,
  onCancel,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assessors, setAssessors] = useState<StormParticipantVm[]>([]);
  const [selectedAssessor, setSelectedAssessor] =
    useState<StormParticipantVm | null>(null);

  const debouncedSearchQuery = useDebouncedValue(searchQuery, 1000);

  async function getAssessors() {
    setIsLoading(true);
    try {
      const res = await fetchParticipants({
        query: debouncedSearchQuery,
        sort: ["lastName", "asc"],
      });
      setAssessors(res.content);
    } catch (err) {
      toast.error(
        <Toast
          status={ToastStatus.Error}
          message="Error fetching participants"
        />,
        errorToastOptions
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAssessors();
  }, [debouncedSearchQuery]);

  return (
    <>
      <ToastContainer
        autoClose={false}
        closeButton={false}
        position="top-left"
      />

      <DrawerHeader title="Choose Assessor" onClose={onCancel} />
      <DrawerContentWrapper>
        <div className={styles.searchBarContainer}>
          <SearchBar
            className={styles.searchBar}
            onSearch={(q: string) => setSearchQuery(q)}
          />
        </div>
        <Loader loading={isLoading}>
          <List>
            {selectedAssessor && (
              <ListItem
                onClick={() => setSelectedAssessor(null)}
                active
                className={styles.assessorListItem}
              >
                <ListItemContent
                  title={selectedAssessor.participant.fullName}
                  aside={selectedAssessor.participant.nickname || ""}
                />
              </ListItem>
            )}

            {!selectedAssessor &&
              assessors.map((a) => (
                <ListItem
                  onClick={() => setSelectedAssessor(a)}
                  className={styles.assessorListItem}
                  key={a.id}
                >
                  <ListItemContent
                    title={a.participant.fullName}
                    aside={a.participant.nickname || ""}
                  />
                </ListItem>
              ))}
          </List>
        </Loader>
        <div className={filterStyles.buttonContainer}>
          <Button
            onClick={() => onSelectAssessor(selectedAssessor?.participant)}
            disabled={!selectedAssessor}
          >
            Select Assessor
          </Button>
          <TertiaryButton onClick={onCancel}>Cancel</TertiaryButton>
        </div>
      </DrawerContentWrapper>
    </>
  );
};
