import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "shared/src/components/Button/Button";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { EventPieChart } from "../Events/EventPieChart";
import { calcEventProgress } from "../Events/helpers";
import { AssessmentCounts } from "./AssessmentCounts";
import styles from "./EventCard.module.scss";

export interface EventCardProps {
  event: EventWithDocStatsVM;
}

export const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const navigate = useNavigate();
  const progress = calcEventProgress(event);

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>{event.eventName}</div>
      <div className={styles.chartWrapper}>
        <div className={styles.pieChart}>
          <EventPieChart progress={progress} />
        </div>
        <div className={styles.percentContainer}>
          <div className={styles.percentWrapper}>
            <div className={joinClassNames(styles.percentage, styles.active)}>
              {progress.activePercentage}
            </div>
            <div className={styles.complete}>Active</div>
          </div>
          <div className={styles.percentWrapper}>
            <div
              className={joinClassNames(styles.percentage, styles.submitted)}
            >
              {progress.submittedPercentage}
            </div>
            <div className={styles.complete}>Submitted</div>
          </div>
        </div>
      </div>
      <AssessmentCounts documentCount={event} />
      <div className={styles.buttonContainer}>
        <SecondaryButton onClick={() => navigate(`/events/${event.id}`)}>
          View Event
        </SecondaryButton>
      </div>
    </div>
  );
};
