import React, { FC } from "react";
import { Table } from "shared/src/components/TableUI/Table/Table";
import {
  SortableColumn,
  TableHead,
} from "shared/src/components/TableUI/TableHead/TableHead";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { BackToTop } from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";
import { calcEventProgress, fmtDate } from "./helpers";
import { Link } from "shared/src/components/Link/Link";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./EventsTable.module.scss";
import { SortOrder } from "shared/src/components/TableUI/TableHeader/SortableTableHeader";
import Loader from "shared/src/components/Loader/Loader";
import NoSearchResults from "../common/NoSearchResults";
import { NoEvents } from "./NoEvents";
import { Storm, SortParams } from "@rtslabs/field1st-fe-common";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface Props {
  summary: {
    size: number;
    page: number;
    totalElements: number;
    totalPages: number;
    sort: SortParams | undefined;
  };
  events: EventWithDocStatsVM[];
  onSortOrderChange: (column: SortableColumn, sortOrder: SortOrder) => void;
  loading?: boolean;
  onPaginationChange: (page: number, size?: number) => void;
  searched: boolean;
  onClearSearch: () => void;
  onExportEvents: typeof Storm.API.downloadEvents;
  exportEventsParams: Omit<Storm.API.DownloadEventsArgs, "mediaType">;
  userRoles: string[];
}

export const EventsTable: FC<Props> = ({
  summary,
  events,
  onSortOrderChange,
  loading,
  onPaginationChange,
  searched,
  onClearSearch,
  onExportEvents,
  exportEventsParams,
  userRoles,
}) => {
  return (
    <div className={styles.tableContainer}>
      <TableSummary
        pageSize={summary.size}
        currentPage={summary.page}
        totalElements={summary.totalElements}
        ofWhat="events"
        exports={["print", "xls", "csv"]}
        onExport={onExportEvents}
        exportParams={exportEventsParams}
      />
      <Loader loading={loading} overlay={true}>
        <Table>
          {events.length ? (
            <>
              <TableHead
                columns={[
                  { id: "eventName", label: "Event" },
                  { id: "startDate", label: "Date" },
                  { id: "progress", label: "Progress", isUnsortable: true }, // awaiting BE
                  { id: "active", label: "Status" },
                  { id: "action", label: "", isUnsortable: true },
                ]}
                handleSortOrderChange={onSortOrderChange}
                initialSort={
                  summary.sort && {
                    columnId: summary.sort[0],
                    order:
                      summary.sort[1] === "asc" ? "ascending" : "descending",
                  }
                }
              />

              <tbody>
                {events.map((event) => {
                  const progress = calcEventProgress(event);
                  return (
                    <TableRow key={event.id}>
                      <TableCell>{event.eventName}</TableCell>
                      <TableCell>
                        {fmtDate(event.startDate)}{" "}
                        {event.endDate && `- ${fmtDate(event.endDate)}`}
                      </TableCell>
                      <TableCell>
                        {progress.progressPercentage} ({progress.verified}/
                        {progress.total})
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            color: event.active
                              ? scssVariables.success
                              : scssVariables.error,
                          }}
                        >
                          {event.active ? "Active" : "Closed"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className={styles.viewEventLink}>
                          <Link to={`/events/${event.id}`}>View Event</Link>
                          <Icon type="chevron-right" className={styles.icon} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <TableCell>
                  {searched ? (
                    <NoSearchResults onClear={onClearSearch} />
                  ) : (
                    <NoEvents userRoles={userRoles} />
                  )}
                </TableCell>
              </tr>
            </tbody>
          )}
        </Table>
      </Loader>
      <PaginationWrapper>
        <RowsPerPage
          pageSize={summary.size || 10}
          onClick={(size) => onPaginationChange(0, size)}
        />
        <Pagination
          currentPage={summary.page}
          totalPages={summary.totalPages}
          onClick={onPaginationChange}
        />
        <BackToTop />
      </PaginationWrapper>
    </div>
  );
};
