import { Storm } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { FormTitle } from "../../../../FormTitle";
import styles from "./AdditionalInformation.module.scss";

interface AdditionalInformationProps {
  assessment: Storm.StormDocumentExtensionVm;
}

export const AdditionalInformation: FC<AdditionalInformationProps> = ({
  assessment,
}) => {
  const { startDate, endDate } = assessment.event;
  const startDateDisplay = moment(startDate).format("M/D/YY [at] h:mm A");
  const endDateDisplay = endDate
    ? moment(endDate).format("M/D/YY [at] h:mm A")
    : "Ongoing";

  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <FormTitle title="Event Data" />
      </div>
      <div className={styles.flexRow}>
        <div>
          <div className={styles.bold}>Name</div>
          <div>{assessment.event.eventName}</div>
        </div>
      </div>
      <div className={styles.flexRow}>
        <div>
          <div className={styles.bold}>Time Start</div>
          <div>{startDateDisplay}</div>
        </div>
        <div className={styles.rightAlign}>
          <div className={styles.bold}>Time Ends</div>
          <div>{endDateDisplay}</div>
        </div>
      </div>
      <div className={joinClassNames(styles.flexRow, styles.substation)}>
        <FormTitle title="Substation" />
      </div>
      <div className={styles.flexRow}>
        <div>
          <div className={styles.bold}>Name</div>
          <div>{assessment.workLocation.name}</div>
        </div>
        <div className={styles.rightAlign}>
          <div className={styles.bold}>ID</div>
          <div>{assessment.workLocation.locationId}</div>
        </div>
      </div>
    </div>
  );
};
