export enum ElementType {
  Button = "button",
  Card = "card",
  Checkbox = "checkbox",
  CheckboxGroup = "checkboxGroup",
  Link = "link",
  Menu = "menu",
  MenuModal = "menuModal",
  MenuItem = "menuItem",
  SelectInput = "selectInput",
  Text = "text",
  TextInput = "textInput",
  RichTextInput = "richTextInput",
  FileUpload = "fileUpload",
  TableRow = "tableRow",
  TableCell = "tableCell",
  Label = "label",
  Icon = "icon",
  Drawer = "drawer",
  Logo = "logo",
  Modal = "modal",
  Toast = "toast",
  Radio = "radio",
  RadioGroup = "radioGroup",
  RadioButton = "radioButton",
  PillBox = "pillBox",
  DropTarget = "dropTarget",
}

export enum WidgetType {
  PhotoGallery = "photoGallery",
  Signature = "signature",
  SafetyRating = "safetyRating",
  Map = "map",
  OperationalExperiences = "operationalExperiences",
  Defenses = "defenses",
  EsriContent = "esriContent",
}

export enum Page {
  Desktop = "page-desktop",
  Mobile = "page-mobile",
}

enum Authentication {
  Activation = "activation",
  Login = "login",
  OAuth2Redirect = "oauth2Redirect",
  PasswordHelp = "passwordHelp",
  PasswordReset = "passwordReset",
  SplashScreen = "splashScreen",
}

enum ColorPicker {
  ColorPicker = "colorPicker",
  ColorPickerButton = "colorPickerButton",
  ColorPickerText = "colorPickerText",
}

enum CompanyInfo {
  CompanyInfo = "companyinfo",
}

enum Dashboard {
  DashboardDocumentDrawer = "dashboardDocumentDrawer",
  HelpDesk = "helpDesk",
  Home = "home",
  MyDocuments = "documents",
  RecentDocuments = "recentDocuments",
  Tutorial = "tutorial",
  Slide = "slide",
  Welcome = "welcome",
  ReportsCard = "reportsCard",
}

enum Document {
  Document = "document",
  AIChatDrawer = "aiChatDrawer",
}

enum FormTypes {
  FormTypes = "formTypes",
  AddModal = "addModal",
}

enum Reports {
  FormReport = "formReport",
  Reports = "reports",
}

enum SidebarNavigation {
  SidebarNavigation = "sidebarNavigation",
}

enum ListItem {
  ListItem = "listItem",
  WorkOrderListItem = "workOrderListItem",
}

enum SearchInput {
  SearchInput = "searchInput",
}

enum Buttons {
  GhostButton = "ghostButton",
  Button = "button",
}

enum TagLibrary {
  TagLibrary = "tagLibrary",
  TagLibraryTable = "tagLibraryTable",
  TagFilters = "tagFilters",
  TagLibraryTablePagination = "tagLibraryTablePagination",
  TagActionMenu = "tagActionMenu",
  TagForm = "tagForm",
}

enum Table {
  TableSummary = "tableSummary",
  TableHeader = "tableHeader",
  TableBody = "tableBody",
}

enum Report {
  ReportHeader = "reportHeader",
}

enum Resources {
  MaintainResources = "maintainResources",
  ResourcesFilters = "resourcesFilters",
  ResourcesTable = "resourcesTable",
  ResourceActionMenu = "resourceActionMenu",
  ResourceTitleField = "resourceTitleField",
  ResourceForm = "resourceForm",
  ResourceTablePagination = "resourceTablePagination",
}

enum RichText {
  StyleControls = "styleControls",
}

enum Breadcrumbs {
  Breadcrumbs = "breadcrumbs",
}

enum DataSets {
  APIDS = "APIDataset",
  APIFormikForm = "APIFormikForm",
  DataSets = "dataSets",
  DSTable = "dataSetsTable",
  ViewUploadedDS = "viewAdvancedDataset",
  BasicDS = "basicDataset",
  DSForm = "dataSetForm",
  UploadedDS = "advancedDataSet",
}

enum FormBuilder {
  FormList = "formList",
  FormBuilder = "formBuilder",
  FormSettings = "formSettings",
}

enum DragToUpload {
  DragToUpload = "dragToUpload",
}

enum Users {
  Users = "users",
  ViewUser = "viewUser",
  EditUser = "editUser",
  UsersStats = "usersStats",
  UserStatusDropdown = "userStatusDropdown",
  RolesAndGroups = "rolesAndGroups",
  GroupsContainerPerRole = "groupsContainerPerRole",
  GroupPicker = "groupPicker",
}

enum Groups {
  Groups = "groups",
  ViewGroup = "viewGroup",
  EditGroup = "EditGroup",
}

enum Profile {
  Profile = "profile",
}

enum Feedback {
  Feedback = "feedback",
  FeedbackForm = "feedbackForm",
}

enum Permissions {
  Permissions = "permissions",
}

enum Privacy {
  Privacy = "privacy",
}

enum Terms {
  Terms = "terms",
}

enum NoPermissions {
  NoPermissions = "noPermissions",
}

enum NotFound {
  NotFound = "notFound",
}

enum PillBox {
  SelectedItemsWrapper = "selectedItemsWrapper",
  SelectedItem = "selectedItem",
  SuggestionsWrapper = "suggestionsWrapper",
  SuggestionItem = "suggestionItem",
  ViewMoreResults = "viewMoreResults",
}

enum IconSelector {
  IconSelector = "iconSelector",
  IconFilter = "iconFilter",
  IconSelectionItem = "iconSelectionItem",
  IconColorPicker = "iconColorPicker",
  IconSelectionPreview = "iconSelectionPreview",
  IconErrorText = "iconSelectorError",
}

enum PhotoArea {
  PhotoArea = "photoArea",
  PhotoInProgress = "photoInProgress",
}

export const Components = {
  ...Authentication,
  ...CompanyInfo,
  ...Dashboard,
  ...Document,
  ...FormTypes,
  ...Reports,
  ...SidebarNavigation,
  ...ListItem,
  ...SearchInput,
  ...Buttons,
  ...TagLibrary,
  ...Table,
  ...Resources,
  ...RichText,
  ...Breadcrumbs,
  ...DataSets,
  ...DragToUpload,
  ...Report,
  ...FormBuilder,
  ...Users,
  ...Groups,
  ...Profile,
  ...Feedback,
  ...Privacy,
  ...Terms,
  ...NoPermissions,
  ...NotFound,
  ...PillBox,
  ...IconSelector,
  ...ColorPicker,
  ...Permissions,
  ...PhotoArea,
};

export interface QAProps {
  qa?: string;
}
