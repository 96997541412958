import React, { FC } from "react";
import styles from "./EventView.module.scss";

interface HashtagsProps {
  tags?: string | null;
}

const Hashtags: FC<HashtagsProps> = ({ tags }) => {
  return (
    <div className={styles.flexColumn}>
      <span className={styles.sectionDescription}>Hashtags</span>
      <span className={styles.bodyLarge}>{tags || "-"}</span>
    </div>
  );
};

export default Hashtags;
