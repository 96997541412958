import {
  API,
  QuestionDTO,
  useDataSourceValues,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { AssistiveText } from "../../../AssistiveText/AssistiveText";
import ErrorText from "../../../ErrorText/ErrorText";
import { PillRadioButton } from "../../../RadioButtons/RadioButton";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "../../../RadioButtons/RadioButtonsGroup";
import CommentField from "../CommentField/CommentField";
import MoreOptionsLoader from "../MoreOptionsLoader/MoreOptionsLoader";
import { useQuestion } from "../useQuestion";
import styles from "./RadioField.module.scss";

interface Props {
  question: QuestionDTO;
}

export const RadioField: FC<Props> = ({ question }) => {
  const [oldDSValue, setOldDSValue] = useState<RadioButtonOption<number>>();

  const {
    error,
    onBlur,
    questionSelections,
    label = "",
    name,
    assistiveText,
    value,
    handleChangeBasicResponse,
    required,
    onChangeComment,
    commentRequired,
    enableComments,
    commentValue,
    commentError,
    qa,
    response,
  } = useQuestion(question);

  let allOptions: RadioButtonOption<number>[];
  let loadMore: () => void | undefined;
  let isLoading: boolean;
  let isLastPage: boolean;
  if (!question.answerSource?.dataSourceKey) {
    allOptions = questionSelections.map((s) => ({
      data: s.id,
      rootId: s.rootId,
      label: { icon: s.properties?.icon, text: s.title },
      hideLabel: s.properties?.hideChoiceLabel,
    }));
    isLoading = false;
    isLastPage = true;
  } else {
    const answerField = question.answerSource?.properties?.answerField;
    const results = useDataSourceValues({
      answerField,
      dataSourceKey: question.answerSource!.dataSourceKey!,
      getDataSourceValues: API.getDataSourceValues,
    });
    allOptions = results.dataSourceUIListItems.map((s) => ({
      data: s.id,
      rootId: s.content?.rootId,
      label: { text: s.title },
      content: s.content,
    }));

    if (!!allOptions.length) {
      if (
        response &&
        !oldDSValue &&
        !allOptions.find((item) => item.data === response.associatedId)
      ) {
        setOldDSValue({
          data: response.associatedId!,
          label: { text: response.answer },
        });
      }

      if (!!oldDSValue) {
        allOptions.unshift(oldDSValue);
      }
    }

    ({ loadMore, isLoading, isLastPage } = results);
  }
  const handleChange = (data: number): void => {
    const option = allOptions.find((option) => option.data === data);
    if (option) {
      handleChangeBasicResponse({
        id: response?.id ?? undefined,
        answer: option.label.text,
        associatedId: option.data,
        associatedRootId: option.rootId,
        content: option.content,
      });
    }
  };

  function handleChangeComment(comments: string) {
    onChangeComment(comments);
  }

  function handleClearComment(): void {
    onChangeComment("");
  }

  return (
    <div
      className={joinClassNames(styles.container, error && styles.error)}
      onBlur={onBlur}
    >
      <RadioButtonsGroup
        label={label}
        name={name}
        onChange={handleChange}
        options={allOptions.map((label) => ({
          ...label,
        }))}
        qa={qa}
        required={required}
        RadioButtonComponent={PillRadioButton}
        row={true}
        value={value || ""}
      />
      {question.answerSource?.dataSourceKey && (
        <MoreOptionsLoader
          onLoadMore={loadMore!}
          isLoadingMore={isLoading}
          isFinalPage={isLastPage}
        />
      )}
      <AssistiveText qa={`${qa}-assistive`}>{assistiveText}</AssistiveText>
      {error && <ErrorText qa={`${qa}-error`}>{error}</ErrorText>}

      {enableComments && value && (
        <CommentField
          className={styles.commentField}
          commentValue={commentValue}
          error={commentError}
          name={`${name}_comment`}
          handleClearComment={handleClearComment}
          handleUpdateComment={handleChangeComment}
          qa={`${qa}-comment`}
          required={commentRequired}
          response={response}
          useDynamicTextArea
        />
      )}
    </div>
  );
};
