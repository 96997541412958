import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { Modal } from "shared/src/components/Modal/Modal";
import { TertiaryButton } from "shared/src/components/Button/Button";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDataOnReassign: (erase?: boolean) => void;
}

export const ReassignDataModal: FC<Props> = ({
  open,
  setOpen,
  handleDataOnReassign,
}) => {
  const EraseAndStartBlankButton: ReactNode = (
    <TertiaryButton onClick={() => handleDataOnReassign(true)}>
      Erase And Start Blank
    </TertiaryButton>
  );

  return (
    <Modal
      title="Attention"
      open={open}
      handleClose={() => setOpen(false)}
      cancellable={false}
      action={{
        text: "Keep Existing Data",
        loading: false,
        callback: () => handleDataOnReassign(),
      }}
      otherButtons={EraseAndStartBlankButton}
      content={
        <div>
          <span>
            There is previous data in this form. Do you wish to keep it or erase
            all entered data and start blank?
          </span>
        </div>
      }
    />
  );
};
