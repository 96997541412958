import {
  DocumentQuestionResponseVm,
  DocumentVm,
  FormSectionVm,
  ParticipantUserVm,
  QuestionDTO,
  Storm,
} from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import { useRef } from "react";
import { QuestionsToDisableProps } from "shared/src/components/Document/DocumentForm/types";
import { setResponses } from "../../../helpers/updateQuestionResponse.helper";
import {
  findInspectedByQuestion,
  inspectedByQuestion,
  reviewerQuestion,
} from "../../../helpers/findInspectedByQuestion.helper";

interface UseInspectedByProps {
  assessment: Storm.StormDocumentExtensionVm;
  user: ParticipantUserVm | null;
}

export function useInspectedBy({ assessment, user }: UseInspectedByProps) {
  const getInspectedBy = (
    values: DocumentVm
  ): DocumentQuestionResponseVm | undefined => {
    const sections: FormSectionVm[] = values.form.sections;

    const targetQuestion = findInspectedByQuestion(
      sections,
      inspectedByQuestion
    );

    let response;
    if (targetQuestion) {
      response = getInspectedByResponse(targetQuestion, values);
    }

    return response;
  };

  const getReviewer = (values: DocumentVm): void => {
    const sections: FormSectionVm[] = values.form.sections;

    const targetQuestion = findInspectedByQuestion(sections, reviewerQuestion);

    if (targetQuestion) {
      questionsToDisable.push({
        questionId: targetQuestion.id,
        questionRootId: targetQuestion.rootId,
      });
    }
  };

  let questionsToDisable: QuestionsToDisableProps[] = [];

  const getInspectedByResponse = (
    targetQuestion: QuestionDTO,
    values: DocumentVm
  ) => {
    questionsToDisable.push({
      questionId: targetQuestion.id,
      questionRootId: targetQuestion.rootId,
    });
    return values.responses.find(
      (r) =>
        r.questionRootId === targetQuestion.rootId &&
        r.questionId === targetQuestion.id
    );
  };

  const generateInspectedByResponse = (
    targetQuestion: QuestionDTO,
    values: DocumentVm,
    setValues: FormikHelpers<DocumentVm>["setValues"]
  ) => {
    const newResponses = [
      {
        answer: user?.fullName || user?.name || "",
        associatedId: user?.participantId,
        questionId: targetQuestion.id,
        questionRootId: targetQuestion.rootId,
        timeAnswered: new Date().toISOString(),
      },
    ];
    setResponses(targetQuestion, values, newResponses, setValues);
  };

  const isInspectedBySet = useRef<boolean>(false);
  const isReviewerSet = useRef<boolean>(false);

  getInspectedBy(assessment.document);
  getReviewer(assessment.document);

  const setIsInspectedBySet = (val: boolean) => {
    isInspectedBySet.current = val;
  };

  const handleInspectedBy = (
    values: DocumentVm,
    setValues: FormikHelpers<DocumentVm>["setValues"]
  ) => {
    // If the inspected by is alerady set in the field, leave it there until refresh.
    if (isInspectedBySet.current) return;
    isInspectedBySet.current = true;
    const sections: FormSectionVm[] = values.form.sections;

    const targetQuestion = findInspectedByQuestion(
      sections,
      inspectedByQuestion
    );

    if (targetQuestion) {
      generateInspectedByResponse(targetQuestion, values, setValues);
    }
  };

  const handleReviewer = (
    values: DocumentVm,
    setValues: FormikHelpers<DocumentVm>["setValues"]
  ) => {
    // If the reviewer is alerady set in the field, leave it there until refresh.
    if (isReviewerSet.current) return;

    const sections: FormSectionVm[] = values.form.sections;

    const targetQuestion = findInspectedByQuestion(sections, reviewerQuestion);

    if (targetQuestion) {
      generateInspectedByResponse(targetQuestion, values, setValues);
      isReviewerSet.current = true;
    }
  };

  return {
    setIsInspectedBySet,
    isReviewerSet,
    handleInspectedBy,
    handleReviewer,
    questionsToDisable,
  };
}
