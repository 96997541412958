import { MainNavigationConfigProperties } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import MediaQuery from "react-responsive";
import { Components } from "../../../qa-slugs";
import scssVariables from "../../../sass/jsExports.module.scss";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import DashboardBottomNavigation from "../DashboardBottomNavigation/DashboardBottomNavigation";
import PrivacySidebar from "../DashboardBottomNavigation/PrivacySidebar/PrivacySidebar";
import SidebarNavigation from "../SidebarNavigation";
import styles from "./AppNavWrapper.module.scss";
import NavigationList, {
  canAccessLink,
  NavigationMenuLink,
} from "./NavigationList";

interface NavProps {
  menuData: NavigationMenuLink[];
  adminMenuData: NavigationMenuLink[];
  adminMenuHeader: string;
  showAdminMenuHeader: boolean;
  userRoles: string[];
  onNavToPrivacy?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

const Nav: FC<NavProps> = ({
  menuData,
  userRoles,
  adminMenuData,
  adminMenuHeader,
  showAdminMenuHeader,
  onNavToPrivacy,
}) => (
  <SidebarNavigation onNavToPrivacy={onNavToPrivacy}>
    <NavigationList menuData={menuData} userRoles={userRoles} />
    {showAdminMenuHeader && (
      <span className={styles.navHeader}>{adminMenuHeader}</span>
    )}
    <NavigationList menuData={adminMenuData} userRoles={userRoles} />
  </SidebarNavigation>
);

interface Props {
  children?: React.ReactNode;
  menuData: NavigationMenuLink[];
  adminMenuData: NavigationMenuLink[];
  adminMenuHeader: string;
  userRoles: string[];
  mainNavigationConfig?: MainNavigationConfigProperties;
  terms: {
    employee: {
      singular: string;
      plural: string;
    };
  };
}

/**
 * @deprecated
 * Use packages/field-first/src/components/navigation/MainLayout/MainLayout.tsx
 * This component can be deleted when there are no more things that reference it.
 */
const AppNavWrapper: FC<Props> = ({
  menuData,
  adminMenuData,
  adminMenuHeader,
  children,
  userRoles,
  terms,
  mainNavigationConfig,
}) => {
  // mobile privacy policy sidebar
  const [privacyVisible, setPrivacyVisible] = useState<boolean>(false);

  const mobileNavToPrivacy = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setPrivacyVisible(true);
  };

  const showAdminMenuHeader = adminMenuData.reduce(
    (canAccess, link) => canAccess || canAccessLink(link, userRoles),
    false
  );

  return (
    <div
      className={styles.navbarWrapper}
      data-testid={`${Components.SidebarNavigation}-Container`}
    >
      <MediaQuery minWidth={scssVariables.maxTablet}>
        {/* Web navigation */}
        <Nav
          menuData={menuData}
          userRoles={userRoles}
          showAdminMenuHeader={showAdminMenuHeader}
          adminMenuHeader={adminMenuHeader}
          adminMenuData={adminMenuData}
        />
      </MediaQuery>
      {/* Page content, used for both mobile-responsive and web */}
      <div className={styles.mainContent} id="contentWrapper">
        <ContentWrapper>{children}</ContentWrapper>
      </div>
      <MediaQuery maxWidth={scssVariables.maxTablet}>
        <div>
          {/* Mobile navigation */}
          <DashboardBottomNavigation
            drawerContent={
              <Nav
                menuData={menuData}
                userRoles={userRoles}
                showAdminMenuHeader={showAdminMenuHeader}
                adminMenuHeader={adminMenuHeader}
                adminMenuData={adminMenuData}
                onNavToPrivacy={mobileNavToPrivacy}
              />
            }
            mainNavigationConfig={mainNavigationConfig}
          />
          {/* Privacy Policy Sidebar */}
          <PrivacySidebar
            isOpen={privacyVisible}
            onClose={() => setPrivacyVisible(false)}
            terms={terms}
          />
        </div>
      </MediaQuery>
    </div>
  );
};

export default AppNavWrapper;
