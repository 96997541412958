import { API, Storm } from "@rtslabs/field1st-fe-common";
import { FilterOption } from "../common/filters/Filter";
import { toTitleCase } from "shared/src/util/toTitleCase";
import { EventNameAndIDProp } from "./AssessmentsFilters";

export function buildEventOptions(
  events: Storm.EventWithDocStatsVM[],
  eventNameAndId?: EventNameAndIDProp
): FilterOption[] {
  const eventOptions: FilterOption[] = events.map((event) => ({
    label: event.eventName,
    value: event.id,
  }));
  eventOptions.unshift(
    {
      value: "all",
      label: "All Events",
    },
    {
      value: "active",
      label: "All Active Events",
    }
  );

  // if available, add the event to the filter (usually a closed event)
  if (eventNameAndId) {
    eventOptions.push({
      label: eventNameAndId.name,
      value: eventNameAndId.id,
    });
  }

  return eventOptions;
}

export const STATUS_OPTIONS: FilterOption[] = [
  {
    value: "all",
    label: "All Statuses",
  },
  {
    label: toTitleCase(Storm.AssessmentState.UNASSIGNED),
    value: Storm.AssessmentState.UNASSIGNED,
  },
  {
    label: toTitleCase(Storm.AssessmentState.ASSIGNED),
    value: Storm.AssessmentState.ASSIGNED,
  },
  {
    label: toTitleCase(Storm.AssessmentState.ASSESSOR_COMPLETE),
    value: Storm.AssessmentState.ASSESSOR_COMPLETE,
  },
  {
    label: toTitleCase(Storm.AssessmentState.IN_PROGRESS),
    value: Storm.AssessmentState.IN_PROGRESS,
  },
  {
    label: toTitleCase(Storm.AssessmentState.IN_REVIEW),
    value: Storm.AssessmentState.IN_REVIEW,
  },
  {
    label: toTitleCase(Storm.AssessmentState.STORM_CENTER_VERIFIED),
    value: Storm.AssessmentState.STORM_CENTER_VERIFIED,
  },
  {
    label: toTitleCase(Storm.AssessmentState.CANCELLED),
    value: Storm.AssessmentState.CANCELLED,
  },
];

export const ACTION_REQUIRED_OPTIONS: FilterOption[] = [
  {
    value: Storm.AssessmentActionRequiredFilter.ALL,
    label: "All",
  },
  { label: "Yes", value: Storm.AssessmentActionRequiredFilter.YES },
  { label: "No", value: Storm.AssessmentActionRequiredFilter.NO },
];

export const LOCATION_OPTIONS: FilterOption[] = [
  {
    value: "all",
    label: "All",
  },
  { label: "5 miles", value: 5 },
  { label: "10 miles", value: 10 },
  { label: "25 miles", value: 25 },
  { label: "50 miles", value: 50 },
  { label: "100 miles", value: 100 },
];

export const ASSESSOR_STATE_OPTIONS: FilterOption[] = [
  {
    value: Storm.AssessmentAssignment.ALL,
    label: "All",
  },
  {
    label: toTitleCase(Storm.AssessmentAssignment.ASSIGNED_TO_ME),
    value: Storm.AssessmentAssignment.ASSIGNED_TO_ME,
  },
  {
    label: "Specific Assessor",
    value: Storm.AssessmentAssignment.ASSIGNED_TO_SPECIFIED,
  },
];

export const getRegionOptions = async (): Promise<FilterOption[]> => {
  let regions: string[] = [];

  try {
    const res = await API.getRegions({});
    regions = res.content;
  } catch (err) {
    console.error(err);
  }
  const options: FilterOption[] = regions.map((region) => ({
    value: region,
    label: region,
  }));

  return [
    {
      value: "all",
      label: "All Regions",
    },
    ...options,
  ];
};

export const getSupervisorOptions = async (): Promise<FilterOption[]> => {
  let supervisors: string[] = [];
  try {
    const wlNicknames = await API.getWorkLocationNicknames({ size: 50 });
    supervisors = wlNicknames.content;
  } catch (err) {
    console.error(err);
  }
  const options: FilterOption[] = supervisors.map((supervisor) => ({
    value: supervisor,
    label: supervisor,
  }));

  return [{ value: "all", label: "All Supervisors" }, ...options];
};
