import React, { FC } from "react";
import { Modal } from "shared/src/components/Modal/Modal";
import { EventWithDocStatsVM } from "@rtslabs/field1st-fe-common/storm";

import { AssessmentCounts } from "../../../Dashboard/AssessmentCounts";

type Props = {
  onSubmit: () => void;
  submitting: boolean;
  open: boolean;
  onCancel: () => void;
  event?: EventWithDocStatsVM;
};
export const CloseEventModal: FC<Props> = ({
  event,
  open,
  onCancel,
  onSubmit,
  submitting,
}) => {
  if (!event) return null;

  const showWarning =
    !!event.nrOfDocumentsPerState.ASSESSOR_COMPLETE ||
    !!event.nrOfDocumentsPerState.ASSIGNED ||
    !!event.nrOfDocumentsPerState.IN_PROGRESS ||
    !!event.nrOfDocumentsPerState.IN_REVIEW ||
    !!event.nrOfDocumentsPerState.UNASSIGNED;

  return (
    <Modal
      open={open}
      title={"Close Event"}
      action={{
        text: "Close Event",
        callback: onSubmit,
        loading: submitting,
      }}
      handleClose={onCancel}
      alert={{
        variant: "warning",
        title: "Incomplete Assessments",
        message:
          "When closing your event all assessments will be locked from editing, but will still be available for review.",
        isVisible: showWarning,
      }}
      content={
        <div>
          <span>Are you sure want to close {event?.eventName}?</span>
          <AssessmentCounts documentCount={event} />
        </div>
      }
    />
  );
};
