import React, { FC, useState } from "react";
import { StormDocumentExtensionVm } from "@rtslabs/field1st-fe-common/storm";
import { DocumentHeader } from "shared/src/components/Document/DocumentHeader/DocumentHeader";
import { ReadOnlySection } from "./ReadOnlySection";
import { useNavigate } from "react-router-dom";
import styles from "./Assessment.module.scss";
import { ReadOnlyDoc } from "shared/src/components/Document/ReadOnly/ReadOnlyDoc";
import { FormikDocument } from "./FormikDocument";
import { AssessmentActionMenu } from "../AssessmentActionMenu/AssessmentActionMenu";
import { CoreRedux, Storm } from "@rtslabs/field1st-fe-common";
import { useSelector } from "react-redux";
import { isReadonly } from "./assessment.helpers";
import { MapWidget } from "./MapWidget/MapWidget";
import { Button } from "shared/src/components/Button/Button";
import { isActionAllowed } from "../helpers";
import { toTitleCase } from "shared/src/util/toTitleCase";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

interface Props {
  assessment: StormDocumentExtensionVm;
  assessmentRefresh: () => void;
  assessmentReload: () => Promise<void>;
}

export const Assessment: FC<Props> = ({
  assessment,
  assessmentRefresh,
  assessmentReload,
}) => {
  const navigate = useNavigate();
  const [formProgress, setFormProgress] = useState<number>(0);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { user } = useSelector(CoreRedux.selectUser)!;
  const documentTerm: string = useGroupTerm(
    "document",
    "noun",
    "singular",
    "Assessment"
  );

  function handleGoBack(): void {
    /* if document was accessed from a location other than Dashboard, navigate to previous screen */
    if (history.length > 2) {
      return navigate(-1);
    }
    /* else return to Dashboard */
    return navigate("/");
  }

  const handleReviewButton = () => {
    navigate(`/assessment/review/${assessment.id}`);
  };

  const canReviewOrVerify =
    isActionAllowed([assessment], Storm.AssessmentAction.START_REVIEW) ||
    isActionAllowed([assessment], Storm.AssessmentAction.COMPLETE_REVIEW);
  const additionalActions = canReviewOrVerify && (
    <Button className={styles.button} onClick={handleReviewButton}>
      Review
    </Button>
  );

  const readOnly = isReadonly(assessment, user);
  // current we have a map section with work location field on the form. It's the first section
  // before Overview. We need to hide it
  const overViewSectionIndex = assessment.document.form.sections.findIndex(
    (s) => s.title === "Overview"
  );
  if (overViewSectionIndex === 1) {
    assessment.document.form.sections.shift();
  }

  return (
    <>
      <DocumentHeader
        formProgress={formProgress}
        handleClickOnBackArrow={handleGoBack}
        handleOnClickMenu={() => setShowDrawer(true)}
        title={assessment.workLocation.name}
        additionalActions={additionalActions}
      />
      <div className={styles.document}>
        <AssessmentActionMenu
          open={showDrawer}
          assessment={assessment}
          onClose={(reset?: boolean) => {
            setShowDrawer(false);
            if (typeof reset === "boolean" && reset) {
              assessmentReload();
            } else {
              assessmentRefresh();
            }
          }}
          hideView
        />

        {readOnly && (
          <>
            <ReadOnlySection assessment={assessment} />
            <MapWidget assessment={assessment} />
            <ReadOnlyDoc
              document={assessment.document}
              appPath={"storm/assessments"}
              altToastTitle={`${toTitleCase(
                assessment.workLocation.name
              )} ${documentTerm}`}
            />
          </>
        )}
        {!readOnly && (
          <FormikDocument
            assessment={assessment}
            setFormProgress={setFormProgress}
            assessmentRefresh={assessmentRefresh}
          />
        )}
      </div>
    </>
  );
};
