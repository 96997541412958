import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";
import ErrorText from "../ErrorText/ErrorText";
import styles from "./FilterSelect.module.scss";
import { Select, SelectInputProps } from "./Select";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface FilterSelectProps<V> extends SelectInputProps<V> {
  qa?: QAProps["qa"];
  className?: string;
  value: V;
  isSelected?: boolean;
}

export const FilterSelect = <T,>({
  answerValue,
  error,
  onChange,
  label,
  name,
  options,
  qa,
  value,
  className,
  isSelected,
  ...selectInputProps
}: FilterSelectProps<T>) => {
  const selectedOption = options?.find(
    (o) =>
      o.value === value || o.label?.toLowerCase() === answerValue?.toLowerCase()
  );
  return (
    <div>
      {label && (
        <label className={styles.selectLabel} htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        error={error}
        qa={qa}
        name={name}
        onChange={onChange}
        value={value}
        options={options}
        className={joinClassNames(styles.select, className)}
        overrideStyles={{
          control: (provided) => {
            /**
             * if `isSelected` is set, then ignore `selectedOption`.
             * Only care about `selectedOption` if `isSelected` prop is
             * undefined
             */
            const _isSelected = isSelected ?? !!selectedOption;
            return {
              ...provided,
              color: _isSelected
                ? scssVariables.primary
                : scssVariables.darkGrey,
              fontWeight: _isSelected ? 500 : 400,
            };
          },
        }}
        {...selectInputProps}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
