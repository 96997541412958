import React, { FC } from "react";
import { AssessmentState } from "@rtslabs/field1st-fe-common/storm";
import { DashboardCardItem } from "./DashboardCardItem";

import styles from "./AssessmentCount.module.scss";
import { AssessmentStateDisplay } from "../Assessments/AssessmentStateDisplay";

interface AssessmentCountProps {
  status: AssessmentState | "ACTION_REQUIRED";
  count: number;
}
export const AssessmentCount: FC<AssessmentCountProps> = ({
  status,
  count,
}) => {
  return (
    <DashboardCardItem>
      <div className={styles.labelContainer}>
        <AssessmentStateDisplay currentState={status} />:
      </div>
      <div>{count}</div>
    </DashboardCardItem>
  );
};
