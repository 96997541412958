import { FilterParams, Storm } from "@rtslabs/field1st-fe-common";
import { getCurrentPosition } from "shared/src/api/geolocationAPI";
import { convertToMeters } from "shared/src/util/geolocation";

export interface AssessmentsFiltersArgs extends FilterParams {
  eventIds?: number | "active";
  statuses?: Storm.AssessmentState[];
  actionRequiredFilter?: Storm.ActionRequiredFilter;
  location?: number;
  regions?: string[];
  assessorState?: Storm.StormDocumentAssessorState;
  assessorId?: number;
  assessorName?: string;
  workLocationNicknames?: string[];
  query?: string;
}

export const fetchAssessments = async (
  args: AssessmentsFiltersArgs
): Promise<Storm.PageOfStormDocumentExtensionVm> => {
  const filterParams = await buildDocumentsRequestParams(args);
  const res = await Storm.API.getAssessments(filterParams);
  return res;
};

export const fetchAssessmentStats = async (
  args: AssessmentsFiltersArgs
): Promise<Storm.StormDocumentStateCountVm> => {
  const filterParams = await buildDocumentsRequestParams(args);
  const res = await Storm.API.getAssessmentStats(filterParams);
  return res;
};

export const fetchAssessmentById = async (
  id: number
): Promise<Storm.StormDocumentExtensionVm> => {
  const res = await Storm.API.getAssessmentById({ id });
  return res;
};

const buildDocumentsRequestParams = async (
  args: AssessmentsFiltersArgs
): Promise<Storm.GetAssessmentsArgs> => {
  let { eventIds, location, ...slicedArgs } = args;
  let filterParams: Storm.GetAssessmentsArgs = slicedArgs;

  filterParams = {
    ...filterParams,
    eventActive: eventIds === "active" || undefined,
  };

  if (eventIds && eventIds !== "active") {
    filterParams = { ...filterParams, eventIds };
  }

  if (location) {
    const currentLocation = await getCurrentPosition();
    const { latitude: lat, longitude: lon } = (
      currentLocation as GeolocationPosition
    ).coords;
    filterParams = {
      ...filterParams,
      radius: convertToMeters(location, "M"),
      lat,
      lon,
    };
  }

  return filterParams;
};
