import {
  API,
  EsriContentWidgetSaveVm,
  MapWidgetSaveVm,
  QuestionAnswerSourceDTO,
} from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import { get, set } from "lodash";
import React, { useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import { Select, SelectOption } from "../../../../Select/Select";
import { TextInput } from "../../../../TextInput/TextInput";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import useDebounce from "../../../../../util/hooks/useDebounce";
import ColorPicker from "../../../../common/form/ColorPicker";
import RichTextEditor from "../../../../common/RichTextEditor";
import {
  getAllFormItems,
  marshallSection,
  unmarshallForm,
} from "../../helpers";
import {
  FBForm,
  FBItem,
  FBSection,
  Property as PropertyType,
} from "../../../types";
import { RemoveMapModal } from "../content/RemoveMapModal";
import { GeneratorFn, ItemParams } from "../Create";
import AnswerSource from "./customFields/AnswerSource";
import { CommentsRequiredFor } from "./customFields/CommentsRequiredFor";
import DisplayConditions from "./customFields/DisplayConditions";
import { EsriContentProperties } from "./customFields/EsriContentProperties/EsriContentProperties";
import LocationProperties from "./customFields/LocationProperties";
import PrefillFromWorkOrder from "./customFields/PrefillFromWorkOrder/PrefillFromWorkOrder";
import { getItemPath } from "./getItemPath";
import s from "./styles.module.scss";
import { useFBConfigs } from "../../../../../util/hooks/useFBConfigs";

interface Props {
  onAddItem?: GeneratorFn;
  itemPath: string;
  property: PropertyType;
  item: FBItem;
  appWidgetsList: ItemParams[];
}

const Property = ({
  onAddItem,
  item,
  itemPath,
  property,
  appWidgetsList,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const { formBuilderConfigs } = useFBConfigs();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { enableDataSets, enableWO } = formBuilderConfigs;
  const propertyPath = `${itemPath}.${property.name}`;
  const targetPropertyPath = property.targetName
    ? `${itemPath}.${property.targetName}`
    : false;
  const widgetWithMap = getAllFormItems(values).find(
    (item) => "includeMap" in item && item.includeMap
  );

  const debouncedSetValue = useDebounce({
    method(path: string, value: unknown): void {
      setFieldValue(path, value);
    },
  });

  const limitLengthSetValue = (value: string, limit?: number) => {
    const _limit = limit || 255;
    if (value?.length > _limit) {
      // Check for value still
      return value.substring(0, _limit);
    }
    return value;
  };

  /**
   * An update to the "Required" checkbox on an Esri content widget will update
   * the widget plus all of its children. Instead of making one API request for each
   * item, combine all the changes into a single request.
   */
  async function saveEsriContentWidgetSection(
    newSection: FBSection
  ): Promise<void> {
    const sectionPath = getItemPath(values.sections, newSection);

    newSection.workflowType = "DRAFT";

    const response = await API.addOrUpdateFormSection({
      formId: values.id,
      section: marshallSection(newSection),
    });

    const updatedSection: FBSection = get(
      unmarshallForm(response),
      sectionPath
    );

    // if the section id has changed (reverted from FINAL), update the section
    if (updatedSection.id !== newSection.id) {
      setFieldValue(sectionPath, updatedSection);
    }
  }

  const handleIncludeMap = async () => {
    const widgetWithMapPath = getItemPath(values.sections, widgetWithMap!);
    setFieldValue(`${widgetWithMapPath}.includeMap`, false);
    const sectionPath = widgetWithMapPath.split(".")[0];
    const section: FBSection = get(values, sectionPath);

    const response = await API.addOrUpdateFormItem({
      formId: values.id,
      sectionId: section.id!,
      item: { ...widgetWithMap!, includeMap: false } as
        | EsriContentWidgetSaveVm
        | MapWidgetSaveVm,
    });

    const updatedSection: FBSection = get(
      unmarshallForm(response),
      sectionPath
    );

    // if the section id has changed (reverted from FINAL), update the section
    if (updatedSection.id !== section.id) {
      setFieldValue(sectionPath, updatedSection);
    }

    setIsModalOpen(false);
    return setFieldValue(propertyPath, true);
  };

  const handleOnChangeCheckBox = async (checked: boolean) => {
    // handle multiple 'Include Map' widgets in modal
    if (widgetWithMap && property.name === "includeMap" && checked)
      return setIsModalOpen(true);
    // if API accepts any values in an array with a check, use the targetName with altCheckedValue set for checkbox
    if (targetPropertyPath) {
      const value: string[] = get(values, targetPropertyPath);
      if (typeof value === "boolean") {
        setFieldValue(targetPropertyPath, checked);
      } else {
        // targetValue is assumed to be an array of allowed signature types
        const newValue = checked
          ? [...value, property.altCheckedValue]
          : value.filter((s) => s !== property.altCheckedValue);
        setFieldValue(targetPropertyPath, newValue);
      }
    }

    const galleryExists = getAllFormItems(values).find(
      (item) => item.subType === "PHOTO_GALLERY"
    );

    // adds photo gallery if 'Allow Photos' is checked and gallery doesn't exist
    if (
      property.name === "properties.allowPhotos" &&
      checked &&
      !galleryExists
    ) {
      // if isAllowPhotos, then onAddItem will exist
      await onAddItem!({
        name: "Photo Gallery",
        type: "WIDGET",
        subType: "PHOTO_GALLERY",
      });
    }

    if (property.forWidgetChildren) {
      /**
       * Only an EsriContentWidgetSaveVm has the `forWidgetChildren` property. Update the widget
       * and its children all at once.
       */
      const section: FBSection = get(values, itemPath.split(".")[0]);
      const widget: EsriContentWidgetSaveVm = get(values, itemPath);
      const childQuestionRootIds =
        widget.questions?.map((q) => q.questionRootId) || [];

      section.items.forEach((i) => {
        if (i.rootId && childQuestionRootIds.indexOf(i.rootId) !== -1) {
          set(i, property.name, checked);
        } else if (i.id === widget.id && property.targetName) {
          set(i, property.targetName, checked);
        }
      });

      saveEsriContentWidgetSection(section);
    }

    return setFieldValue(propertyPath, checked);
  };

  const [field] = useField(propertyPath);

  switch (property.type) {
    case "CHECKBOX":
      let checked: boolean;
      if (targetPropertyPath) {
        const targetValue = get(values, targetPropertyPath);
        if (typeof targetValue === "boolean") {
          checked = targetValue;
        } else {
          // targetValue is assumed to be an array of allowed signature types
          checked = get(values, targetPropertyPath)?.includes(
            property.altCheckedValue
          );
        }
      } else {
        checked = get(values, propertyPath, false);
      }

      return (
        <div>
          <RemoveMapModal
            isModalOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleAction={handleIncludeMap}
          />
          <Checkbox
            {...field}
            containerClassName={s.checkboxContainer}
            className={s.checkbox}
            label={property.label}
            checked={checked}
            onChange={handleOnChangeCheckBox}
          />
        </div>
      );
    case "DROP_DOWN":
      return (
        <Select
          {...field}
          labelClassName={s.label}
          wrapperClassName={joinClassNames(
            s.dropdown,
            property.appendLabel ? s.dropdownLabelAppended : ""
          )}
          onChange={(option?: SelectOption | null) => {
            setFieldValue(propertyPath, option?.value);
          }}
          placeholder={property.placeholder}
          assistiveText={property.assistiveText}
          label={property.label}
          options={property.options}
        />
      );
    case "COLOR_PICKER":
      return (
        <ColorPicker
          {...field}
          id={propertyPath}
          withText
          label={property.label}
          placeholder={property.label}
          colorValue={get(values, propertyPath, "")}
          onColorChange={(value: string) =>
            debouncedSetValue(propertyPath, value)
          }
        />
      );
    case "CONTENT_BLOCK":
      return (
        <RichTextEditor
          {...field}
          className={s.richTextEditor}
          initialContent={get(values, propertyPath, "")}
          label={property.label}
          onChangeContent={(value?: string) =>
            setFieldValue(propertyPath, value)
          }
          placeholder={property.label}
        />
      );
    case "DATA_SOURCE":
      return !!enableDataSets ? (
        <AnswerSource
          {...field}
          label={property.label}
          onChange={(answerSource: QuestionAnswerSourceDTO | null) =>
            setFieldValue(`${itemPath}.answerSource`, answerSource)
          }
          property={property}
          itemPath={itemPath}
          item={item}
          initialValues={
            item.type === "QUESTION" ? item.answerSource : undefined
          }
          appWidgetsList={appWidgetsList}
        />
      ) : null;
    case "LOCATION":
      return <LocationProperties property={property} itemPath={itemPath} />;
    case "PREFILL_FROM_WORK_ORDER": // @TODO display errors
      return !!enableWO ? (
        <PrefillFromWorkOrder
          property={property}
          item={item}
          itemPath={itemPath}
        />
      ) : null;
    case "COMMENTS_REQUIRED_FOR": // @TODO display errors
      if (get(values, `${itemPath}.${property.displayCondition}`)) {
        return <CommentsRequiredFor property={property} itemPath={itemPath} />;
      }
      return null;
    case "TEXT_LINE":
      return (
        <TextInput
          {...field}
          className={s.textInput}
          labelClass={s.label}
          assistiveText={property.assistiveText}
          label={property.label}
          placeholder={property.label}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const value = limitLengthSetValue(e.target.value);
            setFieldValue(propertyPath, value);
          }}
        />
      );
    case "DISPLAY_CONDITIONS": // @TODO display errors
      if (item.type === "QUESTION") {
        return (
          <DisplayConditions
            property={property}
            itemPath={itemPath}
            item={item}
            appWidgetsList={appWidgetsList}
          />
        );
      }
      return null;
    case "ESRI_CONTENT":
      return <EsriContentProperties itemPath={itemPath} />;
    default:
      return (
        <div style={{ marginTop: "10px" }}>
          Field type {property.type} not found
        </div>
      );
  }
};

export default Property;
