import {
  API,
  ContentDTO,
  DefenseDTO,
  DisplayConditionDTO,
  DocumentQuestionResponseVm,
  DocumentVm,
  DocumentVM,
  FormSectionVm,
  Functions,
  PageOfDataSourceValueDTO,
  QuestionDTO,
  ResponseContent,
  SafetyRatingWidgetDTO,
  WidgetDTO,
} from "@rtslabs/field1st-fe-common";
import { FormikErrors, FormikProps } from "formik";
import React, { FC, JSXElementConstructor, RefObject } from "react";
import { createMarkup } from "../../../util/createMarkup";
import Content from "./Content";
import styles from "./documentForm.module.scss";
import { isWidgetQuestion, shouldShowSafetyScale } from "./formEntity.helpers";
import { FormSection } from "./FormSection";
import { Question } from "./Question";
import { RatingScale } from "./RatingField/RatingScale";
import { FormWidgetProps, QuestionsToDisableProps } from "./types";

interface SectionPropertiesProps {
  headerDescription?: string;
  headerTitle?: string;
}

const SectionProperties: FC<SectionPropertiesProps> = ({
  headerDescription,
  headerTitle,
}) => (
  <div className={styles.formSectionProperties}>
    {headerTitle && (
      <div
        className={styles.title}
        dangerouslySetInnerHTML={createMarkup(headerTitle)}
      />
    )}
    {headerDescription && (
      <div
        className={styles.header}
        dangerouslySetInnerHTML={createMarkup(headerDescription)}
      />
    )}
  </div>
);
SectionProperties.displayName = "SectionProperties";

interface Props {
  formikProps: FormikProps<DocumentVM>;
  responses: DocumentQuestionResponseVm[];
  displayConditions?: DisplayConditionDTO[] | null;
  defenses?: DefenseDTO[];
  flattenedQuestions: QuestionDTO[];
  formWidget: JSXElementConstructor<FormWidgetProps>;
  getDataSourceValues: <T>(
    params: API.GetDataSourceValuesArgs
  ) => Promise<PageOfDataSourceValueDTO<T>>;
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  section: FormSectionVm;
  setDocumentResponses: (
    question: QuestionDTO,
    responses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null
  ) => void;
  errors: FormikErrors<DocumentVm>;
  appPath?: string;
  questionsToDisable?: QuestionsToDisableProps[];
}

const Section: FC<Props> = ({
  formikProps,
  responses,
  displayConditions,
  defenses,
  flattenedQuestions,
  formWidget,
  getDataSourceValues,
  itemRefs,
  section,
  setDocumentResponses,
  errors,
  appPath,
  questionsToDisable,
}) => {
  const targetIds = displayConditions?.map((item) => item.targetRootId);

  if (
    !Functions.isSectionVisible(
      section.rootId!,
      responses || [],
      displayConditions
    )
  ) {
    return null;
  }

  const visibleItems = section.items!.filter((item) =>
    Functions.isTargetVisible(
      item.rootId,
      item.type,
      responses || [],
      displayConditions
    )
  );

  /** All safety rating widgets */
  const safetyRatingWidgets = visibleItems.filter(
    (question) => question.subType === "SAFETY_RATING"
  ) as SafetyRatingWidgetDTO[];

  const Widget = formWidget;

  return (
    <FormSection id="formSection">
      {section.properties && Object.keys(section.properties).length > 0 && (
        <SectionProperties
          headerDescription={section.properties.headerDescription}
          headerTitle={section.properties.headerTitle}
        />
      )}
      {section.title && <h3 className={styles.title}>{section.title}</h3>}

      {visibleItems.map((item) => {
        const isDecisionBlock = targetIds?.includes(item.id);
        const isQuestionDisabled = !!questionsToDisable?.find(
          (q) => q.questionId === item.id && q.questionRootId === item.rootId
        );

        /* Question items */
        if (
          item.type === "QUESTION" &&
          !isWidgetQuestion(item, ["LOCATION", "TEXT_LINE"])
        ) {
          const questionResponses =
            responses.filter(
              (response) => response.questionRootId === item.rootId
            ) || [];
          return (
            <div
              key={item.id}
              className={
                isDecisionBlock ? styles.decisionBlock : styles.mainBlock
              }
            >
              <Question
                question={item}
                questionRef={itemRefs.get(item.id)}
                appPath={appPath}
                disabled={isQuestionDisabled}
              />
              {/* Show the safety rating scale */}
              {!!questionResponses.length &&
                shouldShowSafetyScale(item, safetyRatingWidgets) && (
                  <RatingScale />
                )}
            </div>
          );
        }

        /* Widget items */
        if (item.type === "WIDGET") {
          return (
            <div key={item.id} ref={itemRefs.get(item.id)}>
              <Widget
                defenses={defenses || []}
                flattenedQuestions={flattenedQuestions}
                getDataSourceValues={getDataSourceValues}
                item={item as WidgetDTO}
                sectionItems={visibleItems}
                setDocumentResponses={setDocumentResponses}
                responses={responses!}
                formikProps={formikProps}
                // validation error
                errors={errors}
              />
            </div>
          );
        }

        /* Content items */
        if (item.type === "CONTENT") {
          return (
            <div ref={itemRefs.get(item.id)} key={item.id}>
              <Content item={item as ContentDTO} />
            </div>
          );
        }
      })}
      <div className={styles.separator} />
    </FormSection>
  );
};

export default Section;
