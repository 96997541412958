import { RootState } from "..";
import { AssessmentsFiltersArgs } from "../../api/getAssessments";
import { EventViewFilter } from "./reducer";

export const selectAssessmentsFilters = ({
  filters,
}: RootState): AssessmentsFiltersArgs => filters.assessments;

export const selectEventsFilters = ({ filters }: RootState): EventViewFilter =>
  filters.events;
