import {
  API,
  DataSourceUIListItem,
  QuestionDTO,
  useDataSourceValues,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { userClickedSuggestion } from "../../../../helpers/field.helpers";
import { AUTOSAVE_DEBOUNCE_DELAY } from "../../../../util/debounceDelays";
import TextInputWithSuggestions from "../../../TextInput/TextInputWithSuggestions";
import { TextInputProps } from "../../../TextInput/types";
import { useQuestion } from "../useQuestion";

interface TextFieldProps extends Omit<TextInputProps, "onChange"> {
  question: QuestionDTO;
}

export const TextField: FC<TextFieldProps> = ({ question, disabled }) => {
  const {
    value,
    handleChangeBasicResponse,
    onBlur,
    response,
    responses,
    shouldUpdateForm,
    ...inputProps
  } = useQuestion(question);

  const { answerSource } = question;
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const multiline = question.subType === "TEXT_AREA";
  const debouncedValue = useDebouncedValue(value, AUTOSAVE_DEBOUNCE_DELAY);

  const dataSourceKey = question.answerSource?.dataSourceKey;

  let suggestions: DataSourceUIListItem[];
  if (dataSourceKey) {
    const sort =
      question.answerSource?.properties?.detailedSearch?.infiniteListSortBy;

    const res = useDataSourceValues({
      dataSourceKey,
      getDataSourceValues: API.getDataSourceValues,
      query: debouncedValue,
      sort,
    });

    suggestions = res.dataSourceUIListItems;
  } else {
    suggestions = [];
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (!userClickedSuggestion(e)) {
      setShowSuggestions(false);
    }
    onBlur();
  }

  function handleChange(val: string) {
    if (!showSuggestions && val.length > 3) {
      setShowSuggestions(true);
    } else if (showSuggestions) {
      setShowSuggestions(false);
    }

    handleChangeBasicResponse({
      id: response?.id ?? undefined,
      answer: val,
    });
  }

  function handleSelectSuggestion(suggestion: DataSourceUIListItem) {
    if (!showSuggestions && suggestion.title.length > 3) {
      setShowSuggestions(true);
    } else if (showSuggestions) {
      setShowSuggestions(false);
    }

    handleChangeBasicResponse({
      id: response?.id ?? undefined,
      answer: suggestion.title,
      content: suggestion.content,
      associatedId: suggestion.id,
    });
    setShowSuggestions(false);
  }

  return (
    <TextInputWithSuggestions
      value={value}
      onInputChange={handleChange}
      onBlur={handleBlur}
      idField="id"
      labelField="title"
      onSelectSuggestion={handleSelectSuggestion}
      suggestions={showSuggestions ? suggestions : []}
      autoComplete={answerSource ? "off" : ""}
      multiline={multiline}
      response={response}
      debounceDelay={shouldUpdateForm ? 0 : AUTOSAVE_DEBOUNCE_DELAY}
      disabled={disabled}
      {...inputProps}
    />
  );
};
