import React, { FC, useState } from "react";
import styles from "./Filters.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import FilterSelect, { FilterSelectProps } from "./FilterSelect";
import { castArray, isArray } from "lodash";

interface FilterProps extends FilterSelectProps {
  title: string;
  disabled?: boolean;
  multiSelect?: boolean;
}

const FilterSection: FC<FilterProps> = ({
  title,
  selected,
  options,
  name,
  handleSelectFilter,
  disabled,
  multiSelect,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  let selectedOption;
  if (isArray(selected)) {
    if (selected.length) {
      selectedOption = options.filter(
        (o) => o.value && castArray(selected).includes(o.value?.toString())
      );
    } else {
      selectedOption = options.find((o) => o.value?.toString() === "all");
    }
  } else {
    selectedOption = options.find((o) => o.value?.toString() === selected);
  }

  // prevents white page until option selected is available. Which is on a closed event
  if (!selectedOption) return null;
  selectedOption = castArray(selectedOption);

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className={styles.filterHeader}
        onClick={() => {
          !disabled && setOpen(!isOpen);
        }}
        aria-controls={`${name}_accordion`}
        aria-label={`${name}_expander`}
      >
        <div
          role="label"
          id={`${name}_label`}
          className={joinClassNames(styles.title, disabled && styles.disabled)}
        >
          {title}
        </div>
        <div
          className={joinClassNames(
            styles.filter,
            isOpen && styles.open,
            disabled && styles.disabled
          )}
        >
          <div data-testid={`${name}_selected`} id={`${name}_selected`}>
            {selectedOption.map((so) => so.label).join(", ")}
          </div>
          {!disabled && (
            <Icon
              className={styles.icon}
              type={isOpen ? "sort_up" : "sort_down"}
            />
          )}
        </div>
      </div>
      {isOpen && !disabled && (
        <div id={`${name}_accordion`}>
          <FilterSelect
            selected={selected}
            options={options}
            name={name}
            handleSelectFilter={handleSelectFilter}
            multiSelect={multiSelect}
          />
        </div>
      )}
    </div>
  );
};

export default FilterSection;
