import { animations } from "./animations";

declare module "styled-components" {
  export interface DefaultTheme extends ThemeTypes {}
}

const masterColors = {
  black: "rgb(24, 24, 24)", // #222222
  darkGrey: "rgb(102, 102, 102)", // #666666 // actionable
  darkGreyBlue: "rgb(117, 134, 146)", // #758692
  greyBlue: "rgb(197, 207, 218)", // #C5CFDA
  lightBlue: "rgb(242, 247, 253)", // #F2F7FD
  lightGrey: "rgb(238, 241, 245)", // #EEF1F5 // disabled
  lightWhite: "rgb(244,244,244)", // #F4F4F4
  mediumGrey: "rgb(195, 195, 195)", // #C3C3C3
  primary: "rgb(1, 114, 206)", // #0172CE
  secondary: "rgb(29, 79, 145)", // #1D4F91
  success: "rgb(0, 133, 60)", // #00853C
  successFill: "rgb(229, 243, 235)", // #E5F3EB
  error: "rgb(200, 16, 46)", // #C8102E
  errorFill: "rgb(250, 230, 233)", // #FAE6E9
  visited: "rgb(95, 37, 159)", // #5F259F
  warning: "rgb(252, 169, 35)", // #FCA923
  warningFill: "rgb(255, 246, 232)", // #FFF6E8
  white: "rgb(255,255,255)", // #FFFFFF
  yellow: "rgb(254, 219, 0)", // #FEDB00
};

const lightTheme = {
  activeFill: "#E5F1FA", // #0 E5F1FA
  black: masterColors.black,
  lightGrey: masterColors.lightGrey,
  white: masterColors.white,
  background: masterColors.white,
  error: masterColors.error,
  errorFill: masterColors.errorFill,
  warning: masterColors.warning,
  warningFill: masterColors.warningFill,
  primary: masterColors.primary,
  success: masterColors.success,
  fieldInputBackground: masterColors.white,
  fieldInputBorder: masterColors.darkGrey,
  header: masterColors.black,
  activeHeader: masterColors.black,
  assistiveText: masterColors.darkGreyBlue,
  link: "rgb(1, 114, 206)", // #0172CE,

  formFieldLabel: masterColors.black,
  formFieldBorder: masterColors.darkGrey,
  formFieldBackground: masterColors.white,
  formFieldColor: masterColors.black,
  formFieldDisabledBackground: masterColors.lightGrey,
  formFieldErrorBackground: masterColors.errorFill,
  radioCircleBorder: masterColors.darkGrey,

  focusIndicatorOutline: masterColors.yellow,

  iconFill: masterColors.white,
  backIcon: "rbg(102, 102, 102)", //#666
  actionIcon: "rgb(0,0,0)", // #000
  dropShadow: "rgba(0, 0, 0, 0.1294)",

  // LIST ITEMS
  listItemsActiveText: masterColors.black,
  listItemsInActiveText: masterColors.black,

  listItemsActiveSubText: masterColors.black,
  listItemsInActiveSubText: masterColors.black,
};

const darkTheme: typeof lightTheme = {
  activeFill: "rgb(255, 247, 231)", // #FFF7E7
  black: "rgb(255,255,255)", // #FFF
  lightGrey: masterColors.lightGrey,
  white: "rgb(0,0,0)", // #000000
  background: masterColors.black, // #000
  error: "rgb(249, 25,25)", // #F91919
  errorFill: "rgb(255,231,231)", // #FFE7E7
  link: "rgb(249, 172, 25)", // #F9AC19
  warning: masterColors.warning,
  warningFill: masterColors.warningFill,
  primary: "rgb(249, 172, 25)", // #F9AC19
  success: "rgb(0, 133, 60)", // #00853C
  fieldInputBackground: "rgb(102,102,102)", //#666
  fieldInputBorder: "rgb(255,255,255)", // #FFF
  header: masterColors.white,
  activeHeader: masterColors.black,
  assistiveText: "rgb(244,244,244)", // #F4F4F4

  //! TEMPORARY UNTIL WE FIGURE OUT A FINAL THEME SHAPE
  //! should remove
  formFieldLabel: masterColors.white,
  formFieldBorder: masterColors.white,
  formFieldBackground: masterColors.darkGrey,
  formFieldColor: masterColors.white,
  formFieldDisabledBackground: masterColors.lightGrey,
  formFieldErrorBackground: masterColors.errorFill,
  radioCircleBorder: masterColors.white,

  focusIndicatorOutline: masterColors.yellow,

  iconFill: masterColors.black,
  backIcon: "rgb(255,255,255)", // #fff
  actionIcon: "rgb(255,255,255)", // #FFF
  dropShadow: "rgba(0, 0, 0, 0.1294)",

  // LIST ITEMS
  listItemsActiveText: masterColors.black,
  listItemsInActiveText: masterColors.black,

  listItemsActiveSubText: masterColors.black,
  listItemsInActiveSubText: masterColors.black,
};

export interface ThemeTypes {
  animations: typeof animations;
  colors: typeof lightTheme | typeof darkTheme;
  masterColors: typeof masterColors;
}

export const colors = {
  black: "rgb(24, 24, 24)", // #222222
  darkGrey: "rgb(102, 102, 102)", // #666666 // actionable
  darkGreyBlue: "rgb(117, 134, 146)", // #758692
  greyBlue: "rgb(197, 207, 218)", // #C5CFDA
  lightBlue: "rgb(242, 247, 253)", // #F2F7FD
  lightGrey: "rgb(238, 241, 245)", // #EEF1F5 // disabled
  lightWhite: "rgb(244,244,244)", // #F4F4F4
  mediumGrey: "rgb(195, 195, 195)", // #C3C3C3
  primary: "rgb(1, 114, 206)", // #0172CE
  secondary: "rgb(29, 79, 145)", // #1D4F91
  success: "rgb(0, 133, 60)", // #00853C
  successFill: "rgb(229, 243, 235)", // #E5F3EB
  error: "rgb(200, 16, 46)", // #C8102E
  errorFill: "rgb(250, 230, 233)", // #FAE6E9
  visited: "rgb(95, 37, 159)", // #5F259F
  warning: "rgb(252, 169, 35)", // #FCA923
  warningFill: "rgb(255, 246, 232)", // #FFF6E8
  white: "rgb(255,255,255)", // #FFFFFF
  yellow: "rgb(254, 219, 0)", // #FEDB00
  activeFill: "#E5F1FA", // #0 E5F1FA
  link: "rgb(1, 114, 206)", // #0172CE,
  backIcon: "rbg(102, 102, 102)", //#666
  actionIcon: "rgb(0,0,0)", // #000
  dropShadow: "rgba(0, 0, 0, 0.1294)",
  focusIndicatorOutline: "rgb(254, 219, 0)",
  backdropColor: "rgba(0.13333, 0.13333, 0.13333, 0.5)",
};

export default {
  masterColors,
  lightTheme,
  darkTheme,
};
