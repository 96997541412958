import { AssessmentsFiltersArgs } from "../../api/getAssessments";
import {
  ResetAssessmentsFiltersAction,
  ResetEventsFiltersAction,
  SetAssessmentsFiltersAction,
  SetEventsFiltersAction,
} from "./types";

export enum ASSESSMENTS_FILTERS {
  SET = "SET_ASSESSMENTS_FILTERS",
  RESET = "RESET_ASSESSMENTS_FILTERS",
}

export enum EVENTS_FILTERS {
  SET = "SET_EVENTS_FILTERS",
  RESET = "RESET_EVENTS_FILTERS",
}

/**
 * Sets the Assessments filters into memory. Retains the values while navigating the app
 * @param filters - filters that can be set to specific value
 */
export const setAssessmentsFilters = (
  filters: AssessmentsFiltersArgs
): SetAssessmentsFiltersAction => ({
  filters,
  type: ASSESSMENTS_FILTERS.SET,
});

/**
 * Sets the Events filters into memory. Retains the values while navigating the app
 * @param filters - filters that can be set to specific value
 */
export const setEventsFilters = (filters: any): SetEventsFiltersAction => ({
  filters,
  type: EVENTS_FILTERS.SET,
});

/**
 * Resets the filters to initialState
 * @param filters - optional filters that can be reset to specific value rather than remove it
 */
export const resetAssessmentsFilters = (
  filters?: AssessmentsFiltersArgs
): ResetAssessmentsFiltersAction => ({
  filters,
  type: ASSESSMENTS_FILTERS.RESET,
});

/**
 * Resets the filters to initialState
 */
export const resetEventsFilters = (): ResetEventsFiltersAction => ({
  type: EVENTS_FILTERS.RESET,
});
