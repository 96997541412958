import { Storm } from "@rtslabs/field1st-fe-common";

export const fetchParticipants = async (
  args: Storm.API.GetParticipantsArgs
): Promise<Storm.PageOfStormParticipantVm> => {
  const res = await Storm.API.getParticipants(args);
  return res;
};

export const fetchCurrentParticipant =
  async (): Promise<Storm.StormParticipantVm> => {
    const res = await Storm.API.getCurrentParticipant();
    return res;
  };
