import React, { FC } from "react";
import MediaQuery from "react-responsive";
import scssVariables from "../../sass/jsExports.module.scss";
import { Drawer } from "../Drawer/Drawer";
import {
  DataSourceItem,
  SubmitButtonLabel,
  ItemSelectorForm,
} from "./ItemSelectorForm";
import styles from "./ItemSelectorDrawer.module.scss";
import { QuestionAnswerSourceProperties } from "@rtslabs/field1st-fe-common";
import { ElementType } from "../../qa-slugs";

interface Props {
  answerSourceFields: QuestionAnswerSourceProperties;
  dataSourceKey: string;
  handleChangeOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  name: string;
  onLoadMore?: () => void;
  options: DataSourceItem[];

  // formProps
  error?: string;
  handleChangeSearch: (query: string) => void;
  handleSubmit: (selectedOptions: DataSourceItem[]) => void;
  hasMultiSelect?: boolean;
  isLastPage?: boolean;
  isLoadingMore?: boolean;
  isLoadingSearchResults?: boolean;
  listTitle: string;
  noResultsMessage: string;
  searchLabel: string;
  searchPlaceholder?: string;
  selected: DataSourceItem[];
  submitButtonLabel?: SubmitButtonLabel;
  subtitle?: string;
  searched?: boolean;
  title: string;
  qa?: string;
}

export const ItemSelectorDrawer: FC<Props> = ({
  answerSourceFields,
  dataSourceKey,
  handleChangeOpen,
  isOpen,
  name,
  onLoadMore,
  options,
  qa,
  ...formProps
}) => {
  const drawerTitleId = `${name}-drawerTitle`;

  const handleClose = () => {
    handleChangeOpen(false);
  };

  return (
    <>
      {/* MOBILE */}
      <MediaQuery maxWidth={scssVariables.maxPhone}>
        <Drawer
          anchor="bottom"
          className={styles.drawer}
          isOpen={isOpen}
          labelId={drawerTitleId}
          onClose={handleClose}
        >
          <ItemSelectorForm
            className={styles.form}
            handleClose={handleClose}
            name={name}
            options={options}
            onLoadMore={onLoadMore}
            qa={qa ? `${qa}-${ElementType.Drawer}` : undefined}
            {...formProps}
          />
        </Drawer>
      </MediaQuery>

      {/* TABLET / DESKTOP */}

      <MediaQuery minWidth={scssVariables.minTablet}>
        <Drawer
          anchor="right"
          className={styles.drawer}
          isOpen={isOpen}
          labelId={drawerTitleId}
          onClose={handleClose}
        >
          <ItemSelectorForm
            className={styles.form}
            handleClose={handleClose}
            name={name}
            options={options}
            onLoadMore={onLoadMore}
            qa={qa ? `${qa}-${ElementType.Drawer}` : undefined}
            {...formProps}
          />
        </Drawer>
      </MediaQuery>
    </>
  );
};
