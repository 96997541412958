import React, { FC, ReactNode, useEffect, useState } from "react";

import { CoreRedux, LatLng, Storm } from "@rtslabs/field1st-fe-common";
import styles from "./AssessmentsMapWidget.module.scss";
import { GlMap as MapComponent } from "shared/src/components/GlMap/GlMap";
import { Viewport } from "shared/src/components/GlMap/types";
import { setAssessmentMapMarker } from "./setAssessmentMapMarker";
import { Button } from "shared/src/components/Button/Button";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { AssessmentCounts } from "../../Dashboard/AssessmentCounts";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { AssessmentMapPinInfo } from "./AssessmentMapPinInfo";
import { ReassignAssessmentSidebar } from "../AssessmentActionMenu/AssessmentSidebarMenu/ReassignAssessmentSidebar";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { omit, some } from "lodash";
import AssessmentHistorySidebar from "../AssessmentActionMenu/AssessmentHistorySidebar/AssessmentHistorySidebar";
import { VerifyAssessmentSidebar } from "../AssessmentActionMenu/AssessmentSidebarMenu/VerifyAssessmentSidebar";
import { useSelector } from "react-redux";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { ResetAssessment } from "../Modals/ResetAssessment";
import {
  calculateZoom,
  getDistanceBetweenGeoLocations,
  getMidPoint,
} from "shared/src/util/geolocation";
import { LocationMarker } from "shared/src/api/geolocationAPI";

interface AssessmentsMapWidgetProps {
  assessments: Storm.PageOfStormDocumentExtensionVm | undefined;
  assessmentsRefresh: () => void;
  customContent: ReactNode;
  assessmentsFilterParams: Storm.API.StormDocumentRequestParameters;
  onPaginate: (page: number, size?: number) => void;
  loading: boolean;
  assessmentsCount: Storm.StormDocumentStateCountVm;
}

export type DrawerItemNames = "history" | "reassign" | "verify" | "reset";
export type DrawerItemsProps = {
  [key in DrawerItemNames]: boolean;
};

export const AssessmentsMapWidget: FC<AssessmentsMapWidgetProps> = ({
  assessments,
  assessmentsRefresh,
  assessmentsFilterParams,
  customContent,
  onPaginate,
  loading,
  assessmentsCount,
}) => {
  const [viewport, setViewport] = useState<Viewport>();
  const [showViewCount, setShowViewCount] = useState<boolean>(true);
  const [legend, setLegend] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [mapPinInfo, setMapPinInfo] = useState<ReactNode>();
  const [selectedAssessment, setSelectedAssessment] =
    useState<Storm.StormDocumentExtensionVm>();
  const [drawerItems, setDrawerItems] = useState<DrawerItemsProps>({
    history: false,
    reassign: false,
    verify: false,
    reset: false,
  });

  const documentGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "document")
  );

  const documentTerm = conjugateTerm({
    term: documentGroupTerm,
    role: "noun",
    modifier: "singular",
    fallback: "Assessment",
  });

  const changePageSize = (size: number) => {
    onPaginate(0, size);
  };

  /**
   * Get a calculated viewport that encompasses any pins currently placed on the map
   * @param locations - geo coordinates of all location responses in the widget
   */
  function getViewport(locations: LatLng[]): Viewport {
    const { latitude = 0, longitude = 0 } = getMidPoint(locations);
    return {
      width: "100%",
      height: "100%",
      center: { latitude, longitude },
      zoom: calculateZoom(getDistanceBetweenGeoLocations(locations)),
    };
  }

  const setFallbackViewpoint = () => {
    let initialViewport: Viewport = {
      width: "100%",
      height: "100%",
      center: {
        latitude: 37.54129,
        longitude: -77.43476,
      },
      zoom: 5,
    };
    setViewport(initialViewport);
  };

  let newViewPort: Viewport | undefined;
  let mappedMarkers: LocationMarker[] = [];
  if (assessments?.content.length) {
    // this just to make sure we have geolocation available and not cancalled to pin on the map
    const filteredAssessments = assessments.content.filter(
      (assessment) =>
        !!assessment.workLocation.geolocation &&
        assessment.assessmentStatus.currentState !==
          Storm.AssessmentState.CANCELLED
    );

    mappedMarkers = filteredAssessments.map((a) => setAssessmentMapMarker(a));

    const mappedLocations: LatLng[] = filteredAssessments.map((a) => ({
      latitude: a.workLocation.geolocation!.latitude,
      longitude: a.workLocation.geolocation!.longitude,
    }));

    if (mappedLocations.length) newViewPort = getViewport(mappedLocations); // temp bug fix until SAT-585
    !viewport && setFallbackViewpoint();
  }

  const bottomRight = (
    <div className={styles.buttonsContainer}>
      {showViewCount && (
        <Button className={joinClassNames(styles.button, styles.mapViewCount)}>
          <RowsPerPage
            pageSize={assessments?.pageable.pageSize}
            onClick={changePageSize}
            menuPlacement="top"
          />
        </Button>
      )}
      <Button
        className={joinClassNames(styles.button, legend && styles.active)}
        onClick={() => setLegend(!legend)}
      >
        Legend
      </Button>
      {legend && (
        <div className={styles.legendPane}>
          <AssessmentCounts documentCount={assessmentsCount} />
        </div>
      )}
      <Button
        className={joinClassNames(styles.button, fullScreen && styles.active)}
        onClick={() => setFullScreen(!fullScreen)}
      >
        Fullscreen
      </Button>
    </div>
  );

  const handleClickPin = (locationName: string) => {
    const assessment = assessments?.content.find(
      (a) => a.workLocation.name === locationName
    );

    if (assessment) {
      setSelectedAssessment(assessment);
      setMapPinInfo(
        <AssessmentMapPinInfo
          assessment={assessment}
          onClose={() => setMapPinInfo(null)}
          drawerItems={drawerItems}
          setDrawerItems={setDrawerItems}
        />
      );
    } else {
      setSelectedAssessment(undefined);
      setMapPinInfo(null);
    }
  };

  const handleCloseDrawer = (drawerItem: DrawerItemNames) => {
    setDrawerItems({ ...drawerItems, [drawerItem]: false });
    setMapPinInfo(null);
  };

  const keyPressFunction = (event: globalThis.KeyboardEvent) => {
    if (event.key === "Escape") {
      setFullScreen(false);
    }
  };

  useEffect(() => {
    if (fullScreen) {
      window.addEventListener("keyup", keyPressFunction);
    }

    return () => {
      window.removeEventListener("keyup", keyPressFunction);
    };
  }, [fullScreen]);

  useEffect(() => {
    if (
      [10, 50, 100].includes(assessments?.pageable.pageSize || 0) &&
      !assessmentsFilterParams.eventIds
    ) {
      setShowViewCount(true);
    } else {
      setShowViewCount(false);
    }
  }, [assessmentsFilterParams.eventIds, assessments?.pageable.pageSize]);

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  if (!newViewPort && !viewport) return null;
  return (
    <div className={styles.wrapper}>
      <div
        className={joinClassNames(styles.map, fullScreen && styles.fullscreen)}
      >
        <MapComponent
          initialViewport={newViewPort ? newViewPort : viewport!}
          markers={mappedMarkers}
          loading={loading}
          bottomLeft={mapPinInfo}
          bottomRight={bottomRight}
          customContent={customContent}
          onClickPin={handleClickPin}
          staticOnly
        />
      </div>
      <TableSummary
        pageSize={assessments?.size}
        currentPage={assessments?.number}
        totalElements={assessments?.totalElements}
        ofWhat="assessments"
        exports={["print", "xls", "csv"]}
        onExport={Storm.API.downloadAssessments}
        exportParams={assessmentsFilterParams}
      />
      {!!selectedAssessment && (
        <>
          <Drawer
            isOpen={some(omit(drawerItems, ["verify", "reset"]))}
            anchor={isDesktop ? "right" : "bottom"}
            onClose={() => false}
          >
            <AssessmentHistorySidebar
              assessment={selectedAssessment}
              open={drawerItems.history}
              onClose={() => handleCloseDrawer("history")}
              term={documentTerm}
              isMap
            />

            <ReassignAssessmentSidebar
              assessment={selectedAssessment}
              open={drawerItems.reassign}
              onCancel={() => handleCloseDrawer("reassign")}
              onClose={() => {
                handleCloseDrawer("reassign");
                assessmentsRefresh();
              }}
              isStandalone
            />
          </Drawer>
          <VerifyAssessmentSidebar
            assessment={selectedAssessment}
            open={drawerItems.verify}
            onClose={() => {
              handleCloseDrawer("verify");
              assessmentsRefresh();
            }}
          />
          <ResetAssessment
            assessment={selectedAssessment}
            open={drawerItems.reset}
            onClose={() => {
              handleCloseDrawer("reset");
              assessmentsRefresh();
            }}
          />
        </>
      )}
    </div>
  );
};
