import React, { FC } from "react";
import { ItemParams } from "shared/src/components/clientAdmin/formBuilder/create/Create";
import { AppWidgetProps } from "shared/src/components/clientAdmin/types";
import { PhotoGalleryWidget } from "shared/src/components/clientAdmin/formBuilder/create/content/widgets/PhotoGalleryWidget/PhotoGalleryWidget";
import { WidgetType } from "shared/src/qa-slugs";
import { MapWidget } from "./MapWidget";

export const appWidgetsList: ItemParams[] = [
  //   {
  //     name: "Map",
  //     type: "WIDGET",
  //     subType: "MAP",
  //     includeMap: false,
  //     questions: [],
  //   },
  //   {
  //     name: "Photo Gallery",
  //     type: "WIDGET",
  //     subType: "PHOTO_GALLERY",
  //     questions: [],
  //   },
];

export const AppWidgets: FC<AppWidgetProps> = ({ item, qaBase, siblings }) => {
  switch (item.subType) {
    case "MAP":
      return (
        <MapWidget
          qa={`${qaBase}-${WidgetType.Map}-${item.itemIndex}`}
          item={item}
          siblings={siblings}
          disableControls
        />
      );
    case "PHOTO_GALLERY":
      return (
        <PhotoGalleryWidget
          qa={`${qaBase}-${WidgetType.PhotoGallery}-${item.itemIndex}`}
        />
      );
    default:
      return null;
  }
};
