import {
  API,
  FormSummaryVm,
  PageOfWorkLocationDTO,
  Storm,
  WorkLocationDTO,
} from "@rtslabs/field1st-fe-common";
import { Form, Formik } from "formik";
import React, { Dispatch, FC, SetStateAction } from "react";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { Button } from "shared/src/components/Button/Button";
import { TabGroup } from "shared/src/components/Tabs/TabGroup/TabGroup";
import * as Yup from "yup";
import Loader from "shared/src/components/Loader/Loader";
import Header from "../EventView/Header/Header";
import { CloseEventModal } from "./CloseEvent/CloseEventModal";
import { EditEventActionButtons } from "./EditEventActionButtons";
import FormElements from "./FormElements/FormElements";
import SubstationsList from "./SubstationsList/SubstationsList";
import { useNavigate } from "react-router-dom";
import {
  buildCreateEventPayload,
  buildEditEventPayload,
} from "./editEvent.helpers";
import { STORM_CENTER_MANAGER_ROLES } from "../../../permissions/permissionSets";
import { CircularProgress } from "@mui/material";

type Props = {
  closeEventModalOpen: boolean;
  event: Storm.EventVm;
  eventWithDocStats: Storm.EventWithDocStatsVM | undefined;
  forms: FormSummaryVm[];
  id?: string;
  isEventLoading: boolean;
  isStationsListLoading: boolean;
  stations: WorkLocationDTO[];
  stationsListError?: string | undefined;
  handleModalClose: () => void;
  handleCloseEvent: (id: string) => Promise<void>;
  handleGetWorkLocations: () => Promise<PageOfWorkLocationDTO>;
  setError: (message: string) => void;
  userRoles: string[];
};

export const EditEvent: FC<Props> = ({
  closeEventModalOpen,
  event,
  eventWithDocStats,
  forms,
  id,
  isEventLoading,
  isStationsListLoading,
  stations,
  stationsListError,
  handleModalClose,
  handleCloseEvent,
  setError,
  userRoles,
}) => {
  const navigate = useNavigate();

  const handleSubmitForm = async (values: Storm.EventVm): Promise<void> => {
    let action;
    try {
      if (values.id) {
        await Storm.API.editEvent(buildEditEventPayload(values));
        action = "Saved";
      } else {
        await Storm.API.createEvent(buildCreateEventPayload(values));
        action = "Created";
      }
      const state = `Event "${values.eventName}" ${action}`;
      navigate(`/events`, { state });
    } catch (err: unknown) {
      process.env.NODE_ENV === "development" && console.warn(err);
      const message = `Unable to ${values.id ? "save" : "create"} event`;
      setError(message);
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      eventName: Yup.string().trim().required("Event Name cannot be blank"),
      formId: Yup.number()
        .test("Not equal to 0", "Form Type must be specified", (value) => {
          return value !== 0;
        })
        .required(),
      notifiedParticipantEmails: Yup.array().of(
        Yup.string().email((val) => {
          return `${val.value} is not a valid email format.`;
        })
      ),
    });

  const canManagerEdit = userRoles.some((r) =>
    STORM_CENTER_MANAGER_ROLES.includes(r)
  );

  return (
    <Loader loading={isEventLoading}>
      <Formik
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        initialValues={event}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ setFieldValue, isSubmitting, handleSubmit }) => (
          <Form id="mainContent">
            <Breadcrumbs
              paths={[
                { pathName: "Events", href: "/events" },
                {
                  pathName: id ? event.eventName! : "New Event",
                },
              ]}
            />

            {id && (
              <Header title={event.active ? "Edit Event" : "Reopen Event"}>
                <EditEventActionButtons
                  active={event.active}
                  isSubmitting={isSubmitting}
                  onClick={() => handleCloseEvent(id)}
                  handleReopenEvent={() => {
                    setFieldValue("active", true);
                    setFieldValue("endDate", undefined);
                  }}
                  handleSubmit={handleSubmit}
                  canManagerEdit={canManagerEdit}
                />
              </Header>
            )}

            {!id && (
              <Header title="New Event">
                <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
                  Create Event {isSubmitting && <CircularProgress size={16} />}
                </Button>
              </Header>
            )}

            <FormElements forms={forms} canManagerEdit={canManagerEdit} />

            <TabGroup
              variant="white"
              tabs={[
                {
                  label: "List",
                  tabId: "list",
                  tabPanelId: "list",
                  tabPanelContent: (
                    <SubstationsList
                      loading={isStationsListLoading}
                      error={stationsListError}
                      stations={stations}
                      initialSubstationIds={event.substationIds}
                    />
                  ),
                },
              ]}
            />
            <CloseEventModal
              open={closeEventModalOpen}
              submitting={isSubmitting}
              onCancel={handleModalClose}
              onSubmit={() => {
                setFieldValue("active", false);
                handleSubmit();
              }}
              event={eventWithDocStats}
            />
          </Form>
        )}
      </Formik>
    </Loader>
  );
};
