import { FC } from "react";
import { FormWidgetProps } from "shared/src/components/Document/DocumentForm/types";

type Prop = FormWidgetProps;

export const Widget: FC<Prop> = () => {
  /** Placeholder, This is where we can add more customized widgets for the app here
   * See Core's version of FormWidget to get yourself started and consistency
   */

  return null;
};
