import { Storm } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { AssessmentStateIndicator } from "../common/AssessmentStateIndicator";
import styles from "./AssessmentStateDisplay.module.scss";

interface AssessmentStateDisplay {
  actionRequired?: boolean;
  currentState: Storm.AssessmentState | "ACTION_REQUIRED";
  statusDetails?: string;
}

export const AssessmentStateDisplay: FC<AssessmentStateDisplay> = ({
  actionRequired,
  currentState,
  statusDetails,
}) => {
  return (
    <span className={styles.statusWrapper}>
      <AssessmentStateIndicator status={currentState} />
      <span className={styles.statusLabel}>
        <span>{Storm.AssessmentStateLabel[currentState]}</span>
        {!!statusDetails && <span> {statusDetails}</span>}
      </span>
      {!!actionRequired && (
        <>
          <AssessmentStateIndicator status={"ACTION_REQUIRED"} />
          <span className={styles.statusLabel}>
            <span>{Storm.AssessmentStateLabel["ACTION_REQUIRED"]}</span>
          </span>
        </>
      )}
    </span>
  );
};
