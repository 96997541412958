import {
  API,
  ApplicationConfigKey,
  CoreRedux,
  LoginFailureResponse,
  LoginVM,
} from "@rtslabs/field1st-fe-common";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PasswordLogin from "shared/src/components/auth/PasswordLogin";
import { useAppDispatch } from "../redux";

interface LocationState {
  redirect: string;
}

const LoginScreen = () => {
  const [error, setError] = useState<LoginFailureResponse | undefined>();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { redirect } = (location.state as LocationState) || {};

  const getUserData = async () => {
    try {
      const user = await dispatch(CoreRedux.getUser());

      await dispatch(
        CoreRedux.getApplicationConfigByName({
          keyName: ApplicationConfigKey.clientOverrides,
        })
      );
      await dispatch(
        CoreRedux.getApplicationConfigByName({
          keyName: ApplicationConfigKey.mainNavigation,
        })
      );
      await dispatch(
        CoreRedux.getApplicationConfigByName({
          keyName: ApplicationConfigKey.dashboardSidebar,
        })
      );
      // dispatch(getParticipantConfig("tutorial"));

      const termsConfig = await dispatch(
        CoreRedux.getClientGroupConfig({
          clientGroupId: user.primaryGroupId,
          keyName: "terms",
        })
      );
      const terms = termsConfig?.properties.terms;
      if (!terms || terms.length === 0) {
        await dispatch(CoreRedux.getAllDefaultTermDefinitions());
      }
    } catch (err) {
      console.log("error ", { err });
    }
  };

  const otherDispatch = useDispatch(); // I don't understand things - James
  const handleGetAdfsUserConfigs = async (
    adfsToken: string,
    redirectAfterLogin?: string | null
  ) => {
    await otherDispatch(CoreRedux.setAuthToken(adfsToken));
    API.Environment.authToken = adfsToken;
    try {
      await getUserData();
      navigate(`../${redirectAfterLogin || "/"}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async (values: LoginVM): Promise<void> => {
    setError(undefined);
    try {
      await dispatch(
        CoreRedux.getAuthToken({
          username: values.username?.replace(/\s/g, ""),
          password: values.password,
        })
      );
      if (API.Environment.isTokenValid()) {
        try {
          await getUserData();
        } catch (err: unknown) {
          console.error(err);
        }
        navigate(redirect || "/");
      } else {
        throw new Error("Invalid token");
      }
    } catch (err: unknown) {
      if (API.isLoginFailure(err)) {
        setError(err);
      } else {
        setError({
          accountLocked: false,
          message: "Something went wrong. Please try again.",
          recaptchaNeeded: false,
          resetPassword: false,
        });
      }
    }
  };

  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  return (
    <PasswordLogin
      onLogin={handleLogin}
      handleGetAdfsUserConfigs={handleGetAdfsUserConfigs}
      error={error}
      logo={logo}
      authType="SSO"
      redirect={redirect}
    />
  );
};

export default LoginScreen;
