import {
  API,
  DataSourceType,
  DataSourceValueDTO,
  DocumentQuestionResponseVm,
  DocumentVm,
  LatLng,
  MapWidgetQuestionDTO,
  QuestionDTO,
  ResponseContent,
  WorkLocationDTO,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import React, { FC, useMemo } from "react";
import {
  geocode,
  getCurrentPosition,
  reverseGeocode,
} from "../../../../api/geolocationAPI";
import {
  createOrUpdateResponse,
  deleteResponse,
} from "../../../../api/responseRequests";
import { userClickedSuggestion } from "../../../../helpers/field.helpers";
import scssVariables from "../../../../sass/jsExports.module.scss";
import { INPUT_SEARCH_DELAY } from "../../../../util/debounceDelays";
import { formatGeolocation } from "../../../../util/geolocation";
import { Icon } from "../../../Icon/Icon";
import { DataSourceItem } from "../../../ItemSelectorDrawer/ItemSelectorForm";
import TextInputWithSuggestions from "../../../TextInput/TextInputWithSuggestions";
import { AssistiveLink } from "../../../TextInput/types";
import DataSourceDrawer from "../DataSourceDrawer";
import { useQuestion } from "../useQuestion";

interface Props {
  question: QuestionDTO | (QuestionDTO & MapWidgetQuestionDTO);
}

export const LocationField: FC<Props> = ({ question }) => {
  const { values } = useFormikContext<DocumentVm>();
  const { onBlur, response, error, qa, setResponses, shouldUpdateForm } =
    useQuestion(question);
  const [locationStatus, setLocationStatus] = React.useState<
    "initial" | "loading" | "failed" | "success"
  >("initial");
  const [searchDrawerOpen, setSearchDrawer] = React.useState<boolean>(false);
  const responseId = response?.id;
  const locationValue = response?.answer || "";

  const fieldError =
    error ||
    (locationStatus === "failed"
      ? "Error: Please enter a valid location"
      : undefined);

  const isDetailedSearchEnabled =
    question.answerSource?.properties?.detailedSearch?.enabled;

  const clearResponseAndResetStatus = async () => {
    if (!shouldUpdateForm && responseId)
      await deleteResponse(values.id, responseId);
    await setResponses([]);
    setLocationStatus("initial");
  };

  /**
   * Set a location response when user selects an option in the location drawer or finds their current geolocation
   */
  async function setLocationResponse(
    answer: Omit<
      DocumentQuestionResponseVm,
      "timeAnswered" | "questionId" | "questionRootId"
    >,
    content?: ResponseContent | null
  ) {
    if (!answer.answer) {
      if (!shouldUpdateForm && responseId)
        await deleteResponse(values.id, responseId);
      return await setResponses([]);
    }
    const updatedResponse = {
      ...answer,
      id: responseId,
      questionId: question.id,
      questionRootId: question.rootId,
      timeAnswered: moment.utc().format(),
    };
    const newResponse = shouldUpdateForm
      ? updatedResponse
      : await createOrUpdateResponse(values.id, updatedResponse);
    await setResponses([newResponse], content);
  }

  /**
   * Attempt to retrieve a geolocation using the value that the user has entered into the field
   */
  async function getGeolocation(value?: string) {
    setLocationStatus("initial");

    // prevent action when user hasn't changed value
    // or if we already have a successful geolocation value
    if (
      !value ||
      value.length < 3 ||
      (value === response?.answer && !!response.associatedLocation)
    ) {
      return null;
    }

    setLocationStatus("loading");
    try {
      const location = await geocode(value);
      setLocationStatus("success");
      return location;
    } catch (err) {
      setLocationStatus("failed");
      throw err;
    }
  }

  /** Get the coordinates of the user and attempt to get the nearest address - then set either as the response value */
  async function getCurrentLocation() {
    if (locationStatus !== "loading") {
      setLocationStatus("loading");

      let geolocation: LatLng | undefined;

      const position = await getCurrentPosition();
      // if coordinates were found for the user's location
      if ("coords" in position && position.coords) {
        geolocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        // use the user's coordinates to get the nearest address
        const reverseGeo = await reverseGeocode(geolocation);
        const answer =
          reverseGeo || (geolocation && formatGeolocation(geolocation)) || "";
        // set value in state
        // set the response to the nearest address to the user or coordinates if none found
        setLocationResponse({
          answer,
          associatedLocation: geolocation,
        });
        setLocationStatus("success");
        // coordinates were not found for the user's location
      } else {
        setLocationStatus("failed");
      }
    }
  }

  /** Retrieve an address and set it in form responses when field is blurred */
  async function setResponse(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): Promise<void> {
    const response = event.target.value;
    if (response === "") {
      await setResponses([]);
      return;
    }
    const geolocation = await getGeolocation(response);
    if (geolocation) {
      setLocationResponse({
        answer: geolocation.address,
        id: responseId,
        associatedLocation: geolocation.geolocation,
      });
    }
  }

  const assistiveLink: AssistiveLink = useMemo(() => {
    if (!question.answerSource?.properties?.detailedSearch?.enabled) {
      return { label: "" };
    }

    if (locationStatus === "loading") {
      return {
        label: "...loading",
        color: scssVariables.darkGrey,
      };
    }

    const label =
      question.answerSource?.properties?.detailedSearch?.linkName ?? "";
    const fieldIsGPSEnabled = question.answerSource?.type === "CURRENT_GPS";

    if (fieldIsGPSEnabled) {
      if (response?.answer) {
        return {
          label: "Clear",
          color: scssVariables.error,
          onClick: clearResponseAndResetStatus,
        };
      } else {
        return {
          label,
          onClick: getCurrentLocation,
        };
      }
    }

    return {
      label,
      onClick: () => setSearchDrawer(true),
    };
  }, [locationStatus, question.answerSource, response?.answer]);

  const handleClose = () => {
    setSearchDrawer(false);
  };

  const selectedItem = response?.associatedId
    ? [
        {
          id: response.associatedId,
          title: response.answer,
          associatedLocation: response.associatedLocation,
        },
      ]
    : undefined;

  /**
   * Data Sources
   */
  const [suggestions, setSuggestions] = React.useState<
    DataSourceValueDTO<WorkLocationDTO>[]
  >([]);
  const dataSourceKey = question.answerSource?.dataSourceKey;
  async function getSuggestions(query: string) {
    if (dataSourceKey && query.length >= 3) {
      try {
        const sort =
          question.answerSource?.properties?.detailedSearch?.infiniteListSortBy;

        // Assumes all non-null dataSourceKeys are WORK_LOCATION for all LOCATION questions
        const res = await API.getDataSourceValues<WorkLocationDTO>({
          dataSourceKey: DataSourceType.WORK_LOCATION,
          query,
          sort,
          page: 0,
          size: 50,
        });

        setSuggestions(res.content);
      } catch (err) {
        console.error(err);
      }
    } else {
      setSuggestions([]);
    }
  }
  const getSuggestionsDebounced = debounce(getSuggestions, INPUT_SEARCH_DELAY, {
    trailing: true,
  });

  function handleSelectSuggestion(
    suggestion: DataSourceValueDTO<WorkLocationDTO>
  ) {
    const workLocation = suggestion.content;
    setLocationResponse(
      {
        answer: workLocation.name,
        associatedId: workLocation.id,
        associatedLocation: workLocation.geolocation,
      },
      suggestion
    );
    setSuggestions([]);
  }

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    if (!userClickedSuggestion(event)) {
      setSuggestions([]);
      setResponse(event);
    }
    onBlur();
  }

  function handleChange(textValue: string): void {
    if (textValue) {
      // Cancel any delayed function invocations
      getSuggestionsDebounced.cancel();

      // Invoke the function again with the latest arguments
      getSuggestionsDebounced(textValue);
    }
    setLocationResponse({ answer: textValue });
  }

  return (
    <>
      {question.answerSource && (
        <DataSourceDrawer
          isOpen={searchDrawerOpen}
          answerSource={question.answerSource}
          onChangeOpen={handleClose}
          onSubmit={(values: DataSourceItem[]) => {
            values.forEach((val) => {
              setLocationResponse(
                {
                  answer: val.title,
                  associatedId: Number(val.id),
                  associatedLocation: val.associatedLocation,
                },
                val.content
              );
            });
            handleClose();
          }}
          allowMultipleAnswers={question.properties?.allowMultipleAnswers}
          qa="workLocation"
          selected={selectedItem}
          submitButtonLabel={{
            prefix: "Add",
            label: "Work Location",
          }}
        />
      )}

      <TextInputWithSuggestions
        assistiveLink={assistiveLink}
        disabled={locationStatus === "loading"}
        elementBefore={
          <Icon color={question.properties?.pinColor} type="marker" size={38} />
        }
        error={fieldError}
        assistiveText={
          isDetailedSearchEnabled
            ? question.properties?.assistiveText
            : undefined
        }
        required={!!question.formProperties?.isRequired}
        label={question.title}
        name={`${question.id}`}
        placeholder={question.properties?.placeHolderText || question.title}
        onBlur={handleBlur}
        onInputChange={handleChange}
        value={locationValue}
        idField={"id"}
        labelField={"content.name"}
        suggestions={suggestions}
        onSelectSuggestion={handleSelectSuggestion}
        qa={`${qa}-location`}
        response={response}
      />
    </>
  );
};
