import { CoreRedux, Storm } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "shared/src/components/Icon/Icon";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { AssessmentActionMenuHeader } from "../AssessmentActionMenu/AssessmentActionMenuHeader/AssessmentActionMenuHeader";
import { isActionAllowed } from "../helpers";
import { DrawerItemNames, DrawerItemsProps } from "./AssessmentsMapWidget";
import styles from "./AssessmentsMapWidget.module.scss";

interface AssessmentMapPinInfoProps {
  assessment: Storm.StormDocumentExtensionVm;
  onClose: () => void;
  drawerItems: DrawerItemsProps;
  setDrawerItems: Dispatch<SetStateAction<DrawerItemsProps>>;
}

interface ActionButtonProps {
  actionIcon: string;
  actionText: string;
  onClick: () => void;
}

export const AssessmentMapPinInfo: FC<AssessmentMapPinInfoProps> = ({
  assessment,
  onClose,
  drawerItems,
  setDrawerItems,
}) => {
  const navigate = useNavigate();
  const documentGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "document")
  );

  const documentTerm = conjugateTerm({
    term: documentGroupTerm,
    role: "noun",
    modifier: "singular",
    fallback: "Assessment",
  });

  const isEventActive = assessment.event.active;

  const showAssignLink =
    isEventActive &&
    (isActionAllowed([assessment], Storm.AssessmentAction.UNASSIGN) ||
      isActionAllowed([assessment], Storm.AssessmentAction.ASSIGN) ||
      isActionAllowed([assessment], Storm.AssessmentAction.REASSIGN));

  const showVerifyLink =
    isEventActive &&
    isActionAllowed([assessment], Storm.AssessmentAction.COMPLETE_REVIEW);

  const showResetLink =
    isEventActive &&
    isActionAllowed([assessment], Storm.AssessmentAction.RESET);

  const handleNavigateToAssessment = useCallback(
    () => navigate(`/${documentTerm.toLowerCase()}/${assessment.id}`),
    [navigate, assessment.id]
  );

  const handleNavigateToAssessmentReview = useCallback(
    () => navigate(`/${documentTerm.toLowerCase()}/review/${assessment.id}`),
    [navigate, assessment.id]
  );

  const substationInfo = (
    <AssessmentActionMenuHeader assessment={assessment} onClose={onClose} />
  );

  const handleButtonClick = (drawerItem: DrawerItemNames) => {
    setDrawerItems({ ...drawerItems, [drawerItem]: true });
  };

  const ActionButton: FC<ActionButtonProps> = ({
    actionIcon,
    actionText,
    onClick,
  }) => (
    <div className={styles.action} onClick={onClick}>
      <div className={styles.actionIcon}>
        <Icon size={20} type={actionIcon} />
      </div>
      <div className={styles.actionText}>{actionText}</div>
    </div>
  );

  return (
    <div className={styles.mapPinInfo}>
      <div className={styles.substationInfo}>{substationInfo}</div>
      <div className={styles.actionsContainer}>
        <ActionButton
          actionIcon="document-2"
          actionText="View"
          onClick={handleNavigateToAssessment}
        />
        <ActionButton
          actionIcon="history"
          actionText="History"
          onClick={handleButtonClick.bind(this, "history")}
        />
        {/* <ActionButton actionIcon="document-2" actionText="Photos" /> */}
        {showAssignLink && (
          <ActionButton
            actionIcon="assign"
            actionText="Assign"
            onClick={handleButtonClick.bind(this, "reassign")}
          />
        )}
        <ActionButton
          actionIcon="review"
          actionText="Review"
          onClick={handleNavigateToAssessmentReview}
        />
        {showVerifyLink && (
          <ActionButton
            actionIcon="verify"
            actionText="Verify"
            onClick={handleButtonClick.bind(this, "verify")}
          />
        )}
        {/* <ActionButton
          actionIcon="action-required"
          actionText="Action Required"
        /> */}
        {/* <ActionButton
          actionIcon="export_pdf"
          actionText="PDF"
        /> */}
        {showResetLink && (
          <ActionButton
            actionIcon="reset"
            actionText="Reset"
            onClick={handleButtonClick.bind(this, "reset")}
          />
        )}
      </div>
    </div>
  );
};
