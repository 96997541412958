import React, { FC } from "react";
import {
  StormDocumentExtensionVm,
  StormParticipantVm,
} from "@rtslabs/field1st-fe-common/storm";
import { Modal } from "shared/src/components/Modal/Modal";

interface Props {
  open: boolean;
  closeModal: () => void;
  selected: StormDocumentExtensionVm[];
  onConfirm: () => void;
}
interface AssignProps extends Props {
  assessor: StormParticipantVm;
}

export const AssignModal: FC<AssignProps> = ({
  open,
  closeModal,
  selected,
  assessor,
  onConfirm,
}) => {
  const contentString =
    selected.length > 1
      ? `${selected.length} substations to ${assessor.participant.firstName}`
      : `${selected[0].workLocation.name} to ${assessor.participant.firstName}`;
  return (
    <Modal
      title="Confirm Assignment"
      open={open}
      handleClose={closeModal}
      cancellable
      action={{
        text: "Confirm",
        loading: false,
        callback: onConfirm,
      }}
      content={
        <div>
          <span>{`Do you want to assign ${contentString}?`}</span>
        </div>
      }
    />
  );
};

export const UnassignModal: FC<Props> = ({
  open,
  closeModal,
  selected,
  onConfirm,
}) => {
  const contentString =
    selected.length > 1
      ? `${selected.length} substations`
      : `${selected[0].workLocation.name}`;
  return (
    <Modal
      title="Confirm Unassignment"
      open={open}
      handleClose={closeModal}
      cancellable
      action={{
        text: "Confirm",
        loading: false,
        callback: onConfirm,
      }}
      content={
        <div>
          <span>{`Do you want to unassign ${contentString}?`}</span>
        </div>
      }
    />
  );
};
